import React from 'react'
import MissleAnim from "../../assets/json/missle-final.json";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import './homeMobile.scss'
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../../utils/RSS';
import Withdraw from '../../assets/images/withdraw.svg'
import Deposit from '../../assets/images/deposit.svg'
import WithdrawMoney from '../../assets/images/withdrawmoney.svg'
import DepositMoney from '../../assets/images/depositmoney.svg'
import ResponsiveAppBar from './NavbarMobile';
import MarketInHomeMobile from './MarketinhomeMobile';
import HomeFooterMobile from './HomeFooterMobile';
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile';
import AndroidIcon from "@mui/icons-material/Android";
import PlayStore from '../../assets/images/playstore.png'
import Myket from '../../assets/images/myket.png'
import SibApp from '../../assets/images/sibapp.png'
import { useState } from 'react';
import { useEffect } from 'react';

function HomeMobile() {
    const navigate = useNavigate();
    const [appLink, setAppLink] = useState('')

    useEffect(() => {
        fetch("https://s3.terixo.com/public/android/applink.json?nocache=" + Date.now())
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setAppLink(data[0].link);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const navigateToPage = (address) => {
        navigate(address)
        window.scrollTo(0, 0)
    }

    return (
        <div className='home-mobile-main p-0 m-0'>
            <div className="container-fluid ">
                <NavbarTopMobile />
                <div className="row justify-content-center p-0 m-0">
                    <div className="col-11">
                        <Player
                            autoplay
                            loop
                            src={MissleAnim}
                            style={{ maxHeight: "600px", maxWidth: "400px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </div>
                </div>
                <div className="row justify-content-center p-0 m-0">
                    <div className="col-11 mt-5 text-center">
                        <h5 className='home-top-texts'>تریکسو پلتفرمی برای هر ایرانی</h5>
                        <h6 className="mt-3 home-top-texts">اولین درگاه اختصاصی خرید و فروش آنی <span className="tethercolor">تتر</span></h6>
                        {isLoggedIn() ? "" :
                            <Button className="h-top-btn" onClick={() => navigateToPage('/login')}>
                                ورود/ثبت نام
                            </Button>
                        }
                    </div>
                </div>
                <div className="row justify-content-center mt-4">
                    <button className='col-3 btn-top-icon p-0 m-0' onClick={() => navigateToPage('/withdraw#1')}>
                        <div className=" text-center p-0 m-0">
                            <img src={WithdrawMoney} alt="" height={40} />
                            <p className='text-btn-home-icons mt-1'>برداشت تومان</p>
                        </div>
                    </button>
                    <button className='col-3 btn-top-icon p-0 m-0' onClick={() => navigateToPage('/deposit#1')}>
                        <div className=" text-center">
                            <img src={DepositMoney} alt="" height={40} />
                            <p className='text-btn-home-icons mt-1'>واریز تومان</p>
                        </div>
                    </button>
                    <button className='col-3 btn-top-icon p-0 m-0' onClick={() => navigateToPage('/withdraw')}>
                        <div className="text-center">
                            <img src={Withdraw} alt="" height={40} />
                            <p className='text-btn-home-icons mt-1'>برداشت رمز ارز</p>
                        </div>
                    </button>
                    <button className='col-3 btn-top-icon p-0 m-0' onClick={() => navigateToPage('/deposit')}>
                        <div className="text-center p-0 m-0">
                            <img src={Deposit} alt="" height={40} />
                            <p className='text-btn-home-icons mt-1'>واریز رمز ارز</p>
                        </div>
                    </button>
                </div>
                <div className="row market-mobile-home">
                    <div className="row justify-content-center p-0 m-0">
                        <MarketInHomeMobile />
                        <div className="col-3 btn-home-market">
                            <Button className='mt-2 market-table-btn2' onClick={() => navigateToPage('/market')}>ادامه</Button>
                        </div>
                    </div>
                </div>
                <div className="container-fluid application-section-mobile">
                    <div className="container">
                        <div className="row py-5 align-items-center">
                            <div className="col-lg-7 mb-3 mt-5">
                                <div className="hlapp">
                                    <h6 >جامع ترین اپلیکیشن ارزهای دیجیتال</h6>
                                </div>
                                <div className="ulapp">
                                    <ul>
                                        <li className="appli">
                                            در هر زمان و مکان با اپلیکیشن حرفه ای تریکسو دارایی های خود را
                                            مدیریت و کنترل نمایید
                                        </li>
                                    </ul>
                                </div>
                                <div className="row pt-3 app-section">
                                    <div className="col-6 btn-app-main">
                                        <a className="app-btns" href={appLink} target="blank">
                                            {" "}
                                            <AndroidIcon className="btn-icon-app" />
                                            دانلود مستقیم Android
                                        </a>
                                    </div>
                                    <div className="col-6 btn-app-main">
                                        <a className="app-btns" href='https://play.google.com/store/apps/details?id=terixo.com.terixo.terixo' target="blank">
                                            <img src={PlayStore} alt="" height={30} />  پلی استور Android
                                        </a>
                                    </div>
                                </div>
                                <div className="row   app-section">
                                    <div className="col-6 btn-app-main">
                                        <a className="app-btns" href='https://myket.ir/app/terixo.com.terixo.terixo' target="blank">
                                            <img src={Myket} alt="" height={30} />     مایکت Android
                                        </a>
                                    </div>
                                    <div className="col-6 btn-app-main">
                                        <a className="app-btns" href='https://sibapp.com/applications/TerixoPlatform' target="blank">
                                            <img src={SibApp} alt="" height={30} />     سیب‌اپ Iphone
                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row footer-mobile-home">
                    <div className="row justify-content-center p-0 m-0">
                        <HomeFooterMobile />
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeMobile
