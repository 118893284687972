import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import OpenHistoryTable from "./OpenHistoryTableC";
import { grey } from "@mui/material/colors";
import { SocketContext } from "../../../../contexts/providers/socket";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function OpenOrderModule() {
  const [value, setValue] = useState(0);
  const { stateIO } = React.useContext(SocketContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="open-order-main">
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={<Typography fontSize={12}>سفارشات باز</Typography>}
              {...a11yProps(0)}
              sx={{ color: grey[500] }}
            />
            <Tab
              label={<Typography fontSize={12}>تاریخچه سفارش ها</Typography>}
              {...a11yProps(1)}
              sx={{ color: grey[500] }}
            />
            <Tab
              label={<Typography fontSize={12}>سابقه معاملات</Typography>}
              {...a11yProps(1)}
              sx={{ color: grey[500] }}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} className="openorder-list">
          <OpenHistoryTable orders={stateIO.user_orders.open} type={"open"} />
        </TabPanel>
        <TabPanel value={value} index={1} className="order-history-list">
          <OpenHistoryTable
            orders={stateIO.user_orders.matches}
            type={"match"}
          />
        </TabPanel>
        <TabPanel value={value} index={2} className="trade-history-list">
          <OpenHistoryTable
            orders={stateIO.user_orders.orders}
            type={"trade"}
          />
        </TabPanel>
      </Box>
    </div>
  );
}
