import React from 'react'
import '../transaction/deposit/deposit.scss'

function TransactionHistoryEach(props) {

    return (
        <div className='deposit-history-main-each'>
            <div className="container-fluid mb-3">
                <div className="row">
                    <div className="col-2">
                        <img src={props.image} alt="token logo" height={25} style={{ marginBottom: -60 }} />
                    </div>
                    <div className="col-12 text-center mt-2 success-text">
                        <p style={{ direction: 'ltr' }}> {props.amount}{" "}{props.symbol}</p>
                    </div>
                    <div className="col-12 text-center" style={{ marginTop: -15, color: props.amount >= 0 ? '#00fe22' : '#ff276f' }}>
                        <p>  {props.status}  ({props.txid}) </p>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-6">
                        <p>تاریخ و زمان</p>
                    </div>
                    <div className="col-6 text-start">
                        <p>{props.time}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionHistoryEach
