import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import TradeSlider from "./SliderB";
import { ItemNames, getStorage, isLoggedIn } from "../../../../utils/RSS";
import { sendRequestEmit } from "../../../../contexts/reducer/socket";
import { UserContext } from "../../../../contexts/providers/user";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { getCurrencyDecimals, toFixed } from "../../../../utils/math";
import { createApiAddress } from "../../../../constant/api.constant";

function StopLimit({ side = "sell", from, to, balance_to, balance_from, last_price }) {
  const [stopForm, setStopForm] = useState({
    stoplimit: 0,
    price: 0,
    amount: 0,
    total: 0,
  });
  const { tradePair } = useParams();
  const tokenName = tradePair.split("_")[0];
  const secondTokenName = tradePair.split("_")[1];

  const inputChange = (name) => {
    return (event) => {
      const form = stopForm;
      form[name] = event.target.value;
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      const decimalIndex = value.indexOf(".");

      switch (name) {
        case "stoplimit":
          if (decimalIndex !== -1) {
            const decimals = getCurrencyDecimals(tokenName, "price");
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }
          break;
        case "price":
          if (decimalIndex !== -1) {
            const decimals = getCurrencyDecimals(tokenName, "price");
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }

          break;
        case "amount":
          if (decimalIndex !== -1) {
            const decimals = getCurrencyDecimals(tokenName, "amount");
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }
          setTimeout(() => {
            form.total = `${toFixed(form.price * form.amount, getCurrencyDecimals(secondTokenName, "total"))}`;
            setStopForm({ ...form });
          }, 50)
          break;
        case "total":
          if (decimalIndex !== -1) {
            const decimals = state.currency.details[to].total_decimal || 4;
            const decimalPlaces = value.length - decimalIndex - 1;
            if (decimalPlaces > decimals) {
              value = value.slice(0, decimalIndex + decimals + 1);
            }
          }
          setTimeout(() => {
            form.amount = `${toFixed(form.total / form.price, getCurrencyDecimals(tokenName, "amount"))}`;
            setStopForm({ ...form });
          }, 50)
          break;
        default:
          if (form.amount === 0 && form.total !== 0) {
            setTimeout(() => {
              form.amount = `${toFixed(form.total / form.price, getCurrencyDecimals(tokenName, "amount"))}`;
              setStopForm({ ...form });
            }, 50)
          } else {
            setTimeout(() => {
              form.total = `${toFixed(form.price * form.amount, getCurrencyDecimals(secondTokenName, "total"))}`;
              setStopForm({ ...form });
            }, 50)
          }
          break;
      }
      form[name] = value;
      setStopForm({ ...form });
    };
  };

  const slideChange = (e) => {
    let percentage = e;
    let amount_calculated = balance_to * (percentage / 100);
    let total_calculated = balance_from * (percentage / 100);
    if (side === "buy") {
      setStopForm({
        ...stopForm,
        total: toFixed(amount_calculated, getCurrencyDecimals(secondTokenName, "total")),
        amount: toFixed(amount_calculated / stopForm.price, getCurrencyDecimals(tokenName, "amount")),
      });
    } else {
      setStopForm({
        ...stopForm,
        amount: toFixed(total_calculated, getCurrencyDecimals(tokenName, "amount")),
        total: toFixed(total_calculated * stopForm.price, getCurrencyDecimals(secondTokenName, "amount")),
      });
    }
  };



  const sendStopLimitRequest = () => {
    setIsloading(true);
    let data = {
      amount: stopForm.amount,
      price: stopForm.price,
      stoplimit: stopForm.stoplimit,
      pair: `${from}_${to}`,
      order: "stoplimit",
      type: side,
      trading_pwd: state.trading_password,
      token: getStorage(ItemNames.TOKEN),
      device: "web",
    };
    sendRequestEmit("trade:stoplimit", data);
    setStopForm({
      stoplimit: 0,
      price: 0,
      amount: 0,
      total: 0,
    });
    setIsloading(false);
  };

  const navigate = useNavigate();


  const goToLoginPage = () => {
    navigate("/login");
  };

  const goToProfilePage = () => {
    navigate("/profile#1");
  };

  const checkBTNState = () => {
    if (isLoggedIn() === true) {
      if (state?.user_profile?.security?.password?.trading.status === true && state.trading_password === '') {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={handleShowTPWDinput}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "رمز معاملاتی را وارد کنید"}
          </Button>
        );
      } else if (state?.user_profile?.security?.password?.trading.status === false) {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={goToProfilePage}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "رمز معاملاتی را فعال کنید"}
          </Button>
        );
      } else {
        return (
          <Button
            variant="success"
            className={side === "sell" ? "btn-limit-sell" : "btn-limit-buy"}
            onClick={warningHandlerStoplimit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : `${side === "sell" ? "فروش" : "خرید"}`}
          </Button>
        );
      }
    } else if (isLoggedIn() === false) {
      return (
        <Button
          variant="success"
          className="btn-limit-buy"
          onClick={goToLoginPage}
        >
          ورود/ثبت نام
        </Button>
      );
    }
  };

  const [isLoading, setIsloading] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [showTPWDstate, setShowTPWDstate] = useState(false);
  const handleCloseTPWDstate = () => setShowTPWDstate(false);
  const [showTPWDinput, setShowTPWDinput] = useState(false);
  const handleCloseTPWDinput = () => setShowTPWDinput(false);
  const handleShowTPWDinput = () => setShowTPWDinput(true);

  const [tradingPassword, setTradingPassword] = useState(null);

  const handleChangeTradingPassword = (event) => {
    setTradingPassword(event.target.value);
  };

  const goToTPWDpage = () => {
    navigate("/profile");
  };

  const checkTradingPwdValidity = () => {
    if (tradingPassword === null || tradingPassword === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "لطفا رمز عبور خود را وارد کنید",
      });
    } else {
      conFirmTPWD();
    }
  };
  const conFirmTPWD = () => {
    setIsloading(true);
    var uri = createApiAddress("api.CONFIRM_TRADEPASS");
    var data = { password: tradingPassword };
    fetch(uri, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          dispatch({ type: "set:trading_pass", password: tradingPassword });
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: "رمز با موفقیت ثبت شد",
          });
          handleCloseTPWDinput();
          setIsloading(false);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: "رمز اشتباه است",
          });
          setIsloading(false);
        }
      });
  };

  const minimumOrderLimit = 5;
  const balanceToDecimaled = `${toFixed(balance_to, getCurrencyDecimals(secondTokenName, "amount"))}`
  const balanceFromDecimaled = `${toFixed(balance_from, getCurrencyDecimals(tokenName, "amount"))}`

  const warningHandlerStoplimit = () => {
    if (
      stopForm.price === "" ||
      stopForm.price === null ||
      stopForm.price === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "قیمت الزامی است",
      });
    } else if (
      stopForm.amount === "" ||
      stopForm.amount === null ||
      stopForm.amount === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "مبلغ مورد نیاز است",
      });
    } else if (
      stopForm.stoplimit === "" ||
      stopForm.stoplimit === null ||
      stopForm.stoplimit === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "قیمت استاپ الزامی است",
      });
    } else if (
      stopForm.total === "" ||
      stopForm.total === null ||
      stopForm.total === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "مجموع مورد نیاز است",
      });
    } else if (stopForm.total < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "سفارش باید بالاتر از 5 USDT باشد",
      });
    } else if (side === "sell" && stopForm.amount > balance_from) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `مقدار ناکافی است، شما ${balanceFromDecimaled} ${from} دارید`,
      });
    } else if (side === "buy" && stopForm.total > balance_to) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `مقدار ناکافی است، شما ${balanceToDecimaled} ${to} دارید`,
      });
    } else if (side === "buy" && stopForm.stoplimit <= last_price) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: 'قیمت استاپ باید بیشتر از قیمت بازار باشد',
      });
    } else if (side === "sell" && stopForm.stoplimit >= last_price) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: 'قیمت استاپ باید کمتر از قیمت بازار باشد',
      });
    } else {
      sendStopLimitRequest();
    }
  };
  return (
    <div className="limit-buy-main">
      <div className="row  ">
        <div className="col available-section">
          <p>موجودی:</p>
          <p>
            {side === "sell"
              ? `${toFixed(balance_from, getCurrencyDecimals(tokenName, "amount"))} ${from}`
              : `${toFixed(balance_to, getCurrencyDecimals(secondTokenName, "amount"))} ${to}`
            }
          </p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputGroup className="mb-1">
            <InputGroup.Text>استاپ</InputGroup.Text>
            <Form.Control
              aria-label="stopprice"
              name="stoplimit"
              value={stopForm.stoplimit}
              onChange={inputChange("stoplimit")}
            />
            <InputGroup.Text>{to}</InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-1">
            <InputGroup.Text>لیمیت</InputGroup.Text>
            <Form.Control
              aria-label="stoplimitprice"
              name="price"
              value={stopForm.price}
              onChange={inputChange("price")}
            />
            <InputGroup.Text>{to}</InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-1">
            <InputGroup.Text>مقدار</InputGroup.Text>
            <Form.Control
              aria-label="amount"
              name="amount"
              value={stopForm.amount}
              onChange={inputChange("amount")}
            />
            <InputGroup.Text>{from}</InputGroup.Text>
          </InputGroup>
          <div className="row">
            <div className="col slider-trade">
              <TradeSlider onchange={slideChange} />
            </div>
          </div>
          <InputGroup className=" ">
            <InputGroup.Text>کل</InputGroup.Text>
            <Form.Control
              aria-label="price"
              name="total"
              value={stopForm.total}
              onChange={inputChange("total")}
            />
            <InputGroup.Text>{to}</InputGroup.Text>
          </InputGroup>

          <div className="col">{checkBTNState()}</div>
        </div>
      </div>
      <Modal
        show={showTPWDstate}
        onHide={handleCloseTPWDstate}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>لطفا رمز معاملاتی را فعال کنید</Modal.Title>
        </Modal.Header>
        <ModalFooter>
          <Button className="cancel-withdraw" onClick={handleCloseTPWDstate}>
            لغو
          </Button>
          <Button className="confirm-withdraw" onClick={goToTPWDpage}>
            به صفحه امنیت بروید
          </Button>
        </ModalFooter>
      </Modal>
      <>
        <Modal
          show={showTPWDinput}
          onHide={handleCloseTPWDinput}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>رمز معاملاتی خود را وارد کنید</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <FormControl fullWidth>
              <TextField
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="tradePass"
                placeholder="رمز معاملاتی"
                size="medium"
                type="password"
                name="trading_password"
                onChange={handleChangeTradingPassword}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
                inputProps={{
                  maxLength: 6,

                  form: {
                    autocomplete: "off",
                  },
                }}
              />
            </FormControl>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseTPWDinput}>
              لغو
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={checkTradingPwdValidity}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? "بارگذاری..." : "تایید"}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
}

export default StopLimit;
