import { createContext, useContext, useState } from "react";

const OrderContext = createContext();

export const OrderProvider = ({ children }) => {
    const [clickedOrderData, setClickedOrderData] = useState(null);

    return (
        <OrderContext.Provider value={{ clickedOrderData, setClickedOrderData }}>
            {children}
        </OrderContext.Provider>
    );
};

export const useOrderContext = () => {
    return useContext(OrderContext);
};
