import React from "react";
import { Button } from "react-bootstrap";
import Angular1 from "../../assets/images/triangulars-twoR.svg";
import Angular2 from "../../assets/images/triangulars-twoL.svg";
import { useNavigate } from "react-router-dom";

function FirstThreeMiddle() {
  const navigate = useNavigate();
  const goToVideos = () => {
    navigate("/learning");
  };

  const goToCom = () => {
    navigate("/commission");
  };
  return (
    <div className="first-middle-main">
      <div className="container-fluid">
        <div className="row justify-content-center   bot-mid-main">
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 bot-mid ">
            <img src={Angular1} alt="" />
            <div className="btn-two">
              <Button className="btn-two-style" onClick={goToCom}>
                ادامه
              </Button>
            </div>
          </div>
          <div className="col-10 col-md-6 col-lg-4 col-xl-3 bot-mid ">
            <img src={Angular2} alt="" />
            <div className="btn-two">
              <Button className="btn-two-style" onClick={goToVideos}>
                ادامه
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstThreeMiddle;
