import { useEffect } from "react";
import { useState } from "react";
import AnnouncementModule from "./AnnouncementModule";
import "./announcement.scss";
import { getAnnouncement } from "../../utils/Api";
import { RotatingLines } from "react-loader-spinner";
import parse from "html-react-parser";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";


function Announcement() {
  const [announcementData, setAnnouncementData] = useState(null);
  const errorCatch = (error) => { };
  useEffect(() => {
    getAnnouncement(setAnnouncementData, errorCatch);
  }, []);


  if (!announcementData) {
    return (
      <div className="container-fluid rotating-loader-lines p-0 ">
        <div className="row justify-content-center p-0 ">
          <div className="col text-center p-0 ">
            <RotatingLines
              strokeColor="blue"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="announcement-main-mobile">
        <NavbarTopMobile />
        <div className="announcement-module container-fluid ">
          <div className="row d-flex flex-wrap">
            {announcementData?.data?.announcements.map((item) => (
              <div className="col-4 m-4">
                <AnnouncementModule
                  announcementTitle={item.title}
                  announcementContent={parse(item.content)}
                  announcementImage={item?.link}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="announcement-main">
      <div className="announcement-module container-fluid ">
        <div className="row d-flex flex-wrap">
          {announcementData?.data?.announcements.map((item) => (
            <div className="col-4 m-4">
              <AnnouncementModule
                announcementTitle={item.title}
                announcementContent={parse(item.content)}
                announcementImage={item?.link}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default Announcement;