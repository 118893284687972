
import { useContext, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RotatingLines } from "react-loader-spinner";
import { UserContext } from "../../contexts/providers/user";
import { Banks } from "../../components/Banks";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage, isLoggedIn } from "../../utils/RSS";
import { Modal, ModalFooter } from "react-bootstrap";
import { Alert, Button, Snackbar } from "@mui/material";
import Form from "react-bootstrap/Form";
import './profile.scss'
import AddIcon from '@mui/icons-material/Add';
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

export default function CardManager(profile) {

    const handleShow = () => setShow(true);
    const [showDelete, setShowDelete] = useState(false);
    const handleCloseDelete = () => setShowDelete(false);
    const handleShowDelete = () => setShowDelete(true);
    const [bankName, setBankName] = useState("");
    const [bankLogo, setBankLogo] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [iban, setIban] = useState('')
    const [errorMsg, setErrorMsg] = useState('');
    const [id, setId] = useState('')
    const [rowCard, setRowCard] = useState('')
    const { state, dispatch } = useContext(UserContext);

    const getStatus = (data) => {
        if (data === 'pending') {
            return 'در حال بررسی'
        } else if (data === 'rejected') {
            return 'مورد تایید نیست'
        } else if (data === 'approved') {
            return 'تایید شده است'
        }
    }

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setCardNumber('')
        setIban('')
        setBankLogo('')
        setBankName('')
        setShow(false)
    };

    const handleInputChange = (event) => {
        const formattedNumber = event.target.value
            .replace(/\D/g, "") // Remove non-numeric characters
            .slice(0, 16) // Limit the input to 16 characters
            .replace(
                /(\d{4})(\d{0,4})(\d{0,4})(\d{0,4}).*/,
                (match, p1, p2, p3, p4) => {
                    return [p1, p2, p3, p4].filter((group) => !!group).join(" ");
                }
            ); // Add a space after every 4 digits
        setCardNumber(formattedNumber);
        handleBankCardChange(formattedNumber)
    };

    const handleInputChangeIban = (event) => {
        setIban(event.target.value)
    }

    const handleBankCardChange = (e) => {
        const input = e.replace(/\s+/g, '');
        if (input.length >= 6) {
            const firstSixDigits = input.substring(0, 6);
            const matchedBankName = Banks.find((bank) =>
                bank.card_no.toString().startsWith(firstSixDigits)
            );

            if (matchedBankName) {
                setBankName(matchedBankName.bank_title);
                setBankLogo(matchedBankName.bank_logo);
            } else {
                setBankName("بانک پیدا نشد");
                setBankLogo("");
            }
        } else {
            setBankName("کارت بانکی را وارد کنید");
            setBankLogo("");
        }
    };

    const getBankName = (data) => {
        const firstSixDigits = data.substring(0, 6);
        const matchedBankName = Banks.find((bank) =>
            bank.card_no.toString().startsWith(firstSixDigits)
        );
        if (matchedBankName) {
            return matchedBankName;
        } else {
            return "بانک پیدا نشد";
        }
    };

    const [openSnack, setOpenSnack] = useState(false);

    const handleCloseSnack = () => {
        setOpenSnack(false)
    }

    const [openSnack2, setOpenSnack2] = useState(false);

    const handleCloseSnack2 = () => {
        setOpenSnack2(false)
    }

    const [openSnackDelete, setOpenSnackDelete] = useState(false);

    const handleCloseSnackDelete = () => {
        setOpenSnackDelete(false)
    }

    const [openSnack3, setOpenSnack3] = useState(false);

    const handleCloseSnack3 = () => {
        setOpenSnack3(false)
    }

    const [openSnack4, setOpenSnack4] = useState(false);

    const handleCloseSnack4 = () => {
        setOpenSnack4(false)
    }

    const submitBankCard = async (event) => {
        event.preventDefault();
        if (cardNumber === null || cardNumber === '' || cardNumber.length < 19) {
            setOpenSnack3(true)
        } else if (iban === null || iban === '' || iban.length < 26) {
            setOpenSnack4(true)
        } else {
            const bankData = {
                "card_number": cardNumber.replace(/\s/g, ''),
                "iban": iban,
                "bank": bankName
            };
            let res = await fetch(createApiAddress("api.BANK_CARD_LIST"), {
                method: "POST",
                body: JSON.stringify(bankData),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            });
            if (res.status === 200) {
                LoadUserProfile()
                handleClose()
            } else {
                setErrorMsg(res.locale.fa)
                setOpenSnack(true)
            }
        }
    }


    const setSpaceBetweenCard = (data) => {
        let formattedCardNumber = data.replace(/(\d{4})/g, '$1 ').trim();
        formattedCardNumber = formattedCardNumber.replace(/\s/g, '-')
        return formattedCardNumber
    }

    const askForDelete = (id, card) => {
        setId(id)
        let formattedCardNumber = card.replace(/(\d{4})/g, '$1 ').trim();
        formattedCardNumber = formattedCardNumber.replace(/\s/g, '-')
        setRowCard(formattedCardNumber)
        handleShowDelete()
    }

    const deleteBankCard = async (event) => {
        event.preventDefault();
        let res = await fetch(createApiAddress("api.DELETE_CARD", id), {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
        });
        if (res.status === 200) {
            setOpenSnackDelete(true)
            handleCloseDelete()
            LoadUserProfile()
        } else {
            setErrorMsg(res.locale.fa)
            setOpenSnack(true)
        }
    }

    const LoadUserProfile = () => {
        if (isLoggedIn() === true) {
            fetch(createApiAddress("api.user_info"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 1) {
                        dispatch({ type: "set:profile", profile: data.user });
                    } else {
                        dispatch({ type: "Logout" });
                    }
                })
                .catch((error) => dispatch({ type: "Logout" }));
        }
    };

    if (!profile) {
        return (
            <div className="container-fluid rotating-loader-lines">
                <div className="row justify-content-center">
                    <div className="col text-center">
                        <RotatingLines
                            strokeColor="grey"
                            strokeWidth="5"
                            animationDuration="0.75"
                            width="35"
                            visible={true}
                        />
                    </div>
                </div>
            </div>
        );
    }

    const kycStatus = () => {
        if (
            profile.profile?.info?.kyc?.status === 'approved'
            ||
            profile.profile?.info?.kyc?.status === 'approved_lvl2'
            ||
            profile.profile?.info?.kyc?.status === 'pending_lvl3'
            ||
            profile.profile?.info?.kyc?.status === 'rejected_lvl3'
        ) {
            return true;
        } else {
            return false;
        }
    }

    const kycStatus2 = () => {
        if (
            state.user_profile.info?.kyc?.status === 'approved'
            ||
            state.user_profile.info?.kyc?.status === 'approved_lvl2'
            ||
            state.user_profile.info?.kyc?.status === 'pending_lvl3'
            ||
            state.user_profile.info?.kyc?.status === 'rejected_lvl3'
        ) {
            return true;
        } else {
            return false;
        }
    }


    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);

    if (isMobileDevice === true) {
        return <>
            <div className="cardmanager-mobile p-0 m-0">
                <NavbarTopMobile />
                <div className="search-main-mobile">
                    {kycStatus2() === true ? <>
                        <div className="row">
                            <div className="col text-end card-btn p-0 m-0 me-4">
                                <Button className="add-bank-card-btn" onClick={handleShow} >
                                    <AddIcon />
                                    کارت بانکی اضافه کنید
                                </Button>
                            </div>
                        </div>
                        <div className="table-container-wrapper">
                            {state.user_profile?.bank_accounts
                                .map((row) => (
                                    <div className='bankcard-mobile-style mb-5'>
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-6 mt-3">
                                                    <img src={getBankName(row?.card_number).bank_logo} alt="" />{" "}
                                                </div>
                                                <div className="col-6 text-start mt-3">
                                                    {getBankName(row?.card_number).bank_title}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 text-center mt-5">
                                                    <p> {setSpaceBetweenCard(row.card_number)}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-7 mt-5">
                                                    <p style={{ fontSize: 12 }}> {row?.iban?.toUpperCase()}</p>
                                                </div>
                                                <div className="col-5 text-center mt-5">
                                                    <p style={{ fontSize: 12 }}>{getStatus(row.kyc_status)}</p>
                                                </div>
                                                <div className="delete text-center">
                                                    <Button className="delete-btn-card" onClick={() => askForDelete(row._id, row.card_number)}>حذف</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>))}
                        </div>
                    </> : <p className="notif-kyc-card p-3">لطفا ابتدا احراز هویت خود را کامل کنید.</p>}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        className="modal-bank-card"
                        backdrop="static"
                    >
                        <Modal.Header>
                            <Modal.Title>شماره کارت خود را وارد کنید</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modal-list">
                            <p style={{ textAlign: "justify" }}>کاربر گرامی، شما موظف هستید فقط از کارت و حساب بانکی که به اسم شماست استفاده‌ نمایید. در غیر این صورت درخواست شما تایید نمی شود و تکرار زیاد موجب مسدود شدن حساب کاربری شما میشود.</p>
                            <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                                <input
                                    type="text"
                                    value={cardNumber}
                                    onChange={handleInputChange}
                                    maxLength={19} // Set the maximum length to include spaces
                                    placeholder="0000 0000 0000 0000"
                                />
                            </Form.Group>
                            <div className="mt-2 mb-2 d-flex">
                                {bankLogo !== "" ? (
                                    <img
                                        src={bankLogo}
                                        alt="bank-logo"
                                        className="bank-logo"
                                    />
                                ) : (
                                    ""
                                )}
                                <h6>{bankName}</h6>
                            </div>
                            <h6 style={{ textAlign: "justify" }}>لطفا شماره شبای خود را به صورت کامل و به همراه IR وارد کنید.</h6>
                            <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                                <input
                                    type="text"
                                    value={iban}
                                    onChange={handleInputChangeIban}
                                    maxLength={26}
                                    minLength={26}
                                    placeholder="شماره شبای خود را وارد کنید"
                                />
                            </Form.Group>
                        </Modal.Body>
                        <ModalFooter>
                            <div className="row justify-content-around">
                                <div className="col-6">
                                    <Button className="confirm-withdraw" onClick={submitBankCard}>تایید</Button>
                                </div>
                                <div className="col-6">
                                    <Button className="confirm-withdraw" onClick={handleClose}>
                                        لغو
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Modal
                        show={showDelete}
                        onHide={handleCloseDelete}
                        className="modal-bank-card"
                        backdrop="static"
                    >
                        <Modal.Header>
                            <Modal.Title>آیا مطمئن هستید که می خواهید کارت خود را حذف کنید؟</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h6 className="mt-3">
                                {rowCard}
                            </h6>
                        </Modal.Body>
                        <ModalFooter>
                            <div className="row justify-content-around">
                                <div className="col-6">
                                    <Button className="confirm-withdraw" onClick={deleteBankCard}>تایید</Button>
                                </div>
                                <div className="col-6">
                                    <Button className="confirm-withdraw" onClick={handleCloseDelete}>
                                        لغو
                                    </Button>
                                </div>
                            </div>
                        </ModalFooter>
                    </Modal>
                    <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                        <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                            {errorMsg}
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnack3} autoHideDuration={6000} onClose={handleCloseSnack3}>
                        <Alert onClose={handleCloseSnack3} severity="warning" sx={{ width: '100%' }}>
                            شماره کارت ۱۶ رقمی را وارد کنید.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnack4} autoHideDuration={6000} onClose={handleCloseSnack4}>
                        <Alert onClose={handleCloseSnack4} severity="warning" sx={{ width: '100%' }}>
                            شماره شبای 26 رقمی خود را به همراه IR وارد کنید.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
                        <Alert onClose={handleCloseSnack2} severity="success" sx={{ width: '100%' }}>
                            کارت بانکی اضافه شد.
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openSnackDelete} autoHideDuration={6000} onClose={handleCloseSnackDelete}>
                        <Alert onClose={handleCloseSnackDelete} severity="success" sx={{ width: '100%' }}>
                            کارت بانکی حذف شد.
                        </Alert>
                    </Snackbar>
                </div>
                <div className="container-fluid navbar-mobile-bot">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <ResponsiveAppBar />
                        </div>
                    </div>
                </div>
            </div >
        </>
    }

    return (
        <div className="search-main">
            {kycStatus() === true ? <>
                <div className="row">
                    <div className="col text-start card-btn">
                        <Button className="add-bank-card-btn" onClick={handleShow} >
                            <AddIcon />
                            کارت بانکی اضافه کنید
                        </Button>
                    </div>
                </div>
                <div className="table-container-wrapper">
                    <TableContainer component={Paper} className="table-main">
                        <Table sx={{ minWidth: 800 }} aria-label="simple table dark">
                            <TableHead className="table-head">
                                <TableRow className="table-row cardnumber-table">
                                    <TableCell className="table-row" align="right" width={100}>
                                        نام بانک
                                    </TableCell>
                                    <TableCell className="table-row " align="right" width={160}>
                                        شماره کارت
                                    </TableCell>
                                    <TableCell className="table-row" align="right" width={200}>
                                        شماره شبا
                                    </TableCell>
                                    <TableCell className="table-row" align="right" width={100}>
                                        وضعیت
                                    </TableCell>
                                    <TableCell className="table-row" align="right" width={100}>
                                        حذف
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {profile?.profile?.bank_accounts
                                    .map((row) => (
                                        <TableRow
                                            className="table-row"
                                            key={row.card_number}
                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                        >
                                            <TableCell
                                                className="table-row"
                                                component="th"
                                                scope="row"
                                                align="right"
                                            >
                                                <img src={getBankName(row?.card_number).bank_logo} alt="" />{" "}
                                                {getBankName(row?.card_number).bank_title}
                                            </TableCell>
                                            <TableCell className="table-row" align="right">
                                                {setSpaceBetweenCard(row.card_number)}
                                            </TableCell>
                                            <TableCell className="table-row" align="right">
                                                {row?.iban?.toUpperCase()}
                                            </TableCell>
                                            <TableCell className="table-row" align="right">
                                                {getStatus(row.kyc_status)}
                                            </TableCell>
                                            <TableCell className="table-row card-btn" align="right">
                                                <Button className="add-bank-card-btn" onClick={() => askForDelete(row._id, row.card_number)}>حذف</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </> : <p className="notif-kyc-card p-3">لطفا ابتدا احراز هویت خود را کامل کنید.</p>}
            <Modal
                show={show}
                onHide={handleClose}
                className="modal-bank-card"
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>شماره کارت خود را وارد کنید</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-list">
                    <p style={{ textAlign: "justify" }}>کاربر گرامی، شما موظف هستید فقط از کارت و حساب بانکی که به اسم شماست استفاده‌ نمایید. در غیر این صورت درخواست شما تایید نمی شود و تکرار زیاد موجب مسدود شدن حساب کاربری شما میشود.</p>
                    <Form.Group className="mb-1 mt-2" controlId="formBasicEmail">
                        <input
                            type="text"
                            value={cardNumber}
                            onChange={handleInputChange}
                            maxLength={19} // Set the maximum length to include spaces
                            placeholder="0000 0000 0000 0000"
                        />
                    </Form.Group>
                    <div className="mt-2 mb-2 d-flex">
                        {bankLogo !== "" ? (
                            <img
                                src={bankLogo}
                                alt="bank-logo"
                                className="bank-logo"
                            />
                        ) : (
                            ""
                        )}
                        <h6>{bankName}</h6>
                    </div>
                    <h6 style={{ textAlign: "justify" }}>لطفا شماره شبای خود را به صورت کامل و به همراه IR وارد کنید.</h6>
                    <Form.Group className="mb-3 mt-2" controlId="formBasicEmail">
                        <input
                            type="text"
                            value={iban}
                            onChange={handleInputChangeIban}
                            maxLength={26}
                            minLength={26}
                            placeholder="شماره شبای خود را وارد کنید"
                        />
                    </Form.Group>
                </Modal.Body>
                <ModalFooter>
                    <div className="row justify-content-around">
                        <div className="col-6">
                            <Button className="confirm-withdraw" onClick={submitBankCard}>تایید</Button>
                        </div>
                        <div className="col-6">
                            <Button className="confirm-withdraw" onClick={handleClose}>
                                لغو
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal
                show={showDelete}
                onHide={handleCloseDelete}
                className="modal-bank-card"
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>آیا مطمئن هستید که می خواهید کارت خود را حذف کنید؟</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6 className="mt-3">
                        {rowCard}
                    </h6>
                </Modal.Body>
                <ModalFooter>
                    <div className="row justify-content-around">
                        <div className="col-6">
                            <Button className="confirm-withdraw" onClick={deleteBankCard}>تایید</Button>
                        </div>
                        <div className="col-6">
                            <Button className="confirm-withdraw" onClick={handleCloseDelete}>
                                لغو
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>
            <Snackbar open={openSnack3} autoHideDuration={6000} onClose={handleCloseSnack3}>
                <Alert onClose={handleCloseSnack3} severity="warning" sx={{ width: '100%' }}>
                    شماره کارت ۱۶ رقمی را وارد کنید.
                </Alert>
            </Snackbar>
            <Snackbar open={openSnack4} autoHideDuration={6000} onClose={handleCloseSnack4}>
                <Alert onClose={handleCloseSnack4} severity="warning" sx={{ width: '100%' }}>
                    شماره شبای 26 رقمی خود را به همراه IR وارد کنید.
                </Alert>
            </Snackbar>
            <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
                <Alert onClose={handleCloseSnack2} severity="success" sx={{ width: '100%' }}>
                    کارت بانکی اضافه شد.
                </Alert>
            </Snackbar>
            <Snackbar open={openSnackDelete} autoHideDuration={6000} onClose={handleCloseSnackDelete}>
                <Alert onClose={handleCloseSnackDelete} severity="success" sx={{ width: '100%' }}>
                    کارت بانکی حذف شد.
                </Alert>
            </Snackbar>
        </div >
    );
}
