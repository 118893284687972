import { Button, IconButton } from "@mui/material";
import React, { useContext } from "react";
import "./login.scss";
import { useState } from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { createApiAddress } from "../../constant/api.constant";
import { GridVisibilityOffIcon } from "@mui/x-data-grid";
import { VisibilityOutlined } from "@mui/icons-material";
import { UserContext } from "../../contexts/providers/user";
import { useEffect } from "react";
import PassChecker from "../../utils/PassCheker";

function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [token, setToken] = useState(null);
  const [verify, setVerify] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const { state, dispatch } = useContext(UserContext);


  const onChange = (e) => {
    let password = e.target.value;
    setPassword(password)
    initValue({
      ...pwdInput,
      password: e.target.value,
    });
    setError(null);
    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      setError(
        "رمز عبور باید حداقل شامل 8 کاراکتر باشد"
      );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError("باید یک حرف بزرگ اضافه کنید");
        return;
      } else if (small < 1) {
        setError("باید یک حرف کوچک اضافه کنید");
        return;
      } else if (num < 1) {
        setError("شما باید یک عدد اضافه کنید");
        return;
      } else if (specialSymbol < 1) {
        setError("باید یک نماد خاص اضافه کنید: @$! % *؟ & #");
        return;
      }
    }
  };
  const [pwdInput, initValue] = useState({
    password: "",
  });
  const [isStrong, initRobustPassword] = useState(null);
  const [isError, setError] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const tokenParam = params.get('token');
    const verifyParam = params.get('verify');
    setToken(tokenParam);
    setVerify(verifyParam);
  }, [token]);
  const navigate = useNavigate();
  const submitForm = (event) => {
    event.preventDefault();
    if (password === "" || password === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمز را وارد کنید",
      });
    } else if (confirmPassword === "" || confirmPassword === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "تکرار رمز را وارد کنید",
      });
    } else if (isError !== null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمز عبور قوی نیست",
      });
    } else if (password !== confirmPassword) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمزها یکسان نیستند",
      });
    } else {
      setIsLoading(true);
      request2confirm()
    }
  };
  const request2confirm = async () => {
    if (token !== null && verify !== null) {
      let res = await fetch(
        createApiAddress("api.RESET_PASSWORD"),
        {
          method: "POST",
          body: JSON.stringify({
            token: token,
            verify: verify,
            new_pwd: password,
            confirm_pwd: confirmPassword,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let resJson = await res.json();
      if (resJson.status === 1) {
        setIsLoading(false);
        setTimeout(() => {
          navigate("/", { replace: true });
        }, 1000);
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });
      } else if (resJson.status === 0) {
        setIsLoading(false);
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
      } else {
        setIsLoading(false);
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowIcon(!showIcon);
  };

  return (
    <div className="reset-pass-main">
      <div className="reset-pass-module">
        <h6 className="pb-5">رمز عبور خود را بازنشانی کنید</h6>
        <Form onSubmit={submitForm}>
          <InputGroup className="mt-2 register-dir">
            <IconButton
              aria-label="show-eye"
              onClick={togglePasswordVisibility}
            >
              {showIcon ? (
                <GridVisibilityOffIcon />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButton>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="رمز عبور"
              onChange={onChange}
              style={{ direction: "ltr" }}
            />
          </InputGroup>
          {isError !== null
            ? <p className="errors-register">{isError}</p>
            :
            <p className="errors-register">{password !== '' ? "رمز عبور قوی است." : "یک رمز عبور قوی برای امنیت خود وارد کنید."}</p>
          }

          <Form.Control.Feedback type="invalid">
            لطفا رمز عبور خود را وارد کنید.
          </Form.Control.Feedback>
          <div className="container p-0">
            <div className="row justify-content-start p-0">
              <div className="col-12">
                <PassChecker password={pwdInput.password} actions={initPwdInput} />
              </div>
            </div>
          </div>
          {/* {isStrong === "strong" && <button type="submit"> Register </button>} */}
          <InputGroup className="mt-3 register-dir">
            <IconButton
              aria-label="show-eye"
              onClick={togglePasswordVisibility}
            >
              {showIcon ? (
                <GridVisibilityOffIcon />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButton>
            <Form.Control
              type={showPassword ? "text" : "password"}
              placeholder="رمز عبور را تکرار کنید"
              onChange={(e) => setConfirmPassword(e.target.value)}
              style={{ direction: "ltr" }}
            />
          </InputGroup>
          <div className="col-12 text-center">
            <Button
              className="submit-btn-resetpass m-3"
              disabled={isLoading}
              type="submit"
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}

              {isLoading ? "بارگذاری..." : "تغییر رمز عبور"}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
