import "./profile.scss";
import PInfo from "./PInfo";
import PSecurity from "./PSecurity";
import SecurityLogs from "./PSecurityLogs";
import { Button, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserPoints from "./UserPoints";
import { UserContext } from "../../contexts/providers/user";
import { useLocation, useNavigate } from "react-router-dom";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage } from "../../utils/RSS";
import CardManager from "./CardManager";
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import GavelIcon from '@mui/icons-material/Gavel';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AddCardIcon from '@mui/icons-material/AddCard';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import QuizIcon from '@mui/icons-material/Quiz';
import InfoIcon from '@mui/icons-material/Info';
import ResponsiveAppBar from "../home/NavbarMobile";

function ProfileMobile() {
    const [tab, setTab] = useState(0);
    const { state, dispatch } = useContext(UserContext);
    const location = useLocation()
    const [activityHistory, setActivityHistory] = useState([])

    useEffect(() => {
        if (location.hash !== "") {
            const number = location.hash.replace('#', '')
            setTab(parseInt(number))
        }
    }, [])
    const navigate = useNavigate();
    useEffect(() => {
        fetch(createApiAddress("api.user_activity"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
        })
            .then(async (response) => {
                if (response.status === 401) {
                    dispatch({ type: "Logout" })
                    return;
                }
                if (response.status === 503) {
                    navigate('/maintenance')
                    return;
                }
                if (response.ok) {
                    const data = await response.json();
                    setActivityHistory(data);
                } else {
                    // console.log("Error fetching data:", response.statusText);
                }
            })
            .catch((error) => {

            });
    }, []);

    const goToPage = (address) => {
        navigate(address)
    }
    return (
        <div className="profile-mobile-main p-0 m-0">
            <NavbarTopMobile />
            <div className="container-fluid p-0 m-0">
                <div className="row p-0  profile-mobile-module">
                    <div className="col-3 m-1">
                        <Button className="btn-mobile-kyc" onClick={() => goToPage('/kyc')}>احراز هویت</Button>
                    </div>
                    <div className="col-3 m-1">
                        <Button className="btn-mobile-security" onClick={() => goToPage('/securitym')}>امنیت</Button>
                    </div>
                    <div className="col-3 m-1">
                        <Button className="btn-mobile-income" onClick={() => goToPage('/commission')}>درآمد ثابت</Button>
                    </div>
                </div>
                <div className="row mobile-profile-list">
                    <div className="col-11 ps-1 mt-3">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/fee')}>
                            <ProductionQuantityLimitsIcon className="ms-2" />{" "}
                            کارمزدها</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/termsofuse')}>
                            <GavelIcon className="ms-2" />{" "}
                            مقررات و شرایط استفاده</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/announcement')}>
                            <NewspaperIcon className="ms-2" />{" "}
                            اعلانات</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/learning')}>
                            <OndemandVideoIcon className="ms-2" />{" "}
                            ویدئوهای آموزشی</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/cardmanager')}>
                            <AddCardIcon className="ms-2" />{" "}
                            مدیریت کارتهای بانکی</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2" onClick={() => goToPage('/transactionhistory')}>
                        <Button className="mobile-profile-list-btn">
                            <LoginIcon className="ms-2" />{" "}
                            گزارش سوابق مالی </Button>
                    </div>
                    <div className="col-11 ps-1 mt-2" onClick={() => goToPage('/vipwallet')}>
                        <Button className="mobile-profile-list-btn">
                            <LoginIcon className="ms-2" />{" "}
                            کیف پول VIP</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2" onClick={() => goToPage('/viphistory')}>
                        <Button className="mobile-profile-list-btn">
                            <LoginIcon className="ms-2" />{" "}
                            تاریخچه VIP</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2" onClick={() => goToPage('/deposit#2')}>
                        <Button className="mobile-profile-list-btn">
                            <LoginIcon className="ms-2" />{" "}
                            تاریخچه واریز</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/withdraw#2')}>
                            <LogoutIcon className="ms-2" />{" "}
                            تاریخچه برداشت</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/faqm')}>
                            <QuizIcon className="ms-2" />{" "}
                            سوالات متداول</Button>
                    </div>
                    <div className="col-11 ps-1 mt-2">
                        <Button className="mobile-profile-list-btn" onClick={() => goToPage('/about')}>
                            <InfoIcon className="ms-2" />{" "}
                            درباره ما</Button>
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar className="" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileMobile
