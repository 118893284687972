import React, { useContext, useCallback, useEffect } from "react";
import "./kyc.scss";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Button, Form, Modal, ModalFooter, Spinner } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useRef } from "react";
import { Banks } from "../../components/Banks";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { Alert, Snackbar } from "@mui/material";
import KycSample from '../../assets/images/kyc-face-sample.svg'
import Webcam from "react-webcam";
import ReactPlayer from "react-player";
import { digitsFaToEn, numberToWords } from "@persian-tools/persian-tools";
import { useNavigate } from "react-router";
import KycMobile from "./KycMobile";

function isPersianText(text) {
  const persianTextRegex = /^[\u0600-\u06FF\s]+$/;
  return persianTextRegex.test(text);
}


function Kyc() {

  const [idNumber1, setIdNumber1] = useState('')
  const limitDigit = 10
  const [idNumber2, setIdNumber2] = useState('')
  const [showFirstForm, setShowFirstForm] = useState(false);
  const [cardNumber, setCardNumber] = useState("");
  const [errorMessageKyc, setErrorMessageKyc] = useState('')

  const handleCloseFirstForm = () => {
    setIdNumber1('')
    setIdNumber2('')
    setCardNumber('')
    setShowFirstForm(false)
    setBankLogo('')
    setBankName('')
    setFormDataL2({})
    setBirthdate('')
    setPhoneNumber('')
    setBtnDisablerer(true);
    setInputTelValid('');
    setDisableInput(false)
    setIban('')
    setFamilyName('')
    setFirstName('')
    setFatherName('')
    setIsPhoneVerified(false)
  };

  const handleShowFirstForm = () => setShowFirstForm(true);
  const [randomSentence, setRandomSentence] = useState('');
  const [showSecondForm, setShowSecondForm] = useState(false);
  const handleCloseSecondForm = () => {
    setRecordedChunks([])
    setShowSecondForm(false);
    setFormDataL3({});
  }
  const handleShowSecondForm = () => {
    getRndText()
    setShowSecondForm(true);
  }

  const [showThirdForm, setShowThirdForm] = useState(false);
  const handleCloseThirdForm = () => setShowThirdForm(false);
  const handleShowThirdForm = () => setShowThirdForm(true);

  const [showRecForm, setShowRecForm] = useState(false);

  const handleCloseRecForm = () => {
    if (capturing === true) {
      handleStopCaptureClick()
    }
    setShowRecForm(false)
    setIsAvailable(false)
  };

  const handleCloseRecFormCancel = () => {
    setRecordedChunks([])
    setShowRecForm(false)
    setIsAvailable(false)
  };

  const handleShowRecForm = () => {
    setShowRecForm(true)
  };
  const [showSMS, setShowSMS] = useState(false);
  const handleCloseSMS = () => setShowSMS(false);
  const [bankName, setBankName] = useState("");
  const [bankLogo, setBankLogo] = useState("");

  const { state, dispatch } = useContext(UserContext);
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleOpenSnack = () => {
    setOpenSnack(true)
  }

  const [openSnack4, setOpenSnack4] = useState(false);
  const [isLoading, setIsLoading] = useState(false)

  const handleCloseSnack4 = () => {
    setOpenSnack4(false)
  }

  const handleOpenSnack4 = () => {
    setOpenSnack4(true)
  }

  const [openSnack2, setOpenSnack2] = useState(false);

  const handleCloseSnack2 = () => {
    setOpenSnack2(false)
  }

  const handleOpenSnack2 = () => {
    setOpenSnack2(true)
  }

  const [openSnack3, setOpenSnack3] = useState(false);

  const handleCloseSnack3 = () => {
    setOpenSnack3(false)
  }

  const handleOpenSnack3 = () => {
    setOpenSnack3(true)
  }

  const [openSnack5, setOpenSnack5] = useState(false);

  const handleCloseSnack5 = () => {
    setOpenSnack5(false)
  }

  const handleOpenSnack5 = () => {
    setOpenSnack5(true)
  }

  const [openSnack6, setOpenSnack6] = useState(false);
  const [errorSms, setErrorSms] = useState('')

  const handleCloseSnack6 = () => {
    setOpenSnack6(false)
  }

  const handleOpenSnack6 = () => {
    setOpenSnack6(true)
  }

  const [timeRemaining, setTimeRemaining] = useState(180);
  const [openSnack7, setOpenSnack7] = useState(false);
  const [limitTransactionData, setLimitTransactionData] = useState(false);

  useEffect(() => {
    fetch(createApiAddress("api.LIMIT_TRANSACTION_DATA_KYC"), {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          // console.log(data.data)
          setLimitTransactionData(data.data)
        }
      })
      .catch((error) => {
        console.error("Error fetching user wallet data:", error);
      });

  }, []);

  useEffect(() => {
    if (openSnack7 === true) {
      const timerInterval = setInterval(() => {
        if (timeRemaining > 0) {
          setTimeRemaining(timeRemaining - 1);
        } else {
          setOpenSnack7(false);
          clearInterval(timerInterval);
        }
      }, 1000);
      return () => clearInterval(timerInterval);
    }
  }, [openSnack7, timeRemaining]);

  const handleCloseSnack7 = () => {
    setOpenSnack7(false);
    setBtnDisablerer2(false)
    setTimeRemaining(180);
  };

  const [reloader, setReloader] = useState(0);

  const handleReload = () => {
    setReloader(prev => prev + 1);
  };

  const handleChangeIdNumber1 = (event) => {
    if (event.target.value.toString().length <= limitDigit) {
      setIdNumber1(event.target.value)
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    }
  }

  const handleChangeIdNumber2 = (e) => {
    if (e.target.value.toString().length <= limitDigit) {
      setIdNumber2(e.target.value)
    }
  }

  const [birthdate, setBirthdate] = useState('');
  const [isPhoneVerified, setIsPhoneVerified] = useState(false)

  const handleChange = (event) => {
    const inputDate = event.target.value;
    const formattedDate = formatInputDate(inputDate);
    setBirthdate(formattedDate);
    const { name, value } = event.target;
    setFormDataL2({
      ...formDataL2,
      [name]: value,
    });
  };

  const formatInputDate = (inputDate) => {
    const cleanedInput = inputDate.replace(/[^0-9]/g, '');
    if (cleanedInput.length <= 4) {
      return cleanedInput;
    } else if (cleanedInput.length <= 6) {
      return `${cleanedInput.slice(0, 4)}/${cleanedInput.slice(4)}`;
    } else if (cleanedInput.length <= 8) {
      return `${cleanedInput.slice(0, 4)}/${cleanedInput.slice(4, 6)}/${cleanedInput.slice(6)}`;
    } else {
      return `${cleanedInput.slice(0, 4)}/${cleanedInput.slice(4, 6)}/${cleanedInput.slice(6, 8)}`;
    }
  };

  // form Data for level 2
  const [formDataL2, setFormDataL2] = useState({});
  const [inputTelValid, setInputTelValid] = useState('');
  const [btnDisablerer, setBtnDisablerer] = useState(true);
  const [btnDisablerer2, setBtnDisablerer2] = useState(true);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [disableInput, setDisableInput] = useState(false)

  const handleChangeFormLevelMobile = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length > 11) {
      event.target.value = inputValue.slice(0, 11);
    } else if (inputValue === '') {
      setBtnDisablerer(true);
      setInputTelValid('');
    } else {
      const isValidPhoneNumber = /^09\d{9}$/.test(inputValue);
      if (isValidPhoneNumber === true) {
        setPhoneNumber(event.target.value);
        setBtnDisablerer(false);
        const { name, value } = event.target;
        setFormDataL2({
          ...formDataL2,
          [name]: value,
        });
        setInputTelValid('');
      } else {
        setBtnDisablerer(true);
        setInputTelValid('لطفا تلفن همراه خود را به صورت صحیح وارد کنید.');
      }
    }
  };

  const [firstName, setFirstName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [fatherName, setFatherName] = useState('');

  const handleChangeFormUsername = (event) => {
    const inputValue = event.target.value;
    if (isPersianText(inputValue) || inputValue === '') {
      setFirstName(inputValue);
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    }
  }

  const handleChangeFormFamilyName = (event) => {
    const inputValue = event.target.value;
    if (isPersianText(inputValue) || inputValue === '') {
      setFamilyName(inputValue);
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    }
  }

  const handleChangeFormFatherName = (event) => {
    // console.log(cardNumber)
    const inputValue = event.target.value;
    if (isPersianText(inputValue) || inputValue === '') {
      setFatherName(inputValue);
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    }
  }


  const [iban, setIban] = useState('');
  const handleChangeIban = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.replace(/\s/g, '');
    if (inputValue.startsWith('IR')) {
      setIban(inputValue.slice(0, 26));
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    } else if (inputValue === '' || /^[0-9]*$/.test(inputValue)) {
      setIban('IR' + inputValue.slice(0, 24));
      const { name, value } = event.target;
      setFormDataL2({
        ...formDataL2,
        [name]: value,
      });
    }
  };

  const handleChangeOTP = (event) => {
    setReceivedOtp(event.target.value)
  }

  const checkBirthdate = (birthday) => {
    const pattern = /^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])$/;
    return pattern.test(birthday)
  }

  const checkValidation2 = (event) => {
    if (firstName === undefined || firstName === '') {
      setErrorMessageKyc('نام مورد الزامی است')
      handleOpenSnack5()
      return
    }
    if (familyName === undefined || familyName === '') {
      setErrorMessageKyc('نام خانوادگی الزامی است')
      handleOpenSnack5()
      return
    }
    if (fatherName === undefined || fatherName === '') {
      setErrorMessageKyc('نام پدر الزامی است')
      handleOpenSnack5()
      return
    }
    if (idNumber1 === "") {
      setErrorMessageKyc('شماره ملی الزامی است')
      handleOpenSnack5()
      return
    }
    if (idNumber2 === "") {
      setErrorMessageKyc('تکرار شماره ملی الزامی است')
      handleOpenSnack5()
      return
    }
    if (idNumber2 !== idNumber1) {
      handleOpenSnack4()
      return
    }
    if (birthdate === '') {
      setErrorMessageKyc('تاریخ تولد الزامی است')
      handleOpenSnack5()
      return
    }
    if (birthdate !== '' && checkBirthdate(birthdate) === false) {
      setErrorMessageKyc('تاریخ تولد باید به صورت 1370/01/31 وارد شود.')
      handleOpenSnack5()
      return
    }
    if (phoneNumber === '') {
      setErrorMessageKyc('شماره همراه الزامی است')
      handleOpenSnack5()
      return
    }
    if (iban === undefined) {
      setErrorMessageKyc('شماره شبا الزامی است')
      handleOpenSnack5()
      return
    }
    if (cardNumber === '') {
      setErrorMessageKyc('شماره کارت بانکی الزامی است')
      handleOpenSnack5()
      return
    }
    if (isPhoneVerified === false) {
      setErrorMessageKyc('ابتدا شماره همراه خود را تایید کنید.')
      handleOpenSnack5()
      return
    }
    if (iban.length !== 26) {
      setErrorMessageKyc('شماره شبای 26 کاراکتری به همراه IR وارد کنید.')
      handleOpenSnack5()
      return
    } else {

      submitLevel2(event)
    }
  }

  const submitLevel2 = async (event) => {

    try {
      setIsLoading(true)
      event.preventDefault();

      let res = await fetch(createApiAddress("api.kyc_level2"), {
        method: "POST",
        body: JSON.stringify(formDataL2),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let result = await res.json()
      if (result.status === 1) {
        handleCloseFirstForm();
        handleReload()
        setErrorMessageKyc(result.locale.fa)
        handleOpenSnack5()
        window.location.reload();
        setIsLoading(false)
      } else {
        setErrorMessageKyc(result.locale.fa)
        handleOpenSnack5()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
    }
  };

  // form Data for level 3 
  const [formDataL3, setFormDataL3] = useState({});

  const handleChangeFormLevel3 = (event) => {
    const { name, value } = event.target;
    setFormDataL3({
      ...formDataL3,
      [name]: value,

    });
  };

  const handleChangeFormLevel3Files = (event) => {
    const { name, value } = event.target;
    setFormDataL3({
      ...formDataL3,
      [name]: event.target.files[0],

    });
  };

  const checkValidation3 = (event) => {
    // console.log(formDataL3.state)
    if (formDataL3.state < 1 || formDataL3.state === undefined) {
      setErrorMessageKyc('استان مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (formDataL3.city === undefined) {
      setErrorMessageKyc('شهر مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (formDataL3.address === undefined) {
      setErrorMessageKyc('نشانی مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (formDataL3.postcode === undefined) {
      setErrorMessageKyc('کد پستی مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (formDataL3.postcode.length !== 10) {
      setErrorMessageKyc('کد پستی باید ۱۰ رقمی باشد.')
      handleOpenSnack5()
      return
    } else if (formDataL3.front === undefined) {
      setErrorMessageKyc('عکس از جلوی مدرک شناسایی مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (formDataL3.back === undefined) {
      setErrorMessageKyc('عکس از پشت مدرک شناسایی مورد نیاز است')
      handleOpenSnack5()
      return
    } else if (recordedChunks.length === 0) {
      setErrorMessageKyc('ویدئو ضروری است')
      handleOpenSnack5()
      return
    } else {
      submitLevel3(event)
    }
  }

  const [receivedOtp, setReceivedOtp] = useState('');

  const sendSMS = async (event) => {
    event.preventDefault();
    setBtnDisablerer(true)
    try {
      {
        let form = {
          'phone_number': phoneNumber,
        }
        let res = await fetch(createApiAddress("api.SEND_SMS_PHONE_VERIFY"), {
          method: "POST",
          body: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        let result = await res.json()
        if (result.status === 1) {
          setErrorSms(result.locale.fa)
          setOpenSnack7(true)
          setBtnDisablerer2(true)
          setShowSMS(true)
          setTimeRemaining(180)
        } else {
          setErrorSms(result.locale.fa)
          handleOpenSnack6()
          setBtnDisablerer(false)
        }
      }
    } catch (e) {
      setIsLoading(false)
    }
  }

  const verifyOTP = async (event) => {
    event.preventDefault();
    try {
      {
        let form = {
          'phone_number': phoneNumber,
          'otp': receivedOtp
        }
        let res = await fetch(createApiAddress("api.SMS_OTP_VERIFY"), {
          method: "POST",
          body: JSON.stringify(form),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        let result = await res.json()
        if (result.status === 1) {
          handleCloseSnack7()
          setErrorSms(result.locale.fa)
          handleOpenSnack6()
          setShowSMS(false)
          setIsPhoneVerified(true)
          setBtnDisablerer(true)
          setDisableInput(true)
        } else {
          setErrorSms(result.locale.fa)
          handleOpenSnack6()
          setBtnDisablerer(false)
        }
      }
    } catch (e) {
      setIsLoading(false)
    }
  }

  //for compressing video from cam
  const compressVideo = async (blob, quality) => {
    return new Promise((resolve) => {
      const video = document.createElement("video");
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const url = URL.createObjectURL(blob);
      video.src = url;

      video.onloadedmetadata = () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        canvas.toBlob((resultBlob) => {
          URL.revokeObjectURL(url);
          resolve(resultBlob);
        }, 'video/mp4; codecs="avc1.424028, mp4a.40.2"', quality);
      };
    });
  };

  const submitLevel3 = async (event) => {
    try {
      setIsLoading(true)
      event.preventDefault();
      const requiredFields = ['state', 'address', 'postcode', 'front', 'back', 'city'];
      const missingFields = requiredFields.filter((field) => !formDataL3[field]);

      if (missingFields.length > 0) {
        handleOpenSnack()
        setIsLoading(false)
        return;
      } else if (recordedChunks.length > 0) {
        const formU = new FormData();
        const blob = new Blob(recordedChunks, {
          type: getVideoMimeType(),
        });
        // const compressedBlob = await compressVideo(blob, 0.4);

        Object.keys(formDataL3).forEach((item) => {
          formU.append(item, formDataL3[item]);
        });
        formU.append('token', textToken)
        formU.append('selfie', blob, 'selfie.mp4')
        let res = await fetch(createApiAddress("api.kyc_level3"), {
          method: "POST",
          body: formU,
          headers: {
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        if (res.status === 200) {
          handleCloseSecondForm();
          handleReload()
          handleOpenSnack2()
          window.location.reload();
          setIsLoading(false)
        } else {
          handleOpenSnack3()
          setIsLoading(false)
        }
      }
    } catch (e) {
      setIsLoading(false)
    }
  }


  const submitVIP = async (event) => {
    try {
      setIsLoading(true)
      event.preventDefault();
      let res = await fetch(createApiAddress("api.kyc_vip"), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      if (res.status === 200) {
        handleCloseThirdForm();
        handleReload()
        window.location.reload();
        setIsLoading(false)
      } else {
        handleOpenSnack3()
        setIsLoading(false)
      }

    } catch (e) {
      setIsLoading(false)
    }
  }


  // get random text
  const [textToken, setTextToken] = useState()
  const navigate = useNavigate();
  const getRndText = async () => {
    try {
      const response = await fetch(createApiAddress("api.KYC_RND_TEXT"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      if (response.status === 401) {
        dispatch({ type: "Logout" });
        return;
      }
      if (response.status === 503) {
        navigate('/maintenance')
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setTextToken(data?.data?.token);
        setRandomSentence(data?.data?.phrase);


      } else {
      }
    } catch (error) {
    }
  };


  //bank card checker
  const handleInputChange = (event) => {
    const formattedNumber = event.target.value
      .replace(/\D/g, "")
      .slice(0, 16)
      .replace(
        /(\d{4})(\d{0,4})(\d{0,4})(\d{0,4}).*/,
        (match, p1, p2, p3, p4) => {
          return [p1, p2, p3, p4].filter((group) => !!group).join(" ");
        }
      );
    setCardNumber(formattedNumber);
    handleBankCardChange(formattedNumber);

    // Check if the length of the card number is greater than 10 before removing spaces
    if (formattedNumber.length > 10) {
      const spaceString = formattedNumber.replace(/\s/g, "");
      setFormDataL2({
        ...formDataL2,
        ['card_number']: spaceString,
      });
    }
  };


  const handleBankCardChange = (e) => {
    const input = e.replace(/\s+/g, '');
    if (input.length >= 6) {
      const firstSixDigits = input.substring(0, 6);
      const matchedBankName = Banks.find((bank) =>
        bank.card_no.toString().startsWith(firstSixDigits)
      );

      if (matchedBankName) {
        setBankName(matchedBankName.bank_title);
        setBankLogo(matchedBankName.bank_logo);
      } else {
        setBankName("بانک پیدا نشد");
        setBankLogo("");
      }
    } else {
      setBankName("کارت بانکی را وارد کنید");
      setBankLogo("");
    }
  };

  const webcamRef = useRef(null);
  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const handleFileButtonClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleFileButtonClick2 = () => {
    fileInputRef2.current.click();
  };



  const getKycStatus2 = () => {
    if (state?.user_profile?.info?.kyc?.status === "approved_lvl2" || state?.user_profile?.info?.kyc?.status === "approved" || state?.user_profile?.info?.kyc?.status === "pending_lvl3" || state?.user_profile?.info?.kyc?.status === "rejected_lvl3") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          <h6 className="mt-3 accept">تایید شد</h6>
        </div>
      </div>;
    } else if (state?.user_profile?.info?.kyc?.status === "pending_lvl2") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <Spinner animation="border" className="mt-2 ms-2" />
          <h6 className="mt-3 accept">در بررسی وضعیت</h6>
        </div>
      </div>;
    }
    else if (state?.user_profile?.info?.kyc?.status === "not_initiated" || state?.user_profile?.info?.kyc?.status === "rejected_lvl2") {
      return <Button className="btn-in-kycpage" onClick={handleShowFirstForm}>
        اقدام برای سطح 2{" "}
      </Button>
    }
  }

  const getKycStatusIcon2 = () => {
    if (state?.user_profile?.info?.kyc?.status === "approved_lvl2" || state?.user_profile?.info?.kyc?.status === "approved" || state?.user_profile?.info?.kyc?.status === "pending_lvl3" || state?.user_profile?.info?.kyc?.status === "rejected_lvl3") {
      return 'green';
    }
  }

  const setLimitTransData = (number) => {
    try {
      var withCryptoDaily3 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_3?.daily_crypto_amount))
      var depTomanDaily3 = numberToWords(String(limitTransactionData?.deposit?.kyc_level_3?.daily_toman_amount))
      var witTomanDaily3 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_3?.daily_toman_amount))
      var wit24Daily3 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_3?.daily_crypto_amount + limitTransactionData?.withdraw?.kyc_level_3?.daily_toman_amount))
      var witMonthly3 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_3?.monthly_toman_amount + limitTransactionData?.withdraw?.kyc_level_3?.monthly_crypto_amount))
      if (number === 1) {
        return String(withCryptoDaily3)
      } else if (number === 2) {
        return String(depTomanDaily3)
      } else if (number === 3) {
        return String(witTomanDaily3)
      } else if (number === 4) {
        return String(wit24Daily3)
      } else if (number === 5) {
        return String(witMonthly3)
      }
    } catch (error) {
      console.error("Error in setLimitTransData:", error);
      return "Error";
    }
  }

  const setLimitTransData2 = (number) => {
    try {
      var withCryptoDaily2 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_2?.daily_crypto_amount))
      var depTomanDaily2 = numberToWords(String(limitTransactionData?.deposit?.kyc_level_2?.daily_toman_amount))
      var witTomanDaily2 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_2?.daily_toman_amount))
      var wit24Daily2 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_2?.daily_crypto_amount + limitTransactionData?.withdraw?.kyc_level_2?.daily_toman_amount))
      var witMonthly2 = numberToWords(String(limitTransactionData?.withdraw?.kyc_level_2?.monthly_toman_amount + limitTransactionData?.withdraw?.kyc_level_2?.monthly_crypto_amount))
      if (number === 1) {
        return String(withCryptoDaily2)
      } else if (number === 2) {
        return String(depTomanDaily2)
      } else if (number === 3) {
        return String(witTomanDaily2)
      } else if (number === 4) {
        return String(wit24Daily2)
      } else if (number === 5) {
        return String(witMonthly2)
      }
    } catch (error) {
      console.error("Error in setLimitTransData:", error);
      return "Error";
    }
  }

  const getKycStatus3 = () => {
    if (state?.user_profile?.info?.kyc?.status === "approved") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          <h6 className="mt-3 accept">تایید شد</h6>
        </div>
      </div>;
    } else if (state?.user_profile?.info?.kyc?.status === "pending_lvl3") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <Spinner animation="border" className="mt-2 ms-2" />
          <h6 className="mt-3 accept">در بررسی وضعیت</h6>
        </div>
      </div>;
    } else if (state?.user_profile?.info?.kyc?.status !== "approved_lvl2" && state?.user_profile?.info?.kyc?.status !== "rejected_lvl3") {
      return " ";
    } else if (state?.user_profile?.info?.kyc?.status === "approved_lvl2") {
      return <Button className="btn-in-kycpage" onClick={handleShowSecondForm}>
        اقدام برای سطح 3{" "}
      </Button>;
    } else if (state?.user_profile?.info?.kyc?.status === "rejected_lvl3") {
      return <Button className="btn-in-kycpage" onClick={handleShowSecondForm}>
        اقدام برای سطح 3{" "}
      </Button>;
    }
  }
  const getKycStatusIcon3 = () => {

    if (state?.user_profile?.info?.kyc?.status === "approved_lvl2" || state?.user_profile?.info?.kyc?.status === "pending_lvl3" || state?.user_profile?.info?.kyc?.status === "rejected_lvl3") {
      return <div className="kyc-cl-bot">
        < p >
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          ثبت نشانی و تلفن ثابت محل سکونت
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          تصویر مدرک شناسایی
        </p>
        <div className="line-span3"></div>
        <p className="access2">دسترسی ها:</p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          واریز رمز ارز به مقدار نامحدود
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          برداشت روزانه رمز ارز معادل
          {' '}{setLimitTransData(1)}{' '}
          تومان
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          واریز روزانه تومان به مبلغ {' '}{setLimitTransData(2)}{' '}    تومان
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          برداشت روزانه تومان به مبلغ {' '}{setLimitTransData(3)}{' '}   تومان
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          سقف مجاز برداشت رمز ارز و تومان در 24 ساعت  مجموعا" معادل {' '}{setLimitTransData(4)}{' '}
          تومان
        </p>
        <p>
          <HighlightOffIcon
            style={{ color: "rgb(240, 14, 78)" }}
            className="ms-2"
          />
          سقف مجاز برداشت رمز ارز و تومان در یک دوره 30 روزه مجموعا" معادل
          {' '}{setLimitTransData(5)}{' '}  تومان
        </p>
      </div>
    } else if (state?.user_profile?.info?.kyc?.status === "approved") {
      return <div className="kyc-cl-bot">
        < p >
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          ثبت نشانی و تلفن ثابت محل سکونت
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          تصویر مدرک شناسایی
        </p>
        <div className="line-span3"></div>
        <p className="access2">دسترسی ها:</p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          واریز رمز ارز به مقدار نامحدود
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          برداشت روزانه رمز ارز معادل {' '}{setLimitTransData(1)}{' '}  تومان
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          واریز روزانه تومان به مبلغ {' '}{setLimitTransData(2)}{' '}  تومان
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          برداشت روزانه تومان به مبلغ {' '}{setLimitTransData(3)}{' '} تومان
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          سقف مجاز برداشت رمز ارز و تومان در 24 ساعت  مجموعا" معادل {' '}{setLimitTransData(4)}{' '}
          تومان
        </p>
        <p>
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          سقف مجاز برداشت رمز ارز و تومان در یک دوره 30 روزه مجموعا" معادل
          {' '}{setLimitTransData(5)}{' '}   تومان
        </p>
      </div>;
    } else {
      return <div className="kyc-cl-bot"><p className="text-center mt-2">ابتدا باید سطح 2 را بگذرانید</p></div>
    }
  }

  const getKycVipStatus = () => {
    if (state?.user_profile?.info?.kyc?.status === "approved" && state?.user_profile?.info?.kyc?.vip_status === "approved") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <TaskAltIcon
            style={{ color: "#38E54D" }}
            className="ms-2 mt-2"
          />
          <h6 className="mt-3 accept">تایید شد</h6>
        </div>
      </div>;
    } else if (state?.user_profile?.info?.kyc?.vip_status === "pending") {
      return <div className="kyc-cl-mid">
        <div className="align-mid">
          <Spinner animation="border" className="mt-2 ms-2" />
          <h6 className="mt-3 accept">در بررسی وضعیت</h6>
        </div>
      </div>;
    }
    else if (state?.user_profile?.info?.kyc?.status === "approved" && state?.user_profile?.info?.kyc?.vip_status === "not_initiated") {
      return <Button className="btn-in-kycpage" onClick={handleShowThirdForm}>
        اقدام برای VIP{" "}
      </Button>
    } else if (state?.user_profile?.info?.kyc?.status === "approved" && state?.user_profile?.info?.kyc?.vip_status === "rejected") {
      return <Button className="btn-in-kycpage" onClick={handleShowThirdForm}>
        اقدام برای VIP{" "}
      </Button>
    } else {
      return <h6 className="kyc-waiting-text">ابتدا سطح 3 را تکمیل کنید</h6>
    }
  }


  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoUrl, setVideoUrl] = useState()
  const [isAvailable, setIsAvailable] = useState(false)

  useEffect(() => {
    if (recordedChunks.length) {
      handlePlay()
      setTimeout(() => {
        setIsAvailable(true);
      }, 500)
    } else {
      setIsAvailable(false)
    }
  }, [isAvailable, recordedChunks])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  const getVideoMimeType = () => {
    if (isSafari) {
      return 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';
    } else {
      return 'video/webm';
    }
  };
  const handleStartCaptureClick = useCallback(() => {
    setRecordedChunks([])
    setIsAvailable(false)
    setCapturing(true);
    setTimeout(() => {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: getVideoMimeType(),
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    }, 900)
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setIsAvailable(true)
  }, [mediaRecorderRef, setCapturing]);

  const handlePlay = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: getVideoMimeType(),
      });
      const url = URL.createObjectURL(blob);
      setVideoUrl(url)
    }
  }, [recordedChunks]);

  const isMobile = window.innerWidth <= 768;
  const videoConstraints = {
    width: isMobile ? 270 : 450,
    height: isMobile ? 270 : 450,
    facingMode: "user",
  };

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <KycMobile />
    </>
  }

  return (
    <div className="kycpage-main">
      <div className="kycpage-module container">
        <div className="kycpage-top">
          <h5 className="p-3">احراز هویت و سطح کاربر</h5>
          <h6 className="p-3 lh-lg">
            برابر موازین قانونی مالی و پولی کشور و جهت شفاف سازی و ایجاد شرایط
            مناسب و مطمئن در ارائه خدمات، لازم است که هویت کاربران گرامی محرز
            گردد. شما در هر کدام از سطوح کاربری ذیل قرار داشته باشید شامل مزایا و امتیازات خاصی خواهید شد.
          </h6>
          <h6 className="p-3">
            لذا برای امکان استفاده از خدمات تریکسو لازم است نسبت به احراز هویت
            اقدام نمایید.
          </h6>
          {state?.user_profile?.info?.kyc?.status === 'rejected_lvl2' || state?.user_profile?.info?.kyc?.status === 'rejected_lvl3' ? <h6 className="p-3 text-danger">احراز هویت شما به دلیل "{state?.user_profile?.info?.kyc?.reject_reason}" رد شد.</h6> : <></>}
        </div>
        <div className="row justify-content-center">
          <div className="kyc-column">
            <div className="kyc-cl-top">
              <h1 className="pt-4">1</h1>
              <h6> سطح یک کاربری</h6>
            </div>
            <div className="kyc-cl-mid">
              <div className="align-mid">
                <TaskAltIcon
                  style={{ color: "#38E54D" }}
                  className="ms-2 mt-2"
                />
                <h6 className="mt-3 accept">تایید شد</h6>
              </div>
            </div>
            <div className="kyc-cl-bot">
              <p>
                <TaskAltIcon style={{ color: "#38E54D" }} className="ms-2" />
                ایمیل تایید شده است
              </p>
              <p>
                <TaskAltIcon style={{ color: "#38E54D" }} className="ms-2" />
                ثبت نام شما با موفقیت انجام شده است{" "}
              </p>
              <div className="line-span"></div>
              <p className="access">دسترسی ها:</p>
              <p>
                <TaskAltIcon style={{ color: "#38E54D" }} className="ms-2" />
                واریز رمز ارز
              </p>
              <p>
                <HighlightOffIcon
                  style={{ color: "rgb(240, 14, 78)" }}
                  className="ms-2"
                />
                برداشت رمز ارز
              </p>
              <p>
                <HighlightOffIcon
                  style={{ color: "rgb(240, 14, 78)" }}
                  className="ms-2"
                />
                واریز تومان
              </p>
              <p>
                <HighlightOffIcon
                  style={{ color: "rgb(240, 14, 78)" }}
                  className="ms-2"
                />
                برداشت تومان{" "}
              </p>
            </div>
          </div>
          <div className="kyc-column">
            <div className="kyc-cl-top">
              {" "}
              <h1 className="pt-4">2</h1>
              <h6> سطح دو کاربری</h6>
            </div>
            <div className="kyc-cl-mid">
              {getKycStatus2()}
            </div>
            {getKycStatusIcon2() === 'green' ?
              <div className="kyc-cl-bot">
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  اطلاعات فردی{" "}
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  تلفن همراه
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  اطلاعات بانکی
                </p>
                <div className="line-span2"></div>
                <p>دسترسی ها:</p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  واریز رمز ارز به مقدار نامحدود
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  برداشت روزانه رمز ارز معادل
                  {' '}{setLimitTransData2(1)}{' '}
                  تومان{" "}
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  واریز روزانه تومان به مبلغ
                  {' '}{setLimitTransData2(2)}{' '}
                  تومان
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  برداشت روزانه تومان به مبلغ
                  {' '}{setLimitTransData2(3)}{' '}
                  تومان
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  سقف مجاز برداشت رمز ارز و تومان در 24 ساعت مجموعاً معادل
                  {' '}{setLimitTransData2(4)}{' '}
                  تومان
                </p>
                <p>
                  <TaskAltIcon
                    style={{ color: "#38E54D" }}
                    className="ms-2 mt-2"
                  />
                  سقف مجاز برداشت رمز ارز و تومان در یک دوره 30 روزه مجموعاً معادل
                  {' '}{setLimitTransData2(5)}{' '}
                  تومان
                </p>
              </div>
              :
              <div className="kyc-cl-bot">
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  اطلاعات فردی{" "}
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  تلفن همراه
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  اطلاعات بانکی
                </p>
                <div className="line-span2"></div>
                <p>دسترسی ها:</p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  واریز رمز ارز به مقدار نامحدود
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  برداشت روزانه رمز ارز معادل
                  {' '}{setLimitTransData2(1)}{' '}
                  تومان
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  واریز روزانه تومان به مبلغ
                  {' '}{setLimitTransData2(2)}{' '}
                  تومان
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  برداشت روزانه تومان به مبلغ
                  {' '}{setLimitTransData2(3)}{' '}
                  تومان
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  سقف مجاز برداشت رمز ارز و تومان در 24 ساعت مجموعاً معادل
                  {' '}{setLimitTransData2(4)}{' '}
                  تومان
                </p>
                <p>
                  <HighlightOffIcon
                    style={{ color: "rgb(240, 14, 78)" }}
                    className="ms-2"
                  />
                  سقف مجاز برداشت رمز ارز و تومان در یک دوره 30 روزه مجموعاً معادل
                  {' '}{setLimitTransData2(5)}{' '}
                  تومان{" "}
                </p>
              </div>}
          </div>
          <div className="kyc-column">
            <div className="kyc-cl-top">
              {" "}
              <h1 className="pt-4">3</h1>
              <h6> سطح سه کاربری</h6>
            </div>
            <div className="kyc-cl-mid">
              {getKycStatus3()}
            </div>
            {getKycStatusIcon3()}
          </div>
          <div className="kyc-column">
            <div className="kyc-cl-topv">
              {" "}
              <h1 className="pt-5">VIP</h1>
            </div>
            <div className="kyc-cl-midv">
              {getKycVipStatus()}
              {/* <Button className="btn-in-kycpagev" onClick={handleShowThirdForm}>
                درخواست VIP
              </Button> */}
            </div>
            <div className="kyc-cl-botv">
              <p>چنانچه در سطح ۳ احراز هویت شده باشید و در ۳۰ روز گذشته بیش از ۵ میلیارد تومان معامله داشته اید. شما واجد شرایط برای درخواست استفاده از مزایای خدمات کاربری VIP هستید. پس از درخواست همکاران ما با شما تماس میگیرند.</p>
            </div>
          </div>
        </div>
        <div className="container justify-content-center">
          <div className="row ">
            <div className="col-11 col-md-8">
              <Modal
                show={showFirstForm}
                onHide={handleCloseFirstForm}
                className="modal-kyc"
                backdrop="static"
                dialogClassName="modal-90w"
                scrollable
              >
                <Modal.Header>
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <h6 className="modaltitle">لطفا اطلاعات خود را به صورت فارسی وارد کنید</h6>
                <Modal.Body className="modal-list custom-modal-kyc">
                  <Form onSubmit={(e) => { checkValidation2(e) }} noValidate>
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="first_name"
                              name="first_name"
                              placeholder="نام"
                              size="medium"
                              autoFocus={true}
                              required
                              value={firstName}
                              onChange={handleChangeFormUsername}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>

                        </div>
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="last_name"
                              name="last_name"
                              placeholder="نام خانوادگی"
                              size="medium"
                              value={familyName}
                              onChange={handleChangeFormFamilyName}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="father_name"
                              name="father_name"
                              placeholder="نام پدر"
                              size="medium"
                              value={fatherName}
                              onChange={handleChangeFormFatherName}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="id_number"
                              name="id_number"
                              placeholder="شماره ملی"
                              size="medium"
                              onChange={(e) => handleChangeIdNumber1(e)}
                              value={idNumber1}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="birth_date"
                              name="birth_date"
                              placeholder="تاریخ تولد (yyyy/mm/dd)"
                              size="medium"
                              value={birthdate}
                              onChange={handleChange}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="id_number2"
                              name="id_number2"
                              placeholder="تکرار شماره ملی"
                              size="medium"
                              onChange={(e) => handleChangeIdNumber2(e)}
                              value={idNumber2}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-11 col-md-6">
                          <FormControl fullWidth>
                            <TextField
                              type="number"
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="phone_number"
                              name="phone_number"
                              placeholder="لطفا تلفن همراه خود را به صورت 09123456789 وارد کنید."
                              size="medium"
                              onChange={handleChangeFormLevelMobile}
                              maxLength={11}
                              disabled={disableInput}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                          {inputTelValid === '' ? <></> : <p>{inputTelValid}</p>}
                        </div>
                        <div className="col-11 col-md-6 mt-2">
                          <Button
                            className="btn-in-kyc4"
                            onClick={sendSMS}
                            disabled={btnDisablerer}
                          >
                            تایید تلفن همراه
                          </Button>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-11 col-md-6 ">
                          <div className="row">
                            <div className="col">
                              <p style={{ textAlign: "justify" }}>کاربر گرامی، شما موظف هستید فقط از کارت و حساب بانکی که به اسم شماست استفاده‌ نمایید. در غیر این صورت درخواست شما تایید نمی شود و تکرار زیاد موجب مسدود شدن حساب کاربری شما میشود.</p>
                            </div>
                          </div>
                          <div className="col  d-flex">
                            <div className="col-11 col-md-6 d-flex">
                              <Form.Group controlId="formBasicEmail">
                                <input
                                  type="text"
                                  value={cardNumber}
                                  onChange={handleInputChange}
                                  maxLength={19}
                                  placeholder="0000 0000 0000 0000"
                                  className="input-card-kyc"
                                />
                              </Form.Group>
                            </div>
                            <div className="col-11 col-md-6 d-flex">
                              {bankLogo !== "" ? (
                                <img
                                  src={bankLogo}
                                  alt="bank-logo"
                                  className="bank-logo me-3"
                                  height={40}
                                />
                              ) : (
                                ""
                              )}
                              <h6 className="bank-text-kyc">{bankName !== '' ? bankName : 'کارت بانکی را وارد کنید'}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-11 col-md-6">
                          <div className="row">
                            <div className="col">
                              <p style={{ textAlign: "justify" }} className=' mt-1'>لطفا شماره شبای خود را  بدون فاصله و یا خط تیره وارد کنید. IR به صورت خودکار در ابتدا قرار میگیرد.</p>
                            </div>
                          </div>
                          <FormControl fullWidth>
                            <TextField
                              type="text"
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="iban"
                              name="iban"
                              placeholder="شماره شبا"
                              size="medium"
                              value={iban}
                              onChange={handleChangeIban}
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </Form>
                </Modal.Body>
                <ModalFooter>
                  <Button className="btn-in-kyc4" onClick={(e) => { checkValidation2(e) }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : 'تایید'}
                  </Button>
                  <Button
                    className="btn-in-kyc5"
                    onClick={handleCloseFirstForm}
                  >
                    لغو
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
        <Modal
          show={showSMS}
          onHide={handleCloseSMS}
          className="modal-sms"
          backdrop="static"
          scrollable
        >
          <Modal.Body className="pt-2">
            <Form>
              <FormControl fullWidth>
                <h6>کد ارسال شده به تلفن همراه را وارد کنید</h6>
                {/* <p>{errorSms}</p> */}
                <p>بعد از  {timeRemaining} ثانیه می توانید مجدد  درخواست ارسال کنید</p>
                <TextField
                  type="number"
                  className="withdraw-texts"
                  InputProps={{ sx: { height: 57 } }}
                  id="mailotp"
                  name="mailotp"
                  placeholder="کد ارسال شده به تلفن همراه"
                  size="medium"
                  onChange={handleChangeOTP}
                  InputLabelProps={{
                    shrink: true,
                    className: "withdraw-texts",
                  }}
                />
              </FormControl>
            </Form>
            <Button className="btn-in-kyc4 mt-2" disabled={btnDisablerer2} onClick={sendSMS}>ارسال دوباره کد</Button>
          </Modal.Body>
          <ModalFooter>
            <Button className="btn-in-kyc4" onClick={verifyOTP}>تایید</Button>
            <Button className="btn-in-kyc5" onClick={handleCloseSMS}>
              لغو
            </Button>
          </ModalFooter>
        </Modal>
        <div className="container">
          <div className="row">
            <div className="col-11">
              <Modal
                show={showSecondForm}
                onHide={handleCloseSecondForm}
                className="modal-address"
                backdrop="static"
              >
                <Modal.Header >
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-2">
                  <Form>
                    <h6>نشانی و مدارک مربوطه را وارد کنید</h6>
                    <Form.Select name="state" className="cities" onChange={handleChangeFormLevel3}>
                      <option value="0">لطفا استان را انتخاب نمایید</option>
                      <option value="تهران">تهران</option>
                      <option value="گیلان">گیلان</option>
                      <option value="آذربایجان شرقی">آذربایجان شرقی</option>
                      <option value="خوزستان">خوزستان</option>
                      <option value="فارس">فارس</option>
                      <option value="اصفهان">اصفهان</option>
                      <option value="خراسان رضوی">خراسان رضوی</option>
                      <option value="قزوین">قزوین</option>
                      <option value="سمنان">سمنان</option>
                      <option value="قم">قم</option>
                      <option value="مرکزی">مرکزی</option>
                      <option value="زنجان">زنجان</option>
                      <option value="مازندران">مازندران</option>
                      <option value="گلستان">گلستان</option>
                      <option value="اردبیل">اردبیل</option>
                      <option value="آذربایجان غربی">آذربایجان غربی</option>
                      <option value="همدان">همدان</option>
                      <option value="کردستان">کردستان</option>
                      <option value="کرمانشاه">کرمانشاه</option>
                      <option value="لرستان">لرستان</option>
                      <option value="بوشهر">بوشهر</option>
                      <option value="کرمان">کرمان</option>
                      <option value="هرمزگان">هرمزگان</option>
                      <option value="چهارمحال و بختیاری">چهارمحال و بختیاری</option>
                      <option value="یزد">یزد</option>
                      <option value="سیستان و بلوچستان">سیستان و بلوچستان</option>
                      <option value="ایلام">ایلام</option>
                      <option value="کهگلویه و بویراحمد">کهگلویه و بویراحمد</option>
                      <option value="خراسان شمالی">خراسان شمالی</option>
                      <option value="خراسان جنوبی">خراسان جنوبی</option>
                      <option value="البرز">البرز</option>
                    </Form.Select>
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="city"
                        name="city"
                        placeholder="شهر"
                        size="medium"
                        onChange={handleChangeFormLevel3}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="address"
                        name="address"
                        placeholder="نشانی"
                        size="medium"
                        onChange={handleChangeFormLevel3}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="postcode"
                        name="postcode"
                        placeholder="کدپستی"
                        size="medium"
                        onChange={handleChangeFormLevel3}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                    <div>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="mt-2">
                          {" "}
                          عکس از جلوی مدرک شناسایی
                        </Form.Label>
                        <Form.Control
                          type="file"
                          required
                          name="front"
                          ref={fileInputRef1}
                          style={{ display: "none" }}
                          onChange={handleChangeFormLevel3Files}
                        />
                      </Form.Group>
                      <Button
                        onClick={handleFileButtonClick1}
                        className="btn-in-kyc4"
                      >

                        {formDataL3.front === undefined ?
                          "فایل را انتخاب کنید" : formDataL3.front.name}
                      </Button>
                    </div>
                    <div>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label className="mt-2">
                          عکس از پشت مدرک شناسایی
                        </Form.Label>
                        <Form.Control
                          type="file"
                          required
                          name="back"
                          ref={fileInputRef2}
                          style={{ display: "none" }}
                          onChange={handleChangeFormLevel3Files}
                        />
                      </Form.Group>
                      <Button
                        onClick={handleFileButtonClick2}
                        className="btn-in-kyc4"
                      >
                        {formDataL3.back === undefined ?
                          "فایل را انتخاب کنید" : formDataL3.back.name}
                      </Button>
                    </div>{" "}
                    <div>
                      <p className="mt-2"> شما در این مرحله نیاز به وب کم دارید. در صورت نداشتن وب کم میتوانید از اپلیکیشن تریکسو، و یا باز کردن وبسایت در گوشی خود استفاده کنید.</p>
                      <Button
                        onClick={handleShowRecForm}
                        className="btn-in-kyc7 mt-3"
                      >
                        برای باز کردن دوربین کلیک نمایید
                      </Button>
                    </div>
                  </Form>
                </Modal.Body>
                <ModalFooter>
                  <Button className="btn-in-kyc4" onClick={(e) => checkValidation3(e)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : 'تایید'}
                  </Button>
                  <Button
                    className="btn-in-kyc5"
                    onClick={handleCloseSecondForm}
                  >
                    لغو
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-11">
              <Modal
                show={showThirdForm}
                onHide={handleCloseThirdForm}
                className="modal-address"
                backdrop="static"
              >
                <Modal.Header >
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-2">
                  <h6>در صورتی که در 30 روز گذشته بیشتر از 5 میلیارد تومان معامله داشته اید درخواست نمایید. در غیر این صورت درخواست شما رد خواهد شد.</h6>
                </Modal.Body>
                <ModalFooter>
                  <Button className="btn-in-kyc4" onClick={submitVIP}>ارسال درخواست VIP</Button>
                  <Button
                    className="btn-in-kyc5"
                    onClick={handleCloseThirdForm}
                  >
                    لغو
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal
                show={showRecForm}
                onHide={handleCloseRecForm}
                className="modal-address"
                backdrop="static"
              >
                <Modal.Header >
                  <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body className="pt-2">
                  <h6 className="kyc-lvl3-h6">لطفا کارت ملی خود را مانند تصویر زیر در دست بگیرید وبا کلیک بر روی دکمه ضبط، متن زیر را با صدای واضح بخوانید، پس از پایان دکمه توقف را زده و در صورت واضح بودن ویدئو، ارسال کنید.</h6>
                  <h6 className="kyc-lvl3-h6 text-danger">به موارد زیر دقت کنید:</h6>
                  <ul className="ul-kyc-lvl3">
                    <li>محیط به اندازه کافی روشن باشد.</li>
                    <li>مقابل دوربین نور مستقیم نباشد.</li>
                    <li>تصویر بدون عینک آفتابی باشد.</li>
                    <li>صورت و کارت ملی به صورت کامل و واضح قابل رویت باشد.</li>
                    <li>متن به صورت کامل و واضح خوانده شود.</li>
                  </ul>
                  <div className="container-fluid">
                    <div className="row d-flex flex-column justify-content-center">
                      <div className="col text-center">
                        <div className="imglvl3main">
                          <div className="img0lvl3">
                            <img src={KycSample} width={150} height={150} />
                          </div>
                        </div>
                        <div className="kyc-lvl3-h6-main">
                          <h6 className="kyc-lvl3-h6-text">اینجانب {state?.user_profile?.info?.kyc?.first_name} {state?.user_profile?.info?.kyc?.last_name} پس از مطالعه تمامی قوانین و شرایط استفاده از پلتفورم تریکسو را پذیرفتم و به آن احترام می گذارم.</h6>
                          <h6 className="kyc-lvl3-h6-text">{randomSentence}</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          {isAvailable === false ?
                            <div className="Container camera-kyc-main">
                              <Webcam
                                height={isMobile ? 280 : 455}
                                width={isMobile ? 280 : 455}
                                audio={true}
                                mirrored={false}
                                ref={webcamRef}
                                videoConstraints={videoConstraints}
                              />
                              {capturing ? (
                                <Button onClick={handleStopCaptureClick} variant="contained" className="camera-btn mt-1">ضبط را متوقف کنید.</Button>
                              ) : (
                                <Button onClick={handleStartCaptureClick} variant="contained" className="camera-btn mt-1">ضبط را شروع کنید.</Button>
                              )}

                            </div>
                            : <div>
                              <ReactPlayer
                                url={videoUrl}
                                controls
                                height={isMobile ? 270 : 450}
                                width={isMobile ? 270 : 450}
                              />
                              {capturing ? (
                                <Button onClick={handleStopCaptureClick} variant="contained" className="camera-btn mt-1">ضبط را متوقف کنید.</Button>
                              ) : (
                                <Button
                                  className="camera-btn mt-3 mb-2"
                                  onClick={handleCloseRecForm}
                                >
                                  تایید
                                </Button>
                              )}

                            </div>
                          }
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-6">
                          {isAvailable === true && capturing === false ? <h6 className="mt-1" style={{ textAlign: "justify" }}>لطفا صدا و کیفیت ویدئو خود را چک کنید و در صورت نیاز دوباره اقدام به ضبط نمایید.</h6> : <></>}
                        </div>
                        <div className="col-6">
                          {isAvailable === true && capturing === false ? <Button onClick={handleStartCaptureClick} variant="contained" className="camera-btn mt-1">دوباره ضبط کنید</Button> : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <ModalFooter>
                  <Button
                    className="btn-in-kyc5"
                    onClick={handleCloseRecFormCancel}
                  >
                    لغو
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </div>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
            <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
              لطفاً تمامی فیلدهای الزامی را پر کنید
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
            <Alert onClose={handleCloseSnack2} severity="success" sx={{ width: '100%' }}>
              در خواست شما ارسال شد
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack3} autoHideDuration={6000} onClose={handleCloseSnack3}>
            <Alert onClose={handleCloseSnack3} severity="warning" sx={{ width: '100%' }}>
              خطایی رخ داد
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack4} autoHideDuration={6000} onClose={handleCloseSnack4}>
            <Alert onClose={handleCloseSnack4} severity="warning" sx={{ width: '100%' }}>
              شماره های کارت ملی هم خوانی ندارند
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack5} autoHideDuration={6000} onClose={handleCloseSnack5}>
            <Alert onClose={handleCloseSnack5} severity="warning" sx={{ width: '100%' }}>
              {errorMessageKyc}
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack6} autoHideDuration={6000} onClose={handleCloseSnack6}>
            <Alert onClose={handleCloseSnack6} severity="success" sx={{ width: '100%' }}>
              {errorSms}
            </Alert>
          </Snackbar>
        </div>
      </div>
    </div >
  );
}

export default Kyc;
