import React from "react";
import "./home.scss";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YoutubeIcon from "@mui/icons-material/YouTube";

function HomeSocial() {
  return (
    <div className="social-main">
      <div className="container">
        <div className="row social-body">
          <div className="col-12 col-lg-6">
            <h3>در شبکه های اجتماعی ما را دنبال کنید</h3>
            <h5>از آخرین اخبار و دستاوردها مطلع و بهره مند شوید</h5>
          </div>
          <div className="col-12 col-lg-6  social-col-main">
            <div className="white-bg-social">
              <a
                href="https://t.me/terixoexc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon className="social-iconstelegram" />
              </a>
              <a
                href="https://www.instagram.com/terixoexc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon className="social-iconsinsta" />
              </a>
              {/* <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon className="social-iconsface" />
              </a> */}
              <a
                href="https://twitter.com/terixoexchange?t=coIEBCsspkYY5V7JQkNDXQ&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon className="social-iconstwitter" />
              </a>
              <a
                href="https://www.youtube.com/channel/UCl9HkygshLdJ7FhNOO25vnA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon className="social-iconsyoutube" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeSocial;
