import React, { useEffect } from "react";
import "./withdraw.scss";
import { useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useSearchParams } from "react-router-dom";
import { createApiAddress } from "../../../constant/api.constant";

function WithdrawConfirmation() {
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [queryParams] = useSearchParams();

  useEffect(() => {
    const request2confirm = async () => {
      let res = await fetch(
        createApiAddress("api.WITHDRAW_UPDATE"),
        {
          method: "POST",
          body: JSON.stringify({
            h: queryParams.get("h"),
            type: queryParams.get("type"),
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let resJson = await res.json();

      setMsg(resJson.locale.fa);
      setIsLoading(false);
    };
    request2confirm();
  }, []);
  return (
    <div className="container-fluid confirmation-main">
      <div className="row justify-content-center">
        <div className="col-11 col-md-6 text-center">
          <div className="confirmation-main">
            <div className="confirmation-module">
              <h5>وضعیت برداشت</h5>
              {isLoading === true ? (
                <div className="confirm-loader">
                  <Blocks visible={true} height="60" width="60" wrapperStyle={{}} />
                </div>
              ) : (
                <h6>{msg}</h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WithdrawConfirmation;
