import React from "react";
import "../footer/footer.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import { Accordion } from "react-bootstrap";
import Enamad from "../../assets/enamad-1star.png"
import AdsClickIcon from '@mui/icons-material/AdsClick';

function Footer() {
  const { pathname } = useLocation();
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  const regex = new RegExp("/trade/.*");
  const regex1 = new RegExp("/mobilechart/.*");
  const regex2 = new RegExp("/instant-chart/.*");
  const regex3 = new RegExp("/trade-chart-mobile/.*");
  const regex4 = new RegExp("/pmobile");
  const regex5 = new RegExp("/checkuser");
  const regex6 = new RegExp("/logoutuser");
  const regex7 = new RegExp("/maintenance");
  const regex8 = new RegExp("/mhome");
  const regex9 = new RegExp("/withdrawconfirmation");

  if (regex.test(pathname) || regex1.test(pathname) || regex2.test(pathname) || regex3.test(pathname) || regex4.test(pathname) || regex5.test(pathname) || regex6.test(pathname) || regex7.test(pathname) || regex8.test(pathname) || regex9.test(pathname) || isMobileDevice === true) return null;


  return (
    <div className="footer-main container-fluid">
      <div className="row d-none d-xl-flex justify-content-between">
        <div className="col-12 col-md-2 logoherefooter">

        </div>


        <div className="col-12 col-md-2 mt-4">
          <ul>
            <h3>درباره ما</h3>
            <li>
              <NavLink as={Link} to="/about" className="nav-link-footer">
                معرفی <ScrollToTop />
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/career" className="nav-link-footer">
                موقعیت های شغلی
                <ScrollToTop />
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/announcement" className="nav-link-footer">
                اعلانات
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/termsofuse" className="nav-link-footer">
                مقررات و شرایط استفاده
              </NavLink>
            </li>

          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <ul>
            <h3>محصولات</h3>
            <li>
              <NavLink
                as={Link}
                to="/trade/BTC_USDT"
                className="nav-link-footer"
                target="_blank"
              >
                معاملات حرفه ای
              </NavLink>
            </li>
            <li>
              <NavLink
                as={Link}
                to="/instantbuysell"
                className="nav-link-footer"
              >
                درگاه معاملات تتر
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/market" className="nav-link-footer">
                بازار رمز ارزها
              </NavLink>
            </li>

          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <ul>
            <h3>خدمات</h3>
            <li>
              <NavLink as={Link} to="/kyc" className="nav-link-footer">
                احراز هویت{" "}
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/fee" className="nav-link-footer">
                کارمزدها{" "}
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/learning" className="nav-link-footer">
                ویدئوهای آموزشی{" "}
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/support" className="nav-link-footer">
                پشتیبانی ۲۴ ساعته{" "}
              </NavLink>
            </li>
            <li>
              <NavLink as={Link} to="/commission" className="nav-link-footer">
                درآمد ثابت{" "}
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-12 col-md-2 mt-4">
          <h3>ارتباط 24 ساعته با ما</h3>
          <p> ارسال ایمیل به: <span>Support@terixo.com</span></p>

          <p>
            <NavLink as={Link} to="/support" className="nav-link-footer2">
              پشتیبانی آنلاین <AdsClickIcon />
            </NavLink>
          </p>
          <p className="mt-5">تلفن 0۲۱۸۶۱۹۴۶۲۵</p>
          <p className="padding-p">از ساعت ۸/۳۰ تا ساعت ۱۶ روزهای کاری</p>
        </div>

      </div>
      <div className="footer-accordion container">
        <div className="row d-block d-xl-none footer-mobile">
          <div className="logofootermobile">
            {/* <img src={Logo} width={150} height={120} alt="logo" /> */}
          </div>
          <Accordion flush>
            <Accordion.Item eventKey="0">
              <Accordion.Header>درباره ما</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink as={Link} to="/about" className="nav-link-footer">
                      معرفی
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/career"
                      className="nav-link-footer"
                    >
                      موقعیت های شغلی
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={Link} to="/announcement" className="nav-link-footer">
                      اعلانات
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={Link} to="/termsofuse" className="nav-link-footer">
                      مقررات و شرایط استفاده
                    </NavLink>
                  </li>
                  <li>
                    <NavLink as={Link} to="/" className="nav-link-footer">
                      حریم خصوصی
                    </NavLink>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>محصولات</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink
                      as={Link}
                      to="/trade/BTC_USDT"
                      className="nav-link-footer"
                      target="_blank"
                    >
                      معاملات حرفه ای
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/instantbuysell"
                      className="nav-link-footer"
                    >
                      درگاه معاملات تتر
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/market"
                      className="nav-link-footer"
                    >
                      بازار رمز ارزها
                    </NavLink>
                  </li>

                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>خدمات</Accordion.Header>
              <Accordion.Body>
                <ul>
                  <li>
                    <NavLink as={Link} to="/kyc" className="nav-link-footer">
                      احراز هویت
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/fee"
                      className="nav-link-footer"
                    >
                      کارمزدها
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/learning"
                      className="nav-link-footer"
                    >
                      ویدئوهای آموزشی
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/support"
                      className="nav-link-footer"
                    >
                      پشتیبانی ۲۴ ساعته
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={Link}
                      to="/commission"
                      className="nav-link-footer"
                    >
                      درآمد ثابت
                    </NavLink>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>ارتباط با ما</Accordion.Header>
              <Accordion.Body>

                <h3>ارتباط 24 ساعته با ما</h3>
                <p> ارسال ایمیل به: <span>Support@terixo.com</span></p>

                <p>
                  <NavLink as={Link} to="/support" className="nav-link-footer2">
                    پشتیبانی آنلاین <AdsClickIcon />
                  </NavLink>
                </p>
                <p className="mt-5">تلفن 0۲۱۸۶۱۹۴۶۲۵</p>
                <p className="padding-p">از ساعت ۸/۳۰ تا ساعت ۱۶ روزهای کاری</p>
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>
        </div>

        <div className="row justify-content-center">
          <div className="col-3 col-md-1">
            <a target='_blank' href='https://trustseal.enamad.ir/?id=482756&Code=QAAd3K3WUAZRsWtt59wdKouy0t5cLP3L'>
              <img src={Enamad} alt='enamad' style={{ cursor: "pointer", height: 70 }} />
            </a>
          </div>
          <div className="col-12 col-md-9">
            <h6 className="text-center mt-4 copyright" >کلیه حقوق این سایت متعلق به تریکسو می باشد</h6>
          </div>
          <div className="col-12 col-md-1">

          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
