
import { useContext, useEffect } from "react";
import { useState } from "react";
import { getDepositHistory } from "../../../utils/Api";
import { UserContext } from "../../../contexts/providers/user";
import moment from "jalali-moment";
import { toFixed } from "../../../utils/math";
import DepositHistoryEachSite from "./DepositHistoryEachSite";

function DepositHistory() {
  const { state } = useContext(UserContext);
  const [historyDepData, setHistoryDepData] = useState(null);
  const errorCatch = (error) => { };

  useEffect(() => {
    getDepositHistory(setHistoryDepData, errorCatch);
  }, []);



  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="deposit-his-main-mobile">
        {historyDepData !== null
          ? historyDepData
            .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))
            .map((row, index) => (
              <DepositHistoryEachSite
                key={row.currency}
                image={state.currency.images[row.currency]}
                index={index}
                time={`${moment
                  .from(row.updated_at, "en")
                  .locale("fa")
                  .format("jYYYY/jMM/jDD")}${" -- "}
           ${moment(row.updated_at).format("HH:mm:ss")}`}
                symbol={row.currency}
                amount={toFixed(row.amount)}
                status={row.info?.status === "confirmed" ? "تایید شده" : "در حال تایید"}
                txid={row?.info?.transaction_id}
              />
            ))
          : <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="col text-center">
                <p className="loading-deposit">هیچ اطلاعاتی پیدا نشد</p>
              </div>
            </div>
          </div>}
      </div>
    </>
  }
  return (
    <>
      <div className="deposit-his-main-mobile">
        {historyDepData !== null
          ? historyDepData
            .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))
            .map((row, index) => (
              <DepositHistoryEachSite
                key={row.currency}
                image={state.currency.images[row.currency]}
                index={index}
                time={`${moment
                  .from(row.updated_at, "en")
                  .locale("fa")
                  .format("jYYYY/jMM/jDD")}${" -- "}
           ${moment(row.updated_at).format("HH:mm:ss")}`}
                symbol={row.currency}
                amount={toFixed(row.amount)}
                status={row.info?.status === "confirmed" ? "تایید شده" : "در حال تایید"}
                txid={row?.info?.transaction_id}
              />
            ))
          : <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="col text-center">
                <p className="loading-deposit">هیچ اطلاعاتی پیدا نشد</p>
              </div>
            </div>
          </div>}
      </div>
    </>
  );
}

export default DepositHistory;
