import React from 'react'
import { UserContext } from '../../contexts/providers/user';
import { useContext } from 'react';
import { useState } from 'react';
import moment from "jalali-moment";
import TransactionHistoryEach from './TransactionHistoryEach';
import { useEffect } from 'react';
import { getCurrencyDecimals, toFixed } from "../../utils/math";
import ResponsiveAppBar from '../home/NavbarMobile';
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile';
import { createApiAddress } from '../../constant/api.constant';
import { useNavigate } from 'react-router-dom';
import { ItemNames, getStorage } from '../../utils/RSS';

function TransactionHistoryMobile() {

    const [transactionHistory, setTransactionHistory] = useState(null)
    const navigate = useNavigate()
    const { state, dispatch } = useContext(UserContext);

    useEffect(() => {
        if
            (transactionHistory === undefined || transactionHistory === null) {
            getActivity()

        }
    }, [transactionHistory]);

    const getActivity = () => {
        fetch(createApiAddress("api.BALANCE_HISTORY"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
        })
            .then(async (response) => {
                if (response.status === 401) {
                    dispatch({ type: "Logout" })
                    return;
                }
                if (response.status === 503) {
                    navigate('/maintenance')
                    return;
                }
                if (response.status === 200) {
                    const data = await response.json();
                    setTransactionHistory(data?.data?.balance_history);

                } else {
                    // console.log("Error fetching data:", response.statusText);
                }
            })
            .catch((error) => {

            });
    }



    const changeWallet = (wallet) => {
        if (wallet === 'trading') {
            return 'کیف پول معاملاتی'
        } else if (wallet === 'main') {
            return 'کیف پول اصلی'
        }
    }

    return (
        <div className='transactionhistory'>
            <NavbarTopMobile />
            <div className="deposit-his-main-mobile mt-5">
                {transactionHistory !== null
                    ? transactionHistory
                        .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))
                        .map((row, index) => (
                            <TransactionHistoryEach
                                key={row.currency}
                                image={state.currency.images[row.currency]}
                                index={index}
                                time={`${moment
                                    .from(row.updated_at, "en")
                                    .locale("fa")
                                    .format("jYYYY/jMM/jDD")}${" -- "}
                                ${moment(row.updated_at).format("HH:mm:ss")}`}
                                symbol={row.currency}
                                amount={toFixed(parseFloat(`${row?.amount}`), getCurrencyDecimals(row?.currency, "amount"))}
                                status={row?.type_translate}
                                txid={changeWallet(row?.wallet_type)}
                            />
                        ))
                    : <div className="container-fluid ">
                        <div className="row justify-content-center">
                            <div className="col text-center">
                                <p className="loading-deposit">هیچ اطلاعاتی پیدا نشد</p>
                            </div>
                        </div>
                    </div>}
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionHistoryMobile
