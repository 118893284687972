import React, { useContext, useState } from "react";
import "./profile.scss";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useRef } from "react";
import { FileCopy, Telegram, WhatsApp } from "@mui/icons-material";
import { Alert, Button, Snackbar } from "@mui/material";
import { UserContext } from "../../contexts/providers/user";
import { TelegramShareButton, WhatsappShareButton } from "react-share";
import CommissionPageMobile from "./CommissionPageMobile";
import { useEffect } from "react";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage } from "../../utils/RSS";
import { useNavigate } from "react-router-dom";

function CommissionPage() {
  const copyToClipBoard = useRef(null);
  const { state, dispatch } = useContext(UserContext)
  const [show, setShow] = useState(false)
  const [severity, setSeverity] = useState('')
  const [message, setMessage] = useState('')
  const handleCloseSnackBar = () => {
    setShow(false)
  }
  const openErrorSnack = (msg, sev) => {
    setMessage(msg)
    setSeverity(sev)
    setShow(true)
  }

  const navigate = useNavigate()

  const handleCopy = () => {
    if (copyToClipBoard.current) {
      const textToCopy = copyToClipBoard.current.innerText;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(textToCopy);
        openErrorSnack('کپی شده است', 'success')
      } else {
        const tempTextArea = document.createElement("textarea");
        tempTextArea.value = textToCopy;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand("copy");
        document.body.removeChild(tempTextArea);
        openErrorSnack('کپی شده است', 'success')
      }
    }
  };

  useEffect(() => {
    if (state.user_profile.refer_count === undefined || state.user_profile.refer_count === null) {
      getReferCount()
    }

  }, [state.user_profile.refer_count]);


  const getReferCount = () => {
    fetch(createApiAddress("api.REFER_COUNT"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then(async (response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" })
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.ok) {
          const data = await response.json();
          dispatch({ type: "set:profile:refer_count", refer_count: data?.data?.refer_count });
          // console.log(data)

        } else {
          // console.log("Error fetching data:", response.statusText);
        }
      })
      .catch((error) => {

      });
  }

  const url = ' ';
  const whatsappMessage = `  3 عدد موبایل سامسونگ s23  
  7500 تتر  
  برای ۵۰ نفر  
  با ثبت نام در سایت
  http://www.Terixo.com/login/${state?.user_profile?.refer?.id}
  و دعوت دوستان خود با کد معرف شما، شانس خود را برای دریافت جایزه افزایش دهید.
  `;


  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <CommissionPageMobile />
    </>
  }

  return (
    <>
      <div className="commission-main">
        <div className="container commission-table">
          <div className="row justify-content-center">
            <div className="fee-title">
              <h4 className="text-center pt-4 ">
                %30 کارمزد هربار معامله به شما تعلق میگیرد
              </h4>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="pt-3">
                کاربر گرامی کد دعوت ذیل مختص شما میباشد و می توانید با ارسال
                لینک دعوت از طریق واتس اپ، تلگرام، پیامک، ایمیل و.. دوستان خود
                را دعوت نمایید تا از طریق لینک و با کد معرف شما در تریکسو ثبت
                نام نمایند.
              </p>
            </div>
          </div>
          <div className="row p-4">
            <div className="col">
              <div className="d-flex">
                <div className="col-4">
                  <p className="mt-2">کد دعوت: {state?.user_profile?.refer?.id}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-6 d-flex justify-content-between">
                  <p className="mt-2">
                    لینک دعوت:{" "}
                    <span ref={copyToClipBoard}>
                      http://www.terixo.com/register#{state?.user_profile?.refer?.id}
                    </span>
                  </p>
                </div>
                <div className="col-6 d-flex justify-content-between m-1">
                  <Button
                    onClick={handleCopy}
                    endIcon={<FileCopy fontSize="10" />}
                    className="button-copy"
                  >
                    کپی
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div>
                <WhatsappShareButton url={url} title={whatsappMessage} className="share-btn">
                  در واتس اپ به اشتراک بگذارید <WhatsApp />
                </WhatsappShareButton>
              </div>
              <div>
                <TelegramShareButton url={url} title={whatsappMessage} className="share-btn">
                  در تلگرام به اشتراک بگذارید <Telegram />
                </TelegramShareButton>
              </div>
            </div>
          </div>
          <div className="row justify-content-center p-4">
            <div className="col-9 ">
              <div className="line"></div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p className="p-2">
                <FiberManualRecordIcon className="dot-in-com" />
                کافیست دوستان شما لینک بالا را کلیک نمایند تا به آسانی به صفحه
                ثبت نام همراه با کد دعوت شما متصل شوند و با وارد کردن ایمیل خود
                میتوانند ثبت نام نمایند.
              </p>
              <p className="p-2">
                {" "}
                <FiberManualRecordIcon className="dot-in-com" /> تعداد ثبت نام
                برای شما قابل روئیت میباشد.
              </p>
              <p className="p-2">
                {" "}
                <FiberManualRecordIcon className="dot-in-com" />
                شما با صرف چند دقیقه زمان و ارسال لینک برای دوستان و آشنایان خود
                می توانید درآمد ثابت مناسب برای خود ایجاد نمایید و مبلغ درآمد را
                به حساب بانکی خود واریز نمایید.
              </p>
              <p className="p-2">
                {" "}
                <FiberManualRecordIcon className="dot-in-com" />
                معادل 30% کارمزد هربار معامله توسط فرد دعوت شده به شما تعلق
                میگیرد.
              </p>
              <p className="p-2">
                {" "}
                <FiberManualRecordIcon className="dot-in-com" />
                چنانچه کارمزد در قالب رمز ارزهای مختلف پرداخت شده باشد مبلغ شما
                به تومان محاسبه ، تبدیل و پرداخت میگردد.
              </p>
            </div>
          </div>
          <div className="row justify-content-center p-4">
            <div className="col-9 ">
              <div className="line"></div>
            </div>
          </div>
          <div className="row p-5 justify-content-center">
            <h5 className="text-center pb-3">آمار عملکرد و درآمد</h5>
            <div className="col-11 col-md-3 bot-div-com">
              <p className="text-center">تعداد ثبت نام</p>
              <p className="text-center">{state.user_profile.refer_count ?? 0}</p>
            </div>
            <div className="col-11 col-md-3 bot-div-com">
              <p className="text-center">درآمد دریافتی شما (تومان)</p>
              <p className="text-center">0</p>
            </div>
          </div>
        </div>
        <Snackbar
          open={show}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
}

export default CommissionPage;
