import React, { useState } from "react";
import "./highlighter.scss";

export default function Highlighter({ numbers }) {
  const [highlightedIndexes, setHighlightedIndexes] = useState([]);
  const [lastIndex, setLastIndex] = useState();

  const handleMouseEnter = (index) => {
    const indexes = Array.from({ length: numbers.length }, (_, i) =>
      i >= index ? i : null
    ).filter((i) => i !== null);

    setLastIndex(indexes[indexes.length - 1]);
    setHighlightedIndexes(indexes);
  };

  const handleMouseLeave = () => {
    setHighlightedIndexes([]);
  };

  return (
    <div>
      {numbers.map((number, index) => (
        <div
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          className={`number ${
            highlightedIndexes.includes(index) ? "bg-highlight" : ""
          }`}
        >
          {number}
        </div>
      ))}
    </div>
  );
}
