import * as React from "react";
import { Button } from "@mui/material";



export default function TradeSlider({ onchange = () => { }, }) {


  const handleValueOnClick = (newValue) => {
    onchange(newValue)
  }

  return (
    <div className="container-fluid p-0">
      <div className="row justify-content-center p-0 mobile-slider">

        <div className="col-3 slider-btn-holder">
          <Button onClick={() => handleValueOnClick(100)} className="slider-btn-trade">100%</Button>
        </div>
        <div className="col-3 slider-btn-holder">
          <Button onClick={() => handleValueOnClick(75)} className="slider-btn-trade">75%</Button>
        </div>
        <div className="col-3 slider-btn-holder">
          <Button onClick={() => handleValueOnClick(50)} className="slider-btn-trade ">50%</Button>
        </div>
        <div className="col-3 slider-btn-holder">
          <Button onClick={() => handleValueOnClick(25)} className="slider-btn-trade">25%</Button>
        </div>
      </div>
    </div>
  );
}
