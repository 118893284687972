
import Button from '@mui/material/Button';
import React from 'react'
import BuySellModule from './BuySellModuleE';
import CloseIcon from '@mui/icons-material/Close';
function BuySellOpener() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    // console.log(open)
    return (
        <div>
            {open === false ? <Button className='mobile-btn-buysell-opener' fullWidth onClick={handleOpen}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-6 text-center">
                            فروش
                        </div>
                        <div className="col-6 text-center">
                            خرید
                        </div>
                    </div>
                </div>
            </Button> : <></>}
            <div className="mobile-buysell-close">
                {open === true ? <Button className='mobile-btn-buysell-closer' onClick={handleClose}><CloseIcon /></Button> : <></>}
            </div>
            {open === true ? <div className="container-fluid p-0 m-0 buy-sell-mobile-module">
                <div className="row p-0 m-0">
                    <div className="col-12 p-0 m-0">
                        <BuySellModule />
                    </div>
                </div>
            </div> : <></>}
            {open === true ? <div className="container-fluid p-0 m-0 buy-sell-mobile-overlay">
                <div className="row p-0 m-0">
                    <div className="col-12 p-0 m-0">

                    </div>
                </div>
            </div> : <></>}
        </div>
    )
}

export default BuySellOpener
