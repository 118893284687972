import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./home.scss";

function FAccordion2() {
  return (
    <div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            چگونه رمز ارز به کیف پول واریز میشود
          </Accordion.Header>
          <Accordion.Body>
            شما با مراجعه به قسمت واریز و انتخاب گزینه واریز رمز ارز لیست رمز
            ارزها را مشاهده خواهید نمود. سپس رمز ارزی که بایستی به حساب شما
            واریز شود را انتخاب و ادرس کیف پول آن رمز ارز را کپی میکنید و برای
            واریز کننده ارسال نمایید، نکته مهم همراه با ارسال ادرس کیف پول نوع
            شبکه که عملیات روی آن انجام می‌گیرد را هم باید به واریز کننده اعلام
            نمایید.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>
            فرق کیف پول اصلی و کیف پول معاملاتی چیست
          </Accordion.Header>
          <Accordion.Body>
            تریکسو برای افزایش امنیت دارای کاربران خود، برای هرکاربر دو نوع کیف پول در اختیار قرار داده است. لذا مبلغ موجودی را در کیف پول اصیل نگه دارید و چنانچه قصد خرید یا فروش رمز ارز داشتید به همان مقدار مورد نظر رمز ارز را به کیف پول معاملی انتقال دهید و عملیات را انجام دهید. </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            فرق انتقال رمز ارز با واریز رمز ارز چیست
          </Accordion.Header>
          <Accordion.Body>انتقال مقدار دلخواه رمز ارز به قصد مشارکت در خرید یا فروش از کیف پول اصلی شما به کیف پول معاملاتی صورت میگیرد، اما در واریز از کیف پول دیگری به ادرس کیف پول شما رمز ارز واریز میشود.  </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            برای فعالیت در تریکسو شرایط خاصی لازم است
          </Accordion.Header>
          <Accordion.Body>
            شما بایستی حداقل ۱۸ سال تمام سن داشته باشید، احراز هویت نمایید و برابر مقررات تریکسو به آسانی فعالیت
            تجاری داشته باشید.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            رمز ارزهای لیست شده در تریکسو اعتبار سنجی میشوند
          </Accordion.Header>
          <Accordion.Body>تیم فنی و اقتصادی تریکسو با بررسی کامل رمز ارزهای موجود در بازار تلاش دارند که فقط معتبرترین رمزارزها را در پلتفورم لیست نمایند. </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            در صورت قطعی اینترنت، یا اختلال در شبکه چگونه عمل میشود
          </Accordion.Header>
          <Accordion.Body>
            جای نگرانی نیست چون تیم فنی ما از قبل یا بلافاصله موضوع را از طریق
            اعلانات وبسایت و شبکه های  اجتماعی رسمی خود مثل تلگرام، اینستاگرام و... به کاربران خود اطلاع رسانی می‌کند و با جدیت در صدد حل و
            برطرف کردن مشکل و اعلام نتیجه خواهد بود.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default FAccordion2;
