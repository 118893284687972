import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import Withdraw from "./WithdrawCrypto";
import WithdrawMoney from "./WithdrawMoney";
import WithdrawHistory from "./WithdrawHistory";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { createApiAddress } from "../../../constant/api.constant";
import { UserContext } from "../../../contexts/providers/user";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarTopMobile from "../../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../../home/NavbarMobile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;



  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = useState(0);
  const [limitData, setLimitData] = useState();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [walletDatas, setWalletDatas] = useState([]);
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(walletDatas).length === 0) {
      fetch(createApiAddress("trade.user_wallet"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            dispatch({ type: "Logout" });
            return;
          } else if (response.status === 503) {
            navigate('/maintenance')
            return;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setWalletDatas(data.data);
          }
        })
        .catch((error) => {
          console.error("Error fetching user wallet data:", error);
        });
    }
  }, [walletDatas]);

  useEffect(() => {
    fetch(createApiAddress("api.withdraw_limit"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        } else if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setLimitData(data.data)
        }
      })
      .catch((error) => {
        console.error("Error fetching user wallet data:", error);
      });

  }, []);

  const location = useLocation()
  useEffect(() => {
    if (location.hash !== "") {
      var number = location.hash.replace('#', '')
      number = parseInt(number)
      setValue(number)
    }
  }, [location.pathname])

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  if (isMobileDevice === true) {
    return <>
      <div className="withdraw-both-mobile">
        <NavbarTopMobile />
        <div className="withdrawModule">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="withdraw-tab"
              >
                <Tab label="برداشت رمز ارز" {...a11yProps(0)} />
                <Tab label="برداشت تومان" {...a11yProps(1)} />
                <Tab label="تاریخچه" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Withdraw walletData={walletDatas} timeBaseLimit={limitData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <WithdrawMoney walletData={walletDatas} timeBaseLimit={limitData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <WithdrawHistory />
            </TabPanel>
          </Box>
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="withdraw-both">
      <div className="withdrawModule">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="withdraw-tab"
            >
              <Tab label="برداشت رمز ارز" {...a11yProps(0)} />
              <Tab label="برداشت تومان" {...a11yProps(1)} />
              <Tab label="تاریخچه" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Withdraw walletData={walletDatas} timeBaseLimit={limitData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <WithdrawMoney walletData={walletDatas} timeBaseLimit={limitData} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <WithdrawHistory />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
