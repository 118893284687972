import React from "react";
import { initialStateSocket, reducerIO } from "../reducer/socket";

export const SocketContext = React.createContext({
  stateIO: initialStateSocket,
  dispatch: () => null,
});

export const SocketProvider = ({ children }) => {
  const [stateIO, dispatchIO] = React.useReducer(reducerIO, initialStateSocket);

  return (
    <SocketContext.Provider value={{ stateIO, dispatchIO }}>
      {children}
    </SocketContext.Provider>
  );
};
