import * as React from "react";
import { useContext } from "react";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import { Alert } from "@mui/material";
import { Stack } from "react-bootstrap";
import { UserContext } from "../providers/user";
import "./snackglobal.scss";
function TransitionUp() {
  return <Slide direction="up" />;
}

export default function SnackbarGlobal({ closeSnack }) {
  const { state, dispatch } = useContext(UserContext);

  return (
    <Stack spacing={2} sx={{ maxWidth: 300 }}>
      <Snackbar
        open={state.isOpenSnack}
        onClose={closeSnack}
        // TransitionComponent={TransitionUp}
        // anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
      >
        <Alert
          severity={state.snackColor}
          sx={{ fontSize: 18, maxWidth: 400 }}
          variant="filled"
        >
          {state.snackMessage}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
