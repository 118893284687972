import React, { useContext, useEffect, useState } from 'react'
import "./assets.scss"
import VipImage from '../../assets/images/vip.png'
import { UserContext } from '../../contexts/providers/user'
import { createApiAddress } from '../../constant/api.constant'
import { ItemNames, getStorage } from '../../utils/RSS'
import { useNavigate } from 'react-router-dom'
import WalletVIPEach from './WalletVIPEach'
import { toFixed } from '../../utils/math'
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile'
import ResponsiveAppBar from '../home/NavbarMobile'

const VipWallet = () => {
    const { state, dispatch } = useContext(UserContext);
    const [vipWallet, setVipWallet] = useState()
    const navigate = useNavigate();
    useEffect(() => {
        vipUserWallet()
    }, [])
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);


    const vipUserWallet = async () => {
        try {
            const response = await fetch(createApiAddress("api.VIP_WALLET"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            })
            const wallets = await response.json();
            if (response.status === 503) {
                navigate('/maintenance')
                return;
            } else if (response.status === 401) {
                dispatch({ type: "Logout" });
                return;
            } else if (response.status === 403) {
                navigate('/')
                return;
            } else if (response.status === 200) {
                setVipWallet(wallets?.data?.balances)
            }

        } catch (e) {

        }
    }

    const getTokenImage = async (token) => {
        try {
            const response = await fetch(`https://api.terixo.com/api/v3/public/currency/image/${token}`, {
                method: "GET",
            });

            if (response.status === 200) {
                return response.url;
            } else {

                return null;
            }
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    };

    const getImageForCurrency = async (currencyToken) => {
        try {
            const imageData = await getTokenImage(currencyToken);
            return imageData;
        } catch (error) {
            console.error("Error getting image for currency:", error);
            return null;
        }
    };

    const balancesArray = () => {
        if (vipWallet !== null && vipWallet !== undefined) {
            return Object.values(vipWallet);
        } else {
            return []
        }
    }

    const balances = balancesArray();
    if (state.user_profile?.info?.kyc?.vip_status !== 'approved') {
        return <>
            <div className="container-fluid vip-his-main">
                <div className="row justify-content-center">
                    <div className="col-12 mt-5 text-center">
                        <div className="col-12  text-center">
                            <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                        </div>
                        <p className='p-in-history'>شما به این صفحه دسترسی ندارید.</p>
                    </div>
                </div>
            </div>
        </>
    }

    if (state.user_profile?.info?.kyc?.vip_status !== 'approved' && isMobileDevice) {
        return <>
            <div className="container-fluid vip-his-main-mobile">
                <NavbarTopMobile />
                <div className="row justify-content-center">
                    <div className="col-12 mt-5 text-center">
                        <div className="col-12  text-center">
                            <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                        </div>
                        <p className='p-in-history'>شما به این صفحه دسترسی ندارید.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar className="" />
                    </div>
                </div>
            </div>
        </>
    }

    if (state.user_profile?.info?.kyc?.vip_status === 'approved' && isMobileDevice) {
        return <>
            <div className='vip-main-mobile'>
                <NavbarTopMobile />
                <div className="container-fluid vip-module">
                    <div className="row">
                        <div className="col-12 col-md-6 mt-5 text-justify">
                            <h5>کیف پول ویژه اعضای VIP</h5>
                            <h6>در این قسمت می توانید میزان دارایی تومان و تتر خود را مشاهده نمایید.</h6>
                        </div>
                        <div className="col-12 col-md-6 text-start">
                            <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                        </div>
                    </div>
                    <div className="row justify-content-center ">
                        <div className="col-12 col-md-5 text-center ">
                            {balances?.map((items) =>
                                <WalletVIPEach symbol={items.currency !== undefined ? items.currency : "IRT"} image={getImageForCurrency(items.currency)} available={items.balance !== undefined ? toFixed(items.balance, 2) : "0"} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar className="" />
                    </div>
                </div>
            </div>
        </>
    }
    return (
        <div className='vip-main'>
            <div className="container-fluid vip-module">
                <div className="row">
                    <div className="col-12 col-md-6 mt-5 text-justify">
                        <h5>کیف پول ویژه اعضای VIP</h5>
                        <h6>در این قسمت می توانید میزان دارایی تومان و تتر خود را مشاهده نمایید.</h6>
                    </div>
                    <div className="col-12 col-md-6 text-start">
                        <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                    </div>
                </div>
                <div className="row justify-content-center ">
                    <div className="col-12 col-md-5 text-center ms-5 me-5 ">
                        {balances?.map((items) =>
                            <WalletVIPEach symbol={items.currency !== undefined ? items.currency : "IRT"} image={getImageForCurrency(items.currency)} available={items.balance !== undefined ? toFixed(items.balance, 2) : "0"} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VipWallet
