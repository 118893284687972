import React, { useEffect } from "react";
import AndroidIcon from "@mui/icons-material/Android";
import { useState } from "react";
import PlayStore from '../../assets/images/playstore.png'
import Myket from '../../assets/images/myket.png'
import SibApp from '../../assets/images/sibapp.png'

function PhoneApp() {
  const [appLink, setAppLink] = useState('')



  useEffect(() => {
    fetch("https://s3.terixo.com/public/android/applink.json?nocache=" + Date.now())
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setAppLink(data[0].link);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);

  return (
    <div className="container-fluid application-section">
      <div className="container">
        <div className="row py-5 align-items-center">
          <div className="col-lg-7 mb-3 mt-5">
            <div className="hlapp">
              <h3 >جامع ترین اپلیکیشن ارزهای دیجیتال</h3>
            </div>
            <div className="ulapp">
              <ul>
                <li className="appli">
                  در هر زمان و مکان با اپلیکیشن حرفه ای تریکسو دارایی های خود را
                  مدیریت و کنترل نمایید
                </li>
              </ul>
            </div>
            <div className="row pt-5 app-section">
              <div className="col-6 btn-app-main">
                <a className="app-btns" href={appLink} target="blank">
                  {" "}
                  <AndroidIcon className="btn-icon-app" />
                  دانلود مستقیم Android
                </a>
              </div>
              <div className="col-6 btn-app-main">
                <a className="app-btns" href='https://play.google.com/store/apps/details?id=terixo.com.terixo.terixo' target="blank">
                  <img src={PlayStore} alt="" height={30} />  پلی استور Android
                </a>
              </div>
            </div>
            <div className="row   app-section">
              <div className="col-6 btn-app-main">
                <a className="app-btns" href='https://myket.ir/app/terixo.com.terixo.terixo' target="blank">
                  <img src={Myket} alt="" height={30} />     مایکت Android
                </a>
              </div>
              <div className="col-6 btn-app-main">
                <a className="app-btns" href='https://sibapp.com/applications/TerixoPlatform' target="blank">
                  <img src={SibApp} alt="" height={30} />     سیب‌اپ Iphone
                </a>
              </div>

            </div>
          </div>
          <div className="col-lg-5 overflow-hidden">
            <div className="phone-app"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhoneApp;
