import { useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { ItemNames, getStorage } from "../../../../utils/RSS";

let tvScriptLoadingPromise;

export default function TradeChart() {
  const onLoadScriptRef = useRef();
  const { tradePair } = useParams();
  const [chartTheme, setChartTheme] = useState("light");
  const [chartBG, setChartBG] = useState("#ffffff");

  useEffect(() => {
    const handleThemeChanged = (event) => {
      const newTheme = event.detail;
      if (newTheme === 'light-theme') {
        setChartTheme('light');
        setChartBG("#ffffff")
      } else if (newTheme === 'dark-theme') {
        setChartTheme('dark');
        setChartBG("#161513")
      }
    };
    document.addEventListener("themeChanged", handleThemeChanged);
    return () => {
      document.removeEventListener("themeChanged", handleThemeChanged);
    };
  }, []);

  useEffect(() => {

    if (
      getStorage(ItemNames.THEMES) === "light-theme" ||
      getStorage(ItemNames.THEMES) === "" ||
      getStorage(ItemNames.THEMES) === undefined
    ) {
      setChartTheme("light");
      setChartBG("#ffffff")
    } else {
      setChartTheme("dark");
      setChartBG("#161513")
    }
  }, [])

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise && tradePair !== "GBR_USDT") {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }
    if (tvScriptLoadingPromise) {
      tvScriptLoadingPromise.then(
        () => onLoadScriptRef.current && onLoadScriptRef.current()
      );
    }

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_5d703") &&
        "TradingView" in window
      ) {
        let options = {
          height: 367,
          width: { calculatedWidth },
          // autosize: true,
          symbol: `BINANCE:${tradePair.replace("_", "")}`,
          interval: "D",
          timezone: "Etc/UTC",
          theme: chartTheme,
          style: "1",
          locale: "en",
          toolbar_bg: chartBG,
          backgroundColor: chartBG,
          enable_publishing: false,
          // hide_top_toolbar: true,
          // hide_legend: true,
          allow_symbol_change: false,
          container_id: "tradingview_5d703",
          hide_side_toolbar: false,
          // overrides: {
          //   "paneProperties.background": "#161513"
          // }
        };

        if (tradePair !== "GBR_USDT") {
          new window.TradingView.widget(options);
        }
      }
    }
  }, [tradePair, chartTheme]);

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const calculatedWidth = pageWidth - 50;
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="tradingview-widget-container">
      {tradePair === "GBR_USDT" ? (
        <iframe
          src="/chart.html"
          height="367"
          width={calculatedWidth}
          title="chart"
        ></iframe>
      ) : (
        <div id="tradingview_5d703" />
      )}
    </div>
  );
}
