import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Alert, Button, Snackbar } from '@mui/material';
import './support.scss'
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import ConversationPage from './SupportIndividual';
import { createApiAddress } from '../../constant/api.constant';
import { useEffect } from 'react';
import { ItemNames, getStorage } from '../../utils/RSS';
import { useContext } from 'react';
import { UserContext } from '../../contexts/providers/user';
import moment from 'jalali-moment';
import { useRef } from 'react';
import { useNavigate } from 'react-router';
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile';
import ResponsiveAppBar from '../home/NavbarMobile';

export default function Support() {
  const [show, setShow] = useState(false);
  const [ticketsList, setTicketsList] = useState([]);
  const [show2, setShow2] = useState(false);
  const [ticketId, setTicketId] = useState();
  const fileInputRef = useRef(null);
  const handleClose = () => {
    setFormData({
      description: '',
      media_file: null
    });
    setSelectedSubject('')
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const handleCloseModal = () => {
    setShow2(false);
  };

  const handleOpenTicket = (id) => {
    setTicketId(id)
    setShow2(true)
  }

  const { dispatch } = useContext(UserContext);
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    media_file: null
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [openSnackBar, setOpenSnackBar] = useState({
    show: false,
    severity: "warning",
    message: "",
  });

  const handleCloseSnackBar = () => {
    setOpenSnackBar({
      ...openSnackBar,
      show: false,
    });
  };

  const checkValidation = (event) => {
    event.preventDefault();
    if (formData.description === '' || formData.description === null || selectedSubject === 'لطفا انتخاب کنید' || selectedSubject === '') {
      setOpenSnackBar({
        show: true,
        severity: 'warning',
        message: 'عنوان و توضیح الزامی می باشد',
      });
    } else {
      handleSubmit(event)
    }
  }

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("description", formData.description);
    data.append("title", selectedSubject);
    data.append("media_file", formData.media_file);
    fetch(createApiAddress("api.CREATE_TICKET"), {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
      body: data,
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        } else if (response.status === 503) {
          navigate('/maintenance')
        } else if (response.ok) {
          return response.json();
        } else {
          throw setOpenSnackBar({
            show: true,
            severity: 'warning',
            message: data?.locale?.fa,
          });;
        }
      })
      .then((data) => {
        if (data) {
          setOpenSnackBar({
            show: true,
            severity: 'success',
            message: data?.locale?.fa,
          });
          setSelectedSubject('')
          getTicketsList();
          setFormData({
            description: '',
          });
          handleClose();
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    getTicketsList()
  }, []);

  const getTicketsList = () => {
    fetch(createApiAddress("api.CREATE_TICKET"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then(async (response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" })
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.ok) {
          const data = await response.json();
          setTicketsList(data?.data?.tickets)
        } else {
        }
      })
      .catch((error) => {

      });
  }

  const statusChecker = (row) => {
    if (row.status === 'pending') {
      return 'تیکت ایجاد شد'
    } else if (row.status === 'awaiting_process') {
      return 'در انتظار پاسخ'
    } else if (row.status === 'answered') {
      return 'پاسخ داده شد'
    } else if (row.status === 'closed') {
      return 'بسته شد'
    }
  }

  const handleFileButtonClick1 = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      media_file: event.target.files[0],
    });
  };
  const subjectList = [
    'لطفا انتخاب کنید',
    'مشکل در واریز',
    'مشکل در برداشت',
    'مشکل در احراز هویت',
    'مشکل در درگاه تتر',
    'مشکل در معاملات حرفه ای',
    'مشکل در کیف پول',
    'مشکل در امور امنیتی',
    'سایر مشکلات'
  ];

  const [selectedSubject, setSelectedSubject] = useState('');

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSubject(selectedValue);
  };

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="support-main-mobile ">
        <NavbarTopMobile />
        <div className="row">
          <div className="col mt-5">
            <Button onClick={handleShow} className='support-btn'>ارسال تیکت جدید</Button>
          </div>
        </div>
        {ticketsList?.map((row, index) => (
          <div className="container sup-mobile-card">
            <div className="each-ticket-module ">
              <div className="row">
                <div className="col-6">
                  <p>عنوان</p>
                  <p>{row.title}</p>
                </div>
                <div className="col-6 text-start">
                  <p>وضعیت</p>
                  <p>{statusChecker(row)}</p>
                </div>
              </div>
            </div>
            <div className="each-ticket-module">
              <div className="row">
                <div className="col-6">
                  <p>زمان</p>
                  <p> {moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss  ')}</p>
                </div>
                <div className="col-6 text-start">
                  <Button className="support-btn" variant="contained" color="primary" onClick={() => handleOpenTicket(row?._id)}>
                    تیکت را ببینید
                  </Button>
                </div>
              </div>
            </div>
          </div>))
        }

        <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false} className='support-modal'>
          <Modal.Header>
            <Modal.Title>ارسال تیکت جدید</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={checkValidation}>
              <Form.Group className="mb-3 mt-5">
                {/* <Form.Label className="form-label">انتخاب کنید</Form.Label> */}
                <Form.Select
                  className="ticket-title-selection"
                  value={selectedSubject}
                  onChange={handleSelectChange}
                >
                  {/* <option value={0}>انتخاب کنید</option> */}
                  {subjectList.map((subject, index) => (
                    <option key={index} value={subject} className="select-in-deposit-money">
                      {subject}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="description">
                <Form.Label>توضیح</Form.Label>
                <Form.Control as="textarea" rows={3} name="description" value={formData.description} onChange={handleChange} className='form-ticket' />
              </Form.Group>
              <div>
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label className="mt-2">
                    {" "}
                    اگر نیاز به آپلود فایل میباشد.
                  </Form.Label>
                  <Form.Control
                    type="file"
                    name="media_file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </Form.Group>
                <Button
                  onClick={handleFileButtonClick1}
                  className="btn-in-kyc4 mb-3"
                >
                  {formData?.media_file === null ?
                    "فایل را انتخاب کنید" : formData.media_file?.name}
                </Button>
              </div>
              <Button variant="contained" onClick={handleClose} color="primary" className='support-btn ms-5'>
                بستن
              </Button>
              <Button type="submit" variant="contained" color="primary" className='support-btn'>
                ارسال
              </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
        <Modal
          show={show2}
          onHide={() => setShow2(false)}
          size="lg"
          backdrop="static" keyboard={false}
          className='support-modal'
        >

          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body><ConversationPage handleClose={handleCloseModal} ticketId={ticketId} handleReloader={getTicketsList} /></Modal.Body>
        </Modal>
        <Snackbar
          open={openSnackBar.show}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity={openSnackBar.severity}>
            {openSnackBar.message}
          </Alert>
        </Snackbar>
      </div>
      <div className="container-fluid navbar-mobile-bot">
        <div className="row justify-content-center">
          <div className="col-12">
            <ResponsiveAppBar />
          </div>
        </div>
      </div>

    </>
  }

  return (
    <div className="support-main ">
      <TableContainer component={Paper} className="support-module">
        <Button onClick={handleShow} className='support-btn'>ارسال تیکت جدید</Button>
        <Table sx={{ minWidth: 600 }} aria-label="support-table">
          <TableHead className="table-head">
            <TableRow className="table-row">
              {/* <TableCell className="table-row" align="right"  >
                دسته بندی
              </TableCell> */}
              <TableCell className="table-row" align="right"  >
                عنوان
              </TableCell>
              <TableCell className="table-row" align="right"  >
                وضعیت
              </TableCell>
              <TableCell className="table-row" align="right"  >
                زمان
              </TableCell>
              <TableCell className="table-row" align="right"  >
                تیکت را ببینید
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ticketsList?.map((row, index) => (
              <TableRow className="table-row" key={row._id}>
                {/* <TableCell className="table-row" align="right">
                  {row.category}
                </TableCell> */}
                <TableCell className="table-row" align="right">
                  {row.title}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {statusChecker(row)}
                </TableCell>
                <TableCell className="table-row" align="right">
                  {moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss [در] dddd')}
                </TableCell>
                <TableCell className="table-row" align="right">
                  <Button className="support-btn" variant="contained" color="primary" onClick={() => handleOpenTicket(row?._id)}>
                    تیکت را ببینید
                  </Button>
                </TableCell>
              </TableRow>
            ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false} className='support-modal'>
        <Modal.Header>
          <Modal.Title>ارسال تیکت جدید</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={checkValidation}>
            <Form.Group className="mb-3 mt-5">
              {/* <Form.Label className="form-label">انتخاب کنید</Form.Label> */}
              <Form.Select
                className="ticket-title-selection"
                value={selectedSubject}
                onChange={handleSelectChange}
              >
                {/* <option value={0}>انتخاب کنید</option> */}
                {subjectList.map((subject, index) => (
                  <option key={index} value={subject} className="select-in-deposit-money">
                    {subject}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>توضیح</Form.Label>
              <Form.Control as="textarea" rows={3} name="description" value={formData.description} onChange={handleChange} className='form-ticket' />
            </Form.Group>
            <div>
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label className="mt-2">
                  {" "}
                  اگر نیاز به آپلود فایل میباشد.
                </Form.Label>
                <Form.Control
                  type="file"
                  name="media_file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Form.Group>
              <Button
                onClick={handleFileButtonClick1}
                className="btn-in-kyc4 mb-3"
              >
                {formData?.media_file === null ?
                  "فایل را انتخاب کنید" : formData.media_file?.name}
              </Button>
            </div>
            <Button variant="contained" onClick={handleClose} color="primary" className='support-btn ms-5'>
              بستن
            </Button>
            <Button type="submit" variant="contained" color="primary" className='support-btn'>
              ارسال
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="lg"
        backdrop="static" keyboard={false}
        className='support-modal'
      >

        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body><ConversationPage handleClose={handleCloseModal} ticketId={ticketId} handleReloader={getTicketsList} /></Modal.Body>
      </Modal>
      <Snackbar
        open={openSnackBar.show}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert severity={openSnackBar.severity}>
          {openSnackBar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
