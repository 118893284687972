import React, { useContext } from "react";
import "./transfer.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { useEffect } from "react";
import { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Alert, ButtonBase, Snackbar, TextField } from "@mui/material";
import { Button, Form, Spinner } from "react-bootstrap";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { createApiAddress } from "../../../constant/api.constant";
import { getCurrencyDecimals, toFixed } from "../../../utils/math";
import { UserContext } from "../../../contexts/providers/user";
import { useNavigate } from "react-router-dom";
import NavbarTopMobile from "../../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../../home/NavbarMobile";

function Transfer() {
  // const errorCatch = (error) => { };
  const [show, setShow] = useState(false)
  const [severity, setSeverity] = useState('')
  const [message, setMessage] = useState('')
  const [availableCurrency, setAvailableCurrency] = useState([])
  const { dispatch } = useContext(UserContext);
  const handleCloseSnackBar = () => {
    setShow(false)
  }

  const openErrorSnack = (msg, sev) => {
    setMessage(msg)
    setSeverity(sev)
    setShow(true)
  }

  useEffect(() => {
    if (availableCurrency.length === 0) {
      fetch(createApiAddress("api.CURRENCY_GROUP"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setAvailableCurrency(data.data);

        })
    }
  }, [])

  const [walletDatas, setWalletDatas] = useState([]);
  const navigate = useNavigate()

  useEffect(() => {
    if (Object.keys(walletDatas).length === 0) {
      fetch(createApiAddress("trade.user_wallet"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => {
          if (response.status === 401) {
            dispatch({ type: "Logout" })
            return;
          } else if (response.status === 503) {
            navigate('/maintenance')
            return;
          }
          return response.json();
        })
        .then((data) => {
          if (data) {
            setWalletDatas(data.data);
          }
        })
        .catch((error) => {

        });
    }
  }, []);



  const [amount, setAmount] = useState({});

  const [state, setState] = useState({
    transferSide: false,
    amount: '',
  });

  const [switchText, setSwitchText] = useState(
    "کیف پول اصلی به کیف پول معاملاتی"
  );

  const changeForm = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
    setAmount(event.target.value);
  };

  const switchChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
    ChangeSwitchName(event);
  };

  function ChangeSwitchName(event) {
    if (event.target.checked === false) {
      setSwitchText("از کیف پول اصلی به کیف پول معاملاتی");
    } else {
      setSwitchText("از کیف پول معاملاتی به کیف پول اصلی");
    }
  }

  const [selectedToken, setSelectedToken] = useState({});
  const [mainWallet, setMainWallet] = useState(0);
  const [tradeWallet, setTradeWallet] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleTokenChange = (event) => {
    let symbol = event.target.value.symbol;
    setSelectedToken(event.target.value);
    setMainWallet(walletDatas[symbol]?.main || 0);
    setTradeWallet(walletDatas[symbol]?.trading || 0);
  };

  const requestToTransfer = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (selectedToken.symbol === undefined) {
      event.preventDefault();
      openErrorSnack("لطفا رمز ارز را انتخاب کنید", "warning")
    } else if (state.amount === '0' || state.amount === '') {
      event.preventDefault();
      openErrorSnack("لطفا مقدار را وارد کنید", "warning")
    } else if (state.transferSide === true && tradeWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else if (state.transferSide === false && mainWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else if (isNaN(state.amount) === true) {
      event.preventDefault();
      openErrorSnack("لطفا مقدار را به صورت صحیح وارد کنید", "warning")
    } else {
      let uri = createApiAddress("api.TRANSFER_REQUEST");
      setIsLoading(true);
      let data = {
        from: state.transferSide === true ? "trading" : "main",
        amount: state.amount,
        currency: selectedToken.symbol,
      };
      fetch(uri, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          if (data.status === 1) {
            setState({
              ...state,
              amount: '',
            });
            setAmount('')
            openErrorSnack("جابجایی با موفقیت انجام شد.", "success")
            setWalletDatas(data.data.wallets);
            setMainWallet(data.data.wallets[selectedToken.symbol]?.main || 0);
            setTradeWallet(data.data.wallets[selectedToken.symbol]?.trading || 0);
            setIsLoading(false);
          } else {
            openErrorSnack(data.locale.fa, "warning")
          }
        });
    }
  };

  const checkValidation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (state.transferSide === false) {
      if (amount > walletDatas[selectedToken.symbol]?.main) {
        openErrorSnack("مقدار  موجودی کافی نیست", "warning")
      } else {
        requestToTransfer(event);
      }
    } else if (state.transferSide === true) {
      if (amount > walletDatas[selectedToken.symbol]?.trading) {
        openErrorSnack("مقدار موجودی کافی نیست", "warning")
      } else {
        requestToTransfer(event);
      }
    }
  };

  const allAvailable = () => {
    if (selectedToken.symbol === undefined) {
      openErrorSnack("لطفا رمز ارز را انتخاب کنید", "warning")
    } else if (state.transferSide === true && tradeWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else if (state.transferSide === false && mainWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else {
      if (state.transferSide === true) {
        setState({
          ...state,
          amount: toFixed(tradeWallet, getCurrencyDecimals(selectedToken.symbol, "total")),
        });
        setAmount(toFixed(tradeWallet, getCurrencyDecimals(selectedToken.symbol, "total")))
      } else {
        setState({
          ...state,
          amount: toFixed(mainWallet, getCurrencyDecimals(selectedToken.symbol, "total")),
        });
        setAmount(toFixed(mainWallet, getCurrencyDecimals(selectedToken.symbol, "total")))
      }
    }
  }

  const halfAvailable = () => {
    if (selectedToken.symbol === undefined) {
      openErrorSnack("لطفا رمز ارز را انتخاب کنید", "warning")
    } else if (state.transferSide === true && tradeWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else if (state.transferSide === false && mainWallet === 0) {
      openErrorSnack("مقدار موجودی کافی نیست", "warning")
    } else {
      if (state.transferSide === true) {
        setState({
          ...state,
          amount: toFixed(tradeWallet / 2, getCurrencyDecimals(selectedToken.symbol, "total")),
        });
        setAmount(toFixed(tradeWallet / 2, getCurrencyDecimals(selectedToken.symbol, "total")))
      } else {
        setState({
          ...state,
          amount: toFixed(mainWallet / 2, getCurrencyDecimals(selectedToken.symbol, "total")),
        });
        setAmount(toFixed(mainWallet / 2, getCurrencyDecimals(selectedToken.symbol, "total")))
      }
    }
  }

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  if (isMobileDevice) {
    return <>
      <div className="container-fluid p-0 m-0">
        <NavbarTopMobile />

        <div className="transfer-main-mobile">
          <div className="container">
            <div className="row transfer-module">
              <div className="col">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <p >انتقال بین کیف های پول</p>
                  </div>
                  <div className="col-6">
                    <p className="available-trans">
                      مقدار موجود: {state.transferSide ? toFixed(tradeWallet, getCurrencyDecimals(selectedToken.symbol, "total")) : toFixed(mainWallet, getCurrencyDecimals(selectedToken.symbol, "total"))}
                    </p>
                  </div>
                </div>

                <Box sx={{ minWidth: 120 }} mt={2}>
                  <h6>لطفاً توکن را انتخاب کنید:</h6>
                  <Form onSubmit={checkValidation}>
                    <FormControl fullWidth>
                      <Select value={selectedToken} onChange={handleTokenChange}>
                        {availableCurrency
                          .sort((a, b) => a.symbol.localeCompare(b.symbol))
                          .map((currency) => (
                            <MenuItem key={currency.symbol} value={currency}>
                              <div className="d-flex mt-2">
                                <img
                                  src={currency.image}
                                  style={{ width: 30, height: 30, marginRight: 10, marginLeft: 10, marginTop: 1 }}
                                  alt={"currency"}
                                />
                                <div className="d-flex flex-column">
                                  <span>{currency.symbol}</span>
                                  <span style={{ fontSize: "10px", marginTop: "-7px" }}>({currency.name})</span>
                                </div>
                              </div>

                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <div className="mt-4">
                      <FormControl component="fieldset" variant="standard">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={state.transferSide}
                                onChange={switchChange}
                                name="transferSide"
                              />
                            }
                            label={switchText}
                          />
                        </FormGroup>
                      </FormControl>
                    </div>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-6">
                          <FormControl fullWidth>
                            <TextField
                              className="withdraw-texts"
                              InputProps={{ sx: { height: 57 } }}
                              id="address"
                              placeholder="مقدار"
                              name="amount"
                              size="medium"
                              InputLabelProps={{
                                shrink: true,
                                className: "withdraw-texts",
                              }}
                              onChange={changeForm}
                              value={state.amount}
                            />
                          </FormControl>
                        </div>
                        <div className="col-3 mt-2">
                          <Button onClick={() => allAvailable()} style={{ fontSize: 11 }}>تمام موجودی</Button>
                        </div>
                        <div className="col-3 mt-2">
                          <Button onClick={() => halfAvailable()} style={{ fontSize: 11 }}>50 درصد</Button>
                        </div>

                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <Button type="submit" disabled={isLoading}>
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? "بارگذاری..." : "انتقال"}
                      </Button>
                    </div>
                  </Form>
                  <div className="transfer-note">
                    مقدار رمز ارز یا تومان را که قصد معامله دارید، از کیف پول اصلی
                    به کیف پول معاملاتی خود انتقال دهید و سپس عملیات را انجام نمایید
                    و یا برای برداشت از کیف پول معاملاتی خود به کیف پول اصلی خود
                    انتقال دهید.
                  </div>
                </Box>
              </div>
            </div>
            <Snackbar
              open={show}
              autoHideDuration={6000}
              onClose={handleCloseSnackBar}
            >
              <Alert severity={severity}>
                {message}
              </Alert>
            </Snackbar>
          </div>
        </div>
      </div>
      <div className="container-fluid navbar-mobile-bot">
        <div className="row justify-content-center">
          <div className="col-12">
            <ResponsiveAppBar />
          </div>
        </div>
      </div>
    </>
  }
  return (
    <div className="transfer-main">
      <div className="container">
        <div className="row transfer-module">
          <div className="col">
            <div className="row justify-content-between">
              <div className="col-6">
                <h5>انتقال بین کیف های پول</h5>
              </div>
              <div className="col-6">
                <h6 className="available-trans">
                  مقدار موجود: {state.transferSide ? toFixed(tradeWallet, getCurrencyDecimals(selectedToken.symbol, "total")) : toFixed(mainWallet, getCurrencyDecimals(selectedToken.symbol, "total"))}
                </h6>
              </div>
            </div>

            <Box sx={{ minWidth: 120 }} mt={2}>
              <h6>لطفاً توکن را انتخاب کنید:</h6>
              <Form onSubmit={checkValidation}>
                <FormControl fullWidth>
                  <Select value={selectedToken} onChange={handleTokenChange}>
                    {availableCurrency
                      .sort((a, b) => a.symbol.localeCompare(b.symbol))
                      .map((currency) => (
                        <MenuItem key={currency.symbol} value={currency}>
                          <div className="d-flex mt-2">
                            <img
                              src={currency.image}
                              style={{ width: 30, height: 30, marginRight: 10, marginLeft: 10, marginTop: 1 }}
                              alt={"currency"}
                            />
                            <div className="d-flex flex-column">
                              <span>{currency.symbol}</span>
                              <span style={{ fontSize: "10px", marginTop: "-7px" }}>({currency.name})</span>
                            </div>
                          </div>

                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <div className="mt-4">
                  <FormControl component="fieldset" variant="standard">
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={state.transferSide}
                            onChange={switchChange}
                            name="transferSide"
                          />
                        }
                        label={switchText}
                      />
                    </FormGroup>
                  </FormControl>
                </div>
                <div className="mt-4">
                  <div className="row">
                    <div className="col-6">
                      <FormControl fullWidth>
                        <TextField
                          className="withdraw-texts"
                          InputProps={{ sx: { height: 57 } }}
                          id="address"
                          placeholder="مقدار"
                          name="amount"
                          size="medium"
                          InputLabelProps={{
                            shrink: true,
                            className: "withdraw-texts",
                          }}
                          onChange={changeForm}
                          value={state.amount}
                        />
                      </FormControl>
                    </div>
                    <div className="col-3 mt-2 text-center">
                      <Button onClick={() => allAvailable()}>تمام موجودی</Button>
                    </div>
                    <div className="col-3 mt-2 text-start">
                      <Button onClick={() => halfAvailable()}>50 درصد</Button>
                    </div>
                  </div>
                </div>
                <div className="text-center mt-5">
                  <Button type="submit" disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "انتقال"}
                  </Button>
                </div>
              </Form>
              <div className="transfer-note">
                مقدار رمز ارز یا تومان را که قصد معامله دارید، از کیف پول اصلی
                به کیف پول معاملاتی خود انتقال دهید و سپس عملیات را انجام نمایید
                و یا برای برداشت از کیف پول معاملاتی خود به کیف پول اصلی خود
                انتقال دهید.
              </div>
            </Box>
          </div>
        </div>
        <Snackbar
          open={show}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Transfer;
