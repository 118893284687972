import React, { useEffect, useState } from "react";
import "./fee.scss";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import { getWithdrawInfo } from "../../utils/Api";
import FeePageMobile from "./FeePageMobile";

function FeePage() {
  const { state } = useContext(UserContext);
  const [currencies, setCurrencies] = useState({});
  const [errorMsg, setErrorMsg] = useState(null);
  const errorCatch = (error) => {
    setErrorMsg(error);
  };

  useEffect(() => {
    const setWithdrawListFunc = function (data) {
      let tmp = {};
      data.forEach((currency) => {
        if (tmp[currency.symbol] === undefined) {
          tmp[currency.symbol] = {
            name: currency.name,
            symbol: currency.symbol,
            image: currency.image,
            networks: [],
          };
        }
        tmp[currency.symbol].networks.push(currency);
      });

      // Extract the values from the object and sort them
      const sortedValues = Object.values(tmp).sort((a, b) =>
        a.symbol.localeCompare(b.symbol)
      );

      // Map the sorted values back to the structure you want
      const sortedCurrenciesObject = {};
      sortedValues.forEach((currency) => {
        sortedCurrenciesObject[currency.symbol] = currency;
      });

      setCurrencies(sortedCurrenciesObject);
    };

    getWithdrawInfo(setWithdrawListFunc, errorCatch);
  }, []);

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <FeePageMobile />
    </>
  }

  return (
    <div className="fee-main-mobile">
      <div className="fees-module-mobile">
        <div className="container">
          <div className="col-12 row m-0">
            <div className="table-responsive dept-history-table mb-4">
              <div className="fee_top mt-5">
                <h4> کارمزدها</h4>
                <h6 className="pt-5">میکر ( سفارش گزار) </h6>
                <p className="mb-4">
                  کسی است که سفارش خرید یا فروش یک رمز ارز را در بازار معاملات
                  ثبت می نماید تا در قیمت مورد دلخواهش که تعیین کرده است معامله
                  انجام پذیرد.
                </p>
                <h6> تیکر ( سفارش بردار) </h6>
                <p className="mb-4">
                  کسی است که به خرید یا فروش یک رمز ارز که قبلا توسط میکر ثبت
                  معامله شده اقدام می نماید.
                </p>
                <h6> سطح کارمزدها </h6>
                <p>
                  با توجه به حجم معاملات کاربر در طول یک دوره 30 روزه کاری،
                  تعیین میگردد که کاربر در کدام سطح کارمزدی قرار میگیرد.
                </p>
                <p>
                  <h6>کارمزد شبکه</h6>
                  واریز و برداشت یک رمز ارز بر روی یکی از شبکه های بلاک چین مثل
                  BEP20 انجام میگیرد که مستلزم پرداخت کارمزد شبکه میباشد.
                </p>
              </div>
              <div className="d-flex align-items-center">
                <h5 className="mt-5 mb-3">کارمزد معاملات</h5>
              </div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"> میکر  </th>
                    <th scope="col">تیکر  </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> %0 </td>
                    <td> %0 </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex align-items-center">
                <h5 className="mt-5 mb-3">کارمزد درگاه معاملات تتر</h5>
              </div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col"> میکر  </th>
                    <th scope="col">تیکر  </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> %0.2 </td>
                    <td> %0.2 </td>
                  </tr>
                </tbody>
              </table>
              <div className="d-flex align-items-center">
                <h5 className="mt-5 mb-3">کارمزد واریز و برداشت رمز ارزها</h5>
              </div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th>نماد ارز</th>
                    <th>نام ارز</th>
                    <th>کارمزد واریز</th>
                    <th>شبکه</th>
                    <th>حداقل مبلغ برداشت</th>
                    <th> کارمزد برداشت </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(currencies).map((currency) =>
                    <tr key={currency.id}>
                      <td><img
                        src={currencies[currency]?.image}
                        style={{ width: 20, height: 20, marginRight: 10, marginLeft: 10, marginTop: 1 }}
                        alt={"currency"}
                      />
                        {currencies[currency]?.symbol}</td>

                      <td>{currencies[currency]?.name}</td>
                      <td>
                        0
                      </td>
                      <td>
                        {currencies[currency]?.networks.map((network) => (
                          <div key={network.id}>
                            {(network?.info?.network).toUpperCase()}
                          </div>
                        ))}
                      </td>
                      <td>
                        {currencies[currency]?.networks.map((network) => (
                          <div key={network.id}>
                            {network?.withdraw?.min}
                          </div>
                        ))}
                      </td>
                      <td>
                        {currencies[currency]?.networks.map((network) => (
                          <div key={network.id}>
                            {network?.withdraw?.fee?.amount}
                          </div>
                        ))}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="d-flex align-items-center">
                <h5 className="mt-5 mb-3"> کارمزد واریز و برداشت تومان</h5>
              </div>
              <div className="d-flex align-items-center">
                <h6 className="mt-5 mb-3"> کارمزد واریز تومان </h6>
              </div>
              <p>کارمزد واریز تومان 0.02 درصد با حداقل مبلغ ۱۲۰ تومان و حداکثر مبلغ کارمزد 4000 تومان میباشد.</p>
              <div className="d-flex align-items-center">
                <h6 className="mt-5 mb-3">کارمزد برداشت تومان</h6>
              </div>
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">میزان برداشت تومان </th>
                    <th scope="col">کارمزد</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>تا 400 هزار تومان</td>
                    <td> %1</td>
                  </tr>
                  <tr>
                    <td>از 400 هزار تا 50 میلیون تومان</td>
                    <td>4000 تومان</td>
                  </tr>
                  <tr>
                    <td> از 50 میلیون تومان به بالا</td>
                    <td>4000 تومان به ازای هر 50 میلیون تومان</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeePage;
