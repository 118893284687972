import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectLogout({ history }) {
    const navigate = useNavigate();
    const goToHome = () => {
        navigate("/");
    };
    useEffect(() => {
        const redirectTimer = setTimeout(() => {
            goToHome();
            window.location.reload();
        }, 2000);
        return () => clearTimeout(redirectTimer);
    }, [history]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <div>
                <p>شما در حال خروج از سیستم می باشید. لطفا صبر کنید.</p>
                <div className="spinner-border" role="status">
                    <span className="sr-only"> </span>
                </div>
            </div>
        </div>
    );
}

export default RedirectLogout;
