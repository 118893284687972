import { Alert, Button, Fade, Snackbar, Tooltip } from "@mui/material";
import "./withdraw.scss";
import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { Modal, ModalFooter } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { createApiAddress } from "../../../constant/api.constant";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../contexts/providers/user";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { numberToWords } from "@persian-tools/persian-tools";
import { calculateFeeByCondition, toFixed } from "../../../utils/math";
import InfoIcon from '@mui/icons-material/Info';

function WithdrawMoney(props) {
  const navigate = useNavigate()
  const [address, setAddress] = useState(null);
  const [amount, setAmount] = useState(null);
  const [min, setMin] = useState(null);
  const [fee, setFee] = useState(null);
  const [mailOTP, setMailOTP] = useState(null);
  const [smsOTP, setSmsOTP] = useState(null);
  const [gOTP, setGOTP] = useState(null);
  const { state, dispatch } = useContext(UserContext);
  const [bankCards, setBankCards] = useState([])
  const [errorMsg, setErrorMsg] = useState(null);
  const errorCatch = (error) => {
    setErrorMsg(error);
  };

  const goToBankCard = () => {
    navigate("/profile#3");
  }

  const goToProfile = () => {
    navigate("/profile#1", { replace: true });
  }

  const getKycStatus = () => {
    if (state?.user_profile?.info?.kyc?.status === 'approved_lvl2') {
      return '2';
    } else if (state?.user_profile?.info?.kyc?.status === 'approved' && state?.user_profile?.info?.kyc?.vip_status !== 'approved') {
      return '3'
    } else if (state?.user_profile?.info?.kyc?.vip_status === 'approved') {
      return 'VIP'
    } else {
      return ' '
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(createApiAddress("api.BANK_CARD_LIST_VERIFIED"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.status === 200) {
          const data = await response.json();
          setBankCards(data?.data)
        }
      }
      catch (error) {
      }
    };
    fetchData()
  }, []);

  const setSpaceBetweenCard = (data) => {
    let formattedCardNumber = data.replace(/(\d{4})/g, '$1 ');
    formattedCardNumber = formattedCardNumber.trim().replace(/\s/g, '-');
    return formattedCardNumber;
  }

  const goToKyc = () => {
    navigate("/kyc", { replace: true });
  };

  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {
      const numberStr = number.toString();
      const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedNumber;
    } else {
      return number
    }
  }

  const [irtInfo, setIrtInfo] = useState({})

  useEffect(() => {
    fetch(createApiAddress("api.IRT_INFO"), {
      method: "GET",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          setIrtInfo(data.data)
          setMin(data.data?.withdraw?.min)
        }
      })
      .catch((error) => {
        console.error("Error fetching user wallet data:", error);
      });

  }, []);

  const calculateFee = () => {
    if (amount === 0 || amount === undefined || amount < min) {
      return 500;
    }
    return calculateFeeByCondition(amount, irtInfo.withdraw?.fee?.conditions);
  }

  const conditions = irtInfo.withdraw?.fee?.conditions;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCodesInput, setCodesInput] = useState(false);
  const handleCloseCodesInput = () => setCodesInput(false);
  const handleShowCodesInput = () => setCodesInput(true);

  const [showFinal, setShowFinal] = useState(false);
  const handleCloseFinal = () => setShowFinal(false);
  const handleShowFinal = () => setShowFinal(true);
  const [emailEnable, setEmailEnable] = useState(false)
  const [smsEnable, setSmsEnable] = useState(false)

  const [openSnackBar, setOpenSnackBar] = useState({
    show: false,
    severity: "warning",
    message: "",
  });

  const handleCloseSnackBar = () => {
    setOpenSnackBar({
      ...openSnackBar,
      show: false,
    });
  };

  const avblBalance = () => {
    if (props.walletData['IRT']?.main === undefined) {
      return 0;
    } else {
      return props.walletData['IRT']?.main;
    }
  };

  var limitWithdrawDaily = props.timeBaseLimit?.limit?.withdraw?.daily_toman_amount
  var usedWithdrawDaily = props.timeBaseLimit?.used?.withdraw?.daily_toman_amount
  var limitWithdrawMonthly = props.timeBaseLimit?.limit?.withdraw?.monthly_toman_amount
  var usedWithdrawMonthly = props.timeBaseLimit?.used?.withdraw?.monthly_toman_amount

  var dailyRemain = () => {
    if (usedWithdrawDaily < limitWithdrawDaily) {
      return limitWithdrawDaily - usedWithdrawDaily;
    } else {
      return 0
    }
  }
  var monthlyRemain = () => {
    if (usedWithdrawMonthly < limitWithdrawMonthly) {
      return limitWithdrawMonthly - usedWithdrawMonthly;
    } else {
      return 0
    }
  }

  const errorsSnack = () => {
    if (amount > avblBalance()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `مقدار موجود کافی نیست ${avblBalance()}`,
      });
    } else if (amount === null || amount === "") {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: "لطفا مقدار را وارد کنید.",
      });
    } else if (amount > dailyRemain()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `باقیمانده حجم برداشت روزانه شما: ${dailyRemain()}`,
      });
    } else if (amount > monthlyRemain()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `باقیمانده حجم برداشت ماهانه  شما: ${monthlyRemain()}`,
      });
    } else if (amount < min) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `مبلغ برداشت نمی تواند کمتر از ${min} باشد`,
      });
    } else if (address === null || address === undefined) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: 'کارت بانکی را انتخاب کنید.',
      });
    } else {
      handleShow();
    }
  };

  const sendRequestOTP = async (event) => {
    event.preventDefault();
    try {
      let data = {
        type: "withdraw",
        currency: 'IRT',
        fee: fee,
        amount: amount,
        address: address,
      };
      let res = await fetch(createApiAddress("api.OTP_WITHDRAW"), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.locale.fa,
        });
        setEmailEnable(resJson?.data?.options?.email)
        setSmsEnable(resJson?.data?.options?.sms)
        handleClose();
        handleShowCodesInput();
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.locale.fa,
        });
      }
    } catch (err) { }
  };

  const finalizeWithdraw = async (event) => {
    event.preventDefault();
    try {
      // const data = new FormData(event.currentTarget);
      let res = await fetch(createApiAddress("api.FINALIZE_WITHDRAW"), {
        method: "POST",
        body: JSON.stringify({
          tfa_code: gOTP,
          email_verify_code: mailOTP,
          sms_code: smsOTP,
          currency_id: '64f548fef2c2ff2979bb4abb',
          amount: amount,
          address: address,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let resJson = await res.json();
      if (resJson.status === 1) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.locale.fa,
        });
        handleCloseCodesInput();
        handleShowFinal();
        setAddress("");
        setAmount("");
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.locale.fa,
        });
      }
    } catch (err) { }
  };

  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedCardNumber, setSelectedCardNumber] = useState(null);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === '0') {
      setSelectedBank(null);
      setSelectedCardNumber(null);
      setAddress(null)
    } else {
      const selectedCard = bankCards.find((bank) => bank._id === selectedValue);
      setSelectedBank(selectedCard.bank);
      setSelectedCardNumber(selectedCard.card_number);
      setAddress(selectedValue)
    }
  };

  const [wordsOfAmount, setWordsOfAmount] = useState('');
  const [errorLimitMessage, setErrorLimitMessage] = useState('');

  const checkRemainingLimit = () => {
    if (dailyRemain() > monthlyRemain()) {
      return monthlyRemain()
    } else if (dailyRemain() < monthlyRemain()) {
      return dailyRemain()
    }
  }

  const handleAmountChange = (event) => {
    var newAmount = event.target.value;
    newAmount = event.target.value.replace(/[^0-9]/g, "")
    setAmount(newAmount);
    if (newAmount > checkRemainingLimit()) {
      setAmount(checkRemainingLimit())
      const words = numberToWords(checkRemainingLimit());
      setWordsOfAmount(words);
      setErrorLimitMessage(`حجم باقیمانده برداشت روزانه یا ماهانه شما: ${numberToWords(checkRemainingLimit())}`)
      return;
    }
    if (newAmount !== null && newAmount !== undefined) {
      const words = numberToWords(newAmount);
      setWordsOfAmount(words);
      setErrorLimitMessage('')
    } else {
      setWordsOfAmount('');
      setErrorLimitMessage('')
    }
  }

  const feeText = `مقدار برداشت کمتر از  ${numberToWords(conditions?.min_step_amount)} تومان ${numberToWords(conditions?.percentage)} درصد کارمزد، از ${numberToWords(conditions?.min_step_amount)} تومان تا ${numberToWords(conditions?.step)} تومان ${numberToWords(conditions?.max)} تومان کار مزد، و بالای ${numberToWords(conditions?.step)} تومان برداشت به ازای هر ${numberToWords(conditions?.step)} تومان ${numberToWords(conditions?.max)} تومان دیگر کارمزد تعلق می‌گیرد.`;

  const requiredKYCList = ["approved_lvl2", "rejected_lvl3", "approved"]

  if (
    requiredKYCList.includes(state?.user_profile?.info?.kyc?.status) === false
  ) {
    return (
      <div className="when-error">
        <div className="error-module">
          <div className="attention-withdraw">
            <p className="p-2">
              برای برداشت باید <span> فرآیند احراز هویت </span> خود را انجام
              دهید.
            </p>
          </div>
          <div className="error-btn">
            <Button className="btn-withdraw-error" onClick={goToKyc}>
              برای رفتن به صفحه احراز هویت  کلیک کنید
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (state?.user_profile?.security?.tfa?.status !== true) {
    return (
      <div className="when-error">
        <div className="error-module">
          <div className="attention-withdraw">
            <p className="p-2">
              برای برداشت باید <span> احراز هویت دو عاملی</span> خود را انجام
              دهید.
            </p>
          </div>
          <div className="error-btn">
            <Button className="btn-withdraw-error" onClick={goToProfile}>
              برای رفتن به صفحه پروفایل  کلیک کنید
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="container deposit-money-main p-3">
        <h5>برداشت تومان</h5>
        <div className="row ">
          <div className="col">
            <Button className="confirm-withdraw" onClick={goToBankCard} >
              کارت بانکی اضافه کنید
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group className="mb-3 mt-5">
              <Form.Label className="form-label">انتخاب کارت بانکی</Form.Label>
              <Form.Select
                className="deposit-money-selection"
                value={address}
                onChange={handleSelectChange}
              >
                <option value={0} >کارت بانکی را انتخاب کنید</option>
                {bankCards.map((bankName, index) => (
                  <option
                    key={index}
                    value={bankName._id}
                    className="select-in-deposit-money"
                    onClick={() => handleSelectChange({ target: { value: bankName._id } })}
                  >
                    {setSpaceBetweenCard(bankName.card_number)} {bankName.bank}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <FormControl fullWidth>
              <TextField
                type="number"
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="amount"
                placeholder="مبلغ برداشت را به تومان وارد کنید"
                size="medium"
                value={amount}
                onChange={handleAmountChange}
                step={1}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
              />
            </FormControl>
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="p-color mt-2">{wordsOfAmount} تومان</p>
              </div>
              <div className="col-12 col-md-6 text-start">
                <p className="p-color mt-2">{errorLimitMessage}</p>
              </div>
            </div>
            <div className="col d-flex justify-content-between available-amount mt-3">
              <h6>موجودی:</h6>
              <h6 className="ms-2">
                {" "}
                {formatNumber(props.walletData['IRT']?.main) || 0}
              </h6>
            </div>
            <div className="col d-flex justify-content-between available-amount">
              <h6>حداقل مبلغ برداشت: </h6>{" "}
              <h6 className="ms-2"> {formatNumber(min)}</h6>
            </div>
            <div className="row fee-deposit-money mt-5 d-flex justify-content-between">
              <h6 className="h-color2 mt-2"></h6>
              <div className="col-12">
                <div className="row">
                  <div className="col-10">
                    <h6 className="mt-2">کارمزد برداشت
                      <Tooltip
                        title={<h6 className="tooltip-text">{feeText}</h6>}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="top"
                        leaveDelay={400}
                        className="tootltip-main"
                      >
                        <Button><InfoIcon /></Button>
                      </Tooltip>
                    </h6>
                  </div>
                  <div className="col-2 mt-3 text-start">
                    {amount === null || amount === '' ? <h6>0</h6> : <h6 className="mt-2">{calculateFee() ? toFixed(calculateFee(), 0) : 0}</h6>}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-8 col-md-10">
                    <h6 >مبلغ دریافتی شما پس از کسر کارمزد:</h6>
                  </div>
                  <div className="col-4 col-md-2 text-start">
                    <h6 >{(amount - calculateFee() < 0) ? 0 : formatNumber(amount - calculateFee())}</h6>
                  </div>
                </div>
              </div>

            </div>
            <div className="row fee-deposit-money mt-2 p-1">
              <h6 className="h-color">
                چنانچه حساب بانکی شما در یکی از بانک‌های، ملی، صادرات، کشاورزی، سامان، اقتصاد نوین، سپه و یا تجارت باشد، درخواست برداشت شما بصورت سریع انجام می‌پذیرد،  اگر حساب شما در یک بانک دیگر باشد در خواست برداشت شما با سیکل پایا در چهار نوبت زمانی ( ساعت ۴ تا ۷ صبح، ۱۱ صبح، ۱۴ و ۱۹) در روزهای کاری انجام می‌پذیرد.
              </h6>
            </div>
            <div className="row fee-deposit-money mt-2 p-1">
              <div className="col">
                <h6>محدودیت برداشت برای سطح {getKycStatus()} احراز هویت:</h6>
              </div>
              <div className="row">
                <div className="col-6">
                  <h6>مجموع برداشت روزانه:</h6>
                </div>
                <div className="col-6 text-start">
                  <h6>{usedWithdrawDaily ? formatNumber(usedWithdrawDaily) : usedWithdrawDaily} از {limitWithdrawDaily ? formatNumber(limitWithdrawDaily) : limitWithdrawDaily} تومان</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-6"><h6>مجموع برداشت ماهانه:</h6></div>
                <div className="col-6 text-start">
                  <h6>{usedWithdrawMonthly ? formatNumber(usedWithdrawMonthly) : usedWithdrawMonthly} از {limitWithdrawMonthly ? formatNumber(limitWithdrawMonthly) : limitWithdrawMonthly} تومان</h6>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col gateway-btn text-center mt-5">
                  <Button onClick={errorsSnack} className="goto-gateway">
                    تایید برداشت
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={show}
            onHide={handleClose}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title className="text-success">نکته مهم</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <p className="attention-deposit">
                برداشت برای دارندگان حساب در بانک‌های، <span className="bankname-attention">ملی</span>، <span className="bankname-attention">صادرات</span>، <span className="bankname-attention">کشاورزی</span>، <span className="bankname-attention">تجارت</span>، <span className="bankname-attention">سپه</span>، <span className="bankname-attention">اقتصاد نوین</span> و <span className="bankname-attention">سامان</span> فوری میباشد. برداشت در بقیه بانک‌ها طبق زمانبندی سیستم پایا انجام می‌گیرد.
              </p>
              <p>
                لذا توصیه می‌شود برای برداشت فوری، در صورت امکان از حساب/ کارت بانکی یکی از بانک‌های مذکور استفاده نمایید.
              </p>
              <p>کارت انتخاب شده: {selectedCardNumber} {" "} {selectedBank}</p>
            </Modal.Body>
            <ModalFooter className="deposit-footer-btn">
              <div className="container-fluid">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <Button
                      className="deposit-btn-modal"
                      onClick={handleClose}
                    >
                      لغو
                    </Button>
                  </div>
                  <div className="col-6 text-start">
                    <Button
                      className="deposit-btn-modal"
                      onClick={sendRequestOTP}
                    >
                      تایید
                    </Button>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </Modal>
          {/* just 2fa */}
          <Modal
            show={showCodesInput}
            onHide={handleCloseCodesInput}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                کدهای لازم برای تایید برداشت را وارد کنید.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    className="withdraw-texts mt-3"
                    InputProps={{ sx: { height: 57 } }}
                    id="gotp"
                    name="gotp"
                    placeholder="کد احراز هویت گوگل"
                    size="medium"
                    onChange={(event) => setGOTP(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form>
              {emailEnable ? <Form>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    className="withdraw-texts mt-3"
                    InputProps={{ sx: { height: 57 } }}
                    id="emailotp"
                    name="emailotp"
                    placeholder="کد ارسالی به ایمیل"
                    size="medium"
                    onChange={(event) => setMailOTP(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form> : <></>}
              {smsEnable ? <Form>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    className="withdraw-texts mt-3"
                    InputProps={{ sx: { height: 57 } }}
                    id="smsotp"
                    name="smsotp"
                    placeholder="کد ارسالی به تلفن همراه"
                    size="medium"
                    onChange={(event) => setSmsOTP(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form> : <></>}
            </Modal.Body>
            <ModalFooter>
              <Button
                className="confirm-withdraw"
                onClick={finalizeWithdraw}
              >
                تایید
              </Button>
              <Button
                className="cancel-withdraw"
                onClick={handleCloseCodesInput}
              >
                لغو
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            show={showFinal}
            onHide={handleCloseFinal}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>برداشت تایید شد</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <p className="attention-withdraw">
                لطفاً ایمیل خود را بررسی کنید و ایمیلی را که برای تکمیل
                فرآیند برداشت برای شما ارسال شده است تأیید کنید.
              </p>
            </Modal.Body>
            <ModalFooter>
              <Button
                className="confirm-withdraw"
                onClick={handleCloseFinal}
              >
                بستن
              </Button>
            </ModalFooter>
          </Modal>

          <Snackbar
            open={openSnackBar.show}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
          >
            <Alert severity={openSnackBar.severity}>
              {openSnackBar.message}
            </Alert>
          </Snackbar>
        </div>

      </div>
    );
  }
}

export default WithdrawMoney;
