import React from "react";
import "../trade.scss";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrencyDecimals } from "../../../../utils/math";
import { createApiAddress } from "../../../../constant/api.constant";
import { SocketContext } from "../../../../contexts/providers/socket";
import LogoTrade from '../../../../assets/images/TerixoLogo.svg'
import { Dropdown } from "react-bootstrap";
import MenuIcon from '@mui/icons-material/Menu';
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import UserAnim from "../../../../assets/json/user.json";
import SuppAnim from "../../../../assets/json/support.json";
import PrizeAnim from "../../../../assets/json/gift-icon.json";
import HomeIcon from "../../../../assets/json/home.json";
import SelectTheme from "../../../../utils/Themeselector";
import PairListTrade from "./PairListTradeE";
import OrderHistory from "./OrderHistoryE";
import UserOrderList from "./UserOrderListE";
import { useNavigate } from 'react-router-dom';
function TopPriceData() {
  const { tradePair } = useParams();
  const { stateIO } = React.useContext(SocketContext);
  const tokenName = tradePair.split("_")[0];
  const [pageTitle, setPageTitle] = useState("Terixo");
  const [change, setChange] = useState(0);
  const [priceDif, setPriceDef] = useState(0);
  const [priceIrt, setPriceIrt] = useState(0);

  useEffect(() => {
    const originalTitle = document.title;
    document.title = pageTitle;
    return () => {
      document.title = originalTitle;
    };
  }, [pageTitle]);

  const updatePageTitle = (newPageTitle) => {
    setPageTitle(newPageTitle);
  };

  useEffect(() => {
    if (stateIO?.details.last) {
      updatePageTitle(
        `${stateIO.details.last} ${tradePair.replace("_", "/")}  - Terixo`
      );
    }
  }, [stateIO?.details.last, tradePair]);

  useEffect(() => {
    setChange((
      ((stateIO?.details?.last - stateIO?.details?.first) /
        stateIO?.details?.first) *
      100
    ).toFixed(2))
  }, [stateIO?.details?.last, stateIO?.details?.first]);

  useEffect(() => {
    setPriceDef
      ((stateIO?.details?.last - stateIO?.details?.first).toFixed(2))
  }, [stateIO?.details?.last, stateIO?.details?.first]);



  const colorForChange = () => {
    if (change < 0) {
      return "textdanger";
    } else if (change > 0) {
      return "textsuccess";
    }
  };

  const getIRTPrice = async () => {
    try {
      const res = await fetch(createApiAddress("trade.prices"), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setPriceIrt(resJson["USDT"].IRT);
      }
    } catch (e) {
      // Handle the error here
    }
  };

  useEffect(() => {
    getIRTPrice();
    const intervalId = setInterval(getIRTPrice, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  const navigate = useNavigate();
  const gotToHome = () => {
    navigate("/");
  }

  const gotToSupp = () => {
    navigate("/support");
  }

  const gotToPrize = () => {
    navigate("/prizes");
  }

  const gotToProfile = () => {
    navigate("/profile");
  }
  return (
    <div className="toppricedata-main">
      <div className="container-fluid p-0 m-0">
        <div className="row mt-2 p-0 m-0">
          <div className="col-1">
            <Dropdown className="menu-trade-mobile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                <MenuIcon />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item>
                  <button className="btn btn-primary" onClick={gotToHome}>
                    <Player
                      autoplay
                      loop
                      src={HomeIcon}
                      style={{ maxHeight: "35px", maxWidth: "35px" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </button>
                </Dropdown.Item>

                <Dropdown.Item>
                  {/* Button 1 */}
                  <button className="btn btn-primary" onClick={gotToProfile}>
                    <Player
                      autoplay
                      loop
                      src={UserAnim}
                      style={{ maxHeight: "40px", maxWidth: "40px" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </button>
                </Dropdown.Item>

                <Dropdown.Item>
                  {/* Button 2 */}
                  <button className="btn btn-secondary" onClick={gotToPrize}>
                    <Player
                      autoplay
                      loop
                      src={PrizeAnim}
                      style={{ maxHeight: "55px", maxWidth: "50px" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </button>
                </Dropdown.Item>

                <Dropdown.Item>
                  {/* Button 3 */}
                  <button className="btn btn-info" onClick={gotToSupp}>
                    <Player
                      autoplay
                      loop
                      src={SuppAnim}
                      style={{ maxHeight: "40px", maxWidth: "35px" }}
                    >
                      <Controls
                        visible={false}
                        buttons={["play", "repeat", "frame", "debug"]}
                      />
                    </Player>
                  </button>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-1" style={{ marginTop: -18 }}>
            <SelectTheme />
          </div>
          <div className="col-10 text-start">
            <img src={LogoTrade} alt="logo" height={20} />
          </div>
        </div>
        <div className="row p-0 m-0">
          <div className="col-8">
            <div className="row">
              <div className="col-5 p-0 m-0">
                <p className="mobile-24-text"> حجم (24h) {tradePair.split("_")[0]} </p>
                <p className="mobile-24-number">
                  {parseFloat(`${stateIO?.details.base_volume}`).toFixed(
                    getCurrencyDecimals(tokenName, "amount")
                  )}
                </p>
              </div>
              <div className="col-5 p-0 m-0">
                <p className="mobile-high-text"> بالاترین (24h)</p>
                <p className="mobile-high-number">
                  {parseFloat(`${stateIO?.details.high}`).toFixed(
                    getCurrencyDecimals(tokenName, "price")
                  )}
                </p>
              </div>
            </div>
            <div className="row" style={{ marginTop: -5 }}>
              <div className="col-5 p-0 m-0">
                <p className="mobile-24-text"> حجم (24h) {tradePair.split("_")[1]} </p>
                <p className="mobile-24-number">
                  {parseFloat(`${stateIO?.details.volume}`).toFixed(
                    getCurrencyDecimals(tokenName, "price")
                  )}
                </p>
              </div>
              <div className="col-5 p-0 m-0">
                <p className="mobile-high-text"> پایین ترین (24h)</p>
                <p className="mobile-high-number">
                  {parseFloat(`${stateIO?.details.low}`).toFixed(
                    getCurrencyDecimals(tokenName, "price")
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="col-4">
            <p className="mobile-trade-toppair">{tradePair.replace("_", "/")}</p>
            <p className={`mobile-lastprice ${colorForChange()}`}>
              {parseFloat(`${stateIO?.details.last}`).toFixed(
                getCurrencyDecimals(tokenName, "price")
              )}</p>
            <p className={`mobile-change ${colorForChange()}`}>
              {change}% {priceDif}$
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-3 me-2">
            <Dropdown className="menu-pairs-mobile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                آخرین معاملات <MenuIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <OrderHistory />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-1"></div>
          <div className="col-3">
            <Dropdown className="menu-pairs-mobile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                لیست سفارش ها <MenuIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <UserOrderList />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-1"></div>
          <div className="col-3">
            <Dropdown className="menu-pairs-mobile">
              <Dropdown.Toggle variant="success" id="dropdown-basic">
                جفت ارز ها <MenuIcon />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <PairListTrade />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopPriceData;
