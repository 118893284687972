
import { useContext, useEffect } from "react";
import { useState } from "react";
import { getWithdrawHistory } from "../../../utils/Api";
import { UserContext } from "../../../contexts/providers/user";
import moment from "jalali-moment";
import "./withdraw.scss";
import WithdrawHistoryEach from "./WithdrawHistoryEach";
import { toFixed } from "../../../utils/math";

function WithdrawHistory() {
  const { state } = useContext(UserContext);
  const [historyWitData, setHistoryWitData] = useState(null);
  const errorCatch = (error) => { };
  useEffect(() => {
    getWithdrawHistory(setHistoryWitData, errorCatch);
  }, []);

  const witStatus = (...statuses) => {
    let mapStatus = {
      'pending': "منتظر تایید ایمیل توسط کاربر",
      'approved': {
        'pending': "منتظر تایید سیستم",
        'approved': {
          'pending': "منتظر تایید شبکه",
          'approved': "تکمیل شد",
          'rejected': "توسط شبکه رد شد"
        },
        'rejected': "توسط سیستم رد شد"
      },
      'rejected': "توسط کاربر لغو شد"
    }
    let statusLast;
    for (let status of statuses) {
      if (statusLast === undefined) {
        statusLast = mapStatus[status];
      } else {
        statusLast = statusLast[status];
      }
      if (typeof statusLast === typeof "string") {
        return statusLast
      }
      if (statusLast === undefined) {
        return status
      }
    }
    return statuses[statuses.lastIndexOf]
  }


  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="deposit-his-main-mobile">
        {historyWitData !== null
          ? historyWitData
            .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))
            .map((row, index) => (
              <WithdrawHistoryEach
                key={row.currency}
                image={state.currency.images[row.currency]}
                index={index}
                time={`${moment
                  .from(row.updated_at, "en")
                  .locale("fa")
                  .format("jYYYY/jMM/jDD")}${" -- "}
              ${moment(row.updated_at).format("HH:mm:ss")}`}
                symbol={row.currency}
                amount={toFixed(row.amount)}
                userStatus={row.info?.user_status}
                status={witStatus(row.info?.user_status, row.info?.admin_status, row.info?.status)}
                txid={row?.info?.transaction_id}
                id={row._id}
              />
            ))
          : <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="col text-center">
                <p className="loading-deposit">هیچ اطلاعاتی پیدا نشد</p>
              </div>
            </div>
          </div>}
      </div>
    </>
  }

  return (
    <>
      <div className="deposit-his-main-mobile">
        {historyWitData !== null
          ? historyWitData
            .sort((a, b) => moment(b.updated_at).diff(moment(a.updated_at)))
            .map((row, index) => (
              <WithdrawHistoryEach
                key={row.currency}
                image={state.currency.images[row.currency]}
                index={index}
                time={`${moment
                  .from(row.updated_at, "en")
                  .locale("fa")
                  .format("jYYYY/jMM/jDD")}${" -- "}
              ${moment(row.updated_at).format("HH:mm:ss")}`}
                symbol={row.currency}
                amount={toFixed(row.amount)}
                status={witStatus(row.info?.user_status, row.info?.admin_status, row.info?.status)}
                userStatus={row.info?.user_status}
                txid={row?.info?.transaction_id}
                id={row._id}
              />
            ))
          : <div className="container-fluid ">
            <div className="row justify-content-center">
              <div className="col text-center">
                <p className="loading-deposit">هیچ اطلاعاتی پیدا نشد</p>
              </div>
            </div>
          </div>}
      </div>
    </>
  );
}

export default WithdrawHistory;
