import React, { useContext } from "react";
import "../trade.scss";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrencyDecimals } from "../../../../utils/math";
import { createApiAddress } from "../../../../constant/api.constant";
import { SocketContext } from "../../../../contexts/providers/socket";

function TopPriceData() {
  const { tradePair } = useParams();
  const { stateIO } = React.useContext(SocketContext);
  const tokenName = tradePair.split("_")[0];
  const [pageTitle, setPageTitle] = useState("Terixo");
  const [change, setChange] = useState(0);
  const [priceDif, setPriceDef] = useState(0);
  const [priceIrt, setPriceIrt] = useState(0);

  useEffect(() => {
    const originalTitle = document.title;
    document.title = pageTitle;
    return () => {
      document.title = originalTitle;
    };
  }, [pageTitle]);

  const updatePageTitle = (newPageTitle) => {
    setPageTitle(newPageTitle);
  };

  useEffect(() => {
    if (stateIO?.details.last) {
      updatePageTitle(
        `${stateIO.details.last} ${tradePair.replace("_", "/")}  - Terixo`
      );
    }
  }, [stateIO?.details.last, tradePair]);

  useEffect(() => {
    setChange((
      ((stateIO?.details?.last - stateIO?.details?.first) /
        stateIO?.details?.first) *
      100
    ).toFixed(2))
  }, [stateIO?.details?.last, stateIO?.details?.first]);

  const colorInTrade = () => {
    if (stateIO?.details.side === "sell") {
      return "textdanger";
    } else if (stateIO?.details.side === "buy") {
      return "textsuccess";
    }
  };

  useEffect(() => {
    setPriceDef
      ((stateIO?.details?.last - stateIO?.details?.first).toFixed(2))
  }, [stateIO?.details?.last, stateIO?.details?.first]);

  const colorForChange = () => {
    if (change < 0) {
      return "textdanger";
    } else if (change > 0) {
      return "textsuccess";
    }
  };

  const getIRTPrice = async () => {
    try {
      const res = await fetch(createApiAddress("trade.prices"), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const resJson = await res.json();
      if (res.status === 200) {
        setPriceIrt(resJson["USDT"].IRT);
      }
    } catch (e) {
      // Handle the error here
    }
  };

  useEffect(() => {
    getIRTPrice();
    const intervalId = setInterval(getIRTPrice, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="toppricedata-main">
      <div className="row">
        <div className="col-1 pairname-top">
          <p>{tradePair.replace("_", "/")}</p>
        </div>
        <div className="col-1 ms-5 " style={{ width: 120 }}>
          <p className={`priceusdt1 ${colorInTrade()}`}>
            {parseFloat(`${stateIO?.details.last}`).toFixed(
              getCurrencyDecimals(tokenName, "price")
            )}{" "}
            USDT
          </p>
          <p className={`priceirt ${colorInTrade()}`}>
            {new Intl.NumberFormat("ir-FA", {
              style: "currency",
              currency: "irt",
              minimumFractionDigits: 0,
            }).format(
              parseFloat(`${stateIO?.details.last}`).toFixed(0) * priceIrt
            )}
          </p>
        </div>

        <div className="col-1 " style={{ width: 100 }}>
          <p className="data24h-title"> تغییر (24h)</p>
          <p className={`h24percent ${colorForChange()}`}>
            {change}% {priceDif}$
          </p>
        </div>
        <div className="col-1 " style={{ width: 120 }}>
          <p className="data24h-title"> بالاترین قیمت (24h)</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.high}`).toFixed(
              getCurrencyDecimals(tokenName, "price")
            )}
          </p>
        </div>
        <div className="col-1 " style={{ width: 130 }}>
          <p className="data24h-title"> پایین ترین قیمت (24h)</p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.low}`).toFixed(
              getCurrencyDecimals(tokenName, "price")
            )}
          </p>
        </div>
        <div className="col-1 toppricedata-volume" style={{ width: 100 }}>
          <p className="data24h-title"> حجم (24h) {tradePair.split("_")[0]} </p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.base_volume}`).toFixed(
              getCurrencyDecimals(tokenName, "amount")
            )}
          </p>
        </div>
        <div className="col-1 toppricedata-volume" style={{ width: 110 }}>
          <p className="data24h-title"> حجم (24h) {tradePair.split("_")[1]} </p>
          <p className="data24h-data">
            {parseFloat(`${stateIO?.details.volume}`).toFixed(
              getCurrencyDecimals(tokenName, "price")
            )}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TopPriceData;
