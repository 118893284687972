import React from 'react'
import UserAnim from "../../assets/json/user.json";
import SuppAnim from "../../assets/json/support.json";
import PrizeAnim from "../../assets/json/gift-icon.json";
import SelectTheme from '../../utils/Themeselector'
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useNavigate } from 'react-router-dom';
import './navbar.scss'
import { isLoggedIn } from '../../utils/RSS';
import { Button } from '@mui/material';
import { Modal, ModalFooter } from 'react-bootstrap';
import { useState } from 'react';
import { UserContext } from '../../contexts/providers/user';
import { useContext } from 'react';
import { SocketContext } from '../../contexts/providers/socket';
function NavbarTopMobile() {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(UserContext);
    const { dispatchIO } = useContext(SocketContext);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const logoutFunc = () => {
        dispatch({ type: "Logout" });
        dispatchIO({ type: "set:user:balances", wallets: {} });
        navigate("/logoutuser", { replace: true });
        handleClose();
    };
    const gotToSupp = () => {
        navigate("/support");
        window.scrollTo(0, 0);
    }

    const gotToPrize = () => {
        navigate("/prizes");
        window.scrollTo(0, 0);
    }

    const gotToProfile = () => {
        navigate("/profile");
        window.scrollTo(0, 0);
    }

    return (
        <div className='navbartop-mobile container-fluid'>
            <div className="row  p-0 m-0">
                <div className="col-3 p-0 m-0">
                    <button className='btn-top-icon' onClick={gotToProfile}>
                        <Player
                            autoplay
                            loop
                            src={UserAnim}
                            style={{ maxHeight: "50px", maxWidth: "40px", marginRight: -8 }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </button>
                </div>
                <div className="col-2 p-0 m-0">
                    <button className='btn-top-icon' onClick={gotToPrize}>
                        <Player
                            autoplay
                            loop
                            src={PrizeAnim}
                            style={{ maxHeight: "45px", maxWidth: "45px", marginRight: -55 }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </button>
                </div>
                <div className="col-2 p-0 m-0 pt-1">
                    <button className='btn-top-icon' onClick={gotToSupp}>
                        <Player
                            autoplay
                            loop
                            src={SuppAnim}
                            style={{ maxHeight: "40px", maxWidth: "30px", marginRight: -65, marginTop: 5 }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </button>
                </div>
                <div className="col-2">
                    {isLoggedIn() ? (
                        <Button
                            className="me-1"
                            variant="warning"
                            onClick={handleShow}
                            style={{ marginTop: 5 }}
                        >
                            خروج
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="col-3 p-0 m-0" >
                    <SelectTheme />
                </div>
                <Modal
                    show={show}
                    onHide={handleClose}
                    className="modal-logout"
                    backdrop="static"
                >
                    <Modal.Header>
                        <Modal.Title>آیا می خواهید از سیستم خارج شوید؟</Modal.Title>
                    </Modal.Header>
                    <ModalFooter>
                        <Button className="confirm-logout" onClick={logoutFunc}>
                            تایید
                        </Button>
                        <Button className="cancel-logout" onClick={handleClose}>
                            لغو
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </div>
    )
}

export default NavbarTopMobile
