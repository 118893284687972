import { io } from "socket.io-client";
import { createWebsocketAddress } from "../../constant/api.constant";
let socket = null;
let socket_data = {
    trade_pair: null,
    callback_map: {

    }
}

let tradeWS_URL = createWebsocketAddress("trade");

export const addtionalDataForOrder = (orders, side) => {
    if (orders === undefined) {
        return [];
    }
    if (side === 'sell') {
        orders = orders.sort((a, b) => a.price - b.price)
    } else {
        orders = orders.sort((a, b) => b.price - a.price)
    }

    let total_amount = 0;
    let total_to = 0;

    return orders.map((item) => {
        total_amount = total_amount + item.amount;
        total_to = total_to + (item.price * item.amount)
        return {
            ...item,
            total_amount,
            total_to
        }
    })
}

export const listenToEvent = (eventName, cb) => {
    if (socket_data.callback_map[eventName] != null) {
        socket?.off(eventName, cb)
    }
    socket_data.callback_map[eventName] = cb
    socket?.on(eventName, cb)
}

export const sendRequestEmit = (event, data) => {
    socket?.emit(event, data);
}

export const reducerIO = (state, action) => {
    switch (action.type) {
        case "start_socket":
            if (socket === null) {
                socket = io(tradeWS_URL, { transports: ["websocket"] });
            }
            if (state.trade_pair !== action.trade_pair) {
                socket?.emit("user:join:pair_details", {
                    pair: action.trade_pair
                });
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair
                }
            } else {
                socket?.emit("user:join:pair_details", {
                    pair: action.trade_pair
                });
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair
                }
            }
        // return state
        case "LISTEN:pair_info":
            if (state.trade_pair !== action.trade_pair) {
                socket?.emit("user:join:pair_details", {
                    pair: action.trade_pair
                });
                return {
                    ...state,
                    buys: [],
                    sells: [],
                    histories: [],
                    socket: socket,
                    trade_pair: action.trade_pair,
                    details: {
                        last: 0,
                        first: 0,
                        low: 0,
                        high: 0,
                        base_volume: 0,
                        volume: 0,
                        change: 0,
                        price: 0,
                        color: "text-success"
                    },
                }
            }
            return {
                ...state,
                trade_pair: action.trade_pair
            }
        case "set_trade_info":
            let buys = addtionalDataForOrder(action.buys, 'buy')
            let sells = addtionalDataForOrder(action.sells, 'sell')
            let sumOfBuys = buys.reduce((total, order) => total + order.amount, 0)
            let sumOfSells = sells.reduce((total, order) => total + order.amount, 0)
            let percentOfSumBuy = (sumOfBuys * 100) / (sumOfBuys + sumOfSells)
            let percentOfSumBuyInt = parseInt(percentOfSumBuy)
            let percentOfSumSellInt = 100 - percentOfSumBuyInt
            let minSellAmount = 0;
            let maxSellAmount = 0;
            let minBuyAmount = 0;
            let maxBuyAmount = 0;
            if (sells.length > 0) {
                minSellAmount = sells.reduce((min, order) => order.amount < min ? order.amount : min, sells[0].amount)
                maxSellAmount = sells.reduce((max, order) => order.amount > max ? order.amount : max, sells[0].amount)
            }

            if (buys.length > 0) {
                minBuyAmount = buys.reduce((min, order) => order.amount < min ? order.amount : min, buys[0].amount)
                maxBuyAmount = buys.reduce((max, order) => order.amount > max ? order.amount : max, buys[0].amount)
            }

            return {
                ...state,
                buys: buys,
                sells: sells,
                histories: action.histories,
                volume_percentage: {
                    buy: percentOfSumBuyInt,
                    sell: percentOfSumSellInt
                },
                volume_stats: {
                    buy: {
                        min: minBuyAmount,
                        max: maxBuyAmount
                    },
                    sell: {
                        min: minSellAmount,
                        max: maxSellAmount
                    }
                }
            };
        case "set:trade_pair:details":
            return {
                ...state,
                details: action.details
            };
        case "set:trade_pairs":
            return {
                ...state,
                pairs: action.pairs
            };
        case "set:user:balances":
            return {
                ...state,
                user_balances: action.wallets
            };
        case "set:user:orders":
            return {
                ...state,
                user_orders: {
                    open: action.orders.opens,
                    matches: action.orders.matches,
                    orders: action.orders.orders
                }
            }
        default:
            return state;
    }
};

export const initialStateSocket = {
    socket: socket,
    trade_pair: null,
    details: {
        last: 0,
        first: 0,
        low: 0,
        high: 0,
        base_volume: 0,
        volume: 0,
        change: 0,
        price: 0,
        color: "text-success"
    },
    pairs: [
        {
            currency: 'USDT',
            pairs: []
        }
    ],
    user_balances: {

    },
    user_orders: {
        open: [],
        matches: [],
        orders: []
    },
    buys: [],
    sells: [],
    histories: [],
    volume_percentage: {
        buy: 0,
        sell: 0
    },
    volume_stats: {
        buy: {
            min: 0,
            max: 0
        },
        sell: {
            min: 0,
            max: 0
        }
    }
};