import React, { useState, useRef } from "react";
import { Popper, Tooltip } from "@mui/material";
import "./tooltiptrade2.scss";
import { getCurrencyDecimals, toFixed } from "../../utils/math";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
const TooltipTrade2 = ({ order, from, to, children }) => {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { state } = useContext(UserContext);
  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  return (
    <>
      <div
        ref={anchorRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </div>
      <Popper open={open} anchorEl={anchorRef.current} placement="right">
        <div className="triangle-container2">
          <div className="triangle-div2">
            <Tooltip arrow>
              <div className="tooltip-content2">
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <p className="text-end">مقدار </p>
                    <p className="text-end"> {from}:</p>
                  </div>
                  <div className="d-flex">
                    <p className="total-align">
                      {from
                        ? toFixed(
                          parseFloat(`${order?.total_amount}`),
                          getCurrencyDecimals(from, "amount")
                        )
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <p className="text-end">کل </p>
                    <p className="text-end"> {to}:</p>
                  </div>
                  <div className="d-flex">
                    <p className="total-align">
                      {to
                        ? toFixed(
                          parseFloat(`${order?.total_to}`),
                          getCurrencyDecimals(from, "total")
                        )
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <p className="text-end">قیمت تقریبی </p>
                    <p className="text-end"> {to}:</p>
                  </div>
                  <div className="d-flex">
                    <p className="total-align">
                      {to
                        ? toFixed(
                          parseFloat(`${order?.total_to / order?.total_amount}`),
                          getCurrencyDecimals(from, "price")
                        )
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </Popper>
    </>
  );
};

export default TooltipTrade2;
