import React from 'react'
import Image404 from './assets/images/404.svg';
import { Button } from '@mui/material';
import './App.scss'
import { useNavigate } from 'react-router-dom';

function P404() {

    const navigate = useNavigate();
    const goToHome = () => {
        navigate("/");
    };

    return (
        <div>
            <div className="container-fluid P404">
                <div className="row align-content-center d-flex flex-column  ">
                    <div className="col-6">
                        <img src={Image404} alt="404" className='img-fluid' />
                    </div>
                    <div className="col-6   text-center">
                        <Button className="h-top-btn" onClick={goToHome}>بازگشت به صفحه اصلی</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default P404