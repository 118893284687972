import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ViewListIcon from "@mui/icons-material/ViewList";
import ReorderIcon from "@mui/icons-material/Reorder";
import { pink, green, grey } from "@mui/material/colors";
import UserOrderTitle from "./UserOrderTitleE";
import UserOrder from "./UserOrderE";
import LastPriceModule from "./LastPriceModuleE";
import SimpleBar from "simplebar-react";
import { SocketContext } from "../../../../contexts/providers/socket";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserOrderList() {
  const [value, setValue] = useState(0);
  const { stateIO } = React.useContext(SocketContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="orderlist-mobile">
      <Box>
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              sx={{ color: grey[500] }}
              {...a11yProps(0)}
            />

          </Tabs>
        </Box>
        <UserOrderTitle />
        <TabPanel value={value} index={0}>
          <div className="user-order-sell-mobile">
            {stateIO.sells
              .slice(0, 5)
              .reverse()
              .map((item, index) => {
                let percentage = ((item.amount - stateIO.volume_stats['sell']?.min) * 100) / (stateIO.volume_stats['sell']?.max - stateIO.volume_stats['sell']?.min)
                return (
                  <UserOrder
                    key={`limit_order_sell${index}`}
                    order={item}
                    side={"sell"}
                    percentage={percentage}
                  />
                );
              })}
          </div>

          <div className="text-start p-0 " style={{ marginTop: 5, marginBottom: -10 }}>
            <LastPriceModule />
          </div>

          <div className="user-order-sell-mobile">
            {stateIO.buys.slice(0, 5).map((item, index) => {
              let percentage = ((item.amount - stateIO.volume_stats['buy']?.min) * 100) / (stateIO.volume_stats['buy']?.max - stateIO.volume_stats['buy']?.min)
              return (
                <UserOrder
                  key={`limit_order_buy${index}`}
                  order={item}
                  side={"buys"}
                  percentage={percentage}
                />
              );
            })}
          </div>
          <div className="depth-main-e">
            <div className="sell-depth" style={{ width: `${stateIO.volume_percentage.sell - 5}%` }}>
              <p>{stateIO.volume_percentage.sell}% </p>
            </div>
            <div className="angled-line"></div>
            <div className="angled-line2"></div>
            <div className="buy-depth" style={{ width: `${stateIO.volume_percentage.buy - 1}%` }}>
              <p>{stateIO.volume_percentage.buy}% </p>
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
