import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./profile.scss";
import moment from "jalali-moment";
import { useNavigate } from "react-router-dom";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage } from "../../utils/RSS";
import { useEffect } from "react";
import { useState } from "react";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";




export default function SecurityLogs() {

  const [activityHistory, setActivityHistory] = useState(null)
  const navigate = useNavigate()
  const { state, dispatch } = useContext(UserContext);

  useEffect(() => {
    if
      (activityHistory === undefined || activityHistory === null) {
      getActivity()

    }
  }, [activityHistory]);

  const getActivity = () => {
    fetch(createApiAddress("api.user_activity"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then(async (response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" })
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.ok) {
          const data = await response.json();
          setActivityHistory(data);

        } else {
          // console.log("Error fetching data:", response.statusText);
        }
      })
      .catch((error) => {

      });
  }
  if (activityHistory === null) {
    return <></>
  }

  return (
    <TableContainer component={Paper} className="security-table">
      <Table sx={{ minWidth: 650, maxHeight: 400 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">زمان</TableCell>
            <TableCell align="right">نوع</TableCell>
            <TableCell align="right">مرورگر</TableCell>
            <TableCell align="right">سیستم عامل</TableCell>
            <TableCell align="right">ورژن</TableCell>
            <TableCell align="right">آی پی</TableCell>
            <TableCell align="right">قسمت</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {activityHistory.data?.activity?.map((row) => (
            <TableRow
              key={row.time}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="right">{moment(row.created_at).locale("fa").format("jYYYY/jMM/jDD HH:mm:ss")}</TableCell>
              <TableCell align="right">{row?.action}</TableCell>
              <TableCell align="right">{row?.device_info.browser}</TableCell>
              <TableCell align="right">{row?.device_info.os}</TableCell>
              <TableCell align="right">{row?.device_info.version}</TableCell>
              <TableCell align="right">{row?.ip}</TableCell>
              <TableCell align="right">{row?.section}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
