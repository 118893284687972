import React, { useContext, useState } from "react";
import {
  Button,
  Form,
  InputGroup,
  Modal,
  ModalFooter,
  Spinner,
} from "react-bootstrap";
import TradeSlider from "./Slider";
import { sendRequestEmit } from "../../../contexts/reducer/socket";
import { ItemNames, getStorage, isLoggedIn } from "../../../utils/RSS";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../../contexts/providers/user";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { getCurrencyDecimals, toFixed } from "../../../utils/math";
import { createApiAddress } from "../../../constant/api.constant";

function Market({
  side = "sell",
  from,
  to,
  balance_from,
  balance_to,
  last_price,
}) {
  const [marketForm, setMarketForm] = useState({
    amount: 0,
    total: 0,
  });

  const { tradePair } = useParams();
  const tokenName = tradePair.split("_")[0];
  const secondTokenName = tradePair.split("_")[1];

  const inputChange = (name) => {
    return (event) => {
      const form = marketForm;
      form[name] = event.target.value;
      let value = event.target.value;
      value = value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1");
      const decimalIndex = value.indexOf(".");
      if (side === "sell") {
        if (decimalIndex !== -1) {
          const decimals = getCurrencyDecimals(tokenName, "amount");
          const decimalPlaces = value.length - decimalIndex - 1;
          if (decimalPlaces > decimals) {
            value = value.slice(0, decimalIndex + decimals + 1);
          }
        }
        setTimeout(() => {
          form.total = form.amount * last_price;
          setMarketForm({ ...form });
        }, 50)
      } else {
        if (decimalIndex !== -1) {
          const decimals = getCurrencyDecimals(secondTokenName, "amount");
          const decimalPlaces = value.length - decimalIndex - 1;
          if (decimalPlaces > decimals) {
            value = value.slice(0, decimalIndex + decimals + 1);
          }
        }
        setTimeout(() => {
          form.total = form.amount / last_price;
          setMarketForm({ ...form });
        }, 50)
      }
      form[name] = value;
      setMarketForm({ ...form });
    };
  };

  const sendMarketRequest = () => {
    setIsloading(true);
    var data = {
      amount: marketForm.amount,
      pair: `${from}_${to}`,
      order: "market",
      type: side,
      trading_pwd: state.trading_password,
      token: getStorage(ItemNames.TOKEN),
      device: "web",
    };
    sendRequestEmit("trade:market", data);
    setMarketForm({ amount: 0 });
    setIsloading(false);
  };

  const slideChange = (e) => {
    let percentage = e;
    let balance = side === "sell" ? balance_from : balance_to;
    let amount_calculated = balance * (percentage / 100);

    setMarketForm({
      ...marketForm,
      amount:
        side === "sell"
          ? toFixed(amount_calculated, getCurrencyDecimals(tokenName, "amount"))
          : toFixed(amount_calculated, getCurrencyDecimals(secondTokenName, "amount")),

      total:
        side === "sell"
          ? toFixed(last_price * amount_calculated, getCurrencyDecimals(secondTokenName, "total"))
          : toFixed(amount_calculated / last_price, getCurrencyDecimals(tokenName, "total")),
    });
  };

  const navigate = useNavigate();

  const goToLoginPage = () => {
    navigate("/login");
  };

  const goToProfilePage = () => {
    navigate("/profile#1");
  };

  const checkBTNState = () => {
    if (isLoggedIn() === true) {
      if (state?.user_profile?.security?.password?.trading.status === true && state.trading_password === '') {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={handleShowTPWDinput}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "رمز معاملاتی را وارد کنید"}
          </Button>
        );
      } else if (state?.user_profile?.security?.password?.trading.status === false) {
        return (
          <Button
            variant="success"
            className="btn-limit-buy"
            onClick={goToProfilePage}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "رمز معاملاتی را فعال کنید"}
          </Button>
        );
      } else {
        return (
          <Button
            variant="success"
            className={side === "sell" ? "btn-limit-sell" : "btn-limit-buy"}
            onClick={warningHandlerMarket}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : `${side === "sell" ? "فروش" : "خرید"}`}
          </Button>
        );
      }
    } else if (isLoggedIn() === false) {
      return (
        <Button
          variant="success"
          className="btn-limit-buy"
          onClick={goToLoginPage}
        >
          ورود/ثبت نام
        </Button>
      );
    }
  };

  const [isLoading, setIsloading] = useState(false);

  const { state, dispatch } = useContext(UserContext);
  const [showTPWDstate, setShowTPWDstate] = useState(false);
  const handleCloseTPWDstate = () => setShowTPWDstate(false);
  const handleShowTPWDstate = () => setShowTPWDstate(true);

  const [showTPWDinput, setShowTPWDinput] = useState(false);
  const handleCloseTPWDinput = () => setShowTPWDinput(false);
  const handleShowTPWDinput = () => setShowTPWDinput(true);

  const [tradingPassword, setTradingPassword] = useState(null);

  const handleChangeTradingPassword = (event) => {
    setTradingPassword(event.target.value);
  };

  const openTradingPass = () => {
    if ("trading_pwd" === "trading_pwd1") {
      handleShowTPWDstate();
    } else {
      handleShowTPWDinput();
    }
  };

  const goToTPWDpage = () => {
    navigate("/profile");
  };

  const checkTradingPwdValidity = () => {
    if (tradingPassword === null || tradingPassword === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "لطفا رمز عبور خود را وارد کنید",
      });
    } else {
      conFirmTPWD();
    }
  };
  const conFirmTPWD = () => {
    setIsloading(true);
    var uri = createApiAddress("api.CONFIRM_TRADEPASS");
    var data = { password: tradingPassword };
    fetch(uri, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          dispatch({ type: "set:trading_pass", password: tradingPassword });
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: "رمز با موفقیت ثبت شد",
          });
          handleCloseTPWDinput();
          setIsloading(false);
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: "رمز اشتباه است",
          });
          setIsloading(false);
        }
      });
  };

  const minimumOrderLimit = 5;
  const balanceToDecimaled = `${toFixed(balance_to, getCurrencyDecimals(secondTokenName, "amount"))}`
  const balanceFromDecimaled = `${toFixed(balance_from, getCurrencyDecimals(tokenName, "amount"))}`


  const warningHandlerMarket = () => {
    if (
      marketForm.amount === "" ||
      marketForm.amount === null ||
      marketForm.amount === 0
    ) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "مبلغ مورد نیاز است",
      });
    } else if (side === "buy" && marketForm.amount > balance_to) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `مقدار ناکافی است، شما ${balanceToDecimaled} ${to} دارید`,
      });
    } else if (side === "sell" && marketForm.amount > balance_from) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: `مقدار ناکافی است، شما ${balanceFromDecimaled} ${from} دارید`,
      });
    } else if (side === "buy" && marketForm.amount < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "سفارش باید بالاتر از 5 USDT باشد",
      });
    } else if (side === "sell" && marketForm.total < minimumOrderLimit) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "سفارش باید بالاتر از 5 USDT باشد",
      });
    } else {
      sendMarketRequest();
    }
  };
  return (
    <div className="limit-buy-main">
      <div className="row  ">
        <div className="col available-section">
          <p>موجودی:</p>
          <p>
            {side === "sell"
              ? `${toFixed(balance_from, getCurrencyDecimals(tokenName, "amount"))} ${from}`
              : `${toFixed(balance_to, getCurrencyDecimals(secondTokenName, "amount"))} ${to}`
            }
          </p>
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <InputGroup className="mb-1">
            <InputGroup.Text>مقدار</InputGroup.Text>
            <Form.Control
              aria-label="amount"
              value={marketForm.amount}
              onChange={inputChange("amount")}
            />
            <InputGroup.Text>{side === "sell" ? from : to}</InputGroup.Text>
          </InputGroup>
          <div className="row">
            <div className="col slider-trade">
              <TradeSlider onchange={slideChange} />
            </div>
          </div>
          <div className="mb-4">
            <div className="col-12 market-total-value">
              مقدار دریافتی تقریبی:
            </div>
            <div className="row justify-content-between">
              <div className="col-8 market-total-value">
                {parseFloat(marketForm.total).toFixed(getCurrencyDecimals([`${side === "sell" ? secondTokenName : tokenName}`], "amount"))}
              </div>
              <div className="col-4 market-total-value text-end">
                {side === "sell" ? to : from}
              </div>
            </div>
          </div>

          <div className="col">{checkBTNState()}</div>
        </div>
      </div>
      <Modal
        show={showTPWDstate}
        onHide={handleCloseTPWDstate}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>لطفا رمز معاملاتی را فعال کنید</Modal.Title>
        </Modal.Header>
        <ModalFooter>
          <Button className="cancel-withdraw" onClick={handleCloseTPWDstate}>
            لغو
          </Button>
          <Button className="confirm-withdraw" onClick={goToTPWDpage}>
            به صفحه امنیت بروید
          </Button>
        </ModalFooter>
      </Modal>
      <>
        <Modal
          show={showTPWDinput}
          onHide={handleCloseTPWDinput}
          className="modal-withdraw"
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title>رمز معاملاتی خود را وارد کنید</Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list">
            <FormControl fullWidth>
              <TextField
                required
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="tradePass"
                placeholder="رمز معاملاتی"
                size="medium"
                type="password"
                name="trading_password"
                onChange={handleChangeTradingPassword}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
                inputProps={{
                  maxLength: 6,

                  form: {
                    autocomplete: "off",
                  },
                }}
              />
            </FormControl>
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleCloseTPWDinput}>
              لغو
            </Button>
            <Button
              className="confirm-withdraw"
              onClick={checkTradingPwdValidity}
              disabled={isLoading}
            >
              {isLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : null}
              {isLoading ? "بارگذاری..." : "تایید"}
            </Button>
          </ModalFooter>
        </Modal>
      </>
    </div>
  );
}

export default Market;
