import React from "react";
import "./home.scss";
import FAccordion from "./Accordion";
import FAccordion2 from "./Accordion2";
import Accordion from "react-bootstrap/Accordion";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "./NavbarMobile";
import './home.scss'
function HomeQuestion() {

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="home-q-mobile">
        <div className="faq-main-home-mobile mb-3">
          <NavbarTopMobile />
          <div className="faq-module container-fluid">
            <div className="faq-mobile">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <div className="row">
                      <div className="col-12 justify-content-center">
                        <h6 className="pt-2 text-center">سوالات متداول</h6>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-6">
                        <FAccordion />
                      </div>
                      <div className="col-12  col-md-6">
                        <FAccordion2 />
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          <div className="container-fluid navbar-mobile-bot">
            <div className="row justify-content-center">
              <div className="col-12">
                <ResponsiveAppBar className="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="faq-main-home mb-3">
      <div className="faq-module container-fluid">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="row">
                <div className="col-12 justify-content-center">
                  <h6 className="pt-2 text-center">سوالات متداول</h6>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="row justify-content-center">
                <div className="col-12 col-md-6">
                  <FAccordion />
                </div>
                <div className="col-12  col-md-6">
                  <FAccordion2 />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
}

export default HomeQuestion;
