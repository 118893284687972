import React from "react";

import Markettabs from "./Markettabs";
import "./market.scss";
import MarketMobile from "./MarketMobile";
function Market() {
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  if (isMobileDevice === true) {
    return <>
      <MarketMobile />
    </>
  }
  return (
    <div className="market-main">
      <div className="container market-table">
        <Markettabs />
      </div>
    </div>
  );
}

export default Market;
