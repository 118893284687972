import React, { useEffect } from "react";
import './loader.scss'
import LoadingImage from '../assets/images/t-logo.svg'
function Loader() {

    useEffect(() => {
        const circles = document.querySelectorAll('.circle');
        const initialAngles = [0, 100, 200];
        const rotationSpeed = 2;

        const rotateCircles = () => {
            circles.forEach((circle, index) => {
                initialAngles[index] += rotationSpeed;
                circle.style.transform = `rotate(${initialAngles[index]}deg)`;
            });
            requestAnimationFrame(rotateCircles);
        };
        rotateCircles();
    }, []);

    return (
        <div className="container-fluid p-0">
            <div className="row p-0">
                <div className="col p-0">
                    <div className="loading-body p-0">
                        <div className="loading p-0">
                            <img src={LoadingImage} alt="loading" height={125} className="mt-5" />
                            <div className="circle cyan"></div>
                            <div className="circle magenta"></div>
                            <div className="circle yellow"></div>
                            <p>به تریکسو خوش آمدید</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Loader;