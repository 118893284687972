import React, { useState } from 'react';
import { Container, Grid, Paper, TextField, Button, Snackbar, Alert } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './support.scss'
import { createApiAddress } from '../../constant/api.constant';
import { ItemNames, getStorage } from '../../utils/RSS';
import { useEffect } from 'react';
import { UserContext } from '../../contexts/providers/user';
import { useContext } from 'react';
import moment from 'jalali-moment';
import SimpleBar from 'simplebar-react';
import { Form, Modal, ModalFooter } from 'react-bootstrap';
import { useRef } from 'react';
import { useNavigate } from 'react-router';

function ConversationPage({ handleClose, ticketId, handleReloader }) {
    const [conversation, setConversation] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [isConversationClosed, setIsConversationClosed] = useState(false);
    const { dispatch } = useContext(UserContext);
    const [title, setTitle] = useState()
    const [reload, setReload] = useState(true)
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        comment: '',
        media_file: null
    });

    const lastMessageRef = useRef(null);

    const scrollToBottom = () => {
        if (lastMessageRef.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleUserInput = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleReload = () => {
        setReload(!reload)
    }

    const [openSnackBar, setOpenSnackBar] = useState({
        show: false,
        severity: "warning",
        message: "",
    });

    const handleFileButtonClick1 = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        setFormData({
            ...formData,
            media_file: event.target.files[0],
        });
    };

    const handleCloseSnackBar2 = () => {
        setOpenSnackBar({
            ...openSnackBar,
            show: false,
        });
    };

    const [showAsk, setShowAsk] = useState(false);
    const closeAskModal = () => setShowAsk(false);
    const handleShowAsk = () => setShowAsk(true);
    const closeFunc = () => {
        handleCloseConversation()
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData();
        data.append("body", formData.comment);
        data.append("media_file", formData.media_file);
        if (formData.comment !== '') {
            try {
                const response = await fetch(`https://api.terixo.com/api/v3/tickets/${ticketId}/comment`, {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                    },
                    body: data,
                });

                if (response.status === 401) {
                    dispatch({ type: "Logout" });
                    return;
                }

                if (response.status === 503) {
                    navigate('/maintenance')
                    return;
                }

                if (response.ok) {
                    const data = await response.json();
                    setOpenSnackBar({
                        show: true,
                        severity: 'success',
                        message: data?.locale?.fa,
                    })
                    const newMessage = { role: 'user', message: userInput };
                    setConversation([...conversation, newMessage]);
                    formData.comment = ''
                    setUserInput('');
                    handleReload()
                }
            } catch (error) {
            }
        }
    };

    const handleCloseConversation = async () => {


        try {
            const response = await fetch(createApiAddress("api.GET_TICKET", ticketId), {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            });
            if (response.status === 401) {
                dispatch({ type: "Logout" });
                return;
            }
            if (response.status === 503) {
                navigate('/maintenance')
                return;
            }
            if (response.ok) {
                setIsConversationClosed(true);
                closeAskModal();
                handleReloadList();
            }
        } catch (error) {

        }
    };

    const checkValidation = (event) => {
        event.preventDefault();
        if (formData.comment === '') {
            setOpenSnackBar({
                show: true,
                severity: 'warning',
                message: 'توضیح الزامی می باشد',
            });
        } else {
            handleSubmit(event)
        }
    }


    const closeTheModal = () => {
        handleClose();
    };

    const handleReloadList = () => {
        handleReloader()
    }
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(createApiAddress("api.GET_TICKET", ticketId), {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                    },
                });

                if (response.status === 401) {
                    dispatch({ type: "Logout" });
                    return;
                }

                if (response.status === 503) {
                    navigate('/maintenance')
                    return;
                }

                if (response.ok) {
                    const data = await response.json();
                    setConversation(data?.data.ticket.comments)
                    setTitle(data?.data.ticket.title)
                    if (data?.data?.ticket?.status === 'closed') {
                        setIsConversationClosed(true)
                    } else {
                        setIsConversationClosed(false)
                    }
                }
            } catch (error) {
            }
        };

        fetchData();
    }, [reload]);

    useEffect(() => {
        scrollToBottom();
    }, [conversation]);

    return (
        <div className='conversation-main'>
            <Container className='conversation-module'>
                {!isConversationClosed && <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleShowAsk}
                    className='conversation-btn'
                >
                    بستن تیکت پشتیبانی
                </Button>}
                <Grid container spacing={2}>
                    <SimpleBar style={{ maxHeight: 380, width: '97%', marginTop: 30 }} autoHide={false}>

                        <Grid item xs={12}>
                            <Paper elevation={3} style={{ padding: '20px', minHeight: '400px' }}>
                                <p>{title}</p>
                                {conversation.length > 0 ? conversation.map((message, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            justifyContent:
                                                message.is_admin === true ? 'flex-end' : 'flex-start',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        <div
                                            style={{
                                                background:
                                                    message.is_admin
                                                        ? '#f0f0f0'
                                                        : 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                                                borderRadius: '15px',
                                                padding: '10px',
                                                maxWidth: '70%',
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            {message.is_admin === true ? <p>عضو پشتیبانی</p> : <p>{message?.creator?.username.toUpperCase()}</p>}
                                            <p>{message?.body}</p>
                                            <p>{moment(message.updated_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss [در] dddd')}</p>
                                        </div>
                                    </div>
                                )) : <></>}
                                <div ref={lastMessageRef}></div>
                            </Paper>
                        </Grid>
                    </SimpleBar>
                    {isConversationClosed === false ? (
                        <div className='conversation-form'>
                            <Grid item xs={12} className='conversation-form'>
                                <form onSubmit={checkValidation} className='conversation-form'>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        name='comment'
                                        value={formData.comment}
                                        onChange={handleUserInput}
                                        className='conversation-form'
                                        autoFocus
                                    />
                                    <div>
                                        <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label className="mt-2">
                                                {" "}
                                                اگر نیاز به آپلود فایل میباشد.
                                            </Form.Label>
                                            <Form.Control
                                                type="file"
                                                name="media_file"
                                                ref={fileInputRef}
                                                style={{ display: "none" }}
                                                onChange={handleFileChange}
                                            />
                                        </Form.Group>
                                        <Button
                                            onClick={handleFileButtonClick1}
                                            className="btn-in-kyc4 mb-3"
                                        >

                                            {formData.media_file === null ?
                                                "فایل را انتخاب کنید" : formData.media_file?.name}
                                        </Button>
                                    </div>
                                    <div className='d-flex justify-content-between mt-4'>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            endIcon={<SendIcon />}
                                            className='conversation-btn'
                                        >
                                            ارسال
                                        </Button>
                                        <Button variant="contained" color="success" onClick={closeTheModal} className='conversation-btn'>بستن</Button>
                                    </div>
                                </form>
                            </Grid>
                        </div>
                    ) :
                        <div className='d-flex justify-content-between mt-4'>

                            <Button variant="contained" color="success" onClick={closeTheModal} className='conversation-btn'>بستن</Button>
                        </div>
                    }
                </Grid>
            </Container>
            <Snackbar
                open={openSnackBar.show}
                autoHideDuration={6000}
                onClose={handleCloseSnackBar2}
            >
                <Alert severity={openSnackBar.severity}>
                    {openSnackBar.message}
                </Alert>
            </Snackbar>
            <Modal
                show={showAsk}
                onHide={closeAskModal}
                className="modal-logout"
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title>آیا مطمئن هستید که می خواهید تیکت را ببندید؟</Modal.Title>
                </Modal.Header>

                <ModalFooter>
                    <Button className="confirm-logout" onClick={() => closeFunc()}
                    >
                        تایید
                    </Button>
                    <Button className="cancel-logout" onClick={closeAskModal}>
                        لغو
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ConversationPage;
