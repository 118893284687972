import React, { useRef, useState } from "react";
import "./career.scss";
import { Button, Form } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { createApiAddress } from "../../constant/api.constant";
import { Alert, Snackbar } from "@mui/material";
import { useNavigate } from "react-router";

function Career() {
  const fileInputRef = useRef(null);
  const [showSnack, setShowSnack] = useState(false)
  const [showSnack2, setShowSnack2] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [formSubmitted, setFormSubmitted] = useState(true);
  const [formData, setFormData] = useState({
    state: "",
    city: "",
    fullName: "",
    email: "",
    phoneNumber: "",
    resume: null,
  });

  const [selectedOption, setSelectedOption] = useState('0');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleCloseSnack = () => {
    setShowSnack(false)
  }

  const handleCloseSnack2 = () => {
    setShowSnack2(false)
  }

  const getSelectedText = (value) => {
    switch (value) {
      case '1':
        return 'کارشناس ارشد امنیت شبکه';
      case '2':
        return 'کارشناس مارکتینگ دیجیتال';
      case '3':
        return ' کارشناس ارشد برنامه نویسی';
      case '4':
        return 'تحلیلگر بازار ارزهای دیجیتال';
      case '5':
        return 'کارشناس ارشد توسعه کسب و کار';
      case '6':
        return 'موشن گراف';
      case '7':
        return 'کارشناس تولید محتوا';
      case '8':
        return 'مدیر بخش شبکه های اجتماعی';
      default:
        return 'لطفا انتخاب کنید';
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileButtonClick3 = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setFormData({
      ...formData,
      resume: event.target.files[0],
    });
  };



  const checkFormValidation = () => {
    if (selectedOption === null || formData.city === '' || formData.fullName === '' || formData.email === '' || formData.phoneNumber === '' || formData.resume === null) {
      setErrorMsg('همه فیلدها الزامی است')
      setShowSnack2(true)
    } else {
      handleSubmit()
    }
  }


  const handleSubmit = async (event) => {
    // event.preventDefault();
    const data = new FormData();
    data.append("job_position", getSelectedText(selectedOption));
    data.append("city", formData.city);
    data.append("full_name", formData.fullName);
    data.append("email", formData.email);
    data.append("mobile", formData.phoneNumber);
    data.append("resume_file", formData.resume);

    try {
      const response = await fetch(createApiAddress('api.career'), {
        method: "POST",
        body: data,
      });
      const responseData = await response.json();

      if (responseData.status === 1) {
        setFormSubmitted(false)
      } else {
        setErrorMsg(responseData.locale.fa)
        setShowSnack2(true)
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const navigate = useNavigate();
  const goToHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="career-main">
      <div className="career-module">
        <div className="container">
          {formSubmitted ? <div className="row">
            <div className="col p-4">
              <h6>موقعیتهای شغلی مورد نیاز</h6>
              <Form>
                <Form.Select name="state" className="cities" value={selectedOption} onChange={handleOptionChange}>
                  <option value="0">لطفا انتخاب کنید</option>
                  <option value="1">کارشناس ارشد امنیت شبکه</option>
                  <option value="2">کارشناس مارکتینگ دیجیتال</option>
                  <option value="3"> کارشناس ارشد برنامه نویسی</option>
                  <option value="4">تحلیلگر بازار ارزهای دیجیتال</option>
                  <option value="5">کارشناس ارشد توسعه کسب و کار</option>
                  <option value="6">موشن گراف</option>
                  <option value="7">کارشناس تولید محتوا</option>
                  <option value="8">مدیر بخش شبکه های اجتماعی</option>
                </Form.Select>
                <div className="row">
                  <div className="col-11 col-md-6">
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="city"
                        name="city"
                        placeholder="استان و شهر محل سکونت"
                        size="medium"
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-11 col-md-6">
                    <FormControl fullWidth>
                      <TextField
                        type="text"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="fullName"
                        name="fullName"
                        placeholder="نام و نام خانوادگی"
                        size="medium"
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-11 col-md-6">
                    <FormControl fullWidth>
                      <TextField
                        type="email"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="email"
                        name="email"
                        placeholder="ایمیل"
                        size="medium"
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className="col-11 col-md-6">
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="شماره تماس"
                        size="medium"
                        onChange={handleInputChange}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </div>
                </div>
                <div>
                  <Form.Group controlId="formFile" className="mb-3">
                    {/* <Form.Label className="mt-2">
                      عکس سلفی به همراه مدرک شناسایی در دست
                    </Form.Label> */}
                    <Form.Control
                      type="file"
                      required
                      name="selfie"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </Form.Group>
                  <Button
                    onClick={handleFileButtonClick3}
                    className="btn-in-kyc4"
                  >
                    {formData?.resume === null ?
                      "رزومه کاری خود را آپلود کنید" : formData?.resume?.name}
                  </Button>
                </div>
                <div className="row">
                  <div className="col text-start">
                    <Button onClick={checkFormValidation} className="btn-in-kyc4">
                      ثبت فرم درخواست{" "}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
            <Snackbar
              open={showSnack2}
              autoHideDuration={5000}
              onClose={handleCloseSnack2}
              anchorOrigin={{ vertical: 'bottom', horizontal: "right" }}
            >
              <Alert
                severity='error'
                sx={{ fontSize: 18, maxWidth: 400 }}
              >
                {errorMsg}
              </Alert>
            </Snackbar>
          </div> :
            <div className="container mt-5 pt-5" style={{ height: 200 }}>
              <div className="row justify-content-center d-flex flex-column">
                <div className="col">
                  <p className="mt-3 pt-3 text-center">بابت ارسال رزومه کاری خود تشکر می کنیم، در صورت نیاز کارشناسان ما با شما تماس خواهند گرفت.</p>
                </div>
                <div className="col text-center">
                  <Button className="btn-withdraw-error" onClick={goToHome}>
                    برای رفتن به صفحه اصلی  کلیک کنید
                  </Button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {/* <div className="container">
        <div className="row">
          <div className="bottom-career">test</div>
        </div>
      </div> */}
    </div>
  );
}

export default Career;
