import { Alert, Button, Fade, Snackbar, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import './deposit.scss'
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { createApiAddress } from "../../../constant/api.constant";
import { Modal, ModalFooter, Spinner } from "react-bootstrap";
import { numberToWords } from "@persian-tools/persian-tools";
import { calculateFeeByCondition, toFixed } from "../../../utils/math";
import InfoIcon from '@mui/icons-material/Info';

function DepositMoney(props) {
  const navigate = useNavigate()
  const { state, dispatch } = useContext(UserContext);
  const [bankCards, setBankCards] = useState([])
  const [cardNumber, setcardNumber] = useState(null);
  const [amount, setAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showFinal, setShowFinal] = useState(false);
  const handleCloseFinal = () => {
    setIsLoading(false)
    setShowFinal(false);
  }
  const handleShowFinal = () => setShowFinal(true);
  const [openSnackBar, setOpenSnackBar] = useState({
    show: false,
    severity: "warning",
    message: "",
  });

  const handleCloseSnackBar = () => {
    setOpenSnackBar({
      ...openSnackBar,
      show: false,
    });
  };
  var limitDepositDaily = props.timeBaseLimit?.limit?.deposit?.daily_toman_amount
  var usedDepositDaily = props.timeBaseLimit?.used?.deposit?.daily_toman_amount
  var limitDepositMonthly = props.timeBaseLimit?.limit?.deposit?.monthly_toman_amount
  var usedDepositMonthly = props.timeBaseLimit?.used?.deposit?.monthly_toman_amount

  var dailyRemain = () => {
    if (usedDepositDaily < limitDepositDaily) {
      return limitDepositDaily - usedDepositDaily
    } else {
      return 0
    }
  };

  var monthlyRemain = () => {
    if (usedDepositMonthly < limitDepositMonthly) {
      return limitDepositMonthly - usedDepositMonthly
    } else {
      return 0
    }
  };

  const depositValidation = () => {
    if (amount === null || amount === '') {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: 'مقدار را وارد کنید.',
      });
    } else if (cardNumber === null || cardNumber === undefined || cardNumber === "") {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: 'کارت بانکی خود را انتخاب کنید.',
      });
    } else if (amount > dailyRemain()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `باقیمانده حجم واریز روزانه شما: ${dailyRemain()}`,
      });
    } else if (amount > monthlyRemain()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `باقیمانده حجم واریز ماهانه  شما: ${monthlyRemain()}`,
      });
    } else if (amount < props.irtMin) {
      setOpenSnackBar({
        ...openSnackBar,
        show: true,
        severity: "warning",
        message: `مقدار کمترین واریز معادل ${props.irtMin !== undefined ? numberToWords(props.irtMin) : props.irtMin} تومان در هر تراکنش می باشد.`,
      });
    } else {
      handleShowFinal()
    }
  }
  const goToBankCard = () => {
    navigate("/profile#3");
  }

  const goToBankGateway = async () => {
    setIsLoading(true)
    const form = {
      'amount': amount,
      'card_number': cardNumber
    }
    try {
      const response = await fetch(createApiAddress("api.DEPOSIT_MONEY_INVOICE"), {
        method: "POST",
        body: JSON.stringify(form),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });

      if (response.status === 401) {
        dispatch({ type: "Logout" });
        return;
      }
      if (response.status === 503) {
        navigate('/maintenance')
        return;
      }
      if (response.status === 200) {
        const data = await response.json();
        if (data.status !== 0) {
          window.location.replace(data.data?.redirect?.url);
        } else {
          setOpenSnackBar({
            ...openSnackBar,
            show: true,
            severity: "warning",
            message: 'خطایی رخ داد لطفا دوباره تلاش کنید',
          });
        }
      } else {
        setIsLoading(false)
      }
    }
    catch (error) {
      setIsLoading(false)
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(createApiAddress("api.BANK_CARD_LIST_VERIFIED"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });

        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.status === 200) {
          const data = await response.json();
          setBankCards(data?.data)

        }
      }
      catch (error) {
      }
    };
    fetchData()
  }, []);



  const setSpaceBetweenCard = (data) => {
    let formattedCardNumber = data.replace(/(\d{4})/g, '$1 ');
    formattedCardNumber = formattedCardNumber.trim().replace(/\s/g, '-');
    return formattedCardNumber;
  }

  const goToKyc = () => {
    navigate("/kyc", { replace: true });
  };

  const [selectedBank, setSelectedBank] = useState(null);
  const [errorLimitMessage, setErrorLimitMessage] = useState('');


  const checkRemainingLimit = () => {
    if (dailyRemain() > monthlyRemain()) {
      return monthlyRemain()
    } else if (dailyRemain() < monthlyRemain()) {
      return dailyRemain()
    }
  }

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === '0') {
      setSelectedBank(null);
      setcardNumber(null)
    } else {
      const selectedCard = bankCards.find((bankName) => bankName.card_number === selectedValue);
      setSelectedBank(selectedCard.bank);
      setcardNumber(selectedValue)
    }
  };

  const [wordsOfAmount, setWordsOfAmount] = useState('');
  const conditions = props.irtInfo.deposit?.fee?.conditions;

  const handleAmountChange = (event) => {
    var newAmount = event.target.value;
    newAmount = event.target.value.replace(/[^0-9]/g, "")
    setAmount(newAmount);

    if (newAmount > checkRemainingLimit()) {
      setAmount(checkRemainingLimit())
      const words = numberToWords(checkRemainingLimit());
      setWordsOfAmount(words);
      setErrorLimitMessage(`حجم باقیمانده واریز روزانه یا ماهانه شما: ${numberToWords(checkRemainingLimit())}`)
      return;
    }
    if (newAmount !== null && newAmount !== undefined) {
      const words = numberToWords(newAmount);
      setWordsOfAmount(words);
      setErrorLimitMessage('')
    } else {
      setWordsOfAmount('');
      setErrorLimitMessage('')
    }
  }


  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {
      const numberStr = number.toString();
      const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedNumber;
    } else {
      return number
    }
  }

  const calculateFee = () => {
    if (amount === 0 || amount === undefined || amount < props.irtMin) {
      return 120;
    }
    return calculateFeeByCondition(amount, props.irtInfo.deposit?.fee?.conditions);
  }

  const feeText = `کارمزد واریز تومان  ${conditions?.percentage} درصد با حداقل مبلغ ${numberToWords(conditions?.min)} تومان و حداکثر مبلغ کارمزد ${numberToWords(conditions?.max)} تومان میباشد.`;

  if (
    state?.user_profile?.info?.kyc?.status !== "approved_lvl2" &&
    state?.user_profile?.info?.kyc?.status !== "approved"
  ) {
    return (
      <div className="when-error-deposit">
        <div className="error-module-deposit">
          <div className="attention-withdraw-deposit">
            <h5 className="p-2">
              برای واریز تومان باید <span> فرآیند احراز هویت </span> خود را انجام
              دهید.
            </h5>
          </div>
          <div className="error-btn-deposit text-center mt-5">
            <Button className="btn-withdraw-error-deposit" onClick={goToKyc}>
              برای رفتن به صفحه احراز هویت کلیک کنید
            </Button>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="container deposit-money-main p-3">
        <h5>واریز تومان</h5>
        <h6 className="text-danger">توجه داشته باشید که پس از اولین واریز تومان از کارتی که جدید در سیستم اضافه شده است، برداشت از سایت به مدت 72 ساعت به حالت تعلیق تغییر پیدا میکند.این امر برای جلوگیری از هرگونه سوء استفاده افراد دیگر از حساب بانکی کاربر میباشد.</h6>
        <div className="row ">
          <div className="col">
            <Button className="confirm-withdraw" onClick={goToBankCard} >
              کارت بانکی اضافه کنید
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Form.Group className="mb-3 mt-5">
              <Form.Label className="form-label">انتخاب کارت بانکی</Form.Label>
              <Form.Select
                className="deposit-money-selection"
                value={cardNumber}
                onChange={handleSelectChange}
              >
                <option value={0} >کارت بانکی را انتخاب کنید</option>
                {bankCards.map((bankName, index) => (
                  <option
                    key={index}
                    value={bankName.card_number}
                    className="select-in-deposit-money"
                    onClick={() => handleSelectChange({ target: { value: bankName.card_number } })}
                  >
                    <p> {setSpaceBetweenCard(bankName.card_number)} </p><p> {bankName.bank}</p>
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3 mt-5" controlId="formBasicEmail">
              <Form.Control
                className="deposit-money-selection"
                type="number"
                placeholder=" مبلغ واریزی را به تومان وارد کنید"
                onChange={handleAmountChange}
                max={25000000}
                value={amount}
                step={1}
              />
            </Form.Group>
            <div className="row">
              <div className="col-12 col-md-6">
                <p className="p-color mt-2">{wordsOfAmount} تومان</p>
              </div>
              <div className="col-12 col-md-6 text-start">
                <p className="p-color mt-2">{errorLimitMessage}</p>
              </div>
            </div>
            <div className="row fee-deposit-money mt-5 d-flex justify-content-between">
              <div className="col-12">
                <div className="row">
                  <div className="col-10">
                    <h6 className="mt-2">کارمزد واریز
                      <Tooltip
                        title={<h6 className="tooltip-text">{feeText}</h6>}
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="top"
                        leaveDelay={400}
                        className="tootltip-main"
                      >
                        <Button><InfoIcon /></Button>
                      </Tooltip>
                    </h6>
                  </div>
                  <div className="col-2 mt-3 text-start">
                    {amount === null || amount === '' ? <h6>0</h6> : <h6 className="mt-2">{calculateFee() ? toFixed(calculateFee(), 0) : 0}</h6>}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-8 col-md-10">
                    <h6 >مبلغ واریزی شما پس از کسر کارمزد:</h6>
                  </div>
                  <div className="col-4 col-md-2 text-start">
                    <h6 >{(amount - calculateFee() < 0) ? 0 : formatNumber(amount - calculateFee())}</h6>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-color2">
                    مجموع واریز روزانه:
                  </div>
                  <div className="col-6 text-start p-color2">
                    <h6>{usedDepositDaily ? formatNumber(usedDepositDaily) : usedDepositDaily} از {limitDepositDaily ? formatNumber(limitDepositDaily) : limitDepositDaily} تومان</h6>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-6 p-color2">
                    <h6>مجموع واریز ماهانه:</h6>
                  </div>
                  <div className="col-6 text-start p-color2">
                    <h6>{usedDepositMonthly ? formatNumber(usedDepositMonthly) : usedDepositMonthly} از {limitDepositMonthly ? formatNumber(limitDepositMonthly) : limitDepositMonthly} تومان</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="row   mt-5 d-flex justify-content-between">
              <h6 className="p-color">توجه:</h6>
              <p className="p-color2">مقدار کمترین واریز  معادل {props.irtMin !== undefined ? numberToWords(props.irtMin) : props.irtMin} تومان در هر تراکنش می باشد.</p>
              <p className="p-color2">بیشترین مقدار برای واریز  در هر 24 ساعت معادل بیست و پنج میلیون تومان می باشد.</p>
            </div>
            <div className="container">
              <div className="row">
                <div className="col gateway-btn text-center mt-5">
                  <Button className="goto-gateway" onClick={depositValidation} disabled={isLoading} >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "رفتن به درگاه پرداخت"}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={showFinal}
            onHide={handleCloseFinal}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title className="text-success">نکته مهم</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <p>لطفاً اطمینان حاصل کنید که کارت انتخاب شده برای واریز استفاده شود؛ در غیر اینصورت، واریز شما تایید نخواهد شد.</p>
              <p>کارت انتخاب شده: {cardNumber} {selectedBank}</p>
            </Modal.Body>
            <ModalFooter className="deposit-footer-btn">
              <div className="container-fluid">
                <div className="row justify-content-between">
                  <div className="col-6">
                    <Button
                      className="deposit-btn-modal"
                      onClick={handleCloseFinal}
                    >
                      بستن
                    </Button>
                  </div>
                  <div className="col-6 text-start">
                    <Button
                      className="deposit-btn-modal"
                      onClick={goToBankGateway}
                    >
                      رفتن به درگاه پرداخت
                    </Button>
                  </div>
                </div>
              </div>
            </ModalFooter>
          </Modal>
          <Snackbar
            open={openSnackBar.show}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
          >
            <Alert severity={openSnackBar.severity}>
              {openSnackBar.message}
            </Alert>
          </Snackbar>
        </div>
      </div>
    );
  }
}

export default DepositMoney;
