import React from "react";

import Wallets from "./Wallets";
import "./assets.scss";
import WalletMobile from "./WalletMobile";
function Assets() {
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  if (isMobileDevice) {
    return <>
      <WalletMobile />
    </>
  }
  return (
    <div className="assets-main">
      <div className="container wallets">
        <Wallets />
      </div>
    </div>
  );
}

export default Assets;
