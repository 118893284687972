import moment from "jalali-moment";
import React from "react";
import { useParams } from "react-router-dom";
import { getCurrencyDecimals, toFixed } from "../../../../utils/math";

function EachOrderHistory({ info }) {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");

  const colorInTrade = () => {
    if (info.side === "sell") {
      return "textdanger";
    } else if (info.side === "buy") {
      return "textsuccess";
    }
  };
  return (
    <div className="row">
      <div className=" d-flex ">
        <div className="col-3 order-time-in-orderhistory-mobile">
          {moment(info.ctime).locale("fa").format("HH:mm:ss")}
        </div>
        <div className="col-4 order-amount-in-orderhistory-mobile">
          {toFixed(info.amount, getCurrencyDecimals(from, "amount"))}
        </div>
        <div className={"col-4 order-price-in-orderhistory-mobile " + colorInTrade()}>
          {toFixed(info.price, getCurrencyDecimals(from, "price"))}
        </div>
      </div>
    </div>
  );
}

export default EachOrderHistory;
