import React from "react";
import { useParams } from "react-router-dom";

function UserOrderTitle() {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  return (
    <div className="row p-0 m-0">
      <div className="col d-flex justify-content-between pair-title-main" style={{ marginTop: -25 }}>
        <div className="col-3 mobile-last-third">کل {to}</div>
        <div className="col-3 mobile-last-second">مقدار {from}</div>
        <div className="col-3 mobile-last-first">قیمت {to}</div>
      </div>
    </div>
  );
}

export default UserOrderTitle;
