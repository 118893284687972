import Container from "react-bootstrap/Container";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import PersonIcon from "@mui/icons-material/Person";
import "../navbar/navbar.scss";
import * as React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import SelectTheme from "../../utils/Themeselector";
import { IconButton } from "@mui/material";
import ScrollToTop from "../../utils/ScrollToTop";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import { isLoggedIn } from "../../utils/RSS";
import { useState } from "react";
import { useEffect } from "react";
import Animation from '../../assets/json/gift-icon.json'
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import { SocketContext } from "../../contexts/providers/socket";
import NavbarTopMobile from "./NavbarTopMobile";

function MenuWithColapse() {
  const { state, dispatch } = useContext(UserContext);
  const { dispatchIO } = useContext(SocketContext);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const logoutFunc = () => {
    dispatch({ type: "Logout" });
    dispatchIO({ type: "set:user:balances", wallets: {} });
    navigate("/logoutuser", { replace: true });
    handleClose();
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  const { pathname } = useLocation();
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  const regex1 = new RegExp("/mobilechart/.*");
  const regex2 = new RegExp("/instant-chart/.*");
  const regex3 = new RegExp("/trade-chart-mobile/.*");
  const regex4 = new RegExp("/pmobile");
  const regex5 = new RegExp("/checkuser");
  const regex6 = new RegExp("/logoutuser");
  const regex7 = new RegExp("/maintenance");
  const regex8 = new RegExp("/mhome");
  const regex9 = new RegExp("/withdrawconfirmation");


  if (regex1.test(pathname) || regex2.test(pathname) || regex3.test(pathname) || regex4.test(pathname) || regex5.test(pathname) || regex6.test(pathname) || regex7.test(pathname) || regex8.test(pathname) || regex9.test(pathname) || isMobileDevice === true)
    return null;

  return (
    <>
      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="mb-3 navbar-main fixed-top"
          sticky="top"
        >
          <Container fluid>
            <NavLink to="/">
              <Navbar.Brand>
                <div className="logohere"></div>
                <ScrollToTop />
              </Navbar.Brand>
            </NavLink>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setDropdownOpen(!dropdownOpen)}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={dropdownOpen}
              onHide={() => setDropdownOpen(false)}
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className="header-name">
                  Terixo
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-start flex-grow-1 pe-3">
                  <NavLink as={Link} to="/market" className="nav-link">
                    بازار رمز ارزها
                    <ScrollToTop />
                  </NavLink>
                  <NavLink as={Link} to="/trade/BTC_USDT" className="nav-link"
                  // target="_blank"
                  >
                    معاملات حرفه ای <ScrollToTop />
                  </NavLink>
                  <NavLink as={Link} to="/instantbuysell" className="nav-link2 nav-link">
                    درگاه معاملات تتر<ScrollToTop />
                  </NavLink>
                  <NavDropdown
                    title=" واریز و برداشت "
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavLink as={Link} to="/deposit" className="nav-link">
                      واریز
                      <ScrollToTop />
                    </NavLink>
                    {/* <NavDropdown.Divider /> */}
                    <NavLink as={Link} to="/withdraw" className="nav-link">
                      برداشت
                      <ScrollToTop />
                    </NavLink>
                    <NavLink as={Link} to="/transfer" className="nav-link">
                      انتقال
                      <ScrollToTop />
                    </NavLink>
                  </NavDropdown>
                </Nav>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <div className="d-flex">
                    <NavDropdown
                      title="اعضا VIP"
                      id={`offcanvasNavbarDropdown-expand-${expand}`}
                    >
                      <NavLink as={Link} to="/vipwallet" className="nav-link">
                        کیف پول
                        <ScrollToTop />
                      </NavLink>
                      {/* <NavDropdown.Divider /> */}
                      <NavLink as={Link} to="/viphistory" className="nav-link">
                        تاریخچه
                        <ScrollToTop />
                      </NavLink>
                    </NavDropdown>
                    <NavLink as={Link} to="/prizes" className="nav-link prize-nav">
                      جوایز
                      <ScrollToTop />
                    </NavLink>
                    <div className="gift-icon">
                      <Player
                        autoplay
                        loop
                        src={Animation}
                        style={{ maxHeight: "50px", maxWidth: "50px" }}
                        className="nav-link"
                      >
                        <Controls
                          visible={false}
                          buttons={["play", "repeat", "frame", "debug"]}
                        />
                      </Player>
                    </div>
                  </div>
                  <NavLink as={Link} to="/assets" className="nav-link">
                    کیف پول
                    <ScrollToTop />
                  </NavLink>
                  <NavLink as={Link} to="/kyc" className="nav-link">
                    احراز هویت <ScrollToTop />
                  </NavLink>
                  <div className="row d-flex flex-row icon-navbar-main">
                    <div className="col icon-navbar-ui pr-2">
                      <NavLink
                        as={Link}
                        to="/profile"
                        className="nav-link-sec me-2"
                      >
                        <IconButton
                          size="small"
                          style={{ color: "#38E54D" }}
                          aria-label="profile"
                          className="  icon-navbar-ui-profile"
                        >
                          <PersonIcon
                            fontSize="medium"
                            className="icon-navbar-ui-profile"
                          />
                        </IconButton>
                        <ScrollToTop />
                      </NavLink>
                    </div>
                  </div>
                  <div className="pl-2">
                    {isLoggedIn() ? (
                      // <NavLink as={Link} to="/login" className="nav-link-sec">
                      <Button
                        className="me-1"
                        variant="warning"
                        onClick={handleShow}
                      >
                        خروج
                      </Button>
                    ) : (
                      // <ScrollToTop />
                      // </NavLink>
                      <NavLink as={Link} to="/login" className="nav-link-sec">
                        <Button className="me-1 login-navbar-btn" variant="warning">
                          ورود
                        </Button>
                        <ScrollToTop />
                      </NavLink>
                    )}
                  </div>
                  <div className="pl-2 theme-icon sun-icon">
                    <SelectTheme />
                  </div>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-logout"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>آیا می خواهید از سیستم خارج شوید؟</Modal.Title>
        </Modal.Header>
        <ModalFooter>
          <Button className="confirm-logout" onClick={logoutFunc}>
            تایید
          </Button>
          <Button className="cancel-logout" onClick={handleClose}>
            لغو
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default MenuWithColapse;
