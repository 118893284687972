import React, { useEffect } from "react";
import "./deposit.scss";
import { useState } from "react";
import { Blocks } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { createApiAddress } from "../../../constant/api.constant";
import Danger from "../../../assets/images/danger-icon.svg";
import Success from "../../../assets/images/success-icon.svg";
import { Button } from "@mui/material";

function DepositMoneyConfirmation() {
  const [isLoading, setIsLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [data, setData] = useState(false);
  const [color, setColor] = useState(false);
  const [invoice, setInvoice] = useState({
    invoice_id: "",
    ref_id: "",
    deposit_amount: 0,
    fee: 0,
    user_recieve_balance: 0,
  });
  const navigate = useNavigate();
  const currentUrl = window.location.search;
  const urlParams = new URLSearchParams(currentUrl);
  const params = {};
  for (const [key, value] of urlParams) {
    params[key] = value;
  }

  useEffect(() => {
    const request2confirm = async () => {
      let res = await fetch(createApiAddress("api.VERIFY_MONEY_INVOICE"), {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      });
      let resJson = await res.json();
      if (res.status === 200) {
        setIsLoading(false);
        setData(true);
        if (resJson.data !== undefined) {
          setInvoice({
            ...invoice,
            ...resJson.data,
          });
        }
        if (resJson.status === 0) {
          setColor(true);
          setMsg("تراکنش شما ناموفق بود.");
        } else if (resJson.status === 1) {
          setColor(false);
          setMsg("تراکنش شما با موفقیت انجام شد.");
        }
      }
    };
    request2confirm();
  }, []);

  const goToHome = () => {
    navigate("/");
  };

  return (
    <div className="container-fluid confirmation-main">
      <div className="row justify-content-center">
        <div className="col-6 text-center">
          <div className="confirmation-main">
            <div className="confirmation-module">
              <div className="row mt-2">
                {data === true ? (
                  <div className="col mt-4">
                    {color === false ? (
                      <img src={Success} alt="success" height={70} width={70} />
                    ) : (
                      <img src={Danger} alt="danger" height={70} width={70} />
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <h5>وضعیت برداشت</h5>
              </div>
              {isLoading === true ? (
                <div className="confirm-loader">
                  <Blocks
                    visible={true}
                    height="60"
                    width="60"
                    wrapperStyle={{}}
                  />
                </div>
              ) : (
                <h6>
                  {data === true ? (
                    <>
                      <div className="container-fluid">
                        <div className="row justify-content-start">
                          <h3 className={color === false ? "text-success" : "text-danger"}>
                            {msg}
                          </h3>

                          <div className="col">
                            <div className="row justify-content-between">
                              <div className="col-5 text-end">
                                <h6>شماره سفارش: </h6>
                              </div>
                              <div className="col-6 text-start">
                                <h6>{invoice.invoice_id}</h6>
                              </div>
                            </div>

                            <div className="row justify-content-between">
                              <div className="col-5 text-end">
                                <h6>کد پیگیری: </h6>
                              </div>
                              <div className="col-6 text-start">
                                <h6>{invoice.ref_id}</h6>
                              </div>
                            </div>

                            {invoice.deposit_amount !== 0 && (
                              <div className="row justify-content-between">
                                <div className="col-5 text-end">
                                  <h6>مبلغ واریزی: </h6>
                                </div>
                                <div className="col-6 text-start">
                                  <h6>{invoice.deposit_amount}</h6>
                                </div>
                              </div>
                            )}

                            {invoice.fee !== 0 && (
                              <div className="row justify-content-between">
                                <div className="col-5 text-end">
                                  <h6>مبلغ کارمزد: </h6>
                                </div>
                                <div className="col-6 text-start">
                                  <h6>{invoice.fee}</h6>
                                </div>
                              </div>
                            )}

                            {invoice.user_recieve_balance !== 0 && (
                              <div className="row justify-content-between">
                                <div className="col-5 text-end">
                                  <h6>مبلغ واریزی با احتساب کارمزد: </h6>
                                </div>
                                <div className="col-6 text-start">
                                  <h6>{invoice.user_recieve_balance}</h6>
                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                        <Button
                          className="navigate-btn-dmc mt-5"
                          onClick={goToHome}
                        >
                          رفتن به صفحه اصلی
                        </Button>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepositMoneyConfirmation;
