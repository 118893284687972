import { ItemNames, getStorage, setLogOut, setStorage } from "../../utils/RSS";
import { isLoggedIn } from "../../utils/RSS";

export const reducer = (state, action) => {
  switch (action.type) {
    case "Login":
      return {
        ...state,
        isLoggedIn: localStorage.getItem(ItemNames.TOKEN) !== null ? true : false,
      };
    case "Logout":
      setLogOut();
      return {
        ...state,
        isLoggedIn: localStorage.getItem(ItemNames.TOKEN) !== null ? true : false,
      };
    case "toggle_modal":
      return {
        ...state,
        isOpenModal: !state.isOpenModal,
        modalContent: action.content,
      };
    case "open_dialog":
      return {
        ...state,
        isOpenDialog: !state.isOpenDialog,
        dialogContent: action.content,
        dialogTitle: action.title,
        submitDialog: action.submitDialog,
        dialogName: action.dialogName,
        isLoading: action.isLoading,
        btnText: action.btnText,
      };
    case "close_dialog":
      return {
        ...state,
        isOpenDialog: !state.isOpenDialog,
        dialogContent: "",
        dialogTitle: "",
        submitDialog: () => null,
        dialogName: "",
        isLoading: false,
        btnText: "",
      };
    case "open_dialog2":
      return {
        ...state,
        isOpenDialog2: !state.isOpenDialog2,
        dialogContent1: action.content1,
        dialogName1: action.dialogName1,
        dialogTitle1: action.title1,
        dialogContent2: action.content2,
        dialogName2: action.dialogName2,
        submitDialog2: action.submitDialog2,
      };
    case "close_dialog2":
      return {
        ...state,
        isOpenDialog2: !state.isOpenDialog2,
        dialogContent1: action.content1,
        dialogName1: "",
        dialogTitle1: action.title1,
        dialogContent2: "",
        dialogName2: "",
        submitDialog2: () => null,
      };
    case "open_snack":
      return {
        ...state,
        isOpenSnack: !state.isOpenSnack,
        snackColor: action.snackColor,
        snackMessage: action.snackMessage,
      };
    case "load:currency:image":
      return {
        ...state,
        currency: {
          ...state.currency,
          images: action.images,
        },
      };
    case "load:currency:details":
      let details = {};
      action.currencies.forEach((item) => {
        details[item.symbol] = item;
      });
      setStorage(ItemNames.CURRENCIES, JSON.stringify(details));
      return {
        ...state,
        currency: {
          ...state.currency,
          details: details,
        },
      };
    case "pair:fav:set":
      if (action.action === "add") {
        state.pair_fav.push(action.currency);
      } else {
        state.pair_fav = state.pair_fav.filter(
          (currency) => action.currency !== currency
        );
      }
      setStorage(ItemNames.FAV_PAIR, state.pair_fav.join(","));
      return {
        ...state,
      };
    case "set:profile":
      return {
        ...state,
        user_profile: action.profile,
      };
    case "set:profile:refer_count":
      return {
        ...state,
        user_profile: {
          ...state.user_profile,
          refer_count: action.refer_count
        },
      };
    case "set:trading_pass":
      return {
        ...state,
        trading_password: action.password,
      };
    default:
      return state;
  }
};

export const initialState = {
  isLoggedIn: localStorage.getItem(ItemNames.TOKEN) !== null ? true : false,
  isOpenModal: false,
  modalContent: "",
  isOpenDialog: false,
  dialogContent: "",
  dialogTitle: "",
  submitDialog: () => null,
  dialogName: "",

  dialogContent1: "",
  dialogName1: "",
  isOpenDialog2: false,
  dialogContent2: "",
  dialogTitle2: "",
  submitDialog2: () => null,
  dialogName2: "",

  isOpenSnack: false,
  snackColor: "",
  snackMessage: "",
  trading_password: "",
  pair_fav: getStorage(ItemNames.FAV_PAIR)
    ? getStorage(ItemNames.FAV_PAIR).split(",")
    : [],
  user_profile: {},
  currency: {
    images: {},
    details: {},
  },
};
