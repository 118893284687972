import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function AnnouncementModule(props) {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col">
          <Card sx={{ maxWidth: 750 }}>
            <CardMedia
              component="img"
              alt="announcement"
              height="180"
              image={props.announcementImage}
            />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="announcement-title"
              >
                {props.announcementTitle}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="announcement-content"
              >
                <div>{props.announcementContent}</div>
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
}
