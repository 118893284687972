import React, { useState, useEffect } from "react";
import "./learning.scss";
import Card from "react-bootstrap/Card";
import { NavbarText, Row } from "react-bootstrap";
import { Button, Modal, ModalFooter } from "react-bootstrap";
import ReactPlayer from "react-player";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

function Learning() {
  const [show, setShow] = useState(false);
  const [videoData, setVideoData] = useState([]); // Store video data from JSON
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleClose = () => {
    setShow(false);
    setSelectedVideo(null);
  };

  const handleShow = (video) => {
    setSelectedVideo(video);
    setShow(true);
  };

  useEffect(() => {
    getVideoList()
  }, []);

  const getVideoList = () => {
    fetch('https://s3.terixo.com/public/videos/videos.json?nocache=', {
      method: "GET",
    })
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setVideoData(data.data)
        } else {
        }
      })
      .catch((error) => {

      });
  }

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="learning-main-mobile">
        <NavbarTopMobile />
        <div className="container">
          <div className="learning-module">
            <div className="bg-top-learning">
              <h3 className="pt-5">ویدئوهای آموزشی</h3>
            </div>

            <Row xs={1} md={3} className="  m-5">
              {videoData.map((video, index) => (
                <Card className="p-1 text-center" key={index}>
                  <Card.Img variant="top" src={video.thumbnail} />
                  <Card.Body>
                    <Card.Title>{video.title}</Card.Title>
                    <Card.Text>{video.description}</Card.Text>
                    <Button className="video-btn" onClick={() => handleShow(video)}>
                      دیدن ویدئو
                    </Button>
                  </Card.Body>
                </Card>
              ))}
            </Row>
          </div>
        </div>
        <div className="video-player">

          <Modal
            show={show}
            onHide={handleClose}
            className="modal-video"
            backdrop="static"

            fullscreen
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="video-modalbody">
              {selectedVideo && (
                <div className="video-container">
                  <ReactPlayer
                    url={selectedVideo.url}
                    controls={true}
                    width="100%"
                    height="100%"
                  />
                </div>
              )}
            </Modal.Body>
            <ModalFooter>
              <Button className="cancel-withdraw" onClick={handleClose}>
                بستن
              </Button>
            </ModalFooter>
          </Modal>

        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </div>
    </>
  }
  return (
    <div className="learning-main">
      <div className="container">
        <div className="learning-module">
          <div className="bg-top-learning">
            <h3 className="pt-5">ویدئوهای آموزشی</h3>
          </div>

          <Row xs={1} md={3} className="  m-5">
            {videoData.map((video, index) => (
              <Card className="p-1 text-center" key={index}>
                <Card.Img variant="top" src={video.thumbnail} />
                <Card.Body>
                  <Card.Title>{video.title}</Card.Title>
                  <Card.Text>{video.description}</Card.Text>
                  <Button className="video-btn" onClick={() => handleShow(video)}>
                    دیدن ویدئو
                  </Button>
                </Card.Body>
              </Card>
            ))}
          </Row>
        </div>
      </div>
      <div className="video-player">

        <Modal
          show={show}
          onHide={handleClose}
          className="modal-video"
          backdrop="static"

          fullscreen
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="video-modalbody">
            {selectedVideo && (
              <div className="video-container">
                <ReactPlayer
                  url={selectedVideo.url}
                  controls={true}
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </Modal.Body>
          <ModalFooter>
            <Button className="cancel-withdraw" onClick={handleClose}>
              بستن
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    </div>
  );
}

export default Learning;
