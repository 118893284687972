import React from "react";

function PairTitle() {
  return (
    <div className="row p-0 m-0">
      <div className="col d-flex justify-content-between pair-title-main p-3" style={{ marginTop: -30 }}>
        <div className="col-3 mobile-orderlist-third">حجم</div>
        <div className="col-3 mobile-orderlist-second">قیمت</div>
        <div className="col-3 mobile-orderlist-first">جفت ارز</div>
      </div>
    </div>
  );
}

export default PairTitle;
