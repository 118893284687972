import React from 'react'
import './assets.scss'
function WalletMobileEach(props) {
    return (
        <div className='container-fluid wallet-mobile-box mb-2'>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontSize: 15, marginTop: 10 }}>{props.available}</p>
                                    <p style={{ fontSize: 12, marginTop: -15 }} className='text-success'>{props.in_usdt} USDT</p>
                                    {props.symbol !== 'IRT' ? <p style={{ fontSize: 12, marginTop: -15 }} className='irt-avl text-danger' >{props.in_irt}</p> : <p></p>}
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-start">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontSize: 15, marginTop: 18 }}>{props.symbol}</p>
                                    <p style={{ fontSize: 12, marginTop: -20 }}>{props.name}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 mt-3">
                            {props.image ? <img src={props.image} alt="token-logo" height={35} /> : <></>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletMobileEach
