import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link, NavLink } from 'react-router-dom';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import Enamad from "../../assets/enamad-1star.png"

export default function HomeFooterMobile() {
    return (
        <div className='mt-3'>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>درباره ما
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>
                            <NavLink as={Link} to="/about" className="nav-link-footer">
                                معرفی
                            </NavLink>
                        </li>

                        <li>
                            <NavLink as={Link} to="/announcement" className="nav-link-footer">
                                اعلانات
                            </NavLink>
                        </li>
                        <li>
                            <NavLink as={Link} to="/termsofuse" className="nav-link-footer">
                                مقررات و شرایط استفاده
                            </NavLink>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography>محصولات</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>
                            <NavLink
                                as={Link}
                                to="/trade/BTC_USDT"
                                className="nav-link-footer"
                            >
                                معاملات حرفه ای
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/instantbuysell"
                                className="nav-link-footer"
                            >
                                درگاه معاملات تتر
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/market"
                                className="nav-link-footer"
                            >
                                بازار رمز ارزها
                            </NavLink>
                        </li>

                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>خدمات</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ul>
                        <li>
                            <NavLink as={Link} to="/kyc" className="nav-link-footer">
                                احراز هویت
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/fee"
                                className="nav-link-footer"
                            >
                                کارمزدها
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/learning"
                                className="nav-link-footer"
                            >
                                ویدئوهای آموزشی
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/support"
                                className="nav-link-footer"
                            >
                                پشتیبانی ۲۴ ساعته
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                as={Link}
                                to="/commission"
                                className="nav-link-footer"
                            >
                                درآمد ثابت
                            </NavLink>
                        </li>
                    </ul>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                >
                    <Typography>ارتباط با ما</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <h3>ارتباط 24 ساعته با ما</h3>
                    <p> ارسال ایمیل به: <span>Support@terixo.com</span></p>

                    <p>
                        <NavLink as={Link} to="/support" className="nav-link-footer2">
                            پشتیبانی آنلاین <AdsClickIcon />
                        </NavLink>
                    </p>
                    <p className="mt-5">تلفن 0۲۱۸۶۱۹۴۶۲۵</p>
                    <p className="padding-p">از ساعت ۸/۳۰ تا ساعت ۱۶ روزهای کاری</p>
                </AccordionDetails>
            </Accordion>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <a target='_blank' href='https://trustseal.enamad.ir/?id=373376&code=kM3HzYG0wu6VRnhcqF045Pc72b3neJze'>
                            <img src={Enamad} alt='enamad' style={{ cursor: "pointer", height: 70 }} />
                        </a>
                    </div>
                    <div className="col-12">
                        <h6 className="text-center mt-4 copyright" style={{ color: 'white' }}>کلیه حقوق این سایت متعلق به تریکسو می باشد</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}