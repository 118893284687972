import Accordion from "react-bootstrap/Accordion";
import TradeChart from "./TradeChartD";
import "../trade.scss";

function ChartDropdown() {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>نمایش نمودار</Accordion.Header>
        <Accordion.Body>
          <TradeChart />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default ChartDropdown;
