import moment from "jalali-moment";
import React from "react";
import { useParams } from "react-router-dom";
import { getCurrencyDecimals, toFixed } from "../../../../utils/math";

function EachOrderHistory({ info }) {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");

  const colorInTrade = () => {
    if (info.side === "sell") {
      return "textdanger";
    } else if (info.side === "buy") {
      return "textsuccess";
    }
  };
  return (
    <div className="row">
      <div className="col d-flex justify-content-between history-list-main">
        <div className="order-time-in-orderhistory">
          {moment(info.ctime).locale("fa").format("HH:mm:ss")}
        </div>
        <div className="order-amount-in-orderhistory">
          {toFixed(info.amount, getCurrencyDecimals(from, "amount"))}
        </div>
        <div className={"order-price-in-orderhistory " + colorInTrade()}>
          {toFixed(info.price, getCurrencyDecimals(from, "price"))}
        </div>
      </div>
    </div>
  );
}

export default EachOrderHistory;
