import React, { useContext } from "react";
import OrderHistoryTitle from "./OrderHistoryTitleC";
import EachOrderHistory from "./EachOrderHistoryC";
import SimpleBar from "simplebar-react";
import { SocketContext } from "../../../../contexts/providers/socket";

function OrderHistory() {
  const { stateIO } = React.useContext(SocketContext);
  return (
    <div className="orderhistory-mainA">
      <div className="row ">
        <h7 className="me-4 mt-4 mb-1">آخرین معاملات</h7>
        <div className="col">
          <OrderHistoryTitle />
        </div>
        <div className="col">
          <SimpleBar style={{ maxHeight: 215 }} autoHide={false}>
            {stateIO.histories.map(function (item, index) {
              return (
                <EachOrderHistory key={`order_history_${index}`} info={item} />
              );
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
