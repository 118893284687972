import "./profile.scss";
import PInfo from "./PInfo";
import PSecurity from "./PSecurity";
import SecurityLogs from "./PSecurityLogs";
import { Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserPoints from "./UserPoints";
import { UserContext } from "../../contexts/providers/user";
import { useLocation, useNavigate } from "react-router-dom";
import CardManager from "./CardManager";
import ProfileMobile from "./ProfileMobile";
import TransactionHistory from "./TransactionHistory";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Profile() {
  const [tab, setTab] = useState(0);
  const { state } = useContext(UserContext);
  const location = useLocation()


  useEffect(() => {
    if (location.hash !== "") {
      const number = location.hash.replace('#', '')
      setTab(parseInt(number))
    }
  }, [tab])





  if (state.user_profile.status === undefined) {
    return (<></>)
  }

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <ProfileMobile />
    </>
  }

  return (
    <div className=" profile-main">
      <div className="container">
        <div className="col tab-main">
          <Tabs
            value={tab}
            onChange={(event, number) => {
              setTab(number)
              // history.pushState(null, null, `#${number}`)
              window.location.replace(`#${number}`)
            }}
            scrollButtons
            variant="scrollable">
            <Tab label="پروفایل" {...a11yProps(0)} />
            <Tab label="امنیت" {...a11yProps(1)} />
            <Tab label="تاریخچه مالی" {...a11yProps(2)} />
            <Tab label="تاریخچه" {...a11yProps(3)} />
            <Tab label="مدیریت کارتهای بانکی" {...a11yProps(4)} />
            <Tab label="امتیاز شما برای قرعه کشی" {...a11yProps(5)} />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <PInfo profileInfo={state.user_profile} />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <PSecurity profile={state.user_profile} />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <h5 className="header-logs">گزارش سوابق مالی</h5>
            <TransactionHistory />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <h5 className="header-logs">تاریخچه گزارش امنیتی</h5>
            <SecurityLogs />
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <h5 className="header-logs">مدیریت کارتهای بانکی</h5>
            <CardManager profile={state.user_profile} />
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <h6 className='point-table'>در اینجا می توانید امتیازاتی که از هر اقدامی در پلتفرم تریکسو انجام داده و بدست آورده اید را بررسی کنید.</h6>
            <UserPoints />
          </TabPanel>
        </div>
      </div>
    </div>
  );
}

export default Profile;
