import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UserContext } from "../../../../contexts/providers/user";
import moment from "jalali-moment";
import { Button } from "react-bootstrap";
import { ItemNames, getStorage } from "../../../../utils/RSS";
import { useContext } from "react";
import { getCurrencyDecimals, toFixed } from "../../../../utils/math";
import { createApiAddress } from "../../../../constant/api.constant";



export default function OpenHistoryTable({ orders = [], type = "open" }) {
  if (type === "open") {
    return Open(orders);
  } else if (type === "match") {
    return MatchTable(orders);
  }
  return TradeTable(orders);
}

// const getNumberFromObject = (num) => {
//   if (typeof num === "object") {
//     return num["$numberDecimal"];
//   }

//   return num;
// };

const Open = (orders) => {
  const { state, dispatch, dispatchIO } = useContext(UserContext);

  const CancelOrderRequest = (order_id) => {
    let uri = createApiAddress("api.CANCEL_ORDER", order_id);
    fetch(uri, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.success === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: data.locale.fa,
          });
          dispatchIO({ type: "set:user:orders", orders: data.data.history });
          dispatchIO({ type: "set:user:balances", wallets: data.data.wallets });
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: data.locale.fa,
          });
        }
      });
  };
  const changeToPersian = (x) => {
    if (x === 'market') {
      return 'مارکت';
    } else if (x === 'limit') {
      return 'لیمیت';
    } else {
      return 'استاپ/لیمیت'
    }
  }

  const changeToPersianOrder = (x) => {
    if (x === 'filled') {
      return 'تکمیل شد';
    } else if (x === 'canceled') {
      return 'لغو شد';
    } else if (x === 'partially') {
      return 'ناتمام'
    } else if (x === 'not_triggered') {
      return 'در انتظار'
    } else {
      return 'فعال شد'
    }
  }

  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
        stickyHeader
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">تاریخ و ساعت</TableCell>
            <TableCell align="left">جفت ارز</TableCell>
            <TableCell align="left">نوع</TableCell>
            <TableCell align="left">مقدار</TableCell>
            <TableCell align="left">قیمت</TableCell>
            <TableCell align="left">شرط</TableCell>
            <TableCell align="left">کل</TableCell>
            <TableCell align="left">وضعیت</TableCell>
            <TableCell align="left">اقدام</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss [در] dddd')}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency?.images[row.pair?.from]}
                  style={{ width: 25, height: 25, marginLeft: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}/{row.pair.to}
              </TableCell>
              <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {changeToPersian(row.order_type)} | {row.side === 'sell' ? 'فروش' : 'خرید'}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.amount, getCurrencyDecimals(row.pair.from, 'amount'))}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell>
              {changeToPersian(row.order_type) === 'استاپ/لیمیت' ? <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {row.side === 'buy' ? 'بزرگتر یا برابر' : 'کوچکتر یا برابر'}{" "}
                {toFixed(row.stop_price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell> : <TableCell>-</TableCell>}
              <TableCell align="left">
                {toFixed(row.total, getCurrencyDecimals(row.pair.from, 'total'))}
              </TableCell>
              <TableCell align="left">{changeToPersianOrder(row.status)}</TableCell>
              <TableCell align="left">
                <Button onClick={() => CancelOrderRequest(row.id)}>لغو</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const TradeTable = (orders) => {
  const { state } = React.useContext(UserContext);
  const changeToPersianOrder = (x) => {
    if (x === 'filled') {
      return 'تکمیل شد';
    } else if (x === 'canceled') {
      return 'لغو شد';
    } else if (x === 'partially') {
      return 'ناتمام'
    } else if (x === 'not_triggered') {
      return 'در انتظار'
    } else {
      return 'فعال شد'
    }
  }

  const changeToPersian = (x) => {
    if (x === 'market') {
      return 'مارکت';
    } else if (x === 'limit') {
      return 'لیمیت';
    } else {
      return 'استاپ/لیمیت'
    }
  }

  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">تاریخ و ساعت</TableCell>
            <TableCell align="left">جفت ارز</TableCell>
            <TableCell align="left">نوع</TableCell>
            <TableCell align="left">مقدار</TableCell>
            <TableCell align="left">قیمت</TableCell>
            <TableCell align="left">شرط</TableCell>
            <TableCell align="left">کل</TableCell>
            <TableCell align="left">وضعیت</TableCell>
            <TableCell align="left"> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss [در] dddd')}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency.images[row.pair.from]}
                  style={{ width: 25, height: 25, marginLeft: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}/{row.pair.to}
              </TableCell>
              <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {changeToPersian(row.order_type)} | {row.side === 'sell' ? 'فروش' : 'خرید'}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.amount, getCurrencyDecimals(row.pair.from, 'amount'))}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell>
              {changeToPersian(row.order_type) === 'استاپ/لیمیت' ? <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {row.side === 'buy' ? 'بزرگتر یا برابر' : 'کوچکتر یا برابر'}{" "}
                {toFixed(row.stop_price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell> : <TableCell>-</TableCell>}
              <TableCell align="left">
                {toFixed(row.total, getCurrencyDecimals(row.pair.from, 'total'))}
              </TableCell>
              <TableCell align="left">{changeToPersianOrder(row.status)}</TableCell>
              <TableCell align="left"> </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const MatchTable = (orders) => {
  const { state } = useContext(UserContext);
  const changeToPersian = (x) => {
    if (x === 'market') {
      return 'مارکت';
    } else if (x === 'limit') {
      return 'لیمیت';
    } else {
      return 'استاپ/لیمیت'
    }
  }

  const getFeeSide = (row) => {
    if (row.side === 'sell') {
      return row.pair.to;
    } else {
      return row.pair.from
    }
  }

  return (
    <TableContainer
      component={Paper}
      className="security-table"
      sx={{ minWidth: 1050, maxHeight: 275 }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: 1050, maxHeight: 275 }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell align="left">تاریخ و ساعت</TableCell>
            <TableCell align="left">جفت ارز</TableCell>
            <TableCell align="left">نوع</TableCell>
            <TableCell align="left">مقدار</TableCell>
            <TableCell align="left">قیمت</TableCell>
            <TableCell align="left">شرط</TableCell>
            <TableCell align="left">کارمزد ها</TableCell>
            <TableCell align="left">کل</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders?.map((row) => (
            <TableRow
              key={row.created_at}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">
                {moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss [در] dddd')}
              </TableCell>
              <TableCell align="left">
                <img
                  src={state.currency?.images[row.pair?.from]}
                  style={{ width: 25, height: 25, marginLeft: 10 }}
                  alt={"currency"}
                />
                {row.pair.from}/{row.pair.to}
              </TableCell>
              <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {changeToPersian(row.order_type)} | {row.side === 'sell' ? 'فروش' : 'خرید'}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.amount, getCurrencyDecimals(row.pair.from, 'amount'))}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell>
              {changeToPersian(row.order_type) === 'استاپ/لیمیت' ? <TableCell align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                {row.side === 'buy' ? 'بزرگتر یا برابر' : 'کوچکتر یا برابر'}{" "}
                {toFixed(row.stop_price, getCurrencyDecimals(row.pair.from, 'price'))}
              </TableCell> : <TableCell>-</TableCell>}
              <TableCell align="left">
                {getFeeSide(row)}{' '}
                {toFixed(row.fee, 8)}
              </TableCell>
              <TableCell align="left">
                {toFixed(row.total, getCurrencyDecimals(row.pair.from, 'total'))}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
