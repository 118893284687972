import React, { useContext, useState, useEffect } from "react";
import "./register.scss";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Button, Spinner } from "react-bootstrap";
import { ItemNames, getStorage, setStorage, isLoggedIn } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { useLocation, useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Modal, ModalFooter } from "react-bootstrap";
import { createApiAddress } from "../../constant/api.constant";
import { IconButton } from "@mui/material";
import { GridVisibilityOffIcon } from "@mui/x-data-grid";
import { VisibilityOutlined } from "@mui/icons-material";
import PassChecker from "../../utils/PassCheker";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

function Register() {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);
  const [checkMail, setCheckMail] = useState(false);
  const [userName, setUsername] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const handleCloseOTP = () => setShowOTP(false);
  const handleShowOTP = () => setShowOTP(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isLoading3, setIsLoading3] = useState(false);
  const [mailOTP, setMailOTP] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowIcon(!showIcon);
  };
  const sendOTP = async (event) => {
    event.preventDefault();
    if (userName === "" || userName === null || email === "" || email === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "نام کاربری و ایمیل را وارد کنید",
      });
    } else if (userName.length < 5) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "نام کاربری حداقل 6 حرفی باشد.",
      });
    } else {
      try {
        setIsLoading(true);
        let res = await fetch(createApiAddress("api.send_email_otp"), {
          method: "POST",
          body: JSON.stringify({
            email,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });
        let resJson = await res.json();
        if (resJson.status === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: "OTP به ایمیل شما ارسال شد",
          });
          handleShowOTP();
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.locale.fa
          });
        }
      } catch (err) {
        // console.log(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const verifyEmailRequest = async (event) => {
    event.preventDefault();
    setIsLoading3(true);
    if (mailOTP === "" || mailOTP === undefined || mailOTP === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "کد را وارد کنید",
      });
      setIsLoading3(false);
    } else {
      try {
        let res = await fetch(createApiAddress("api.verify_email"), {
          method: "POST",
          body: JSON.stringify({
            email: email,
            otp: mailOTP,
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        let resJson = await res.json();
        if (resJson.status === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: "ایمیل تایید شد",
          });
          setIsLoading3(false);
          setCheckMail(true);
          handleCloseOTP();
        } else {
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.locale.fa,
          });
          setIsLoading3(false);
        }
      } catch (err) {
        setIsLoading3(false);
      }
    }
  };


  const LoadUserProfile = () => {
    if (isLoggedIn() === true) {
      fetch(createApiAddress("api.user_info"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({ type: "set:profile", profile: data.user });
          } else {
            dispatch({ type: "Logout" });
          }
        })
        .catch((error) => dispatch({ type: "Logout" }));
    }
  };

  const signUp = async (event) => {
    event.preventDefault();
    if (password === "" || password === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمز را وارد کنید",
      });
    } else if (confirmPassword === "" || confirmPassword === null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "تکرار رمز را وارد کنید",
      });
    } else if (isError !== null) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمز عبور قوی نیست",
      });
    } else if (password !== confirmPassword) {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "رمزها یکسان نیستند",
      });
    } else {
      try {
        setIsLoading2(true);
        let res = await fetch(createApiAddress("api.signup"), {
          method: "POST",
          body: JSON.stringify({
            username: userName,
            country: "IRI",
            email: email,
            password: password,
            confirm_password: confirmPassword,
            ref_code: refCode,
            device_type: "web",
          }),
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });
        let resJson = await res.json();
        if (resJson.status === 1) {
          dispatch({
            type: "open_snack",
            snackColor: "success",
            snackMessage: "حساب کاربری با موفقیت ایجاد گردید",
          });
          setStorage(ItemNames.TOKEN, resJson.data.token);
          isLoggedIn();
          dispatch({ type: "login" });
          LoadUserProfile();
          navigate("/", { replace: true });
        } else {
          // console.log(resJson)
          dispatch({
            type: "open_snack",
            snackColor: "warning",
            snackMessage: resJson.locale.fa,
          });
        }
      } catch (err) {
        // console.log(err);
      } finally {
        setIsLoading2(false);
      }
    }
  };

  const [pwdInput, initValue] = useState({
    password: "",
  });
  const [isError, setError] = useState(null);

  const onChange = (e) => {
    let password = e.target.value;
    setPassword(password)
    initValue({
      ...pwdInput,
      password: e.target.value,
    });
    setError(null);
    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      setError(
        "رمز عبور باید حداقل شامل 8 کاراکتر باشد"
      );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/\W/g) || []).length;
      if (caps < 1) {
        setError("باید یک حرف بزرگ اضافه کنید");
        return;
      } else if (small < 1) {
        setError("باید یک حرف کوچک اضافه کنید");
        return;
      } else if (num < 1) {
        setError("شما باید یک عدد اضافه کنید");
        return;
      } else if (specialSymbol < 1) {
        setError("باید یک نماد خاص اضافه کنید: @$! % *؟ & #");
        return;
      }
    }
  };
  const [isStrong, initRobustPassword] = useState(null);
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };

  const [loginStatus, setLoginStatus] = useState(false);
  const checkLogingStatus = () => {
    if (isLoggedIn() === true) {
      setLoginStatus(true);
    } else if (isLoggedIn() === false) {
      setLoginStatus(false);
    }
  };

  useEffect(() => {
    checkLogingStatus();
  });


  const location = useLocation();

  useEffect(() => {
    if (location.hash !== "") {
      const referralInLink = location.hash.replace('#', '');
      setRefCode(referralInLink)
    }
  }, [])
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return loginStatus === false ? (
      <>
        <NavbarTopMobile />
        <div className="register-main-mobile">
          <div className="container">
            <div className="row register-module">
              <div className="col text-center">
                <h5 className="mt-4 register-title">ثبت نام</h5>
                <Form onSubmit={sendOTP}>
                  <InputGroup className="mt-5 register-dir">
                    <Form.Control
                      type="text"
                      placeholder="نام کاربری"
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </InputGroup>
                  <InputGroup className="mt-3 register-dir">
                    <Form.Control
                      type="text"
                      placeholder="ایمیل"
                      name="email"
                      onChange={handleEmailChange}
                    />
                  </InputGroup>
                  {!isValidEmail && <p className="text-error">{email === "" ? "" : "فرمت ایمیل نامعتبر است."}</p>}
                  {checkMail === false ? (
                    <div className="col">
                      <Button
                        className="submit-btn m-3"
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? "بارگذاری..." : "تأیید ایمیل"}
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </Form>
                {checkMail === true ? (
                  <Form onSubmit={signUp}>
                    <InputGroup className="mt-2 register-dir">
                      <IconButton
                        aria-label="show-eye"
                        onClick={togglePasswordVisibility}
                      >
                        {showIcon ? (
                          <GridVisibilityOffIcon />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="کلمه عبور"
                        onChange={onChange}
                      />
                    </InputGroup>
                    {isError !== null
                      ? <p className="errors-register">{isError}</p>
                      :
                      <p className="errors-register">{password !== '' ? "رمز عبور قوی است." : "یک رمز عبور قوی برای امنیت خود وارد کنید."}</p>
                    }

                    <Form.Control.Feedback type="invalid">
                      لطفا رمز عبور خود را وارد کنید.
                    </Form.Control.Feedback>
                    <div className="container p-0">
                      <div className="row justify-content-start p-0">
                        <div className="col-12">
                          <PassChecker password={pwdInput.password} actions={initPwdInput} />
                        </div>
                      </div>
                    </div>
                    {/* {isStrong === "strong" && <button type="submit"> Register </button>} */}
                    <InputGroup className="mt-3 register-dir">
                      <IconButton
                        aria-label="show-eye"
                        onClick={togglePasswordVisibility}
                      >
                        {showIcon ? (
                          <GridVisibilityOffIcon />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="رمز عبور را تکرار کنید"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </InputGroup>
                    <InputGroup className="mt-3 register-dir">
                      <Form.Control
                        type="number"
                        placeholder="کد ارجاع (اختیاری)"
                        onChange={(e) => setRefCode(e.target.value)}
                        value={refCode}
                      />
                    </InputGroup>
                    <div className="col">
                      <Button
                        className="submit-btn m-3"
                        disabled={isLoading2}
                        type="submit"
                      >
                        {isLoading2 ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}

                        {isLoading2 ? "بارگذاری..." : "ثبت نام"}
                      </Button>
                    </div>
                  </Form>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Modal
            show={showOTP}
            onHide={handleCloseOTP}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>کد ایمیل را وارد کنید</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    type="number"
                    className="withdraw-texts"
                    InputProps={{ sx: { height: 57 } }}
                    id="mailotp"
                    name="mailotp"
                    placeholder="کد ایمیل"
                    size="medium"
                    onChange={(event) => setMailOTP(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                    }}
                  />
                </FormControl>
              </Form>
            </Modal.Body>
            <ModalFooter>
              <Button
                className="confirm-withdraw"
                onClick={verifyEmailRequest}
                disabled={isLoading3}
              >
                {isLoading3 ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading3 ? "بارگذاری..." : "تأیید"}
              </Button>
              <Button className="cancel-withdraw" onClick={handleCloseOTP}>
                لغو
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </>
    ) : (
      navigate("/")
    );
  }

  return loginStatus === false ? (
    <div className="register-main">
      <div className="container">
        <div className="row register-module">
          <div className="col text-center">
            <h5 className="mt-4 register-title">ثبت نام</h5>
            <Form onSubmit={sendOTP}>
              <InputGroup className="mt-5 register-dir">
                <Form.Control
                  type="text"
                  placeholder="نام کاربری"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </InputGroup>
              <InputGroup className="mt-3 register-dir">
                <Form.Control
                  type="text"
                  placeholder="ایمیل"
                  name="email"
                  onChange={handleEmailChange}
                />
              </InputGroup>
              {!isValidEmail && <p className="text-error">{email === "" ? "" : "فرمت ایمیل نامعتبر است."}</p>}
              {checkMail === false ? (
                <div className="col">
                  <Button
                    className="submit-btn m-3"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "تأیید ایمیل"}
                  </Button>
                </div>
              ) : (
                ""
              )}
            </Form>
            {checkMail === true ? (
              <Form onSubmit={signUp}>
                <InputGroup className="mt-2 register-dir">
                  <IconButton
                    aria-label="show-eye"
                    onClick={togglePasswordVisibility}
                  >
                    {showIcon ? (
                      <GridVisibilityOffIcon />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="کلمه عبور"
                    onChange={onChange}
                  />
                </InputGroup>
                {isError !== null
                  ? <p className="errors-register">{isError}</p>
                  :
                  <p className="errors-register">{password !== '' ? "رمز عبور قوی است." : "یک رمز عبور قوی برای امنیت خود وارد کنید."}</p>
                }

                <Form.Control.Feedback type="invalid">
                  لطفا رمز عبور خود را وارد کنید.
                </Form.Control.Feedback>
                <div className="container p-0">
                  <div className="row justify-content-start p-0">
                    <div className="col-12">
                      <PassChecker password={pwdInput.password} actions={initPwdInput} />
                    </div>
                  </div>
                </div>
                {/* {isStrong === "strong" && <button type="submit"> Register </button>} */}
                <InputGroup className="mt-3 register-dir">
                  <IconButton
                    aria-label="show-eye"
                    onClick={togglePasswordVisibility}
                  >
                    {showIcon ? (
                      <GridVisibilityOffIcon />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="رمز عبور را تکرار کنید"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputGroup>
                <InputGroup className="mt-3 register-dir">
                  <Form.Control
                    type="number"
                    placeholder="کد ارجاع (اختیاری)"
                    onChange={(e) => setRefCode(e.target.value)}
                    value={refCode}
                  />
                </InputGroup>
                <div className="col">
                  <Button
                    className="submit-btn m-3"
                    disabled={isLoading2}
                    type="submit"
                  >
                    {isLoading2 ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}

                    {isLoading2 ? "بارگذاری..." : "ثبت نام"}
                  </Button>
                </div>
              </Form>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Modal
        show={showOTP}
        onHide={handleCloseOTP}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>کد ایمیل را وارد کنید</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-list">
          <Form>
            <FormControl fullWidth>
              <TextField
                type="number"
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="mailotp"
                name="mailotp"
                placeholder="کد ایمیل"
                size="medium"
                onChange={(event) => setMailOTP(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                }}
              />
            </FormControl>
          </Form>
        </Modal.Body>
        <ModalFooter>
          <Button
            className="confirm-withdraw"
            onClick={verifyEmailRequest}
            disabled={isLoading3}
          >
            {isLoading3 ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading3 ? "بارگذاری..." : "تأیید"}
          </Button>
          <Button className="cancel-withdraw" onClick={handleCloseOTP}>
            لغو
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    navigate("/")
  );

}

export default Register;
