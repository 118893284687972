import React from 'react'
import ResponsiveAppBar from '../../home/NavbarMobile'
import MarketMobileTable from './MarketMobileTable'
import './marketmobiletable.scss'
import NavbarTopMobile from '../../../components/navbar/NavbarTopMobile'

function MarketMobile() {
    return (
        <div>
            <div className="market-mobile-module p-0 m-0">
                <NavbarTopMobile />
                <div className="container-fluid market-mobile-main p-0">
                    <div className="row justify-content-center p-0 m-0">
                        <div className="col-12 p-0 m-0">
                            <MarketMobileTable />
                        </div>
                    </div>
                </div>
                <div className="container-fluid navbar-mobile-bot">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <ResponsiveAppBar className="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MarketMobile
