import { Controls, Player } from '@lottiefiles/react-lottie-player'
import React from 'react'
import MaintenanceAnim from "../assets/json/maintenance.json";
import Logo from '../assets/images/t-logo.svg'
import { useEffect } from 'react';
import { createApiAddress } from '../constant/api.constant';

function Maintenance() {

    useEffect(() => {
        const getCurrencyImages = async () => {
            try {
                const response = await fetch(createApiAddress("api.currency_images"));

                if (response.status === 200) {
                    window.location.replace(window.location.origin);
                    return;
                }
            } catch (error) {
                console.error("Error fetching currency images:", error);
            }
        };

        getCurrencyImages();
        const intervalId = setInterval(() => {
            getCurrencyImages();
        }, 60000);
        return () => clearInterval(intervalId);

    }, []);

    return (
        <div>
            <div className="container-fluid" style={{ backgroundColor: '#f4f4f4', minHeight: "100vh" }}>
                <div className="row justify-content-center">
                    <div className="col-7  mt-5 text-center   p-0 m-0">
                        <Player
                            autoplay
                            loop
                            src={MaintenanceAnim}
                            style={{ maxHeight: "500px", maxWidth: "500px" }}
                        >
                            <Controls
                                visible={false}
                                buttons={["play", "repeat", "frame", "debug"]}
                            />
                        </Player>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-11  text-center" style={{ marginTop: "50px", }}>
                        <p>ما در حال بروز رسانی سایت میباشیم، لطفا شکیبا باشید</p>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-8 col-md-1">
                        <img src={Logo} alt="" maxHeight={150} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Maintenance
