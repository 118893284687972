import React from "react";
import { useParams } from "react-router-dom";

function OrderHistoryTitle() {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  return (
    <div className="row">
      <div className="col d-flex justify-content-between orderhistory-title">
        <div className="top-title-orderhistory-third">زمان</div>
        <div className="top-title-orderhistory-second">مقدار ({from})</div>
        <div className="top-title-orderhistory-first">قیمت ({to})</div>
      </div>
    </div>
  );
}

export default OrderHistoryTitle;
