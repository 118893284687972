const ADDRESSES = {
    servers: {
        TRANSACTION: {
            ADDRESS: "https://tapi.terixo.com",
            PATHS: {
                CURRENCY_DEPOSIT_AVAILABLE: "/api/v1/currency/available/deposit",
                CURRENCY_WITHDRAW_AVAILABLE: "/api/v1/currency/available/withdraw",
                GET_DEPOSIT_ADDRESS: "/api/v1/user/deposit/wallet/",
                DEPOSIT_HISTORY: "/api/v1/user/deposit/list",
            }
        },
        API: {
            ADDRESS: "https://api.terixo.com",
            PATHS: {
                SEND_EMAIL_OTP: "/api/v3/send/otp",
                VERIFY_EMAIL: "/api/v3/verify/email",
                SIGNUP: "/api/v3/signup",
                LOGIN: "/api/v3/web/login",
                RESEND_OTP: "/api/v3/resend/otp",
                TFA_LOGIN: "/api/v3/tfa/login",
                USER_INFO: "/api/v3/user/info",
                KYC_LEVEL2: "/api/v3/user/kyc/level2",
                KYC_LEVEL3: "/api/v3/user/kyc/level3",
                KYC_VIP: '/api/v3//user/kyc/vip',
                KYC_RND_TEXT: "/api/v3/user/kyc/phrase",
                TRADE_PASSWORD_CHECK: "/api/v3/user/trading/check",
                CURRENCY_IMAGES: "/api/v3/public/currency/images",
                CURRENCY_GROUP: "/api/v3/public/currency/group",
                REFERRALS: '/api/v3/user/referral/count',
                WITHDRAW_HISTORY: "/api/v3/user/withdraw/history",
                DEPOSIT_CURRENCIES: '/trans/get_currencies', // trans
                WITHDRAW_INFO: "/trans/send", // trans
                OTP_WITHDRAW: "/api/v3/user/withdraw/otp", // trans
                FINALIZE_WITHDRAW: "/api/v3/user/withdraw/request", // trans
                WITHDRAW_UPDATE: "/api/v3/user/withdraw/confirm", // trans
                GET_DEPOSIT_ADDRESS: "/trans/createAddress", // trans
                TRANSFER_REQUEST: "/api/v3/user/wallet/transfer",
                TRADEPASS_CHANGE: "/api/v3/user/trading/password/change",
                GOOGLE_ACTION: "/api/v3/user/tfa/action",
                TRADEPASS_FORGOT: "/api/v3/user/trading/password/forgot",
                PASS_CHANGE: "/api/v3/user/password/change",
                CONFIRM_TRADEPASS: "/api/v3/user/trading/check",
                CANCEL_ORDER: "/trade/cancel/",
                CHART_DATA: "/trade/chart", // trade
                RESET_PASSWORD: "/api/v3/change/password/apply",
                USER_POINTS: "/api/v3/user/points",
                RESET_PASS_IN_LOGIN: "/api/v3/forgot/password",
                RESET_2FA_IN_LOGIN: "/api/v3/forgot/tfa",
                CURRENCIES_INFO: "/api/v3/public/currency",
                CAREER: "/api/v3/public/career",
                USER_ACTIVITY: '/api/v3/user/activity',
                CREATE_TICKET: '/api/v3/tickets',
                GET_TICKET: '/api/v3/tickets/',
                GET_ANNOUNCEMENT: '/api/v3/public/announcements',
                SEND_SMS_PHONE_VERIFY: '/api/v3/user/kyc/phone_verify_otp',
                SMS_OTP_VERIFY: '/api/v3/user/kyc/phone_verify',
                SMS_STATUS_FOR_WITHDRAW: '/api/v3/user/settings',
                CHECK_SOCIAL: '/api/v3/user/social_media',
                WITHDRAW_LIMIT: '/api/v3/user/withdraw/limit',
                BANK_CARD_LIST: '/api/v3/user/bank_accounts',
                BANK_CARD_LIST_VERIFIED: '/api/v3/user/bank_accounts?verified=true',
                DELETE_CARD: '/api/v3/user/bank_accounts/',
                IRT_INFO: '/api/v3/public/currency/info/IRT', // if irt change with other symbol can receive data
                DEPOSIT_MONEY_INVOICE: '/api/v3/user/invoice',
                VERIFY_MONEY_INVOICE: '/api/v3/user/invoice/verify',
                LIMIT_TRANSACTION_DATA_KYC: '/api/v3/public/transaction_limitations',
                PRIZES_DATA: '/api/v3/prizes',
                ATTEND_PRIZE: '/api/v3/prizes/buy/',
                REFER_COUNT: '/api/v3/user/referral/count',
                RESEND_WITHDRAW_EMAIL: '/api/v3/user/withdraw/resend',
                BALANCE_HISTORY: '/api/v3/user/balance_history',
                VIP_WALLET: '/api/v3/user/vip/balance',
                VIP_HISTORY: '/api/v3/user/vip/history'
            }
        },
        TRADE: {
            ADDRESS: "https://io.terixo.com",
            WEBSOCKET: "wss://io.terixo.com",
            PATHS: {
                INSTANT_MARKET: "/api/instant/market",
                PRICES: "/api/prices",
                CANCEL_ORDER: "/api/cancel/",
                PAIR_DETAILS: "/api/pair/",
                USER_WALLET: "/api/user/wallets",
                SUMMARY_WITH_CHANGE: '/api/summary?with_change=true',
                INSTANT_PRICE: '/api/instant/prices',
                INSTANT_APPLY: '/api/instant/apply',
                INSTANT_HISTORY: '/api/instant/history'
            }
        }
    }
}

export const createApiAddress = (name, addons = '') => {
    name = name.toUpperCase();
    const [server, path] = name.split(".");
    return `${ADDRESSES.servers[server].ADDRESS}${ADDRESSES.servers[server].PATHS[path]}${addons}`;
}

export const createWebsocketAddress = (name) => {
    name = name.toUpperCase();
    return `${ADDRESSES.servers[name].WEBSOCKET}`;
}