import React, { useContext, useEffect, useState } from 'react'
import VipWithEach from './VipWithEach'
import VipImage from '../../assets/images/vip.png'
import { UserContext } from '../../contexts/providers/user'
import { createApiAddress } from '../../constant/api.constant'
import { ItemNames, getStorage } from '../../utils/RSS'
import { useNavigate } from 'react-router-dom'
import moment from 'jalali-moment'
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile'
import ResponsiveAppBar from '../home/NavbarMobile'

function VipHistory() {
    const { state, dispatch } = useContext(UserContext);
    const [vipHistory, setVipHistory] = useState()
    const navigate = useNavigate();

    useEffect(() => {
        vipUserHistory()
    }, [])

    const vipUserHistory = async () => {
        try {
            const response = await fetch(createApiAddress("api.VIP_HISTORY"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            })
            const history = await response.json();
            if (response.status === 503) {
                navigate('/maintenance')
                return;
            } else if (response.status === 401) {
                dispatch({ type: "Logout" });
                return;
            } else if (response.status === 403) {
                navigate('/')
                return;
            } else if (response.status === 200) {
                setVipHistory(history?.data?.histories)
            }

        } catch (e) {

        }
    }

    const getTokenImage = async (token) => {
        try {
            const response = await fetch(`https://api.terixo.com/api/v3/public/currency/image/${token}`, {
                method: "GET",
            });

            if (response.status === 200) {
                return response.url;
            } else {

                return null;
            }
        } catch (error) {
            console.error("Error fetching image:", error);
            return null;
        }
    };

    const getImageForCurrency = async (currencyToken) => {
        try {
            const imageData = await getTokenImage(currencyToken);
            return imageData;
        } catch (error) {
            console.error("Error getting image for currency:", error);
            return null;
        }
    };
    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);
    if (state.user_profile?.info?.kyc?.vip_status !== 'approved' && isMobileDevice) {
        return <>
            <div className="container-fluid vip-his-main-mobile">
                <NavbarTopMobile />
                <div className="row justify-content-center">
                    <div className="col-12 mt-5 text-center">
                        <div className="col-12  text-center">
                            <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                        </div>
                        <p className='p-in-history'>شما به این صفحه دسترسی ندارید.</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar className="" />
                    </div>
                </div>
            </div>
        </>
    }

    if (state.user_profile?.info?.kyc?.vip_status === 'approved' && isMobileDevice) {
        return <>
            <div>
                <div className="container-fluid vip-his-main-mobile">
                    <NavbarTopMobile />
                    <div className="row vip-his-module mb-5">
                        <p className='p-in-history m-5'>تاریخچه واریز و برداشت VIP</p>
                        <div className="col-12">
                            {vipHistory?.map((items) =>
                                <VipWithEach amount={items.amount} symbol={items.currency} status={items.amount > 0 ? 'واریز ' : 'برداشت '} time={`${moment
                                    .from(items.updated_at, "en")
                                    .locale("fa")
                                    .format("jYYYY/jMM/jDD")}${" -- "}
                         ${moment(items.updated_at).format("HH:mm:ss")}`} txid={items.id} image={getImageForCurrency(items.currency)} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar className="" />
                    </div>
                </div>
            </div>
        </>
    }

    if (state.user_profile?.info?.kyc?.vip_status !== 'approved') {
        return <>
            <div className="container-fluid vip-his-main">
                <div className="row justify-content-center">
                    <div className="col-12 mt-5 text-center">
                        <div className="col-12  text-center">
                            <img src={VipImage} alt="" style={{ maxHeight: "300px" }} className='img-fluid' />
                        </div>
                        <p className='p-in-history'>شما به این صفحه دسترسی ندارید.</p>
                    </div>
                </div>
            </div>
        </>
    }
    return (
        <div>
            <div className="container-fluid vip-his-main">
                <div className="row vip-his-module">
                    <p className='p-in-history m-5'>تاریخچه واریز و برداشت VIP</p>
                    <div className="col-12">
                        {vipHistory?.map((items) =>
                            <VipWithEach amount={items.amount} symbol={items.currency} status={items.amount > 0 ? 'واریز ' : 'برداشت '} time={`${moment
                                .from(items.updated_at, "en")
                                .locale("fa")
                                .format("jYYYY/jMM/jDD")}${" -- "}
                         ${moment(items.updated_at).format("HH:mm:ss")}`} txid={items.id} image={getImageForCurrency(items.currency)} />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VipHistory
