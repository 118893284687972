import { useState, useContext } from "react";
import "./withdraw.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Button, Form, Modal, ModalFooter } from "react-bootstrap";
import { Alert, Grid, Snackbar, Stack } from "@mui/material";
import { getWithdrawInfo } from "../../../utils/Api";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { useNavigate } from "react-router-dom";
import { createApiAddress } from "../../../constant/api.constant";

function Withdraw(props) {
  const [errorMsg, setErrorMsg] = useState(null);
  const errorCatch = (error) => {
    setErrorMsg(error);
  };

  const [currencies, setCurrencies] = useState({});
  const { state } = useContext(UserContext);
  const [selectedToken, setSelectedToken] = useState({});
  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [address, setAddress] = useState(null);
  const [amount, setAmount] = useState(null);
  const [min, setMin] = useState(null);
  const [fee, setFee] = useState(null);
  const [mailOTP, setMailOTP] = useState(null);
  const [smsOTP, setSmsOTP] = useState(null);
  const [gOTP, setGOTP] = useState(null);

  useEffect(() => {
    const setWithdrawListFunc = function (data) {
      let tmp = {};
      data.map((currency) => {
        if (tmp[currency.symbol] === undefined) {
          tmp[currency.symbol] = {
            name: currency.name,
            symbol: currency.symbol,
            image: currency.image,
            networks: [],
          };
        }
        tmp[currency.symbol].networks.push(currency);
        return currency;
      });
      setCurrencies(tmp);
    };
    getWithdrawInfo(setWithdrawListFunc, errorCatch);
  }, []);

  const getKycStatus = () => {
    if (state?.user_profile?.info?.kyc?.status === 'approved_lvl2') {
      return '2';
    } else if (state?.user_profile?.info?.kyc?.status === 'approved' && state?.user_profile?.info?.kyc?.vip_status !== 'approved') {
      return '3'
    } else if (state?.user_profile?.info?.kyc?.vip_status === 'approved') {
      return 'VIP'
    } else {
      return ' '
    }
  }

  var limitWithdrawDaily = props.timeBaseLimit?.limit?.withdraw?.daily_crypto_amount
  var usedWithdrawDaily = props.timeBaseLimit?.used?.withdraw?.daily_crypto_amount
  var limitWithdrawMonthly = props.timeBaseLimit?.limit?.withdraw?.monthly_crypto_amount
  var usedWithdrawMonthly = props.timeBaseLimit?.used?.withdraw?.monthly_crypto_amount

  var dailyRemain = () => {
    if (usedWithdrawDaily < limitWithdrawDaily) {
      return limitWithdrawDaily - usedWithdrawDaily;
    } else {
      return 0
    }
  }

  var monthlyRemain = () => {
    if (usedWithdrawMonthly < limitWithdrawMonthly) {
      return limitWithdrawMonthly - usedWithdrawMonthly;
    } else {
      return 0
    }
  }

  const handleTokenChange = (event) => {
    setSelectedToken(event.target.value);
    setSelectedNetwork(event.target.value.networks[0]);
    setMin(event.target.value.networks[0].withdraw.min);
    // setMax(event.target.value.networks[0].withdraw.max);
    setFee(event.target.value.networks[0].withdraw.fee.amount);
    setAddress("");
    setAmount("");
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCodesInput, setCodesInput] = useState(false);
  const handleCloseCodesInput = () => setCodesInput(false);
  const handleShowCodesInput = () => setCodesInput(true);

  const [showFinal, setShowFinal] = useState(false);
  const handleCloseFinal = () => setShowFinal(false);
  const handleShowFinal = () => setShowFinal(true);
  const [emailEnable, setEmailEnable] = useState(false)
  const [smsEnable, setSmsEnable] = useState(false)

  const [openSnackBar, setOpenSnackBar] = useState({
    show: false,
    severity: "warning",
    message: "",
  });

  const checkAllOptions = () => {
    if (
      selectedToken === "" ||
      selectedNetwork === "" ||
      address === null ||
      amount === null
    ) {
      return true;
    }
  };

  const handleCloseSnackBar = () => {
    setOpenSnackBar({
      ...openSnackBar,
      show: false,
    });
  };

  const avblBalance = () => {
    if (props.walletData[selectedToken?.symbol]?.main === undefined) {
      return 0;
    } else {
      return props.walletData[selectedToken?.symbol]?.main;
    }
  };

  const errorsSnack = () => {
    // check forms
    if (amount > avblBalance()) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `مقدار موجود کافی نیست ${avblBalance()}`,
      });
    } else if (amount < min) {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: `مبلغ برداشت نمی تواند کمتر از ${min} باشد`,
      });
    } else if (address === null || address === "") {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: "لطفا آدرس را وارد کنید.",
      });
    } else if (selectedNetwork === null || selectedNetwork === "") {
      setOpenSnackBar({
        show: true,
        severity: "warning",
        message: "لطفا شبکه را انتخاب کنید.",
      });
    } else {
      handleShow();
    }
  };

  const sendRequestOTP = async (event) => {
    event.preventDefault();

    try {
      let data = {
        type: "withdraw",
        currency: selectedNetwork.symbol,
        fee: selectedNetwork.withdraw.fee.amount,
        amount: amount,
        address: address,
      };
      let res = await fetch(createApiAddress("api.OTP_WITHDRAW"), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let resJson = await res.json();
      // console.log(resJson)
      if (res.status === 200) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.locale.fa,
        });
        setEmailEnable(resJson?.data?.options?.email)
        setSmsEnable(resJson?.data?.options?.sms)
        handleClose();
        handleShowCodesInput();
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.locale.fa,
        });
      }
    } catch (err) { }
  };

  const finalizeWithdraw = async (event) => {
    event.preventDefault();
    try {
      // const data = new FormData(event.currentTarget);
      let res = await fetch(createApiAddress("api.FINALIZE_WITHDRAW"), {
        method: "POST",
        body: JSON.stringify({
          tfa_code: gOTP,
          email_verify_code: mailOTP,
          sms_code: smsOTP,
          currency_id: selectedNetwork.id,
          amount: amount,
          address: address,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });
      let resJson = await res.json();
      if (resJson.status === 1) {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "success",
          message: resJson.locale.fa,
        });
        handleCloseCodesInput();
        handleShowFinal();
        getWithdrawInfo(setCurrencies, errorCatch);
        setAddress("");
        setAmount("");
        setSelectedNetwork("");
        setSelectedToken({});
      } else {
        setOpenSnackBar({
          ...openSnackBar,
          show: true,
          severity: "warning",
          message: resJson.locale.fa,
        });
      }
    } catch (err) { }
  };

  function fixNetworkNames(item) {
    if (item.toString().toUpperCase() === "BEP20") {
      return "BEP20";
    } else if (item.toString().toUpperCase() === "TRX") {
      return "Tron TRC20";
    } else if (item.toString().toUpperCase() === "BTC") {
      return "Bitcoin Network";
    } else if (item.toString().toUpperCase() === "ETH") {
      return "Ethereum ERC20";
    } else if (item.toString().toUpperCase() === "DOGE") {
      return "Dogecoin";
    } else if (item.toString().toUpperCase() === "LTC") {
      return "Litecoin Network";
    } else {
      return item.toString().toUpperCase();
    }
  }
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate("/profile#1", { replace: true });
  };

  const goToKyc = () => {
    navigate("/kyc", { replace: true });
  };

  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {
      const numberStr = number.toString();
      const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedNumber;
    } else {
      return number
    }
  }

  const requiredKYCList = ["approved_lvl2", "rejected_lvl3", "approved"]

  if (
    requiredKYCList.includes(state?.user_profile?.info?.kyc?.status) === false
  ) {
    return (
      <div className="when-error">
        <div className="error-module">
          <div className="attention-withdraw">
            <p className="p-2">
              برای برداشت باید <span> فرآیند احراز هویت </span> خود را انجام
              دهید.
            </p>
          </div>
          <div className="error-btn">
            <Button className="btn-withdraw-error" onClick={goToKyc}>
              برای رفتن به صفحه احراز هویت کلیک کنید
            </Button>
          </div>
        </div>
      </div>
    );
  } else if (state?.user_profile?.security?.tfa?.status !== true) {
    return (
      <div className="when-error">
        <div className="error-module">
          <div className="attention-withdraw">
            <p className="p-2">
              برای برداشت باید <span> احراز هویت دو عاملی</span> خود را انجام
              دهید.
            </p>
          </div>
          <div className="error-btn">
            <Button className="btn-withdraw-error" onClick={goToProfile}>
              برای رفتن به صفحه پروفایل کلیک کنید
            </Button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="withdraw-main">
        <div className="container withdraw">
          <div className="row justify-content-center">
            <div className="col-10 col-md-6 withdraw-module m-4">
              <div className="row m-3">
                <div className="col">
                  <h5>برداشت رمز ارز</h5>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <h6>لطفاً توکن را انتخاب کنید:</h6>
                    <FormControl fullWidth>
                      <Select
                        value={selectedToken}
                        onChange={handleTokenChange}
                      >
                        {Object.keys(currencies).map((currency) => (
                          <MenuItem
                            key={currencies[currency].symbol}
                            value={currencies[currency]}
                          >
                            <div className="d-flex mt-2">
                              <img
                                src={currencies[currency].image}
                                style={{
                                  width: 30,
                                  height: 30,
                                  marginRight: 10,
                                  marginLeft: 10,
                                  marginTop: 1,
                                }}
                                alt={"currency"}
                              />
                              <div className="d-flex flex-column text-left">
                                <span>{currencies[currency].symbol}</span>
                                <span
                                  style={{
                                    fontSize: "10px",
                                    marginTop: "-7px",
                                  }}
                                >
                                  ({currencies[currency].name})
                                </span>
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <div className="mt-2">
                      <h6>لطفاً شبکه را انتخاب کنید:</h6>
                      <FormControl fullWidth>
                        <Select
                          value={selectedNetwork}
                          onChange={(event) => {
                            setMin(event.target.value.withdraw.min);
                            // setMax(event.target.value.withdraw.max);
                            setFee(event.target.value.withdraw.fee.amount);
                            setSelectedNetwork(event.target.value);
                          }}
                        >
                          {selectedToken?.networks?.map((currency) => (
                            <MenuItem key={currency} value={currency}>
                              {fixNetworkNames(currency.info.network)}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                  {/* <div className="col d-flex">
                  <h6>موجود: </h6>{" "}
                  <h6 className="ms-2"> {selectedToken?.balance}</h6>
                </div> */}
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="address"
                        placeholder="آدرس کیف گیرنده"
                        size="medium"
                        name="usernetwork"
                        value={address}
                        onChange={(event) => setAddress(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                    <FormControl fullWidth>
                      <TextField
                        type="number"
                        className="withdraw-texts"
                        InputProps={{ sx: { height: 57 } }}
                        id="amount"
                        placeholder="مقدار"
                        size="medium"
                        value={amount}
                        onChange={(event) => setAmount(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          className: "withdraw-texts",
                        }}
                      />
                    </FormControl>
                  </Box>
                  <div className="withdraw-bottom">
                    <div className="col d-flex justify-content-between">
                      <h6>موجودی:</h6>
                      <h6 className="ms-2">
                        {" "}
                        {props.walletData[selectedToken?.symbol]?.main || 0}
                      </h6>
                    </div>
                    <div className="col d-flex justify-content-between">
                      <h6>حداقل مبلغ برداشت: </h6>{" "}
                      <h6 className="ms-2"> {min}</h6>
                    </div>
                    {/* <div className="col d-flex justify-content-between">
                      <h6>حداکثر مبلغ برداشت: </h6>{" "}
                      <h6 className="ms-2"> {max}</h6>
                    </div> */}
                    <div className="col d-flex justify-content-between">
                      <h6>کارمزد برداشت: </h6> <h6 className="ms-2"> {fee}</h6>
                    </div>
                    <div className="col">
                      <h6>محدودیت برداشت برای سطح {getKycStatus()} احراز هویت:</h6>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        مجموع برداشت روزانه:
                      </div>
                      <div className="col-6 text-start">
                        <h6>{formatNumber(usedWithdrawDaily)} از {formatNumber(limitWithdrawDaily)} تومان</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6"><h6>مجموع برداشت ماهانه:</h6></div>
                      <div className="col-6 text-start">
                        <h6>{formatNumber(usedWithdrawMonthly)} از {formatNumber(limitWithdrawMonthly)} تومان</h6>
                      </div>
                    </div>
                    {selectedToken.symbol ? (
                      <>
                        <p style={{ textAlign: "justify" }} className="mt-4">
                          <span className="textdanger">مهم: </span>
                          <span style={{ fontWeight: 500, fontSize: 12 }}>
                            لطفا به آدرس گیرنده{" "}
                            <span className="textsuccess">
                              {selectedToken.symbol}
                            </span>{" "}
                            که وارد میکنید توجه نمایید، اگر اشتباهی در آدرس
                            گیرنده یا انتخاب شبکه{" "}
                            <span className="textsuccess">
                              {fixNetworkNames(selectedNetwork.info.network)}
                            </span>{" "}
                            داشته باشید، ممکن است رمز ارز ارسالی شما در شبکه
                            بلاک چین از بین برود و ما نمی توانیم برای بازیابی
                            دارایی تان به شما کمک کنیم.
                          </span>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    className="gateway-btn"
                  >
                    <Button onClick={errorsSnack} disabled={checkAllOptions()} className="goto-gateway">
                      تایید برداشت
                    </Button>
                  </Grid>
                  <Modal
                    show={show}
                    onHide={handleClose}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header>
                      <Modal.Title>برداشت</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <p
                        className="attention-withdraw"
                        style={{ textAlign: "justify" }}
                      >
                        لطفاً توکن و شبکه انتخابی خود را بررسی کنید، اگر به آدرس
                        شبکه نادرست ارسال کنید، دارایی های خود را از دست خواهید
                        داد و تریکسو هیچ مسئولیتی در قبال دارایی های از دست رفته
                        ندارد.
                      </p>
                      <p className="attention-withdraw">
                        توکن انتخابی:{" "}
                        <span className="ms-2">{selectedToken.symbol}</span>
                      </p>
                      <p className="attention-withdraw">
                        شبکه انتخابی:{" "}
                        <span className="ms-2">
                          {selectedNetwork !== ""
                            ? fixNetworkNames(selectedNetwork?.info?.network)
                            : ""}
                        </span>
                      </p>
                      <p className="attention-withdraw">
                        آدرس شبکه: <span className="ms-2">{address}</span>
                      </p>
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={sendRequestOTP}
                      >
                        تایید
                      </Button>
                      <Button className="cancel-withdraw" onClick={handleClose}>
                        لغو
                      </Button>
                    </ModalFooter>
                  </Modal>
                  {/* just 2fa */}
                  <Modal
                    show={showCodesInput}
                    onHide={handleCloseCodesInput}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>
                        کدهای لازم برای تایید برداشت را وارد کنید.
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <Form>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            className="withdraw-texts mt-3"
                            InputProps={{ sx: { height: 57 } }}
                            id="gotp"
                            name="gotp"
                            placeholder="کد احراز هویت گوگل"
                            size="medium"
                            onChange={(event) => setGOTP(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              className: "withdraw-texts",
                            }}
                          />
                        </FormControl>
                      </Form>
                      {emailEnable ? <Form>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            className="withdraw-texts mt-3"
                            InputProps={{ sx: { height: 57 } }}
                            id="emailotp"
                            name="emailotp"
                            placeholder="کد ارسالی به ایمیل"
                            size="medium"
                            onChange={(event) => setMailOTP(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              className: "withdraw-texts",
                            }}
                          />
                        </FormControl>
                      </Form> : <></>}
                      {smsEnable ? <Form>
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            className="withdraw-texts mt-3"
                            InputProps={{ sx: { height: 57 } }}
                            id="smsotp"
                            name="smsotp"
                            placeholder="کد ارسالی به تلفن همراه"
                            size="medium"
                            onChange={(event) => setSmsOTP(event.target.value)}
                            InputLabelProps={{
                              shrink: true,
                              className: "withdraw-texts",
                            }}
                          />
                        </FormControl>
                      </Form> : <></>}
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={finalizeWithdraw}
                      >
                        تایید
                      </Button>
                      <Button
                        className="cancel-withdraw"
                        onClick={handleCloseCodesInput}
                      >
                        لغو
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Modal
                    show={showFinal}
                    onHide={handleCloseFinal}
                    className="modal-withdraw"
                    backdrop="static"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>برداشت تایید شد</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="modal-list">
                      <p className="attention-withdraw">
                        لطفاً ایمیل خود را بررسی کنید و ایمیلی را که برای تکمیل
                        فرآیند برداشت برای شما ارسال شده است تأیید کنید.
                      </p>
                    </Modal.Body>
                    <ModalFooter>
                      <Button
                        className="confirm-withdraw"
                        onClick={handleCloseFinal}
                      >
                        بستن
                      </Button>
                    </ModalFooter>
                  </Modal>

                  <Snackbar
                    open={openSnackBar.show}
                    autoHideDuration={6000}
                    onClose={handleCloseSnackBar}
                  >
                    <Alert severity={openSnackBar.severity}>
                      {openSnackBar.message}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Withdraw;
