import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import './navbarmobile.scss'
import { useNavigate } from 'react-router-dom';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CandlestickChartOutlinedIcon from '@mui/icons-material/CandlestickChartOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import BarChartTwoToneIcon from '@mui/icons-material/BarChartTwoTone';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

const pages = ['Products', 'Pricing', 'Blog'];

function ResponsiveAppBar() {

    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");
        window.scrollTo(0, 0);
    };

    const goToAssets = () => {
        navigate("/assets");
        window.scrollTo(0, 0);
    };

    const goToTether = () => {
        navigate("/instantbuysell");
        window.scrollTo(0, 0);
    };

    const goToTrade = () => {
        navigate("/trade/BTC_USDT");
        window.scrollTo(0, 0);
    };

    const goToMarket = () => {
        navigate("/market");
        window.scrollTo(0, 0);
    };

    return (
        <div className="appbar-home p-0 m-0">
            <AppBar position="static">
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <div className="container  p-0 m-0">
                            <div className="row justify-content-center p-0 m-0">
                                <div className="p-0 m-0 col-2 text-center">
                                    <Button className='nav-mobile-bot-textbtn p-0 m-0' onClick={goToHome}>
                                        <div className="row">
                                            <div className="col mt-2">
                                                <HomeRoundedIcon />
                                                <p>خانه</p>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="p-0 m-0 col-2 text-center">
                                    <Button className='nav-mobile-bot-textbtng p-0 m-0' onClick={goToTether}>
                                        <div className="row">
                                            <div className="col mt-2">
                                                <CurrencyExchangeOutlinedIcon />
                                                <p>درگاه تتر</p>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="p-0 m-0 col-2 text-center">
                                    <Button className='nav-mobile-bot-textbtn p-0 m-0' onClick={goToMarket}>
                                        <div className="row">
                                            <div className="col mt-2">
                                                <BarChartTwoToneIcon />
                                                <p>بازار   </p>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="p-0 m-0 col-2 text-center">
                                    <Button className='nav-mobile-bot-textbtn p-0 m-0' onClick={goToTrade}>
                                        <div className="row">
                                            <div className="col mt-2">
                                                <CandlestickChartOutlinedIcon />
                                                <p>معاملات</p>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                                <div className="p-0 m-0 col-2 text-center">
                                    <Button className='nav-mobile-bot-textbtn p-0 m-0' onClick={goToAssets}>
                                        <div className="row">
                                            <div className="col mt-2">
                                                <AccountBalanceWalletOutlinedIcon />
                                                <p>کیف پول</p>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}
export default ResponsiveAppBar;