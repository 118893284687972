import React from "react";
import MiniChart from "../products/instantBuySell/MiniChart";
import "./mobileinstant.scss";
import { useParams } from "react-router-dom";

function MobileTradeChart() {
  const params = useParams();

  return (
    <div className="chart-main">
      <div className="chart">
        <MiniChart
          key={params.token}
          symbol={params.token}
          colorOne={"#00b8f9"}
          colorTwo={"#ffffff00"}
          colorThree={"#007df9"}
        />
      </div>
    </div>
  );
}

export default MobileTradeChart;
