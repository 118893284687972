import React from "react";
import Arrow from "../../assets/images/arrow-icon.svg";

function Home3StepsTopSecond() {
  return (
    <div className="container-fluid top3Stepssecond">
      <div className="boxes-top">
        <div className="top-3-box">
          <div className="top-3-main">
            <div className="top3-text">
              <h6>ثبت نام</h6>
              <p>
                با آدرس ایمیل خود ثبت نام نمایید و دارای کیف پول دیجیتال باشید.
              </p>
            </div>
          </div>
        </div>
        <div className="arrow-container">
          <img src={Arrow} alt="arrow" width={40} className="arrow-rotate" />
        </div>
      </div>
      <div className="boxes-top">
        <div className="top-3-box">
          <div className="top-3-main">
            <div className="top3-text">
              <h6>واریز</h6>
              <p>
                ظرف ۵ دقیقه احراز هویت نمایید سپس می توانید تومان یا رمز ارز را
                به کیف خود در تریکسو واریز کنید.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="arrow-container">
          <img src={Arrow} alt="arrow" width={40} className="arrow-rotate" />
        </div>
      </div>
      <div className="boxes-top">
        <div className="top-3-box">
          <div className="top-3-main">
            <div className="top3-text">
              <h6> شروع معاملات</h6>
              <p>اکنون شما می توانید به آسانی معاملات معتبرترين رمزارزهای دنیا را در تریکسو بصورت آنی انجام دهید.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home3StepsTopSecond;
