import React, { useEffect } from "react";

function MiniChart({ symbol, colorOne, colorTwo, colorThree }) {
  const width = "110%";
  const height = "100%";
  const locale = "en";
  const dateRange = "3M";
  const colorTheme = "dark";
  const trendLineColor = colorThree;
  const underLineColor = colorOne;
  const underLineBottomColor = colorTwo;
  const isTransparent = true;
  const chartOnly = true;
  const noTimeScale = true;
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol,
      width,
      height,
      locale,
      dateRange,
      colorTheme,
      trendLineColor,
      underLineColor,
      underLineBottomColor,
      isTransparent,
      chartOnly,
      noTimeScale,
    });

    document.getElementById("mini-chart-container").appendChild(script);

    return () => {
      try {
        if (
          script.parentNode === document.getElementById("mini-chart-container")
        ) {
          document.getElementById("mini-chart-container").removeChild(script);
        }
      } catch (e) {
        // console.log("");
      }
    };
  }, []);

  return <div id="mini-chart-container" />;
}

export default MiniChart;
