import React from "react";
import "./aboutus.scss";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

function Aboutus() {

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="about-page-main-mobile">
        <NavbarTopMobile />
        <div className="about-page-module">
          <div className="about-top">
            <h5 className="first-title">ماموریت ما</h5>
            <p className="third-title">
              افزایش قدرت مالی هر شخص بر اساس یک <br /> سیستم رمزنگار سریع، مطمئن
              و شفاف
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col second-about">
                <p>
                  تریکسو یک سیستم اقتصادی رمزنگار کار آمد، شفاف و امن است که بر
                  مبنای شبکه بلاک چین و رمزارزها فعال می باشد. هر کسی در هر کجا
                  ،باید بتواند به راحتی و بصورت ایمن رمز ارزها را ارسال، دریافت،
                  نگهداری و یا معامله کند. ما متعهدانه تلاش میکنیم یک پلتفرم قابل
                  اعتماد و با دسترسی اسان به دنیای رمز ارزها را برای کاربران گرامی
                  خود فراهم و ارتقاء دهیم و سخت تلاش کرده ایم تا انتخابی با
                  یکپارچگی بالا برای شما فراهم کنیم و مشتاقانه منتظر جلب و حفظ
                  اعتماد شما هستیم. بنابراین شما چه یک معامله گر حرفه ای یا مبتدی
                  باشید تریکسو به شما این امکان را می دهد که مسیر مالی خود را در
                  بازار رمز ارزها ترسیم کنید.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col second-about">
                <h6>تعهد ما</h6>
                <p>
                  پایبندی به الزامات نظارتی تضمین می‌کند که ما پلتفرم و دارایی های
                  مشتریان خود را ایمن نگه داریم. برای این منظور، ما یک برنامه
                  انطباق ایجاد کرده‌ایم که ریشه در بهترین شیوه‌های خدمات مالی سنتی
                  و همچنین فن‌آوری انطباق نوآورانه و پیچیده دارد تا صنعت کریپتو را
                  به پیش ببرد. ما برای اینکه چه دارایی هایی را فهرست می کنیم، چه
                  خدماتی ارائه می دهیم و چه کسانی به محصولات ما دسترسی داشته
                  باشند، استاندارد بالایی داریم.
                </p>
              </div>
            </div>
          </div>{" "}
          <div className="container">
            <div className="row">
              <div className="col second-about">
                {" "}
                <h6>رمز ارزها</h6>
                <p>
                  {" "}
                  تراکنش رمز ارز بر روی بلاک چین ثبت می شود و پس از تایید غیرقابل
                  تغییر می شود. برخلاف ارزهای سنتی که برای کنترل متکی و متمرکز در
                  بانک‌ها هستند، تراکنش‌های رمز ارزها بر روی یک بلاک چین عمومی
                  انجام می‌شوند که برای همه قابل دسترس و مشاهده است. علاوه بر این،
                  بسته به مکانیسم اجماع، هر کسی می‌تواند تراکنش‌ها را تایید کرده و
                  آنها را به بلاک چین اضافه کند لذا چنین کارکردی ارزهای دیجیتال را
                  غیرمتمرکز میکند.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar className="" />
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="about-page-main ">
      <div className="about-page-module">
        <div className="about-top">
          <h5 className="first-title">ماموریت ما</h5>
          <p className="third-title">
            افزایش قدرت مالی هر شخص بر اساس یک <br /> سیستم رمزنگار سریع، مطمئن
            و شفاف
          </p>
        </div>
        <div className="container">
          <div className="row">
            <div className="col second-about">
              <p>
                تریکسو یک سیستم اقتصادی رمزنگار کار آمد، شفاف و امن است که بر
                مبنای شبکه بلاک چین و رمزارزها فعال می باشد. هر کسی در هر کجا
                ،باید بتواند به راحتی و بصورت ایمن رمز ارزها را ارسال، دریافت،
                نگهداری و یا معامله کند. ما متعهدانه تلاش میکنیم یک پلتفرم قابل
                اعتماد و با دسترسی اسان به دنیای رمز ارزها را برای کاربران گرامی
                خود فراهم و ارتقاء دهیم و سخت تلاش کرده ایم تا انتخابی با
                یکپارچگی بالا برای شما فراهم کنیم و مشتاقانه منتظر جلب و حفظ
                اعتماد شما هستیم. بنابراین شما چه یک معامله گر حرفه ای یا مبتدی
                باشید تریکسو به شما این امکان را می دهد که مسیر مالی خود را در
                بازار رمز ارزها ترسیم کنید.
              </p>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col second-about">
              <h6>تعهد ما</h6>
              <p>
                پایبندی به الزامات نظارتی تضمین می‌کند که ما پلتفرم و دارایی های
                مشتریان خود را ایمن نگه داریم. برای این منظور، ما یک برنامه
                انطباق ایجاد کرده‌ایم که ریشه در بهترین شیوه‌های خدمات مالی سنتی
                و همچنین فن‌آوری انطباق نوآورانه و پیچیده دارد تا صنعت کریپتو را
                به پیش ببرد. ما برای اینکه چه دارایی هایی را فهرست می کنیم، چه
                خدماتی ارائه می دهیم و چه کسانی به محصولات ما دسترسی داشته
                باشند، استاندارد بالایی داریم.
              </p>
            </div>
          </div>
        </div>{" "}
        <div className="container">
          <div className="row">
            <div className="col second-about ">
              {" "}
              <h6>رمز ارزها</h6>
              <p>
                {" "}
                تراکنش رمز ارز بر روی بلاک چین ثبت می شود و پس از تایید غیرقابل
                تغییر می شود. برخلاف ارزهای سنتی که برای کنترل متکی و متمرکز در
                بانک‌ها هستند، تراکنش‌های رمز ارزها بر روی یک بلاک چین عمومی
                انجام می‌شوند که برای همه قابل دسترس و مشاهده است. علاوه بر این،
                بسته به مکانیسم اجماع، هر کسی می‌تواند تراکنش‌ها را تایید کرده و
                آنها را به بلاک چین اضافه کند لذا چنین کارکردی ارزهای دیجیتال را
                غیرمتمرکز میکند.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
