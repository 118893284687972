import React, { useContext, useEffect, useLayoutEffect } from "react";
import "./trade.scss";
import { UserContext } from "../../../contexts/providers/user";
import { useNavigate, useParams } from "react-router";
import { listenToEvent } from "../../../contexts/reducer/socket";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useState } from "react";
import TopPriceData from "./TopPriceData";
import UserOrderList from "./UserOrderList";
import TradeChart from "./TradeChart";
import BuySellModule from "./BuySellModule";
import PairListTrade from "./PairListTrade";
import OrderHistory from "./OrderHistory";
import OpenOrderModule from "./OpenOrderModule";
// style B
import TopPriceDataB from "./styleB/TopPriceDataB";
import UserOrderListB from "./styleB/UserOrderListB";
import TradeChartB from "./styleB/TradeChartB";
import BuySellModuleB from "./styleB/BuySellModuleB";
import PairListTradeB from "./styleB/PairListTradeB";
import OrderHistoryB from "./styleB/OrderHistoryB";
import OpenOrderModuleB from "./styleB/OpenOrderModuleB";
// style C
import TopPriceDataC from "./styleC/TopPriceDataC";
import UserOrderListC from "./styleC/UserOrderListC";
import TradeChartC from "./styleC/TradeChartC";
import BuySellModuleC from "./styleC/BuySellModuleC";
import PairListTradeC from "./styleC/PairListTradeC";
import OrderHistoryC from "./styleC/OrderHistoryC";
import OpenOrderModuleC from "./styleC/OpenOrderModuleC";
// style D
import TopPriceDataD from "./styleD/TopPriceDataD";
import BuySellModuleD from "./styleD/BuySellModuleD";
import BotSectionD from "./styleD/BotSectionD";
import ChartDropdown from "./styleD/ChartDropdown";
import OpenOrderModuleD from "./styleD/OpenOrderModuleD";
// style E
import TopPriceDataE from "./styleE/TopPriceDataE";
import OpenOrderModuleE from "./styleE/OpenOrderModuleE";
import PairListTrade0 from "./PairListTrade0";
import OrderHistory0 from "./OrderHistory0";
import TradeChart0 from "./TradeChart0";
import { createApiAddress } from "../../../constant/api.constant";
import { SocketContext } from "../../../contexts/providers/socket";
import { RotatingLines } from "react-loader-spinner";
import Joyride from 'react-joyride';
import MobileTradeMiniChart from "../../mobile-chart/Mobile_trade_minichart";
import BuySellOpener from "./styleE/BuySellOpener";

function Trade() {
  const { state, dispatch } = useContext(UserContext);
  const { stateIO, dispatchIO } = useContext(SocketContext);
  const { tradePair } = useParams();
  const [style, setStyle] = useState(style_A());

  useEffect(() => {
    console.log(1)
    const reloadPage = () => {
      window.location.reload();
    };

    const intervalId = setInterval(reloadPage, 15 * 60 * 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let uri = createApiAddress("trade.pair_details", tradePair);
    fetch(uri)
      .then((data) => data.json())
      .then((data) => {
        dispatchIO({
          type: "set_trade_info",
          buys: data.data.trade_info.buys,
          sells: data.data.trade_info.sells,
          histories: data.data.trade_info.tradeHists,
        });
        dispatchIO({
          type: "set:trade_pair:details",
          details: data.data.details,
        });
      });
  }, [tradePair, dispatchIO]);

  useLayoutEffect(() => {
    // console.log(2)
    const cleanup = () => {
      dispatchIO(stateIO.trade_pair === null)
    };
    if (stateIO.trade_pair !== tradePair) {
      dispatchIO({ type: "LISTEN:pair_info", trade_pair: tradePair });
    }
    return cleanup;
  }, [dispatchIO, stateIO, tradePair]);

  const userTradingWalletCallback = (e) => {
    dispatchIO({ type: "set:user:balances", wallets: e });
  };

  const userTradingHistoryCallback = (e) => {
    dispatchIO({ type: "set:user:orders", orders: e });
  };

  useEffect(() => {
    const pairInfoCallback = (e) => {
      if (e.pair === stateIO.trade_pair) {
        dispatchIO({
          type: "set_trade_info",
          buys: e.buys,
          sells: e.sells,
          histories: e.tradeHists,
        });
      }
    };

    const pairDetailsCallback = (e) => {
      if (e.pair === stateIO.trade_pair) {
        dispatchIO({ type: "set:trade_pair:details", details: e.details });
        dispatchIO({
          type: "set_trade_info",
          buys: e.trade_info.buys,
          sells: e.trade_info.sells,
          histories: e.trade_info.tradeHists || [],
        });
      }
    };

    const listPairsCallback = (e) => {
      let result = {};
      e.map((item, index) => {
        if (result[item.quote_currency] === undefined) {
          result[item.quote_currency] = [];
        }
        result[item.quote_currency].push(item)
        return true;
      });
      let result2 = [];
      Object.keys(result).map((item, index) => {
        result2.push({
          currency: item,
          pairs: result[item]
        })
        return false
      })
      dispatchIO({ type: "set:trade_pairs", pairs: result2 });
    };



    const tradeResponseCallback = (e) => {
      if (e.status !== 1) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: e.locale.fa,
        });
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: e.locale.fa,
        });
      }
    };


    const getSummary = () => {

      fetch("https://io.terixo.com/api/summary?with_change=true")
        .then((data) => data.json())
        .then((data) => {
          listPairsCallback(data.data);
        });
    };

    getSummary();

    if (stateIO.socket === null) {
      dispatchIO({ type: "start_socket", trade_pair: tradePair });
    } else {
      listenToEvent("pairResponse", pairInfoCallback);
      listenToEvent("pair:details", pairDetailsCallback);
      listenToEvent("pair:summaries", listPairsCallback);
      listenToEvent("user:trade:response", tradeResponseCallback);
      listenToEvent("createResponse", tradeResponseCallback);
      if (state.isLoggedIn === true) {
        listenToEvent("user:balance", userTradingWalletCallback);
        listenToEvent("user:orders", userTradingHistoryCallback);
        var data = { token: getStorage(ItemNames.TOKEN) };
        stateIO.socket?.emit("user:join", data);
      }
    }
    return () => {
      stateIO.socket?.off("pairResponse", pairInfoCallback);
      stateIO.socket?.off("pairDetails", pairDetailsCallback);
      stateIO.socket?.off("emitPairsResponse", listPairsCallback);
    };
  }, [
    state.isLoggedIn,
    stateIO.socket,
    stateIO.trade_pair,
    dispatchIO,
    tradePair,
    dispatch,
  ]);


  const checkUserOrdersLists = () => {
    // alert('here2')
    listenToEvent("user:balance", userTradingWalletCallback);
    listenToEvent("user:orders", userTradingHistoryCallback);
    var data = { token: getStorage(ItemNames.TOKEN) };
    stateIO.socket?.emit("user:join", data);
  }
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(1)
    const loadWallet = async () => {

      try {
        const response = await fetch(createApiAddress("trade.user_wallet"), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        });

        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        const data = await response.json();
        dispatchIO({ type: "set:user:balances", wallets: data.data });
      } catch (error) {
        console.log('');
      }

    };

    const waitForLoginStatus = async () => {
      const waitTime = 300;
      const maxAttempts = 30;
      let attempts = 0;
      const checkLoginStatus = async () => {
        if (attempts >= maxAttempts) {
          return false;
        }
        if (localStorage.getItem(ItemNames.TOKEN) !== null) {
          return true;
        }
        attempts++;
        await new Promise((resolve) => setTimeout(resolve, waitTime));
        return checkLoginStatus();
      };

      const loginStatus = await checkLoginStatus();
      if (loginStatus === true) {
        // alert('here')
        loadWallet();
        checkUserOrdersLists()
      }
    };

    waitForLoginStatus();
  }, [state.isLoggedIn]);



  useEffect(() => {
    function handleSizeOfPage() {
      if (window.innerWidth > 1600) {
        setStyle(style_A0());
      } else if (1255 <= window.innerWidth && window.innerWidth <= 1600) {
        setStyle(style_A());
      } else if (1055 <= window.innerWidth && window.innerWidth < 1255) {
        setStyle(style_B());
      } else if (825 <= window.innerWidth && window.innerWidth < 1055) {
        setStyle(style_C());
      } else if (500 <= window.innerWidth && window.innerWidth < 825) {
        setStyle(style_D());
      } else if (window.innerWidth < 500) {
        setStyle(Style_E());
      }
    }

    handleSizeOfPage();

    window.addEventListener("resize", handleSizeOfPage);
    return () => window.removeEventListener("resize", handleSizeOfPage);
  }, [window.innerWidth]);

  if (state.currency.details === undefined) {

    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
            <p className="loading-deposit">در حال بار گذاری</p>
          </div>
        </div>
      </div>
    );
  }

  function style_A0() {
    const steps =
      [{
        target: '.topprice-in-trade',
        content: 'در اینجا می توانید تغییرات و اطلاعات مربوط به 24 ساعت گذشته را بررسی کنید',
        placement: 'top'
      },
      {
        target: '.pairlist-0',
        content: 'در اینجا لیستی از جفت هایی وجود دارد که می توانید انتخاب کنید',
        placement: 'left'
      },
      {
        target: '.orderhistory-0',
        content: 'در اینجا لیست آخرین معاملات وجود دارد',
        placement: 'left'
      },
      {
        target: '.orders-section',
        content: 'در اینجا لیست سفارشات باز خرید و فروش آمده است',
        placement: 'right'
      },
      {
        target: '.buysell-a0',
        content: 'در اینجا می توانید با استفاده از لیمیت، مارکت و استاپ/لیمیت خرید و فروش کنید.',
        placement: 'top-end'
      },]

    return (
      <div className="trade-page0">
        <Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          disableScrolling={true}
          showSkipButton={true}
          locale={{
            back: 'بازگشت',
            close: 'بستن',
            last: ' ',
            next: 'بعدی',
            skip: 'بستن',
          }}
          styles={{
            options: {
              arrowColor: "#00a2ff",
              backgroundColor: "#00a2ff",
              overlayColor: "#00a2ff25",
              primaryColor: "#00a2ff",
              textColor: "#fff",
            },
            spotlight: {
              backgroundColor: "transparent",
            },
          }}
        />
        <div className="trade-mainA0">
          <div className="trade-module p-0">
            <div className="topprice-in-trade">
              <TopPriceData />
            </div>
            <div className="row trade-section-controller">
              <div className="  pairlist-section">
                <div className="pairlist-0">
                  <PairListTrade0 />
                </div>
                <div className="orderhistory-0">
                  <OrderHistory0 />
                </div>
              </div>

              <div className="chart-a0 p-0 m-0">
                <div className="row">
                  <div className="col-12">
                    <TradeChart0 />
                  </div>
                  <div className="buysell-a0">
                    <BuySellModule />
                  </div>
                </div>
              </div>
              <div className="orders-section">
                <UserOrderList />
              </div>
            </div>
          </div>
          <div className="container-openorder p-0 ">
            <div className="row p-0 m-0">
              <div className="col openorder-main p-0 m-0">
                <OpenOrderModule />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  }

  function style_A() {
    const steps =
      [{
        target: '.topprice-in-trade',
        content: 'در اینجا می توانید تغییرات و اطلاعات مربوط به 24 ساعت گذشته را بررسی کنید',
      },
      {
        target: '.pairlist-0',
        content: 'در اینجا لیستی از جفت هایی وجود دارد که می توانید انتخاب کنید',
      },
      {
        target: '.orderhistory-0',
        content: 'در اینجا لیست آخرین معاملات وجود دارد',
      },
      {
        target: '.orders-section',
        content: 'در اینجا لیست سفارشات باز خرید و فروش آمده است',
      },
      {
        target: '.buysell-a',
        content: 'در اینجا می توانید با استفاده از لیمیت، مارکت و استاپ/لیمیت خرید و فروش کنید.',
      },]
    return (
      <div className="trade-page">
        <Joyride
          steps={steps}
          continuous={true}
          showProgress={true}
          disableScrolling={true}
          showSkipButton={true}
          locale={{
            back: 'بازگشت',
            close: 'بستن',
            last: ' ',
            next: 'بعدی',
            skip: 'بستن',
          }}
          styles={{
            options: {
              arrowColor: "#00a2ff",
              backgroundColor: "#00a2ff",
              overlayColor: "#00a2ff25",
              primaryColor: "#00a2ff",
              textColor: "#fff",
            },
            spotlight: {
              backgroundColor: "transparent",
            },
          }}
        />
        <div className="trade-mainA">
          <div className="trade-module p-0">
            <div className="topprice-in-trade">
              <TopPriceData />
            </div>
            <div className="row trade-section-controller">
              <div className="  pairlist-section">
                <div className="pairlist-0">
                  <PairListTrade />
                </div>
                <div className="orderhistory-0">
                  <OrderHistory />
                </div>

              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-12">
                    <TradeChart />
                  </div>
                  <div className="col-12 buysell-a">
                    <BuySellModule />
                  </div>
                </div>
              </div>
              <div className="col-3 orders-section">
                <UserOrderList />
              </div>
            </div>
          </div>
          <div className="container-openorder p-0 ">
            <div className="row p-0 m-0">
              <div className="col openorder-main p-0 m-0">
                <OpenOrderModule />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_B() {
    return (
      <div className="trade-pageB">
        <div className="trade-main">
          <div className="trade-module">
            <div className="topprice-in-trade">
              <TopPriceDataB />
            </div>
            <div className="row style-b justify-content-between">
              <div className="col-5 buysell-b text-right">
                <BuySellModuleB />
              </div>
              <div className="col-6 chart-b text-left me-1">
                <TradeChartB />
              </div>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-3">
                  <UserOrderListB />
                </div>
                <div className="col-3">
                  <OrderHistoryB />
                </div>
                <div className="col-6 ">
                  <PairListTradeB />
                </div>
              </div>
            </div>
          </div>
          <div className="container-openorderB">
            <div className="row p-0 m-0">
              <div className="col openorder-main p-0 m-0">
                <OpenOrderModuleB />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_C() {
    return (
      <div className="trade-pageC">
        <div className="trade-main">
          <div className="trade-module">
            <div className="topprice-in-trade">
              <TopPriceDataC />
            </div>
            <div className="row style-b justify-content-between">
              <div className="col-5 buysell-b text-right p-0">
                <BuySellModuleC />
              </div>
              <div className="col-6 chart-c p-0">
                <TradeChartC />
              </div>
            </div>
            <div className="container-fluid bottom-container">
              <div className="row">
                <div className="col-4">
                  <UserOrderListC />
                </div>
                <div className="col-4">
                  <OrderHistoryC />
                </div>
                <div className="col-4">
                  <PairListTradeC />
                </div>
              </div>
            </div>
          </div>
          <div className="container-openorder">
            <div className="row p-0 m-0">
              <div className="col openorder-main p-0 m-0">
                <OpenOrderModuleC />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function style_D() {
    return (
      <div className="trade-pageD">
        <div className="trade-main">
          <div className="trade-module">
            <div className="topprice-in-trade">
              <TopPriceDataD />
            </div>
            <div className="container-fluid">
              <div className="row style-d justify-content-between">
                <div className="col chart-d text-center">
                  <ChartDropdown />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                {" "}
                <BuySellModuleD />{" "}
              </div>
            </div>
            <div className="container-fluid bottom-container">
              <div className="row">
                <div className="col">
                  <BotSectionD />
                </div>
              </div>
            </div>
          </div>
          <div className="container-openorder">
            <div className="row justify-content-center">
              <div className="col openorder-main">
                <OpenOrderModuleD />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function Style_E() {

    return (
      <div className="trade-mobile">
        <div className="trade-mobile-module">
          <TopPriceDataE />
          <div className="chart-mobile-mini">
            <MobileTradeMiniChart />
          </div>
          <div className="open-orders-mobile">
            <OpenOrderModuleE />
          </div>
          <div className="buysell-opener-mobile">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 text-center p-0">
                  <BuySellOpener />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>{style}</div>;
}
export default Trade;
