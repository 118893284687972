import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Deposit from "./DepositCrypto";
import { useState } from "react";
import DepositMoney from "./DepositMoney";
import DepositHistory from "./DepositHistory";
import { createApiAddress } from "../../../constant/api.constant";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { useContext } from "react";
import { UserContext } from "../../../contexts/providers/user";
import { useNavigate, useLocation } from "react-router";
import NavbarTopMobile from "../../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../../home/NavbarMobile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DepositMain() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    // console.log(newValue)
    setValue(newValue);
  };

  const location = useLocation()
  useEffect(() => {
    if (location.hash !== "") {
      var number = location.hash.replace('#', '')
      number = parseInt(number)
      setValue(number)
    }
  }, [location.pathname])

  const [irtInfo, setIrtInfo] = useState({})
  const [irtInfoMin, setIrtInfoMin] = useState()
  const { state, dispatch } = useContext(UserContext);
  const [limitData, setLimitData] = useState();
  useEffect(() => {
    fetch(createApiAddress("api.IRT_INFO"), {
      method: "GET",

    })
      .then((response) => {

        return response.json();
      })
      .then((data) => {
        if (data) {
          setIrtInfo(data.data)
          setIrtInfoMin(data.data?.deposit?.min)
        }
      })
      .catch((error) => {
        console.error("Error fetching user wallet data:", error);
      });
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    fetch(createApiAddress("api.withdraw_limit"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" });
          return;
        } else if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        return response.json();
      })
      .then((data) => {
        if (data) {
          setLimitData(data.data)
        }
      })
      .catch((error) => {
        console.error("Error fetching user wallet data:", error);
      });

  }, []);


  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="deposit-both-mobile">
        <NavbarTopMobile />
        <div className="depositModule">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="واریز رمز ارز" {...a11yProps(0)} />
                <Tab label="واریز تومان" {...a11yProps(1)} />
                <Tab label="تاریخچه" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Deposit timeBaseLimit={limitData} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <DepositMoney irtMin={irtInfoMin} irtInfo={irtInfo} timeBaseLimit={limitData} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <DepositHistory />
            </TabPanel>
          </Box>
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="deposit-both">
      <div className="depositModule">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="واریز رمز ارز" {...a11yProps(0)} />
              <Tab label="واریز تومان" {...a11yProps(1)} />
              <Tab label="تاریخچه" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Deposit timeBaseLimit={limitData} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DepositMoney irtMin={irtInfoMin} irtInfo={irtInfo} timeBaseLimit={limitData} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DepositHistory />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}
