import React, { useState } from 'react'
import './instant.scss'
import ImageUsdt from '../../../assets/images/USDT.png'
import { Alert, Button, Slide, Snackbar, Stack, TextField } from '@mui/material'
import { Dropdown, Modal, Spinner } from 'react-bootstrap'
import { useEffect } from 'react'
import { createApiAddress } from '../../../constant/api.constant'
import { ItemNames, getStorage } from '../../../utils/RSS'
import { toFixed } from '../../../utils/math'
import InstantHistory from './InstantHistory'
import { RotatingLines } from 'react-loader-spinner'
import { useContext } from 'react'
import { UserContext } from '../../../contexts/providers/user'
import { useNavigate } from 'react-router'
import NavbarTopMobile from '../../../components/navbar/NavbarTopMobile'
import ResponsiveAppBar from '../../home/NavbarMobile'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ClearIcon from '@mui/icons-material/Clear';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InstantBuySellMobile() {
  const [instantSide, setInstantSide] = useState(false)
  const [inputText, setInputText] = useState('مقدار تومان را وارد کنید:')
  const [token, setToken] = useState('IRT')
  const [secondToken, setSecondToken] = useState('USDT')
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [inputValue, setInputValue] = useState('')
  const [showSnack, setShowSnack] = useState(false)
  const [showSnack2, setShowSnack2] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [usdtBuyPrice, setUsdtBuyPrice] = useState('')
  const [usdtSellPrice, setUsdtSellPrice] = useState('')
  const [buyToken, setBuyToken] = useState('')
  const [buyTokenDate, setBuyTokenDate] = useState('')
  const [sellToken, setSellToken] = useState('')
  const [sellTokenDate, setSellTokenDate] = useState('')
  const [sellFee, setSellFee] = useState('')
  const [buyFee, setBuyFee] = useState('')
  const [loader, setLoader] = useState([])
  const [sideDecider, setSideDecider] = useState(true)
  const { state, dispatch } = useContext(UserContext);
  const [changeUSDT, setChangeUSDT] = useState()
  const [changeBUSD, setChangeBUSD] = useState()

  const usdtSide = () => {
    setSideDecider(true)
  }

  const handleOpenSnack = (snackNumber) => {
    if (snackNumber === '1') {
      setShowSnack(true)
    } else if (snackNumber === '2') {
      setShowSnack2(true)
    }
  }

  const handleCloseSnack = (snackNumber) => {
    if (snackNumber === '1') {
      setShowSnack(false)
    } else if (snackNumber === '2') {
      setShowSnack2(false)
    }
  }

  const handleInputChange = (event) => {
    const value = event.target.value;
    if (!value.includes('.')) {
      setInputValue(value);
      setErrorMessage('')
    }
  }

  const handleOpenModal = (name, points) => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenModal2 = (name, points) => {
    setShowModal2(true);
  };

  const handleCloseModal2 = () => {
    setShowModal2(false);
  };

  const buySide = () => {
    setInstantSide(false)
    setInputText('مقدار تومان را وارد کنید:')
    setToken('IRT')
    setSecondToken('USDT')
    setInputValue('')
    setErrorMessage('')
  }

  const sellSide = () => {
    setInstantSide(true)
    setInputText('مقدار تتر را وارد کنید:')
    setToken('USDT')
    setSecondToken('IRT')
    setInputValue('')
    setErrorMessage('')
  }

  const calculatedSellAmount = toFixed((usdtSellPrice * inputValue), 0)
  const calculatedBuyAmount = toFixed((inputValue / usdtBuyPrice), 2)
  const getPrices = async () => {
    try {
      const res = await fetch(createApiAddress("trade.instant_price"), {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const resJson = await res.json();
      setLoader(resJson)
      if (res.status === 200) {
        setBuyToken(resJson?.prices?.USDT_IRT?.buy?.token?.amount);
        setBuyTokenDate(resJson?.prices?.USDT_IRT?.buy?.token?.date);
        setSellToken(resJson?.prices?.USDT_IRT?.sell?.token?.amount);
        setSellTokenDate(resJson?.prices?.USDT_IRT?.sell?.token?.date);
        setUsdtBuyPrice(resJson?.prices?.USDT_IRT?.buy?.price);
        setUsdtSellPrice(resJson?.prices?.USDT_IRT?.sell?.price);
        setBuyFee(resJson?.prices?.USDT_IRT?.buy?.fee?.amount)
        setSellFee(resJson?.prices?.USDT_IRT?.sell?.fee?.amount)
        setChangeUSDT(resJson?.prices?.USDT_IRT?.buy?.change)
      }
    } catch (e) {

    }
  };

  useEffect(() => {
    getPrices();
    const intervalId = setInterval(getPrices, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const [walletDatas, setWalletDatas] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(walletDatas).length === 0) {
      fetch(createApiAddress("trade.user_wallet"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then(async (response) => {
          if (response.status === 401) {
            dispatch({ type: "Logout" });
            return;
          }
          if (response.status === 503) {
            navigate('/maintenance')
            return;
          }
          if (response.ok) {
            const data = await response.json();
            let wallets = {};
            Object.keys(data.data).map((item, index) => {
              wallets[item] = {
                ...data.data[item],
              };
              return true;
            });
            setWalletDatas(wallets);
          } else {

          }
        })
        .catch((error) => {

        });
    }
  }, [walletDatas]);



  const reloadWallet = () => {
    fetch(createApiAddress("trade.user_wallet"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((response) => response.json())
      .then(async (data) => {
        let wallets = {};
        Object.keys(data.data).map((item, index) => {
          wallets[item] = {
            ...data.data[item],
          };
          return true;
        });

        setWalletDatas(wallets);
      })
      .catch((error) => console.log(''));
  }

  const buyCheck = () => {
    setIsLoading(true)
    if (inputValue === null || inputValue === 0 || inputValue === undefined || inputValue === "") {
      setIsLoading(false)
      return setErrorMessage('مقدار را وارد کنید.')
    } else if (inputValue > walletDatas?.IRT?.trading || walletDatas?.IRT?.trading === undefined) {
      setIsLoading(false)
      return setErrorMessage('موجودی کافی نیست.')
    } else if (calculatedBuyAmount < 5) {
      setIsLoading(false)
      return setErrorMessage('مبلغ معامله باید بیشتر از 5 تتر باشد.')
    } else {
      finalizeTrade('buy');
    }
  }

  const sellCheck = () => {
    setIsLoading(true)
    if (inputValue === null || inputValue === 0 || inputValue === undefined || inputValue === "") {
      setIsLoading(false)
      return setErrorMessage('مقدار را وارد کنید.')
    } else if (inputValue > walletDatas?.USDT?.trading) {
      setIsLoading(false)
      return setErrorMessage('موجودی کافی نیست.')
    } else if (inputValue < 5) {
      setIsLoading(false)
      return setErrorMessage('مبلغ معامله باید بیشتر از 5 تتر باشد.')
    } else {
      finalizeTrade('sell');
    }
  }

  const finalizeTrade = async (actionType) => {
    setIsLoading(true)
    const tokenData = {
      date: actionType === 'buy' ? buyTokenDate : sellTokenDate,
      amount: actionType === 'buy' ? buyToken : sellToken
    };

    const res = await fetch(createApiAddress('trade.instant_apply'), {
      method: "POST",
      body: JSON.stringify({
        token: tokenData,
        amount: inputValue
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    });

    const resJson = await res.json();

    if (res.status === 200) {
      setInputValue('');
      handleOpenSnack('1')
      reloadWallet();
      setIsLoading(false)
    } else {
      handleOpenSnack('2')
      setIsLoading(false)
    }
  };

  const dropdownItems = [
    {
      label: "USDT",
      description: "Tether",
      image: ImageUsdt,
    },

  ];

  const [selectedItem, setSelectedItem] = useState(dropdownItems[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item.label === 'USDT') {
      usdtSide()
    } else {
      // busdSide()
    }
  };

  const setMaxUsdt = () => {
    if (instantSide === true) {
      setInputValue(toFixed(walletDatas?.USDT?.trading, 2))
    } else {
      setInputValue(toFixed(walletDatas?.IRT?.trading, 0))
    }
  }

  const clearInput = () => {

    setInputValue(0)

  }



  const changeColor = () => {
    if (changeBUSD <= 0) {
      return 'text-danger'
    } else if (changeBUSD > 0) {
      return 'text-success'
    } else if (changeUSDT <= 0) {
      return 'text-danger'
    } else if (changeUSDT > 0) {
      return 'text-success'
    }
  }

  if (loader.length === 0) {
    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
            <p className="loading-deposit">در حال بار گذاری</p>
          </div>
        </div>
      </div>
    );
  }

  const formatNumber = (number) => {
    if (number !== undefined && number !== null) {
      const numberStr = number.toString();
      const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return formattedNumber;
    } else {
      return number
    }
  }


  return (<div className="container-fluid instant-main p-0 m-0">
    <NavbarTopMobile />
    <div className="container instant-module">
      <Button onClick={handleOpenModal}>تاریخچه</Button>
      <div className="row justify-content-center">
        <div className="col text-center m-4">
          <h6>درگاه خرید و فروش تتر</h6>
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className="dropdownbtn-in-instant">
              <div className="d-flex">
                <div className="top-token-instant">
                  <div className="d-flex flex-column">
                    <span>{selectedItem.label}</span>
                    <span style={{ fontSize: "11px", marginTop: "-6px" }}>{selectedItem.description}</span>
                  </div>
                  <img src={selectedItem.image} alt="" height={25} style={{ marginTop: 2, marginRight: 8 }} />
                </div>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-in-instant">
              {dropdownItems.map((item, index) => (
                <Dropdown.Item key={index} onClick={() => handleItemClick(item)} className="dropdownitem-in-instant">
                  <div className="d-flex justify-content-center">
                    <div className="top-token-instant">
                      <div className="d-flex flex-column">
                        <span>{item.label}</span>
                        <span style={{ fontSize: "11px", marginTop: "-6px" }}>{item.description}</span>
                      </div>
                      <img src={item.image} alt="" height={25} style={{ marginTop: 2, marginRight: 8 }} />
                    </div>
                  </div>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <div className="price-amount mt-5">
            {instantSide ? <h4 className="price-amount">{usdtSellPrice} IRT</h4> : <h4 className="price-amount">{usdtBuyPrice} IRT</h4>}
            <h6 className={`price-amount + ${changeColor()}`}>{changeUSDT}</h6>
          </div>
          <div className="container-fluid">
            <div className="row justify-content-around">
              <div className="col-3 buy-btn-instant">
                <Button className='buy-btn' onClick={buySide}>
                  <div className="container p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-8 text-center p-0 m-0">
                        خرید
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
              <div className="col-3 sell-btn-instant">
                <Button className='sell-btn' onClick={sellSide}>
                  <div className="container p-0 m-0">
                    <div className="row p-0 m-0">
                      <div className="col-8 text-center p-0 m-0">
                        فروش
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            </div>
          </div>
          <div className="container mt-5">
            <div className="row justify-content-center">
              <h6>{inputText}</h6>
              <div className="col-12">
                <div className='d-flex input-instant-ltr'>
                  <span><Button onClick={() => setMaxUsdt()} style={{ fontSize: 10, minWidth: 70 }} className='mt-3'>کل موجودی</Button></span>
                  <span><TextField id="input-instant-ltr" variant="standard" value={inputValue} onChange={handleInputChange} type='number' inputProps={{ step: 1 }} /></span>
                  <span className='mt-3'><Button onClick={() => clearInput()}><ClearIcon /></Button></span>
                </div>
                <p className='text-error'>{errorMessage}</p>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-4 text-end">
                <h6 className='h6-instant-fz'>موجودی:</h6>
              </div>
              <div className="col-8 text-start">
                {instantSide ? <h6 className='h6-instant-fz'>{token} <span> {formatNumber(toFixed(walletDatas?.USDT?.trading, 2))}</span></h6> : <h6 className='h6-instant-fz'>{token} <span> {walletDatas?.IRT?.trading ? formatNumber(toFixed(walletDatas?.IRT?.trading, 0)) : 0}</span></h6>}
                <h6 className='h6-instant-fz'></h6>
              </div>
            </div>
          </div>
          {instantSide ? <div className='available-section-instant-sell'>
            <div className="container-fluid mt-2">
              <div className="row">
                <div className="col-4 text-end">
                  <h6>کارمزد:</h6>
                </div>
                <div className="col-8 text-start">
                  <h6>{secondToken} <span>{formatNumber(toFixed(calculatedSellAmount * sellFee, 0))}</span></h6>
                </div>
              </div>
            </div>
            <div className="container-fluid mt-2">
              <div className="row">
                <div className="col-4 text-end">
                  <h6>مقدار دریافتی:</h6>
                </div>
                <div className="col-8 text-start">
                  <h6>{secondToken} <span>{inputValue ? formatNumber(toFixed(calculatedSellAmount - (calculatedSellAmount * sellFee), 0)) : 0}</span></h6>
                </div>
              </div>
            </div>
          </div>
            :
            <div className='available-section-instant-buy'>
              <div className="container-fluid mt-2">
                <div className="row">
                  <div className="col-4 text-end">
                    <h6>کارمزد:</h6>
                  </div>
                  <div className="col-8 text-start">
                    <h6>{secondToken} <span>{formatNumber(toFixed(calculatedBuyAmount * buyFee, 3))}</span></h6>
                  </div>
                </div>
              </div>
              <div className="container-fluid mt-2">
                <div className="row">
                  <div className="col-4 text-end">
                    <h6>مقدار دریافتی:</h6>
                  </div>
                  <div className="col-8 text-start">
                    <h6>{secondToken} <span>{inputValue ? formatNumber(toFixed(calculatedBuyAmount - (calculatedBuyAmount * buyFee), 3)) : 0}</span></h6>
                  </div>
                </div>
              </div>
            </div>}

          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col">
                {instantSide ?

                  <Button variant='contained' color='error' fullWidth className='btn-final-instant-sell' onClick={sellCheck} disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "فروش"}</Button> :
                  <Button variant='contained' color='success' fullWidth className='btn-final-instant-buy' onClick={buyCheck} disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "خرید"}
                  </Button>}
              </div>
            </div>
          </div>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col text-end">
                <Button onClick={handleOpenModal2} style={{ fontSize: 11 }}>در مورد خرید و فروش سریع <HelpOutlineIcon /></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      show={showModal2}
      onHide={handleCloseModal2}
      className="modal-withdraw m-2"
      TransitionComponent={Transition}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>در مورد خرید و فروش سریع</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>شما در لحظه  میتوانید تتر  را به قیمت روز در اینجا خرید و فروش نمایید. خرید و یا فروش شما بر مبنای میزان تقاضای بازار با کمترین شکاف قیمتی در بازار در سریعترین زمان انجام میگیرد</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal2}>
          بستن
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal
      show={showModal}
      onHide={handleCloseModal}
      className="modal-withdraw m-2"
      TransitionComponent={Transition}
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>تاریخچه</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InstantHistory />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseModal}>
          بستن
        </Button>
      </Modal.Footer>
    </Modal>

    <Stack spacing={2} sx={{ maxWidth: 300 }}>
      <Snackbar
        open={showSnack}
        onClose={() => handleCloseSnack('1')}
        autoHideDuration={5000}
      >
        <Alert
          severity='success'
          sx={{ fontSize: 18, maxWidth: 400 }}
        >
          با موفقیت انجام گرفت.
        </Alert>
      </Snackbar>
    </Stack>
    <Stack spacing={2} sx={{ maxWidth: 300 }}>
      <Snackbar
        open={showSnack2}
        onClose={() => handleCloseSnack('2')}
        autoHideDuration={5000}
      >
        <Alert
          severity='error'
          sx={{ fontSize: 18, maxWidth: 400 }}
        >
          دوباره تلاش کنید.
        </Alert>
      </Snackbar>
    </Stack>
    <div className="container-fluid navbar-mobile-bot">
      <div className="row justify-content-center">
        <div className="col-12">
          <ResponsiveAppBar className="" />
        </div>
      </div>
    </div>
  </div >
  )
}

export default InstantBuySellMobile