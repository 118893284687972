import { Star, StarBorder } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../../../../contexts/providers/user";

function EachPair({ pair }) {
  const { state, dispatch } = useContext(UserContext);
  const [blink, setBlink] = useState(false);
  const [change, setChange] = useState(0);

  useEffect(() => {
    setChange((
      ((pair?.last_price - pair?.first_price) /
        pair?.first_price) *
      100
    ).toFixed(2))
  }, [pair?.last_price, pair?.first_price]);

  const colorForChange = () => {
    if (change < 0) {
      return "textdanger";
    } else if (change > 0) {
      return "textsuccess";
    }
  };

  useEffect(() => {
    setBlink(true);
    setTimeout(() => {
      setBlink(false);
    }, 1000);
  }, [pair.price]);

  const favBotton = (crypto_pair) => {
    const favToggle = (action) => {
      return () => {
        dispatch({
          type: "pair:fav:set",
          currency: crypto_pair,
          action: action,
        });
      };
    };

    if (state.pair_fav.includes(crypto_pair)) {
      return (
        <Star
          sx={{ fontSize: 40 }}
          className={"star-icon " + (blink === true ? "blink" : "")}
          onClick={favToggle("remove")}
        />
      );
    }
    return (
      <StarBorder
        sx={{ fontSize: 40 }}
        className={"star-icon " + (blink === true ? "blink" : "")}
        onClick={favToggle("add")}
      />
    );
  };

  return (
    <div className="row">
      <div className={"col d-flex justify-content-between "}>
        <div className="order-total-in-orderlist">
          {pair?.base_volume.toFixed(2)}
        </div>
        <div
          className={
            `order-amount-in-orderlist ${colorForChange()}` + (blink === true ? "blink" : "")
          }
        >
          {pair?.last_price}
        </div>
        <div
          className={`order-price-in-orderlist   d-flex justify-content-end`}
        >
          <NavLink
            as={Link}
            to={`/trade/${pair.trading_pairs}`}
            className={"nav-link " + (blink === true ? "blink" : "")}
          >
            {pair?.trading_pairs.replace("_", "/")}
          </NavLink>
          {favBotton(pair?.trading_pairs)}
        </div>
      </div>
    </div>
  );
}

export default EachPair;
