import React from "react";
import Card from "react-bootstrap/Card";
import "./profile.scss";
import MarkEmailReadRoundedIcon from "@mui/icons-material/MarkEmailReadRounded";
import PatternRoundedIcon from "@mui/icons-material/PatternRounded";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FingerprintRoundedIcon from "@mui/icons-material/FingerprintRounded";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import EventNoteRoundedIcon from "@mui/icons-material/EventNoteRounded";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { ItemNames, getStorage } from "../../utils/RSS";
import { createApiAddress } from "../../constant/api.constant";
import { useEffect } from "react";
import { UserContext } from "../../contexts/providers/user";
import { useContext } from "react";
import { useState } from "react";

function PInfo({ profileInfo }) {

  const [referCount, setReferCount] = useState();
  const { state, dispatch } = useContext(UserContext);
  const kycStatus = () => {
    // console.log(profileInfo)
    if (profileInfo?.info?.kyc?.status === 'approved_lvl2' || profileInfo?.info?.kyc?.status === 'approved') {
      return 2;
    } else {
      return 0;
    }
  }

  const kycStatus3 = () => {
    if (profileInfo?.info?.kyc?.status === 'approved') {
      return 3;
    } else {
      return 0;
    }
  }

  let navigate = useNavigate();

  const goToCommission = () => {
    navigate(`/commission`);
  };

  useEffect(() => {
    if (state.user_profile.refer_count === undefined || state.user_profile.refer_count === null) {
      getReferCount()
    }

  }, [state.user_profile.refer_count]);


  const getReferCount = () => {
    fetch(createApiAddress("api.REFER_COUNT"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then(async (response) => {
        if (response.status === 401) {
          dispatch({ type: "Logout" })
          return;
        }
        if (response.status === 503) {
          navigate('/maintenance')
          return;
        }
        if (response.ok) {
          const data = await response.json();
          dispatch({ type: "set:profile:refer_count", refer_count: data?.data?.refer_count });
          // console.log(data)

        } else {
          // console.log("Error fetching data:", response.statusText);
        }
      })
      .catch((error) => {

      });
  }

  return (
    <div className="">
      <div className="row d-flex align-items-center">
        <div className="col">
          <Card style={{ width: "18rem", height: "20rem" }}>
            <Card.Body className="user-info">
              <Card.Title>اطلاعات کاربر</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                اطلاعات حساب منحصر به فرد شما، که باید ایمن نگه دارید.
              </Card.Subtitle>
              <Card.Text>
                <p> <MarkEmailReadRoundedIcon style={{ color: "#38E54D" }} />  ایمیل: {profileInfo?.email_format}</p>
              </Card.Text>
              <Card.Text>
                <p> <SupervisedUserCircleIcon style={{ color: "#38E54D" }} /> نام کاربری: {profileInfo?.info?.username}</p>
              </Card.Text>
              <Card.Text>
                <p><VpnKeyIcon style={{ color: "#38E54D" }} /> شناسه کاربر: {profileInfo.unique_id}</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "18rem", height: "20rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">
                اطلاعات امنیتی
              </Card.Title>
              <Card.Text>
                <p>
                  <PatternRoundedIcon style={{ color: "#38E54D" }} /> احراز هویت
                  دو عاملی: {profileInfo.security?.tfa.status ? " فعال شده است " : " غیر فعال "}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <VpnKeyRoundedIcon style={{ color: "#38E54D" }} /> رمز
                  معاملاتی: {profileInfo?.security?.password?.trading.status === true ? "فعال شده است" : 'غیر فعال'}
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "18rem", height: "20rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">
                وضعیت حساب
              </Card.Title>
              <Card.Text>
                <p>
                  <FingerprintRoundedIcon style={{ color: "#38E54D" }} /> احراز
                  هویت:
                </p>

              </Card.Text>
              <Card.Text>
                <p>
                  <TaskAltIcon style={{ color: "#38E54D" }} className="ms-2" />
                  سطح یک کاربری
                </p>{" "}
                <p>
                  {kycStatus() === 2 ?
                    <TaskAltIcon
                      style={{ color: "#38E54D" }}
                      className="ms-2"
                    />

                    :
                    <HighlightOffIcon
                      style={{ color: "rgb(240, 14, 78)" }}
                      className="ms-2"
                    />
                  }
                  سطح دو کاربری
                </p>
                <p>
                  {kycStatus3() === 3 ?
                    <TaskAltIcon
                      style={{ color: "#38E54D" }}
                      className="ms-2"
                    />

                    :
                    <HighlightOffIcon
                      style={{ color: "rgb(240, 14, 78)" }}
                      className="ms-2"
                    />
                  }
                  سطح سه کاربری
                </p>
              </Card.Text>
              <Card.Text>
                {/* <p>
                  <EmojiEventsRoundedIcon style={{ color: "#38E54D" }} />{" "}
                  Airdrop Point: {profileInfo.airdrop_count}
                </p> */}
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div className="col">
          <Card style={{ width: "18rem", height: "20rem" }}>
            <Card.Body className="user-info">
              <Card.Title className="card-title-security">ارجاعات</Card.Title>
              <Card.Text>
                <p>
                  <EventNoteRoundedIcon style={{ color: "#38E54D" }} /> تعداد
                  ارجاعات: {state.user_profile.refer_count ?? 0}
                </p>
              </Card.Text>
              <Card.Text>
                <p>
                  <HttpsRoundedIcon style={{ color: "#38E54D" }} /> شناسه ارجاع: {profileInfo.refer?.id}
                </p>
              </Card.Text>
              <CardActions>
                {/* <Button size="small" onClick={handleShow}>
                  لیست ارجاع
                </Button> */}
                <Button size="small" variant="contained" color="info" onClick={goToCommission} className="fix-income">
                  درآمد ثابت{" "}
                </Button>
              </CardActions>
            </Card.Body>
          </Card>

          {/* <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>لیست ارجاع</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              {referralList.map((item, index) => {
                return (
                  <>
                    <ul>
                      <li Key={index}>کاربر: {item.username}</li>
                    </ul>
                  </>
                );
              })}
            </Modal.Body>
          </Modal> */}
        </div>
      </div>
    </div>
  );
}

export default PInfo;
