import React, { useState, useEffect } from 'react';
import './assets.scss';

function WalletVIPEach(props) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const imageData = await props.image;
                setImageSrc(imageData);
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };

        fetchImage();

        // Cleanup function to prevent memory leaks
        return () => {
            setImageSrc(null);
        };
    }, [props.image]);

    return (
        <div className='container-fluid wallet-mobile-box mb-2'>
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontSize: 22, marginTop: 20 }}>{props.available}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-start">
                            <div className="row">
                                <div className="col-12">
                                    <p style={{ fontSize: 20, marginTop: 20 }}>{props.symbol}</p>
                                    <p style={{ fontSize: 13, marginTop: -18 }}>{props.symbol === 'IRT' ? 'Tooman' : 'Tether'}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-2 mt-3">
                            {imageSrc && <img src={imageSrc} alt="token-logo" height={35} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WalletVIPEach;
