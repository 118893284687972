import { ItemNames, getStorage } from "./RSS";

export const toFixed = (num, decimal = 6) => {
  try {
    if (num === null || num?.toString() === null) {
      return 0;
    }

    const multiplier = Math.pow(10, decimal);
    const truncated = Math.trunc(num * multiplier) / multiplier;

    const [num1, num2] = truncated.toString().split(".");

    if (decimal === 0 || num2 === undefined) {
      return num1.toString();
    } else {
      const paddedNum2 = num2.padEnd(decimal, "0");
      return `${num1}.${paddedNum2}`;
    }
  } catch {
    return 0;
  }
};


export const getCurrencyDecimals = (currency, type) => {
  let data = JSON.parse(getStorage(ItemNames.CURRENCIES))
  let result = data[currency]?.decimals[type];
  if (result === undefined) {
    return 6
  } else {
    return result;
  }
}

const DEFAULT_FEE_CONDITION = {
  min: 500,
  max: 4000,
  percentage: 1,
  step: 25000000
};

export const calculateFeeByCondition = (amount, conds = DEFAULT_FEE_CONDITION) => {
  let fee = conds.min;
  if (amount > conds.step && conds.step !== 0) {
    let mod = Math.floor(amount / conds.step);
    let remainder = amount - (mod * conds.step)
    fee = conds.max * mod;
    if (remainder > 0) {
      fee = fee + calculateFeeByCondition(remainder, conds)
    }
    return fee
  } else {
    let perc = amount * (conds.percentage / 100)
    if (perc > conds.min && perc <= conds.max) {
      return perc
    } else if (perc >= conds.max) {
      return conds.max;
    } else if (perc < conds.min) {
      return conds.min;
    }
    return conds.min;
  }
}
