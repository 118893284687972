
import React from 'react'
import moment from "jalali-moment";
import { UserContext } from '../../../../contexts/providers/user';
import { useContext } from 'react';
import { getCurrencyDecimals, toFixed } from '../../../../utils/math';
function OrdersAllHistoryMobile(props) {

    const { state } = useContext(UserContext);

    const changeToPersian = (x) => {
        if (x === 'market') {
            return 'مارکت';
        } else if (x === 'limit') {
            return 'لیمیت';
        } else {
            return 'استاپ/لیمیت'
        }
    }

    const changeToPersianOrder = (x) => {
        if (x === 'filled') {
            return 'تکمیل شد';
        } else if (x === 'canceled') {
            return 'لغو شد';
        } else if (x === 'partially') {
            return 'ناتمام'
        } else if (x === 'not_triggered') {
            return 'در انتظار'
        } else {
            return 'فعال شد'
        }
    }

    return (
        <> {props.all_orders?.map((row) => (
            <div className='container-fluid' key={row.created_at}>
                <div className="row">
                    <div className="col-6">
                        <p className='open-order-mobile-pair'> <span>
                            <img
                                src={state.currency?.images[row.pair?.from]}
                                style={{ width: 25, height: 25, marginLeft: 10 }}
                                alt={"currency"}
                            />
                        </span> {row.pair.from}/{row.pair.to}</p>
                    </div>
                    <div className={row.side === 'sell' ? 'text-danger col-6 text-start' : 'text-success col-6 text-start'}>
                        {changeToPersian(row.order_type)} | {row.side === 'sell' ? 'فروش' : 'خرید'}
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <p className='mobile-openorder-text'>مقدار</p>
                        <p className='mobile-openorder-text'>قیمت</p>
                        <p className='mobile-openorder-text'>کل</p>
                    </div>
                    <div className="col-3 text-start">
                        <p className='mobile-openorder-text'>{toFixed(row.amount, getCurrencyDecimals(row.pair.from, 'amount'))}</p>
                        <p className='mobile-openorder-text'>{toFixed(row.price, getCurrencyDecimals(row.pair.from, 'price'))}</p>
                        <p className='mobile-openorder-text'>{toFixed(row.total, getCurrencyDecimals(row.pair.from, 'total'))}</p>
                    </div>
                    <div className="col-2">
                        <p className='mobile-openorder-text'>شرط</p>
                        <p className='mobile-openorder-text'>وضعیت</p>
                        <p className='mobile-openorder-text'>تاریخ   ساعت</p>
                    </div>
                    <div className="col-4 text-start">
                        <p className='mobile-openorder-text'>
                            {changeToPersian(row.order_type) === 'استاپ/لیمیت' ? <p align="left" className={row.side === 'sell' ? 'text-danger' : 'text-success'}>
                                {row.side === 'buy' ? 'بزرگتر از ' : 'کوچکتر از'}{" "}
                                {toFixed(row.stop_price, getCurrencyDecimals(row.pair.from, 'price'))}
                            </p> : <p>-</p>}</p>
                        <p className='mobile-openorder-text'>{changeToPersianOrder(row.status)}</p>
                        <p className='mobile-openorder-text'>{moment(row.created_at).locale('fa').format('jYYYY/jMM/jDD HH:mm:ss ')}</p>
                    </div>
                </div>

            </div>))}</>
    )
}

export default OrdersAllHistoryMobile
