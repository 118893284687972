import { useContext, useState } from "react";
import "./deposit.scss";
import QRCode from "react-qr-code";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { Alert, Button, Divider, Grid, Snackbar } from "@mui/material";
import { getDepositCurrency } from "../../../utils/Api";
import { useEffect } from "react";
import { ItemNames, getStorage } from "../../../utils/RSS";
import { UserContext } from "../../../contexts/providers/user";
import { useRef } from "react";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { createApiAddress } from "../../../constant/api.constant";
import { useNavigate } from "react-router";

function Deposit() {
  const { state, dispatch } = useContext(UserContext);
  const [tokenSelected, setTokenSelected] = useState("");
  const [networkSelected, setNetworkSelected] = useState("");
  const [isTokenSelected, setIsTokenSelected] = useState(true);
  const [cryptoAddress, setCryptoAddress] = useState(null);
  const [show, setShow] = useState(false)
  const [severity, setSeverity] = useState('')
  const [message, setMessage] = useState('')

  const handleCloseSnackBar = () => {
    setShow(false)
  }

  const openErrorSnack = (msg, sev) => {
    setMessage(msg)
    setSeverity(sev)
    setShow(true)
  }

  const errorCatch = (error) => { dispatch({ type: "Logout" }) };

  const [depositList, setDepositList] = useState({});

  useEffect(() => {
    const setDepositListFunc = function (data) {
      let tmp = {};
      data.map((currency) => {
        if (tmp[currency.symbol] === undefined) {
          tmp[currency.symbol] = {
            name: currency.name,
            symbol: currency.symbol,
            image: currency.image,
            networks: [],
          };
        }
        tmp[currency.symbol].networks.push(currency);
        return currency;
      });
      setDepositList(tmp);
    };
    getDepositCurrency(setDepositListFunc, errorCatch);
  }, []);

  const sortedTokens = Object.values(depositList).sort((a, b) =>
    a.symbol.localeCompare(b.symbol)
  );

  const tokenOptions = sortedTokens.map((token) => (
    <MenuItem value={token.symbol} key={token.symbol}>
      <div className="d-flex mt-2">
        <img
          src={token.image}
          style={{ width: 30, height: 30, marginRight: 10, marginLeft: 10, marginTop: 1 }}
          alt={"currency"}
        />{" "}
        <div className="d-flex flex-column">
          <span>{token.symbol}</span>
          <span style={{ fontSize: "10px", marginTop: "-7px" }}>{token.name}</span>
        </div>
      </div>
    </MenuItem>
  ));


  const networkOptions = depositList[tokenSelected]
    ? depositList[tokenSelected].networks.map((currency) => {
      // console.log(currency);
      const fixedNetworkName = fixNetworkNames(currency.info.network);

      return (
        <MenuItem value={currency} key={currency.info.network}>
          {fixedNetworkName}
        </MenuItem>
      );
    })
    : null;

  const handleTokenChange = (event) => {
    setCryptoAddress(null);
    setTokenSelected(event.target.value);
    setIsTokenSelected(false);
    setNetworkSelected("");
  };

  const handleNetworkChange = (event) => {
    setNetworkSelected(event.target.value);
    requestGetAddress(event.target.value);
  };

  const navigate = useNavigate();

  const requestGetAddress = async (currency) => {
    try {
      const host = createApiAddress("transaction.GET_DEPOSIT_ADDRESS", currency.id);
      const response = await fetch(host, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      });

      if (response.status === 401) {
        dispatch({ type: "Logout" })
        return;
      }
      if (response.status === 503) {
        navigate('/maintenance')
        return;
      }

      if (response.ok) {
        const data = await response.json();
        setCryptoAddress(data.public_address);
      } else {

      }
    } catch (error) {

    }
  };



  function fixNetworkNames(item) {
    if (item.toString().toUpperCase() === "BEP20") {
      return "BEP20";
    } else if (item.toString().toUpperCase() === "TRX") {
      return "Tron TRC20";
    } else if (item.toString().toUpperCase() === "BTC") {
      return "Bitcoin Network";
    } else if (item.toString().toUpperCase() === "ETH") {
      return "Ethereum ERC20";
    } else if (item.toString().toUpperCase() === "DOGE") {
      return "Dogecoin";
    } else if (item.toString().toUpperCase() === "LTC") {
      return "Litecoin Network";
    } else {
      return item.toString().toUpperCase();
    }
  }

  const handleCopyClick = (event) => {

    event.preventDefault();
    if (navigator.clipboard) {
      openErrorSnack('کپی شده است', 'success')
      navigator.clipboard.writeText(cryptoAddress)
    } else {
      openErrorSnack('کپی شده است', 'success')
      const textField = document.createElement('textarea');
      textField.value = cryptoAddress;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
  };


  return (
    <div className="deposit-main">
      <div className="container deposit">
        <div className="row justify-content-center">
          <div className="col-10 col-md-6 deposit-module m-4">
            <div className="row m-3">
              <div className="col">
                <h5>واریز رمز ارز</h5>
                <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                  <h6>لطفاً توکن را انتخاب کنید:</h6>

                  <FormControl fullWidth>
                    <Select value={tokenSelected} onChange={handleTokenChange}>
                      <MenuItem value=""></MenuItem>
                      {tokenOptions}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ minWidth: 120 }} mt={2} mb={2}>
                  <h6 hidden={isTokenSelected}>لطفاً شبکه را انتخاب کنید:</h6>
                  <FormControl fullWidth>
                    <Select
                      value={networkSelected}
                      onChange={handleNetworkChange}
                      hidden={isTokenSelected}
                    >
                      <MenuItem value={networkOptions}></MenuItem>
                      {networkOptions}
                    </Select>
                  </FormControl>
                </Box>

                {cryptoAddress !== null ? (
                  <>
                    <div className="container-fluid">
                      <div className="row justify-content-center">
                        <div className="col-12 col-md-10 d-flex align-items-center">
                          <h6 className="deposit-address">
                            نشانی: {cryptoAddress}
                          </h6>
                        </div>
                        <div className="col-12 col-md-2  align-items-center mb-2 copy-btn-main text-center">
                          <Button onClick={handleCopyClick} variant="contained" className="copy-btn"><FileCopyIcon /></Button>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        height: "auto",
                        margin: "0 auto",
                        maxWidth: 200,
                        width: "100%",
                        backgroundColor: "white",
                        padding: 5
                      }}
                    >
                      <QRCode
                        size={512}
                        style={{
                          height: "auto",
                          maxWidth: "100%",
                          width: "100%",
                        }}
                        value={cryptoAddress}
                        viewBox={`0 0 512 512`}
                      />
                    </div>
                    <Grid
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      mt={2}
                    ></Grid>
                    <div>
                      <p className="text-danger">مهم: </p>
                      <p>
                        این آدرس کیف پول شما فقط برای واریز کردن{" "}
                        <span className="textsuccess">{tokenSelected}</span>{" "}
                        میباشد. در صورتیکه رمز ارز دیگری به این آدرس واریز شود
                        منجر به از بین رفتن مبلغ  واریزی می‌گردد و دیگر قابل
                        استرداد نمی‌باشد.
                      </p>
                      <p>
                        واریز ودریافت رمز ارز الزاما باید بر روی یک شبکه یکسان
                        انجام گیرد. در صورت استفاده از شبکه های متفاوت برای یک
                        انتقال، مبلغ ارسالی از بین می‌ رود و دیگر قابل استرداد
                        نمی‌باشد.
                      </p>
                    </div>
                  </>
                ) : ""}
              </div>
            </div>
          </div>
        </div>
        <Snackbar
          open={show}
          autoHideDuration={6000}
          onClose={handleCloseSnackBar}
        >
          <Alert severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default Deposit;
