import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import Icon1 from '../../../assets/images/USDT.png'
import MarketTable from "./MarketTable";

function MarketTabs() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);

  const handleTabChange = (e, tabIndex) => {
    setCurrentTabIndex(tabIndex);
  };

  return (
    <React.Fragment>
      <Tabs value={currentTabIndex} onChange={handleTabChange}>
        <Tab label="بازار رمز ارزها" icon={<img src={Icon1} style={{ paddingLeft: 10 }} />} iconPosition="start" />
        {/* <Tab label="تومان" icon={<img src={Icon1} style={{ paddingLeft: 10 }} />} iconPosition="start" /> */}
      </Tabs>

      {/* TAB 1 Contents */}
      {currentTabIndex === 0 && (
        <Box sx={{ p: 3 }}>
          <MarketTable />
        </Box>
      )}

      {/* TAB 2 Contents */}
      {/* {currentTabIndex === 1 && (
        <Box sx={{ p: 3 }}>
          <MarketTable />
        </Box>
      )} */}
    </React.Fragment>
  );
}

export default MarketTabs;
