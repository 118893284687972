import React from "react";

function PairTitle() {
  return (
    <div className="row">
      <div className="col d-flex justify-content-between pair-title-main">
        <div className="top-title-orderlist-third">حجم</div>
        <div className="top-title-orderlist-second">قیمت</div>
        <div className="top-title-orderlist-first">جفت ارز</div>
      </div>
    </div>
  );
}

export default PairTitle;
