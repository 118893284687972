import React from "react";
import MiniChart from "../products/instantBuySell/MiniChart";
import "./mobileinstant.scss";
import { useParams } from "react-router-dom";

function MobileInstantChart() {
  const params = useParams();

  return (
    <div className="chart-main">
      <div className="chart">
        <MiniChart
          key={params.token}
          symbol={params.token}
          colorOne={"#f9b50075"}
          colorTwo={"#f9b50000"}
          colorThree={"#f9b500"}
        />
      </div>
    </div>
  );
}

export default MobileInstantChart;
