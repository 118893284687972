import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createApiAddress } from '../../constant/api.constant';
import { ItemNames, getStorage } from '../../utils/RSS';
import { UserContext } from '../../contexts/providers/user';
import { useContext } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import NavbarTopMobile from '../../components/navbar/NavbarTopMobile';
import WalletMobileEach from './WalletMobileEach';
import { toFixed } from '../../utils/math';
import ResponsiveAppBar from '../home/NavbarMobile';
import SimpleBar from 'simplebar-react';
import { Button, Tooltip, Fade, } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';



function WalletMobile() {
    const [value, setValue] = useState(0);
    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };
    const [bitcoinPrice, setBitcoinPrice] = useState(0);
    const [irtPrice, setIrtPrice] = useState(0);
    const [walletDatas, setWalletDatas] = useState([]);
    const { state, dispatch } = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [searchQuery2, setSearchQuery2] = useState("");
    const [searchQuery3, setSearchQuery3] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(walletDatas).length === 0) {
            fetch(createApiAddress("trade.user_wallet"), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            })
                .then(async (response) => {
                    if (response.status === 401) {
                        dispatch({ type: "Logout" })
                        return;
                    }
                    if (response.status === 503) {
                        navigate('/maintenance')
                        return;
                    }
                    const data = await response.json();
                    let pricesResponse = await fetch(createApiAddress("trade.prices"));
                    let prices = await pricesResponse.json();
                    let wallets = {};
                    setBitcoinPrice(prices["BTC"].USDT);
                    setIrtPrice(prices["USDT"].IRT);
                    Object.keys(data.data).map((item, index) => {
                        wallets[item] = {
                            ...data.data[item],
                            ...prices[item],
                        };
                        return true;
                    });
                    setWalletDatas(wallets);
                })
                .catch((error) => console.log(''));
        }
    }, [walletDatas]);


    const mainAllAssetsUSDT = Object.keys(walletDatas).reduce((acc, item) => {
        if (item !== "IRT") {
            const totalAmount2USDT = walletDatas[item].main * walletDatas[item].USDT;
            return acc + totalAmount2USDT;
        }
        const totalAmount2USDT = walletDatas[item].main / irtPrice;
        return acc + totalAmount2USDT;
    }, 0);

    const tradeAllAssetsUSDT = Object.keys(walletDatas).reduce((acc, item) => {
        if (item !== "IRT") {
            const totalAmount2USDT = walletDatas[item].trading * walletDatas[item].USDT;
            return acc + totalAmount2USDT;
        }
        const totalAmount2USDT = walletDatas[item].trading / irtPrice;
        return acc + totalAmount2USDT;
    }, 0);

    const userBTCinMain = mainAllAssetsUSDT / bitcoinPrice;
    const userBTCinTrade = tradeAllAssetsUSDT / bitcoinPrice;

    // const handleInputChange = (e) => {
    //     setSearchQuery(e.target.value.toUpperCase());
    // };

    // const handleInputChange2 = (e) => {
    //     setSearchQuery2(e.target.value.toUpperCase());
    // };

    // const handleInputChange3 = (e) => {
    //     setSearchQuery3(e.target.value.toUpperCase());
    // };

    const filteredData = Object.keys(state.currency.details)
        .sort((a, b) => {
            let itema = state.currency.details[a];
            let itemb = state.currency.details[b];
            let amountA = parseFloat(
                `${walletDatas[itema.symbol]?.main +
                walletDatas[itema.symbol]?.trading || 0
                }`
            );
            let amountB = parseFloat(
                `${walletDatas[itemb.symbol]?.main +
                walletDatas[itemb.symbol]?.trading || 0
                }`
            );
            if (amountA > amountB) {
                return -1;
            } else if (amountA < amountB) {
                return 1;
            }
            return 0;
        })
        .filter((item1) => {
            const item = state.currency.details[item1];
            const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery.toLowerCase());
            const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery.toLowerCase());
            return symbolIncludesQuery || nameIncludesQuery;
        });

    const filteredData2 = Object.keys(state.currency.details)
        .sort((a, b) => {
            let itema = state.currency.details[a];
            let itemb = state.currency.details[b];
            let amountA = parseFloat(
                `${walletDatas[itema.symbol]?.main || 0}`
            );
            let amountB = parseFloat(
                `${walletDatas[itemb.symbol]?.main || 0}`
            );
            if (amountA > amountB) {
                return -1;
            } else if (amountA < amountB) {
                return 1;
            }
            return 0;
        })
        .filter((item1) => {
            const item = state.currency.details[item1];
            const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery2.toLowerCase());
            const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery2.toLowerCase());
            return symbolIncludesQuery || nameIncludesQuery;
        });

    const filteredData3 = Object.keys(state.currency.details)
        .sort((a, b) => {
            let itema = state.currency.details[a];
            let itemb = state.currency.details[b];
            let amountA = parseFloat(
                `${walletDatas[itema.symbol]?.trading || 0}`
            );
            let amountB = parseFloat(
                `${walletDatas[itemb.symbol]?.trading || 0}`
            );
            if (amountA > amountB) {
                return -1;
            } else if (amountA < amountB) {
                return 1;
            }
            return 0;
        })
        .filter((item1) => {
            const item = state.currency.details[item1];
            const symbolIncludesQuery = item.symbol.toLowerCase().includes(searchQuery3.toLowerCase());
            const nameIncludesQuery = item.name.toLowerCase().includes(searchQuery3.toLowerCase());
            return symbolIncludesQuery || nameIncludesQuery;
        });

    const addCommasToNumber = (item) => {
        let number;
        if (item.symbol !== "IRT") {
            number = parseFloat(
                `${(walletDatas[item.symbol]?.USDT || 0) *
                (walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0)
                }`
            );
        } else {
            number = parseFloat(
                `${(walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0) / irtPrice
                }`
            );
        }
        number = number.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        return number;
    };

    const addCommasToNumber2 = (item) => {
        let number =
            parseFloat(
                `${walletDatas[item.symbol]?.main + walletDatas[item.symbol]?.trading || 0}`)
        if (item.symbol === "IRT") {
            number = number.toLocaleString("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        } else {
            number = number.toLocaleString("en-US", {
                minimumFractionDigits: 6,
                maximumFractionDigits: 6,
            });
        }
        return number;
    };

    // const addCommasToNumber3 = (item) => {
    //     let number = parseFloat(`${walletDatas[item.symbol]?.main || 0}`)
    //     if (item.symbol === "IRT") {
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 0,
    //         });
    //     } else {
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 6,
    //             maximumFractionDigits: 6,
    //         });
    //     }
    //     return number;
    // };

    // const addCommasToNumber4 = (item) => {
    //     let number;
    //     if (item.symbol !== "IRT") {
    //         number = parseFloat(`${(walletDatas[item.symbol]?.USDT || 0) * (walletDatas[item.symbol]?.main || 0)}`)
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //         });

    //     } else {
    //         number = (walletDatas[item.symbol]?.main || 0) / irtPrice
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //         });
    //     }
    //     return number;
    // };

    // const addCommasToNumber5 = (item) => {
    //     let number = parseFloat(`${walletDatas[item.symbol]?.trading || 0}`)
    //     if (item.symbol === "IRT") {
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 0,
    //             maximumFractionDigits: 0,
    //         });
    //     } else {
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 6,
    //             maximumFractionDigits: 6,
    //         });
    //     }
    //     return number;
    // };

    // const addCommasToNumber6 = (item) => {
    //     let number;
    //     if (item.symbol !== "IRT") {
    //         number = parseFloat(`${(walletDatas[item.symbol]?.USDT || 0) * (walletDatas[item.symbol]?.trading || 0)}`)
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //         });

    //     } else {
    //         number = (walletDatas[item.symbol]?.trading || 0) / irtPrice
    //         number = number.toLocaleString("en-US", {
    //             minimumFractionDigits: 2,
    //             maximumFractionDigits: 2,
    //         });
    //     }
    //     return number;
    // };
    const goToTransfer = () => {
        navigate('/transfer')
    }
    const feeText = `جهت ارتقاء امنیت کاربران و جلوگیری از اشتباه رقمی در سفارش گذاری، لطفاً مقدار رمز ارزی را که قصد معامله دارید ار حساب اصلی به حساب معاملاتی انتقال دهید  و سپس انجام معامله نمایید. توصیه می شود پس از انجام معامله مقدار رمز ارز را از حساب معاملاتی خود به حساب اصلی منتقل نمایید.`;
    return (
        <div className="container-fluid wallet-mobile-module p-0 m-0">
            <NavbarTopMobile />
            <SimpleBar style={{ maxHeight: 720 }} autoHide={false}>
                <div className="row justify-content-center p-0 m-0">
                    <div className="col-12">
                        <div className="wallet-mobile-main ">
                            <div className="row">
                                <div className="col-8">
                                    <p style={{ fontSize: 13 }}>
                                        کل دارایی معادل تتر:{" "}
                                        {(mainAllAssetsUSDT + tradeAllAssetsUSDT).toFixed(2)}
                                    </p>
                                    <p style={{ fontSize: 13 }}>
                                        کل دارایی معادل بیتکوین:{" "}
                                        {(userBTCinMain + userBTCinTrade).toFixed(5)}
                                    </p>
                                    <p style={{ fontSize: 13 }}>
                                        کل دارایی معادل تومان:{" "}
                                        {new Intl.NumberFormat("ir-FA", {
                                            style: "currency",
                                            currency: "irt",
                                            minimumFractionDigits: 0,
                                        }).format(
                                            (
                                                (mainAllAssetsUSDT + tradeAllAssetsUSDT).toFixed(2) *
                                                irtPrice
                                            ).toFixed(0)
                                        )}
                                    </p>
                                </div>
                                <div className="col-4 trns-btn-m">
                                    <Tooltip
                                        title={<h6 className="tooltip-text">{feeText}</h6>}
                                        TransitionComponent={Fade}
                                        TransitionProps={{ timeout: 600 }}
                                        placement="top"
                                        leaveDelay={400}
                                        className="tootltip-main"
                                    >
                                        <Button><InfoIcon /></Button>
                                    </Tooltip>
                                    <Button variant='contained' className='transfer-btn-mobile mt-2' onClick={goToTransfer}>انتقال</Button>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="total"
                                id="justify-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="total" title="تمام دارایی ها">
                                    {filteredData
                                        .map((item1, index) => {
                                            let item = state.currency.details[item1];
                                            return (
                                                <WalletMobileEach image={item.image} name={item.name} symbol={item.symbol}
                                                    in_irt={new Intl.NumberFormat("ir-FA", {
                                                        style: "currency",
                                                        currency: "irt",
                                                        minimumFractionDigits: 0,
                                                    }).format(
                                                        toFixed(
                                                            parseFloat(
                                                                `${(walletDatas[item.symbol]?.main +
                                                                    walletDatas[item.symbol]?.trading || 0) *
                                                                (walletDatas[item.symbol]?.IRT || 0)
                                                                }`
                                                            ),
                                                            0
                                                        )
                                                    )}
                                                    available={addCommasToNumber2(item)}
                                                    in_usdt={addCommasToNumber(item)}
                                                />)
                                        })}
                                </Tab>
                                <Tab eventKey="main" title="اصلی">
                                    {filteredData2
                                        .map((item1, index) => {
                                            let item = state.currency.details[item1];
                                            return (
                                                <WalletMobileEach image={item.image} name={item.name} symbol={item.symbol}
                                                    in_irt={new Intl.NumberFormat("ir-FA", {
                                                        style: "currency",
                                                        currency: "irt",
                                                        minimumFractionDigits: 0,
                                                    }).format(
                                                        toFixed(
                                                            parseFloat(
                                                                `${(walletDatas[item.symbol]?.main +
                                                                    walletDatas[item.symbol]?.trading || 0) *
                                                                (walletDatas[item.symbol]?.IRT || 0)
                                                                }`
                                                            ),
                                                            0
                                                        )
                                                    )}
                                                    available={addCommasToNumber2(item)}
                                                    in_usdt={addCommasToNumber(item)}
                                                />)
                                        })}
                                </Tab>
                                <Tab eventKey="trading" title="معاملاتی">
                                    {filteredData3
                                        .map((item1, index) => {
                                            let item = state.currency.details[item1];
                                            return (
                                                <WalletMobileEach image={item.image} name={item.name} symbol={item.symbol}
                                                    in_irt={new Intl.NumberFormat("ir-FA", {
                                                        style: "currency",
                                                        currency: "irt",
                                                        minimumFractionDigits: 0,
                                                    }).format(
                                                        toFixed(
                                                            parseFloat(
                                                                `${(walletDatas[item.symbol]?.main +
                                                                    walletDatas[item.symbol]?.trading || 0) *
                                                                (walletDatas[item.symbol]?.IRT || 0)
                                                                }`
                                                            ),
                                                            0
                                                        )
                                                    )}
                                                    available={addCommasToNumber2(item)}
                                                    in_usdt={addCommasToNumber(item)}
                                                />)
                                        })}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </SimpleBar>
            <div className="container-fluid navbar-mobile-bot">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <ResponsiveAppBar />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletMobile
