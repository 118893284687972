import { React, useState, useContext, useEffect } from "react";
import "./login.scss";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { ItemNames, getStorage, isLoggedIn, setStorage } from "../../utils/RSS";
import { UserContext } from "../../contexts/providers/user";
import { InputGroup, Modal, ModalFooter, Spinner } from "react-bootstrap";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { Alert, IconButton, Slide, Snackbar, Stack } from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";
import { GridVisibilityOffIcon } from "@mui/x-data-grid";
import { createApiAddress } from "../../constant/api.constant";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

const SITE_KEY = "6LcV2WwnAAAAABdjdE6qIFwv2kDeOyzONnXYp5OU";


export default function Login() {

  const [validated, setValidated] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [pws, setPws] = useState('')
  const [mailAgain, setMailAgain] = useState('')
  const navigate = useNavigate();
  const [showForgot, setShowForgot] = useState(false);
  const handleCloseForgot = () => setShowForgot(false);
  const handleShowForgot = () => setShowForgot(true);
  const [showNotice, setShowNotice] = useState(false);
  const handleCloseNotice = () => setShowNotice(false);
  const [timer, setTimer] = useState(60);
  const [apiStatus, setApiStatus] = useState(true);
  const [showResetEmail, setShowResetEmail] = useState(false);
  const handleCloseResetEmail = () => setShowResetEmail(false);
  const handleShowResetEmail = () => setShowResetEmail(true);

  const [showReset2FA, setShowReset2FA] = useState(false);
  const handleCloseReset2FA = () => setShowReset2FA(false);

  const [mail, setMail] = useState(null);

  const [isLoading, setIsLoading] = useState();

  const [showMailOTP, setMailOTP] = useState(false);
  const handleCloseMailOTP = () => {
    setTimer(60)
    setMailOTP(false)
  };

  const [showGOTP, setGOTP] = useState(false);
  const handleCloseGOTP = () => setGOTP(false);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const [showIcon, setShowIcon] = useState(false);
  const [showModalDisableTfa, setShowModalDisableTfa] = useState(false)
  const [showSnack, setShowSnack] = useState(false)
  const handleCloseModalDisableTfa = () => {
    setShowModalDisableTfa(false)
  }


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowIcon(!showIcon);
  };

  const handleCloseSnack = () => {
    setShowSnack(false)
  }

  const location = useLocation();

  useEffect(() => {
    setShowNotice(true)
  }, [])


  useEffect(() => {
    const search = new URLSearchParams(location.search);

    if (search.get('action') !== undefined && search.get('action') === "disabled_tfa") {
      if (search.get('action') === "disabled_tfa") {
        const applyForgotTFA = async () => {
          let res = await fetch("https://api.terixo.com/api/v3/forgot/tfa/apply", {
            method: "POST",
            body: JSON.stringify({
              token: search.get('token')
            }),
            headers: { "Content-Type": "application/json" },
          });
          const resJson = res.json()
          if (res.status === 503) {
            navigate('/maintenance')
          } else if (res.status === 200) {
            setShowModalDisableTfa(true)
            return;
          } else {
            setShowSnack(true)
          }
        }
        applyForgotTFA()
      }
    }
  }, []);


  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () { }
    );
  }, []);

  useEffect(() => {
    if (showGOTP) {
      setTimer(60);
    }
  }, [showGOTP]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setApiStatus(false)
    }
    return () => clearInterval(interval);
  }, [timer]);


  //////////////////// login function

  const handleLogin = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setTimer(60)
    setIsLoading(true);
    const form = event.currentTarget;
    const loginInformation = new FormData(event.currentTarget);
    setMailAgain(loginInformation.get("email"))
    setPws(loginInformation.get("password"))
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(SITE_KEY, { action: "/" })
        .then(async (token) => {
          if (form.checkValidity() === false) {
            setValidated(true);
            setIsLoading(false);
          } else {
            /////////////////// fetch data
            try {
              let res = await fetch(createApiAddress("api.login"), {
                method: "POST",
                body: JSON.stringify({
                  email: loginInformation.get("email"),
                  password: loginInformation.get("password"),
                  recaptcha: token,
                }),
                headers: { "Content-Type": "application/json" },
              });

              let resJson = await res.json();

              /////////////////// login result

              if (resJson.status === 1) {
                dispatch({
                  type: "open_snack",
                  snackColor: "success",
                  snackMessage: resJson.locale.fa,
                });
                if (resJson.tfa_status === false) {
                  setMailOTP(true);
                } else {
                  setGOTP(true);
                }
                setStorage(ItemNames.TTOKEN, resJson.ttoken);
                setIsLoading(false);
              } else {
                dispatch({
                  snackColor: "warning",
                  type: "open_snack",
                  snackMessage: resJson.locale.fa,
                });
                setIsLoading(false);
              }
            } catch (err) {
              setIsLoading(false);
            }
          }
        });
    });
  };

  // resend otp again

  const resendOTP = async (event) => {
    setIsLoading(true);
    setApiStatus(true)
    event.preventDefault();
    event.stopPropagation();
    try {
      let res = await fetch(createApiAddress("api.resend_otp"), {
        method: "POST",
        body: JSON.stringify({
          email: mailAgain,
          password: pws,
          ttoken: getStorage(ItemNames.TTOKEN),
        }),
        headers: { "Content-Type": "application/json" },
      });

      let resJson = await res.json();

      ///////////////////   result

      if (resJson.status === 0) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
        setApiStatus(false)
      } else if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });
        setApiStatus(true)
        setTimer(60)
        setIsLoading(false);

      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  /////////////////////email tfa

  const onSubmitMailOTP = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    event.stopPropagation();
    const mailOTP = new FormData(event.currentTarget);
    try {
      let res = await fetch(createApiAddress("api.tfa_login"), {
        method: "POST",
        body: JSON.stringify({
          verify_code: mailOTP.get("mailotp"),
          ttoken: getStorage(ItemNames.TTOKEN),
        }),
        headers: { "Content-Type": "application/json" },
      });

      let resJson = await res.json();

      ///////////////////   result

      if (resJson.status === 0) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      } else if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });

        setStorage(ItemNames.TOKEN, resJson.token);
        isLoggedIn();
        setIsLoading(false);
        setMailOTP(false);
        LoadUserProfile();
        navigate('/checkuser')

      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const LoadUserProfile = () => {
    if (isLoggedIn() === true) {
      fetch(
        createApiAddress("api.user_info"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({ type: "set:profile", profile: data.user });
          } else {
            dispatch({ type: "Logout" });
          }
        })
        .catch((error) => dispatch({ type: "Logout" }));
    }
  }
  const onSubmitWith2FA = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    const mailOTP = new FormData(event.currentTarget);
    try {
      let res = await fetch(createApiAddress("api.tfa_login"), {
        method: "POST",
        body: JSON.stringify({
          verify_code: mailOTP.get("mailotp"),
          tfa_code: mailOTP.get("gotp"),
          ttoken: getStorage(ItemNames.TTOKEN),
        }),
        headers: { "Content-Type": "application/json" },
      });

      let resJson = await res.json();

      ///////////////////   result

      if (resJson.status === 0) {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      } else if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
        setStorage(ItemNames.TOKEN, resJson.token);
        isLoggedIn();
        dispatch({ type: "login" });
        LoadUserProfile();
        navigate('/checkuser')

      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const reset2FA = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLoading(true);
    setShowForgot(false);
    try {
      let res = await fetch(
        createApiAddress("api.RESET_2FA_IN_LOGIN"),
        {
          body: JSON.stringify({
            email: mail,
          }),
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
        setShowReset2FA(false);
        setMail("");
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const forgotPassword = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowForgot(false);
    setIsLoading(true);
    try {
      let res = await fetch(
        createApiAddress("api.RESET_PASS_IN_LOGIN"),
        {
          body: JSON.stringify({
            email: mail,
          }),
          method: "POST",
          headers: { "Content-Type": "application/json" },
        }
      );

      let resJson = await res.json();
      if (resJson.status === 1) {
        dispatch({
          type: "open_snack",
          snackColor: "success",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
        setShowResetEmail(false);
        setMail("");
      } else {
        dispatch({
          type: "open_snack",
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
        });
        setIsLoading(false);
      }
    } catch (err) {
      // console.log(err);
      setIsLoading(false);
    }
  };

  const checkValidation = (event, callback) => {
    if (mail === null || mail === "") {
      dispatch({
        type: "open_snack",
        snackColor: "warning",
        snackMessage: "لطفا آدرس ایمیل خود را وارد نمایید.",
      });
    } else {
      callback(event);
    }
  };
  const [loginStatus, setLoginStatus] = useState(false);
  const checkLogingStatus = () => {
    if (isLoggedIn() === true) {
      setLoginStatus(true);
    } else if (isLoggedIn() === false) {
      setLoginStatus(false);
    }
  };
  useEffect(() => {
    checkLogingStatus();
  }, [isLoggedIn]);

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return loginStatus === false ? (
      <>
        <div className="login-main-mobile">
          <NavbarTopMobile />
          <div className="login-module">
            <div className="row">
              <div className="col text-center m-5">
                <h4 className="login-title">به تریکسو خوش آمدید</h4>
                <h5 className="login-title mb-5">برای ادامه وارد شوید</h5>
                <div
                  className="g-recaptcha"
                  data-sitekey="6LfRKdIlAAAAAOzdlD2OqlHquQWlhFt389XGNsvE"
                  data-size="invisible"
                ></div>
                <Form noValidate validated={validated} onSubmit={handleLogin}>
                  <Form.Group md="6" controlId="emailvalidation" className="mb-3">
                    <InputGroup className="input-login">
                      <Form.Control
                        type="email"
                        placeholder="ایمیل"
                        required
                        name="email"
                        className="input-login input-login-color"
                      />
                      <Form.Control.Feedback type="invalid">
                        لطفا ایمیل خود را وارد کنید.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group md="6" controlId="passwordvalidation">
                    <InputGroup className="input-login">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="کلمه عبور"
                        required
                        name="password"
                        onChange={handlePasswordChange}
                        className="input-login input-login-color"
                      />

                      <IconButton
                        aria-label="show-eye"
                        onClick={togglePasswordVisibility}
                      >
                        {showIcon ? (
                          <GridVisibilityOffIcon />
                        ) : (
                          <VisibilityOutlined />
                        )}
                      </IconButton>

                      <Form.Control.Feedback type="invalid">
                        لطفا رمز عبور خود را وارد کنید.
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Button
                    className="login-btn"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "ورود"}
                  </Button>
                </Form>
              </div>
              <div className="d-flex justify-content-between">
                <div className="forgot-pass">
                  <h6>
                    مشکل ورود؟
                    <Button
                      variant="outline"
                      onClick={handleShowForgot}
                      className="forgot-btn"
                    >
                      اینجا کلیک کنید
                    </Button>
                  </h6>
                </div>
                <div className="forgot-pass">
                  <NavLink as={Link} to="/register" className="register-btn">
                    <h6 className="pt-2">برای ثبت نام اینجا کلیک کنید</h6>
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p>
                    این سایت توسط reCAPTCHA و Google محافظت می شود
                    <a href="https://policies.google.com/privacy">
                      {" "}
                      سیاست حفظ حریم خصوصی
                    </a>{" "}
                    و
                    <a href="https://policies.google.com/terms">
                      {" "}
                      مقررات استفاده از خدمات
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* open reset links */}
          <Modal
            show={showNotice}
            onHide={handleCloseNotice}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                نکته مهم
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list " style={{ textAlign: "justify", lineHeight: "2", height: 400, overflowY: 'scroll' }}>
              <p>پلتفرم تریکسو فقط از طریق سیستم تیکت یا با ارسال ایمیل به support@terixo.com با کاربران خود ارتباط برقرار می‌کند. در صورتی که فردی از طریق تلگرام یا هر روش دیگری خارج از سیستم تریکسو با شما تماس بگیرد، لطفاً اطلاعات حساب و اطلاعات شخصی خود را ارائه ندهید و از ارتباط با آنها خودداری کنید. همچنین، بخش پشتیبانی تریکسو هیچگاه از شما اطلاعات ورود به حسابتان مانند رمز عبور و کد ورود را از طریق ایمیل یا تیکت درخواست نمی‌کند. لطفاً در حفظ و نگهداری اطلاعات خود دقت فرمایید. هر گونه عملیات تقلبی یا خطایی که از سوی کاربر انجام شود، به عهده خود کاربر خواهد بود.</p>
              <p className="text-danger">موکدا" توصیه می‌گردد اگر شخصی از شما درخواست کرد که برایش رمز ارز خرید نمایید، چنانچه منبع پول ارسال شده از سمت وی برای شما، غیر قانونی(مانند فیشینگ، سرقت، قمار و ....) باشد، مسئولیت قانونی با شماست‌.</p>
            </Modal.Body>
            <ModalFooter>
              <Button className="confirm-withdraw" onClick={handleCloseNotice}>
                اطلاعات را خواندم و تایید می‌کنم
              </Button>
            </ModalFooter>
          </Modal>
          <Modal
            show={showForgot}
            onHide={handleCloseForgot}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                می توانید کد احراز هویت دوم یا رمز عبور خود را بازنشانی کنید
              </Modal.Title>
            </Modal.Header>
            {/* <Modal.Body className="modal-list"></Modal.Body> */}
            <ModalFooter>
              <Button className="confirm-withdraw" onClick={setShowReset2FA}>
                کد احراز هویت دوم را بازنشانی کنید
              </Button>
              <Button className="cancel-withdraw" onClick={handleShowResetEmail}>
                رمز عبور را فراموش کرده اید؟
              </Button>
            </ModalFooter>
          </Modal>



          {/* open form for reset password */}
          {/* توجه داشته باشید که پس از اولین واریز تومان از کارتی که جدید در سیستم اضافه شده است، برداشت از سایت به مدت 72 ساعت به حالت تعلیق تغییر پیدا میکند.این امر برای جلوگیری از هرگونه سوء استفاده افراد دیگر از حساب بانکی کاربر میباشد.           */}
          <Modal
            show={showResetEmail}
            onHide={handleCloseForgot}
            className="modal-withdraw"
            backdrop="static"
          >
            <Modal.Header>
              <Modal.Title>
                برای دریافت لینک بازنشانی رمز عبور، ایمیل خود را وارد کنید
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    className="withdraw-texts"
                    InputProps={{ sx: { height: 57 } }}
                    id="mail"
                    name="mail"
                    placeholder="ایمیل شما"
                    size="medium"
                    required
                    onChange={(event) => setMail(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                      dir: "rtl",
                    }}
                  />
                </FormControl>
              </Form>
            </Modal.Body>
            <ModalFooter>
              <Button
                className="confirm-withdraw"
                onClick={handleCloseResetEmail}
              >
                لغو کنید
              </Button>
              <Button
                className="cancel-withdraw"
                onClick={(event) => checkValidation(event, forgotPassword)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? "بارگذاری..." : "ارسال لینک"}
              </Button>
            </ModalFooter>
          </Modal>

          {/* open reset 2fa */}
          <Modal
            show={showReset2FA}
            onHide={handleCloseReset2FA}
            className="modal-withdraw"
            backdrop="static"
            dir="rtl"
          >
            <Modal.Header>
              <Modal.Title>
                برای دریافت لینک ریست کد احراز هویت دوم، ایمیل خود را وارد کنید.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-list" dir="rtl">
              <Form>
                <FormControl fullWidth>
                  <TextField
                    className="withdraw-texts"
                    InputProps={{ sx: { height: 57 } }}
                    id="mail"
                    name="mail"
                    placeholder="ایمیل شما"
                    size="medium"
                    required
                    onChange={(event) => setMail(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                      className: "withdraw-texts",
                      dir: "rtl",
                    }}
                  />
                </FormControl>
              </Form>
            </Modal.Body>
            <ModalFooter>
              <Button className="confirm-withdraw" onClick={handleCloseReset2FA}>
                لغو کنید
              </Button>
              <Button
                className="cancel-withdraw"
                onClick={(event) => checkValidation(event, reset2FA)}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? "بارگذاری..." : "ارسال لینک"}
              </Button>
            </ModalFooter>
          </Modal>

          {/* open login just mail  */}

          <Modal show={showMailOTP} onHide={handleCloseMailOTP} backdrop="static">
            <Modal.Header>
              <Modal.Title className="mt-2">کد دریافتی را وارد کنید</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmitMailOTP}>
                <Form.Group className="mb-3">
                  <Form.Control
                    type="number"
                    placeholder="کد ایمیل"
                    autoFocus
                    name="mailotp"
                    required
                    className="input-login-codes"
                  />
                </Form.Group>

                <div className="row justify-content-around text-center">
                  <div className="col mb-2">
                    <Button variant="secondary" onClick={handleCloseMailOTP}>
                      بستن
                    </Button>
                  </div>
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? "بارگذاری..." : "ارسال"}
                    </Button>
                  </div>
                </div>
              </Form>
              <div><Button variant="text" onClick={resendOTP} disabled={isLoading || apiStatus} className="btntext-otp"  >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"

                  />
                ) : null}
                {isLoading ? "بارگذاری..." : apiStatus ? ` کد را دریافت نکردید؟ اینجا کلیک کنید. (${timer} ثانیه)` : "کد را دریافت نکردید؟ اینجا کلیک کنید."}
              </Button>
              </div>
            </Modal.Body>
          </Modal>

          {/* open login 2fa and mail */}

          <Modal
            show={showGOTP}
            onHide={handleCloseGOTP}
            backdrop="static"
            dir="rtl"
          >
            <Modal.Header>
              <Modal.Title>کد دریافتی را وارد کنید</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={onSubmitWith2FA}>
                <Form.Group className="mb-3">
                  <Form.Group className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="کد ایمیل"
                      name="mailotp"
                      required
                      autoFocus
                      className="input-login-codes"
                    />
                  </Form.Group>
                  <Form.Control
                    type="number"
                    placeholder="کد احراز هویت دوم"
                    name="gotp"
                    required
                    className="input-login-codes"
                  />
                </Form.Group>
                <div className="row justify-content-around text-center">
                  <div className="col mb-2">
                    <Button variant="secondary" onClick={handleCloseGOTP}>
                      بستن
                    </Button>
                  </div>
                  <div className="col">
                    <Button variant="primary" type="submit" disabled={isLoading}>
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : null}
                      {isLoading ? "بارگذاری..." : "ارسال"}
                    </Button>

                  </div>
                </div>
              </Form>
              <div><Button variant="text" onClick={resendOTP} disabled={isLoading || apiStatus} className="btntext-otp"  >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"

                  />
                ) : null}
                {isLoading ? "بارگذاری..." : apiStatus ? ` کد را دریافت نکردید؟ اینجا کلیک کنید. (${timer} ثانیه)` : "کد را دریافت نکردید؟ اینجا کلیک کنید."}
              </Button>
              </div>
            </Modal.Body>
          </Modal>

          {/* disable 2fa modal */}

          <Modal
            show={showModalDisableTfa}
            onHide={handleCloseModalDisableTfa}
            className="modal-withdraw m-2"
            size="xl"
          >
            <Modal.Header>
              <Modal.Title>احراز هویت گوگل</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>احراز هویت گوگل شما برای حساب شما غیرفعال شده است. لطفاً درک کنید که فعال کردن آن برای امنیت شما بسیار مهم است و برای برداشت ارز دیجیتال یا پول به آن نیاز دارید.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseModalDisableTfa}>
                متوجه هستم
              </Button>
            </Modal.Footer>
          </Modal>

          <Stack spacing={2} sx={{ maxWidth: 300 }}>
            <Snackbar
              open={showSnack}
              autoHideDuration={5000}
              onClose={handleCloseSnack}
            >
              <Alert
                severity='error'
                sx={{ fontSize: 18, maxWidth: 400 }}
              >
                درخواست نامعتبر برای غیرفعال کردن احراز هویت عامل دوم
              </Alert>
            </Snackbar>
          </Stack>

        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </>
    ) : (
      navigate("/")
    );
  }

  return loginStatus === false ? (
    <div className="login-main">
      <div className="login-module">
        <div className="row">
          <div className="col text-center m-5">
            <h4 className="login-title">به تریکسو خوش آمدید</h4>
            <h5 className="login-title mb-5">برای ادامه وارد شوید</h5>
            <div
              className="g-recaptcha"
              data-sitekey="6LfRKdIlAAAAAOzdlD2OqlHquQWlhFt389XGNsvE"
              data-size="invisible"
            ></div>
            <Form noValidate validated={validated} onSubmit={handleLogin}>
              <Form.Group md="6" controlId="emailvalidation" className="mb-3">
                <InputGroup className="input-login">
                  <Form.Control
                    type="email"
                    placeholder="ایمیل"
                    required
                    name="email"
                    className="input-login input-login-color"
                  />
                  <Form.Control.Feedback type="invalid">
                    لطفا ایمیل خود را وارد کنید.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group md="6" controlId="passwordvalidation">
                <InputGroup className="input-login">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="کلمه عبور"
                    required
                    name="password"
                    onChange={handlePasswordChange}
                    className="input-login input-login-color"
                  />

                  <IconButton
                    aria-label="show-eye"
                    onClick={togglePasswordVisibility}
                  >
                    {showIcon ? (
                      <GridVisibilityOffIcon />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>

                  <Form.Control.Feedback type="invalid">
                    لطفا رمز عبور خود را وارد کنید.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Button
                className="login-btn"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}
                {isLoading ? "بارگذاری..." : "ورود"}
              </Button>
            </Form>
          </div>
          <div className="d-flex justify-content-between">
            <div className="forgot-pass">
              <h6>
                مشکل ورود؟
                <Button
                  variant="outline"
                  onClick={handleShowForgot}
                  className="forgot-btn"
                >
                  اینجا کلیک کنید
                </Button>
              </h6>
            </div>
            <div className="forgot-pass">
              <NavLink as={Link} to="/register" className="register-btn">
                <h6 className="pt-2">برای ثبت نام اینجا کلیک کنید</h6>
              </NavLink>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                این سایت توسط reCAPTCHA و Google محافظت می شود
                <a href="https://policies.google.com/privacy">
                  {" "}
                  سیاست حفظ حریم خصوصی
                </a>{" "}
                و
                <a href="https://policies.google.com/terms">
                  {" "}
                  مقررات استفاده از خدمات
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* open reset links */}

      <Modal
        show={showForgot}
        onHide={handleCloseForgot}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            می توانید کد احراز هویت دوم یا رمز عبور خود را بازنشانی کنید
          </Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className="modal-list"></Modal.Body> */}
        <ModalFooter>
          <Button className="confirm-withdraw" onClick={setShowReset2FA}>
            کد احراز هویت دوم را بازنشانی کنید
          </Button>
          <Button className="cancel-withdraw" onClick={handleShowResetEmail}>
            رمز عبور را فراموش کرده اید؟
          </Button>
        </ModalFooter>
      </Modal>
      <div className="login-notif">

        <Modal
          show={showNotice}
          onHide={handleCloseNotice}
          className="modal-withdraw"
          backdrop="static"
          size="lg"
        >
          <Modal.Header>
            <Modal.Title>
              نکته مهم
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-list " style={{ textAlign: "justify", lineHeight: "2", height: 400, overflowY: 'scroll' }}>
            <p>پلتفرم تریکسو فقط از طریق سیستم تیکت یا با ارسال ایمیل به support@terixo.com با کاربران خود ارتباط برقرار می‌کند. در صورتی که فردی از طریق تلگرام یا هر روش دیگری خارج از سیستم تریکسو با شما تماس بگیرد، لطفاً اطلاعات حساب و اطلاعات شخصی خود را ارائه ندهید و از ارتباط با آنها خودداری کنید. همچنین، بخش پشتیبانی تریکسو هیچگاه از شما اطلاعات ورود به حسابتان مانند رمز عبور و کد ورود را از طریق ایمیل یا تیکت درخواست نمی‌کند. لطفاً در حفظ و نگهداری اطلاعات خود دقت فرمایید. هر گونه عملیات تقلبی یا خطایی که از سوی کاربر انجام شود، به عهده خود کاربر خواهد بود.</p>
            <p className="text-danger">موکدا" توصیه می‌گردد اگر شخصی از شما درخواست کرد که برایش رمز ارز خرید نمایید، چنانچه منبع پول ارسال شده از سمت وی برای شما، غیر قانونی(مانند فیشینگ، سرقت، قمار و ....) باشد، مسئولیت قانونی با شماست‌.</p>
          </Modal.Body>
          <ModalFooter>
            <Button className="confirm-withdraw" onClick={handleCloseNotice}>
              اطلاعات را خواندم و تایید می‌کنم
            </Button>
          </ModalFooter>
        </Modal>

      </div>
      {/* open form for reset password */}

      <Modal
        show={showResetEmail}
        onHide={handleCloseForgot}
        className="modal-withdraw"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            برای دریافت لینک بازنشانی رمز عبور، ایمیل خود را وارد کنید
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-list">
          <Form>
            <FormControl fullWidth>
              <TextField
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="mail"
                name="mail"
                placeholder="ایمیل شما"
                size="medium"
                required
                onChange={(event) => setMail(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                  dir: "rtl",
                }}
              />
            </FormControl>
          </Form>
        </Modal.Body>
        <ModalFooter>
          <Button
            className="confirm-withdraw"
            onClick={handleCloseResetEmail}
          >
            لغو کنید
          </Button>
          <Button
            className="cancel-withdraw"
            onClick={(event) => checkValidation(event, forgotPassword)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "ارسال لینک"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* open reset 2fa */}
      <Modal
        show={showReset2FA}
        onHide={handleCloseReset2FA}
        className="modal-withdraw"
        backdrop="static"
        dir="rtl"
      >
        <Modal.Header>
          <Modal.Title>
            برای دریافت لینک ریست کد احراز هویت دوم، ایمیل خود را وارد کنید.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-list" dir="rtl">
          <Form>
            <FormControl fullWidth>
              <TextField
                className="withdraw-texts"
                InputProps={{ sx: { height: 57 } }}
                id="mail"
                name="mail"
                placeholder="ایمیل شما"
                size="medium"
                required
                onChange={(event) => setMail(event.target.value)}
                InputLabelProps={{
                  shrink: true,
                  className: "withdraw-texts",
                  dir: "rtl",
                }}
              />
            </FormControl>
          </Form>
        </Modal.Body>
        <ModalFooter>
          <Button className="confirm-withdraw" onClick={handleCloseReset2FA}>
            لغو کنید
          </Button>
          <Button
            className="cancel-withdraw"
            onClick={(event) => checkValidation(event, reset2FA)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : null}
            {isLoading ? "بارگذاری..." : "ارسال لینک"}
          </Button>
        </ModalFooter>
      </Modal>

      {/* open login just mail  */}

      <Modal show={showMailOTP} onHide={handleCloseMailOTP} backdrop="static">
        <Modal.Header>
          <Modal.Title className="mt-2">کد دریافتی را وارد کنید</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitMailOTP}>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                placeholder="کد ایمیل"
                autoFocus
                name="mailotp"
                required
                className="input-login-codes"
              />
            </Form.Group>

            <div className="row justify-content-around text-center">
              <div className="col mb-2">
                <Button variant="secondary" onClick={handleCloseMailOTP}>
                  بستن
                </Button>
              </div>
              <div className="col">
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {isLoading ? "بارگذاری..." : "ارسال"}
                </Button>
              </div>
            </div>
          </Form>
          <div><Button variant="text" onClick={resendOTP} disabled={isLoading || apiStatus} className="btntext-otp"  >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"

              />
            ) : null}
            {isLoading ? "بارگذاری..." : apiStatus ? ` کد را دریافت نکردید؟ اینجا کلیک کنید. (${timer} ثانیه)` : "کد را دریافت نکردید؟ اینجا کلیک کنید."}
          </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* open login 2fa and mail */}

      <Modal
        show={showGOTP}
        onHide={handleCloseGOTP}
        backdrop="static"
        dir="rtl"
      >
        <Modal.Header>
          <Modal.Title>کد دریافتی را وارد کنید</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmitWith2FA}>
            <Form.Group className="mb-3">
              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  placeholder="کد ایمیل"
                  name="mailotp"
                  required
                  autoFocus
                  className="input-login-codes"
                />
              </Form.Group>
              <Form.Control
                type="number"
                placeholder="کد احراز هویت دوم"
                name="gotp"
                required
                className="input-login-codes"
              />
            </Form.Group>
            <div className="row justify-content-around text-center">
              <div className="col mb-2">
                <Button variant="secondary" onClick={handleCloseGOTP}>
                  بستن
                </Button>
              </div>
              <div className="col">
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : null}
                  {isLoading ? "بارگذاری..." : "ارسال"}
                </Button>

              </div>
            </div>
          </Form>
          <div><Button variant="text" onClick={resendOTP} disabled={isLoading || apiStatus} className="btntext-otp"  >
            {isLoading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"

              />
            ) : null}
            {isLoading ? "بارگذاری..." : apiStatus ? ` کد را دریافت نکردید؟ اینجا کلیک کنید. (${timer} ثانیه)` : "کد را دریافت نکردید؟ اینجا کلیک کنید."}
          </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* disable 2fa modal */}

      <Modal
        show={showModalDisableTfa}
        onHide={handleCloseModalDisableTfa}
        className="modal-withdraw m-2"
        size="xl"
      >
        <Modal.Header>
          <Modal.Title>احراز هویت گوگل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>احراز هویت گوگل شما برای حساب شما غیرفعال شده است. لطفاً درک کنید که فعال کردن آن برای امنیت شما بسیار مهم است و برای برداشت ارز دیجیتال یا پول به آن نیاز دارید.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleCloseModalDisableTfa}>
            متوجه هستم
          </Button>
        </Modal.Footer>
      </Modal>

      <Stack spacing={2} sx={{ maxWidth: 300 }}>
        <Snackbar
          open={showSnack}
          autoHideDuration={5000}
          onClose={handleCloseSnack}
        >
          <Alert
            severity='error'
            sx={{ fontSize: 18, maxWidth: 400 }}
          >
            درخواست نامعتبر برای غیرفعال کردن احراز هویت عامل دوم
          </Alert>
        </Snackbar>
      </Stack>

    </div>
  ) : (
    navigate("/")
  );
}

