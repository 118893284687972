import React, { useContext } from "react";
import OrderHistoryTitle from "./OrderHistoryTitleD";
import EachOrderHistory from "./EachOrderHistoryD";
import SimpleBar from "simplebar-react";
import { SocketContext } from "../../../../contexts/providers/socket";

function OrderHistory() {
  const { stateIO } = React.useContext(SocketContext);
  return (
    <div className="orderhistory-mainA">
      <div className="row ">
        <div className="col">
          <OrderHistoryTitle />
          <SimpleBar style={{ maxHeight: 263 }} autoHide={false}>
            {stateIO.histories.map(function (item, index) {
              return (
                <EachOrderHistory key={`order_history_${index}`} info={item} />
              );
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory;
