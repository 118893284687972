import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Video from "../../assets/videos/dots.mp4";
import { useNavigate } from "react-router-dom";
import MissleAnim from "../../assets/json/missle-final.json";
import { Controls, Player } from "@lottiefiles/react-lottie-player";
import Arrow from "../../assets/images/arrow-icon.svg";
import Home3StepsTopSecond from "./Home3StepsTopSecond";
import { isLoggedIn, setLogOut } from "../../utils/RSS";

function HomeTop() {
  const navigate = useNavigate();
  const goToLog = () => {
    navigate("/login");
  };
  const images = ['https://s3.terixo.com/public/home-slide/airw.jpg', 'https://s3.terixo.com/public/home-slide/porw.jpg', 'https://s3.terixo.com/public/home-slide/usdtw.jpg'];
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="container-fluid home-top-section  align-content-center">
      <div className="row home-top-a justify-content-around">
        <video className="top-video" autoPlay loop muted>
          <source src={Video} type="video/mp4" />
        </video>
        <div className="col-11 col-md-6 h-top-right">
          <h1>تریکسو پلتفرمی برای هر ایرانی</h1>
          <h2 className="mt-5">اولین درگاه اختصاصی خرید و فروش آنی <span className="tethercolor">تتر</span></h2>
          <h3 className="h3title">پلتفورم ارزهای دیجیتال تریکسو</h3>
          {isLoggedIn() ? "" :
            <Button className="h-top-btn" onClick={goToLog}>
              ورود/ثبت نام
            </Button>
          }
        </div>
        <div className="col-11 col-md-4 h-top-left">
          <Player
            autoplay
            loop
            src={MissleAnim}
            style={{ maxHeight: "600px", maxWidth: "400px" }}
          >
            <Controls
              visible={false}
              buttons={["play", "repeat", "frame", "debug"]}
            />
          </Player>
        </div>
      </div>
      <div className="blue-div">
        <div className="home3-second">

          <Home3StepsTopSecond />
        </div>
        <div className="right-side">
          <div className="container-fluid top3Steps">
            <div className="boxes-top">
              <div className="top-3-box">
                <div className="top-3-main">
                  <div className="top3-text">
                    <h6>ثبت نام</h6>
                    <p>
                      با آدرس ایمیل خود ثبت نام نمایید و دارای کیف پول دیجیتال شوید.
                    </p>
                  </div>
                </div>
              </div>
              <div className="arrow-container">
                <img src={Arrow} alt="arrow" width={40} />
              </div>
            </div>
            <div className="boxes-top">
              <div className="top-3-box">
                <div className="top-3-main">
                  <div className="top3-text">
                    <h6>واریز</h6>
                    <p>
                      ظرف ۵ دقیقه احراز هویت نمایید سپس می توانید تومان یا رمز ارز را
                      به کیف خود در تریکسو واریز کنید.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="arrow-container">
                <img src={Arrow} alt="arrow" width={40} />
              </div>
            </div>
            <div className="boxes-top">
              <div className="top-3-box">
                <div className="top-3-main">
                  <div className="top3-text">
                    <h6> شروع معاملات</h6>
                    <p>اکنون شما می توانید به آسانی معاملات معتبرترين رمزارزهای دنیا را در تریکسو بصورت آنی انجام دهید.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="left-side">
          <div className="carousel">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className={index === currentImage ? 'active' : ''}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default HomeTop;
