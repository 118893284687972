import * as React from "react";
import Table from "react-bootstrap/Table";
import { createApiAddress } from "../../constant/api.constant";
import { ItemNames, getStorage } from "../../utils/RSS";
import { useEffect } from "react";

export default function UserPoints() {
  const [prizes, setPrizes] = React.useState({
    REGISTERATION: 0,
    REGISTRATION: 0,
    USER_REFER: 0,
    USER_TRADE: 0,
    FOLLOW_SOCIAL: 0,
  });
  const total =
    prizes.REGISTERATION +
    prizes.REGISTRATION +
    prizes.USER_REFER +
    prizes.USER_TRADE +
    prizes.FOLLOW_SOCIAL;

  useEffect(() => {
    fetch(createApiAddress("api.user_points"), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let update = {}
        for (let item of data.points_list) {
          update[item.name] = item.total;
        }
        setPrizes({
          ...prizes,
          ...update
        })
      });
  }, []);

  return (
    <div className="container mt-5 me-5">
      <div className="row">
        <div className="col-8">
          <Table striped="columns">
            <thead>
              <tr>
                <th> </th>
                <th className="point-table">مورد</th>
                <th className="point-table">امتیاز</th>
              </tr>
            </thead>
            <tbody className="point-table">
              <tr>
                <td className="point-table">1</td>
                <td className="point-table">از ثبت نام</td>
                <td className="point-table">{prizes.REGISTERATION || prizes.REGISTRATION}</td>
              </tr>
              <tr>
                <td className="point-table">2</td>
                <td className="point-table">از معامله</td>
                <td className="point-table">{prizes.USER_TRADE}</td>
              </tr>
              <tr>
                <td className="point-table">3</td>
                <td className="point-table">از ارجاع</td>
                <td className="point-table">{prizes.USER_REFER}</td>
              </tr>
              <tr>
                <td className="point-table">4</td>
                <td className="point-table">از دنبال کردن شبکه اجتماعی</td>
                <td className="point-table">{prizes.FOLLOW_SOCIAL}</td>
              </tr>
              <tr>
                <td className="point-table"> </td>
                <th className="point-table">مجموع</th>
                <th className="point-table">{total}</th>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}
