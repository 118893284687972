export const ItemNames = {
  TTOKEN: "ttoken",
  TOKEN: "token",
  USERNAME: "username",
  EMAILFORMAT: "emailformat",
  AIRDROP: "airdrop",
  EMAILSTATUS: "emailstatus",
  TFASTATUS: "tfastatus",
  KYCSTATUS: "kycstatus",
  REFCOUNT: "refcount",
  UID: "uid",
  TRADEPWDSTATUS: "tradepwdstatus",
  REFNUMBER: "refer_id",
  SECURITYLVL: "securitylvl",
  THEMES: "theme_selected",
  FAV_PAIR: "fav_pair",
  CURRENCIES: "currency:details",
  NUMBERS: "number_selected",
};

export function getStorage(name) {
  return localStorage.getItem(name);
}

export function setStorage(name, value) {
  return localStorage.setItem(name, value);
}

export function isLoggedIn() {
  if (getStorage(ItemNames.TOKEN) === null) {
    return false;
  } else {
    return true;
  }
}


export function setLogOut() {
  localStorage.removeItem('ttoken')
  localStorage.removeItem('token')
  localStorage.removeItem('kycstatus')
  localStorage.removeItem('uid')
  localStorage.removeItem('emailformat')
  localStorage.removeItem('username')
  return 'success';
}
