import React, { useContext, useState } from "react";
import Card from "react-bootstrap/Card";
import { Alert, Button, IconButton, Snackbar, Switch } from "@mui/material";
import "./profile.scss";
import { UserContext } from "../../contexts/providers/user";
import { ItemNames, getStorage, isLoggedIn } from "../../utils/RSS";
import { Form, Modal, Spinner } from "react-bootstrap";
import { createApiAddress } from "../../constant/api.constant";
import { GridVisibilityOffIcon } from "@mui/x-data-grid";
import { VisibilityOutlined } from "@mui/icons-material";
import QRCode from "react-qr-code";

function PSecurity({ profile }) {
  const [modal2fa, setModal2fa] = useState({
    active_show: false,
    tfa_status: 0,
    tfa_url: "",
  });

  const [password, setPassword] = useState("");
  const [tradingPassword, setTradingPassword] = useState({
    active_show: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [errorMsgPWD1, setErrorMsgPWD1] = useState('')
  const [errorMsgPWD2, setErrorMsgPWD2] = useState('')
  const [showPWDChanger, setShowPWDChanger] = useState(false);
  const handleClosePWDChanger = () => {
    // if()
    setError('')
    setShowPassword(false);
    setShowIcon(false);
    setShowPWDChanger(false)
  };
  const handleShowPWDChanger = () => setShowPWDChanger(true);
  const [showTradeChanger, setShowTradeChanger] = useState(false);
  const handleCloseTradeChanger = () => {

    setShowPassword(false);
    setShowIcon(false);
    setShowTradeChanger(false)
  };
  const handleShowTradeChanger = () => setShowTradeChanger(true);
  const { state, dispatch } = useContext(UserContext);
  const [validated, setValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowIcon(!showIcon);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false)
  }

  const handleOpenSnack = () => {
    setOpenSnack(true)
  }



  const [openSnack2, setOpenSnack2] = useState(false);

  const handleCloseSnack2 = () => {
    setOpenSnack2(false)
  }

  const handleOpenSnack2 = () => {
    setOpenSnack2(true)
  }


  //security functions
  const LoadUserProfile = () => {
    if (isLoggedIn() === true) {
      fetch(createApiAddress("api.user_info"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({ type: "set:profile", profile: data.user });
          } else {
            dispatch({ type: "Logout" });
          }
        })
        .catch((error) => dispatch({ type: "Logout" }));
    }
  };

  const passwordChanger = async (event) => {
    event.preventDefault();
    if (isError === null) {
      setIsLoading(true);
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        event.stopPropagation();
        const pwdChanger = new FormData(event.currentTarget);
        try {
          let res = await fetch(
            createApiAddress("api.PASS_CHANGE"),
            {
              method: "POST",
              body: JSON.stringify({
                current_pwd: pwdChanger.get("currentpass"),
                confirm_pwd: pwdChanger.get("confirmpass"),
                new_pwd: pwdChanger.get("newpass"),
              }),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
              },
            }
          );

          let resJson = await res.json();
          if (resJson.status === 1) {
            setErrorMsgPWD1('رمز عبور با موفقیت تغییر پیدا کرد.')
            handleClosePWDChanger()
            handleOpenSnack()
            setIsLoading(false);
            event.target.reset();
            setValidated(false);
            setShowPassword(false);
            setShowIcon(false);
          } else {
            setErrorMsgPWD2(resJson.locale.fa)
            // console.log(resJson.locale.fa)
            setIsLoading(false);
            handleOpenSnack2();
          }
        } catch (err) {
          event.preventDefault();
          // console.log(err);
          setIsLoading(false);
        }
      }
    }
  };

  const tradePasswordChanger = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    try {
      let res = await fetch(
        createApiAddress("api.TRADEPASS_FORGOT"),
        {
          method: "POST",
          headers: {
            // "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();
      if (resJson.status === 1) {
        setTradingPassword({ ...tradingPassword, active_show: true });
        setValidated(false);
        setShowPassword(false);
        setShowIcon(false);
        setIsLoading(false)
      } else {
        event.preventDefault();
        dispatch({
          snackColor: "warning",
          snackMessage: resJson.locale.fa,
          type: "open_snack",
        });
      }
    } catch (err) {
      event.preventDefault();
    }
  };

  const GoogleActive2fa = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    fetch(createApiAddress("api.GOOGLE_ACTION"), {
      method: "POST",
      body: JSON.stringify({
        tfa_code: form.get("tfa_code"),
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    })
      .then((data) => data.json())
      .then((data) => {
        if (data.status === 1) {
          setModal2fa({
            ...modal2fa,
            active_show: false,
          });
          fetch(
            createApiAddress("api.user_info"), {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === 1) {
                LoadUserProfile()
                dispatch({ type: "set:profile", profile: data.user });
              } else {
                dispatch({ type: "Logout" });
              }
            })
          setIsLoading(false);
          setShowPassword(false);
          setShowIcon(false);
        } else {

          dispatch({
            snackColor: "warning",
            snackMessage: data.locale.fa,
            type: "open_snack",
          });
          setIsLoading(false);
        }
      });
  };
  const Google2faAction = () => {
    setIsLoading(true);
    if (
      profile.security.tfa.status === false &&
      (profile.security.tfa.url === undefined || profile.security.tfa.url === "")
    ) {
      fetch(createApiAddress("api.GOOGLE_ACTION"), {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((data) => data.json())
        .then((data) => {
          setIsLoading(false);
          LoadUserProfile()
          setModal2fa({
            ...modal2fa,
            active_show: true,
            tfa_url: data.data['security.tfa.url'].split('chl=')[1],
          });
        });
    } else if (profile.security.tfa.status === false && profile.security.tfa.url !== undefined) {
      setIsLoading(false);
      setShowPassword(false);
      setShowIcon(false);
      setModal2fa({
        ...modal2fa,
        active_show: true,
        tfa_url: profile.security.tfa.url.split('chl=')[1],
      });

    } else {
      setIsLoading(false);
      setModal2fa({
        ...modal2fa,
        active_show: true,
      });
    }
  };
  const changeTradingPassword = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const form = new FormData(event.currentTarget);
    try {
      let res = await fetch(
        createApiAddress("api.TRADEPASS_CHANGE"),
        {
          method: "POST",
          body: JSON.stringify({
            password: form.get("password"),
            confirm_password: form.get("confirm_password"),
            forgot_code: form.get("forgot_code"),
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
          },
        }
      );
      let resJson = await res.json();

      if (resJson.status === 1) {
        dispatch({
          snackColor: "success",
          snackMessage: 'رمز عبور معاملاتی با موفقیت تغییر پیدا کرد.',
          type: "open_snack",
        });
        LoadUserProfile()
        handleCloseTradeChanger();
        setTradingPassword({ ...tradingPassword, active_show: false });
        setIsLoading(false);
        setValidated(false);
        setShowPassword(false);
        setShowIcon(false);
      } else {
        event.preventDefault();
        setShowPWDChanger(true);
        setShowPassword(false);
        setShowIcon(false);
        dispatch({
          snackColor: "warning",
          snackMessage: 'خطایی رخ داد.',
          type: "open_snack",
        });
        setIsLoading(false);
      }
    } catch (err) {
      event.preventDefault();
      // console.log(err);
      setIsLoading(false);
    }
  };

  const handleShow2FAModal = () => {
    setModal2fa({ ...modal2fa, active_show: false })
    setShowPassword(false);
    setShowIcon(false);
  }

  // password strong check

  const [pwdInput, initValue] = useState({
    password: "",
  });

  const [isError, setError] = useState(null);

  const onChange = (e) => {
    let password = e.target.value;
    setPassword(password)
    initValue({
      ...pwdInput,
      password: e.target.value,
    });
    setError(null);
    let caps, small, num, specialSymbol;
    if (password.length < 8) {
      setError(
        "رمز عبور باید حداقل شامل 8 کاراکتر باشد"
      );
      return;
    } else {
      caps = (password.match(/[A-Z]/g) || []).length;
      small = (password.match(/[a-z]/g) || []).length;
      num = (password.match(/[0-9]/g) || []).length;
      specialSymbol = (password.match(/[^\w\s]/g) || []).length;

      if (caps < 1) {
        setError("باید یک حرف بزرگ اضافه کنید");
        return;
      } else if (small < 1) {
        setError("باید یک حرف کوچک اضافه کنید");
        return;
      } else if (num < 1) {
        setError("شما باید یک عدد اضافه کنید");
        return;
      } else if (specialSymbol < 1) {
        setError("باید یک نماد خاص اضافه کنید: @ $ ! % * ؟ & #");
        return;
      }
    }
  };
  const [isStrong, initRobustPassword] = useState(null);
  const initPwdInput = async (childData) => {
    initRobustPassword(childData);
  };
  const initialWithdrawSwitchStatus = profile?.settings?.sms?.withdraw;
  const initialEmailSwitchStatus = profile?.settings?.email?.withdraw;
  const [isWithdrawSwitchOn, setisWithdrawSwitchOn] = useState(initialWithdrawSwitchStatus);
  const [isEmailSwitchOn, setisEmailSwitchOn] = useState(initialEmailSwitchStatus);

  const handleWithdrawSwitchChange = async () => {
    let form = {
      "sms": {
        "withdraw": !isWithdrawSwitchOn
      }
    }

    let res = await fetch(createApiAddress("api.SMS_STATUS_FOR_WITHDRAW"), {
      method: "PATCH",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    });
    let result = await res.json()
    if (result.status === 1) {
      setisWithdrawSwitchOn(!isWithdrawSwitchOn);
      LoadUserProfile()
    } else {
      setisWithdrawSwitchOn(initialWithdrawSwitchStatus)
    }
  };

  const handleEmailSwitchChange = async () => {
    let form = {
      "email": {
        "withdraw": !isEmailSwitchOn
      }
    }

    let res = await fetch(createApiAddress("api.SMS_STATUS_FOR_WITHDRAW"), {
      method: "PATCH",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
      },
    });
    let result = await res.json()
    if (result.status === 1) {
      setisEmailSwitchOn(!isEmailSwitchOn);
      LoadUserProfile()
    } else {
      setisEmailSwitchOn(initialEmailSwitchStatus)
    }
  };

  return (
    <div className="security-main  d-flex flex-wrap">
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "18.1rem" }}>
          <Card.Body className="user-info">
            <Card.Title>رمز عبور حساب کاربری</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              در اینجا می توانید رمز عبور خود را تغییر دهید.
            </Card.Subtitle>
            <Card.Text>
              <Button variant="contained" onClick={handleShowPWDChanger}>
                رمز عبور را تغییر دهید
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "18.1rem" }}>
          <Card.Body className="user-info">
            <Card.Title>احراز هویت فاکتور دوم</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              در اینجا می توانید 2FA خود را فعال/غیرفعال کنید.
            </Card.Subtitle>
            <Card.Text>
              <Button
                variant="contained"
                onClick={Google2faAction}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : null}

                {isLoading
                  ? "ارسال"
                  : `${profile.security.tfa.status === false
                    ? "2FA را فعال کنید"
                    : "غیر فعال کردن"
                  }`}
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "18.1rem" }}>
          <Card.Body className="user-info">
            <Card.Title>رمز معاملاتی</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              در اینجا می توانید رمز معاملاتی خود را تغییر دهید.
            </Card.Subtitle>
            <Card.Text>
              <Button variant="contained" onClick={handleShowTradeChanger}>
                برای فعال کردن و یا تغییر رمز معاملاتی خود کلیک کنید
              </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div className="col m-2">
        <Card style={{ width: "18rem", height: "18.1rem" }}>
          <Card.Body className="user-info">
            <Card.Title>تنظیمات ارسال کد برای برداشت</Card.Title>
            <Card.Subtitle className="mb-4 text-muted">
              <p style={{ textAlign: 'justify' }}>در این قسمت می توانید وضعیت ارسال کد برای برداشت تومان و رمز ارز را تنظیم کنید.</p>
            </Card.Subtitle>
            <Card.Text>
              <p>

                <label> ارسال اس ام اس : {isWithdrawSwitchOn ? 'فعال می باشد' : 'غیر فعال می باشد'}</label>
              </p>
              <Switch
                checked={isWithdrawSwitchOn}
                onChange={handleWithdrawSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <p>

                <label>  ارسال ایمیل : {isEmailSwitchOn ? 'فعال می باشد' : 'غیر فعال می باشد'}</label>
              </p>
              <Switch
                checked={isEmailSwitchOn}
                onChange={handleEmailSwitchChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Card.Text>
          </Card.Body>
        </Card>
      </div>
      <div>
        <Modal
          show={modal2fa.active_show}
          onHide={handleShow2FAModal}
          data-backdrop={"static"}
          data-toggle={"modal"}
          backdrop="static"
        >
          <Modal.Header  >
            <Modal.Title>
              {modal2fa.tfa_url !== ""
                ? "فعال کردن 2FA"
                : "2FA را غیر فعال کنید"}
              <IconButton
                aria-label="show-eye"
                onClick={togglePasswordVisibility}
              >
                {showIcon ? (
                  <GridVisibilityOffIcon />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={GoogleActive2fa} autoComplete="off">
            <Modal.Body className="modal-pwd">
              {console.log(modal2fa.tfa_url)}
              {modal2fa.tfa_url !== "" && (
                <div className="row justify-content-center">
                  <div className="col-12 text-center mb-4">
                    <QRCode value={modal2fa.tfa_url} className="qrcode-frame" />
                  </div>
                </div>

              )}
              <Form.Group className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="کد احراز هویت دو عاملی"
                  autoFocus
                  name="tfa_code"
                  required
                  className="input-login-codes"
                />
              </Form.Group>
              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button
                    variant="secondary"
                    onClick={handleShow2FAModal}
                    className="btn-security"
                  >
                    بستن
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-security"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "ثبت"}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          show={showPWDChanger}
          onHide={handleClosePWDChanger}
          data-backdrop={"static"}
          data-toggle={"modal"}
          backdrop="static"
        >
          <Modal.Header  >
            <Modal.Title>رمز عبور خود را تغییر دهید
              <IconButton
                aria-label="show-eye"
                onClick={togglePasswordVisibility}
              >
                {showIcon ? (
                  <GridVisibilityOffIcon />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={passwordChanger} autoComplete="new-password">
            <Modal.Body className="modal-pwd-changer">
              <Form.Group className="mb-3 modal-pwd-changer">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="رمز عبور فعلی"
                  autoFocus
                  name="currentpass"
                  required
                  className="modal-pwd-changer input-login-codes"
                />

              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="رمز عبور جدید"
                  name="confirmpass"
                  required
                  className="modal-pwd-changer input-login-codes"
                  onChange={onChange}


                />
              </Form.Group>
              {isError !== null
                ? <p className="errors-register">{isError}</p>
                :
                <p className="errors-register">{password !== '' ? "رمز عبور قوی است." : "یک رمز عبور قوی برای امنیت خود وارد کنید."}</p>
              }

              <Form.Group className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  placeholder="رمز عبور جدید را تأیید کنید"
                  name="newpass"
                  required
                  className="modal-pwd-changer input-login-codes"

                />
              </Form.Group>
              <div className="row justify-content-around text-center">
                <div className="col">
                  <Button
                    variant="secondary"
                    onClick={handleClosePWDChanger}
                    className="btn-security"
                  >
                    بستن
                  </Button>
                </div>
                <div className="col">
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-security"
                  >
                    {isLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : null}
                    {isLoading ? "بارگذاری..." : "ارسال"}
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Form>
        </Modal>
        <Modal
          show={showTradeChanger}
          onHide={handleCloseTradeChanger}
          data-backdrop={"static"}
          data-toggle={"modal"}
          backdrop="static"
        >
          <Modal.Header>
            <Modal.Title>رمز عبور معاملاتی خود را تغییر و یا فعال نمایید.
              <IconButton
                aria-label="show-eye"
                onClick={togglePasswordVisibility}
              >
                {showIcon ? (
                  <GridVisibilityOffIcon />
                ) : (
                  <VisibilityOutlined />
                )}
              </IconButton>
            </Modal.Title>
          </Modal.Header>
          <Form onSubmit={changeTradingPassword} autoComplete="new-password">
            <Modal.Body className="modal-pwd">
              {tradingPassword.active_show === false ? (
                <>
                  <h6 className="pb-4">
                    در صورت نیاز بر روی دکمه ارسال کلیک کنید تا کد رمز برای شما ارسال شود.
                  </h6>
                  <div className="row justify-content-around text-center">
                    <div className="col">
                      <Button
                        variant="secondary"
                        onClick={handleCloseTradeChanger}
                        className="btn-security"
                      >
                        بستن
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        className="btn-security"
                        onClick={tradePasswordChanger}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? "بارگذاری..." : "ارسال"}
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <Form.Group className="mb-3">
                    <h6>(رمز فقط می تواند عدد باشد.)</h6>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="کد ارسال شده به ایمیل"
                        autoFocus
                        name="forgot_code"
                        required
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        className="input-login-codes"
                      />
                    </Form.Group>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="تعیین رمز معاملاتی 6 رقمی"
                      name="password"
                      required
                      maxLength={6}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');

                      }}
                      className="input-login-codes"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="تایید رمز معاملاتی 6 رقمی"
                      name="confirm_password"
                      required
                      maxLength={6}
                      onInput={(e) => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      className="input-login-codes"
                    />
                  </Form.Group>
                  <div className="row justify-content-around text-center">
                    <div className="col">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          handleCloseTradeChanger();
                          setTradingPassword({
                            ...tradingPassword,
                            active_show: false,
                          });
                        }}
                        className="btn-security"
                      >
                        بستن
                      </Button>
                    </div>
                    <div className="col">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-security"
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : null}
                        {isLoading ? "بارگذاری..." : "تغییر دادن"}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
          </Form>
        </Modal>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
          <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }} closeText="none">
            {errorMsgPWD1}
          </Alert>
        </Snackbar>
        <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
          <Alert onClose={handleCloseSnack2} severity="warning" sx={{ width: '100%' }}>
            {errorMsgPWD2}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default PSecurity;
