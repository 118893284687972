import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SimpleBar from "simplebar-react";
import PairTitle from "./PairTitleB";
import EachPair from "./EachPairB";
import { Star } from "@mui/icons-material";
import { SocketContext } from "../../../../contexts/providers/socket";
import { UserContext } from "../../../../contexts/providers/user";
import { Input } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PairListTrade() {
  const [value, setValue] = useState(0);
  const { stateIO } = React.useContext(SocketContext);
  const { state } = React.useContext(UserContext);
  const [searchQuery, setSearchQuery] = useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredPairs = stateIO.pairs.map((item) => {
    const filteredPairs = item.pairs.filter((pair) =>
      pair.trading_pairs.toUpperCase().includes(searchQuery.toUpperCase())
    );
    return { ...item, pairs: filteredPairs.sort((a, b) => a.trading_pairs.localeCompare(b.trading_pairs)) };
  });

  return (
    <div className="pairlist-mainA">
      <Box>
        <Box sx={{ borderBottom: 0, borderColor: "divider", width: "100%" }}>
          {/* Add a search input field */}
          <Input
            type="text"
            placeholder="جستجو..."
            className="searchbar"
            value={searchQuery}
            onChange={(e) => {
              const inputValue = e.target.value.toUpperCase();
              setSearchQuery(inputValue);
            }}
          />

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {stateIO.pairs.sort().map((item, index) => {
              return (
                <Tab
                  label={<Typography>تتر USDT</Typography>}
                  {...a11yProps(index)}
                />
              );
            })}
            <Tab
              label={
                <Typography>
                  برگزیده ها
                  <Star className="star-icon" />
                </Typography>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <PairTitle />
        <TabPanel value={value} index={1} className="fav-pair-list">
          <SimpleBar style={{ maxHeight: 180 }} autoHide={false}>
            {stateIO.pairs.sort().map((item, index) => {
              return (
                <>
                  {item.pairs
                    .filter((pair) => {
                      return state.pair_fav.includes(pair.trading_pairs);
                    })
                    .sort((a, b) => a.trading_pairs.localeCompare(b.trading_pairs))
                    .map((pair) => {
                      return <EachPair pair={pair} />;
                    })}
                </>
              );
            })}
          </SimpleBar>
        </TabPanel>
        {filteredPairs.map((item, index) => (
          <TabPanel value={value} index={index} className="pair-list" key={item.label}>
            <SimpleBar style={{ maxHeight: 180 }} autoHide={false}>
              {item.pairs.map((pair) => (
                <EachPair pair={pair} key={pair.trading_pairs} />
              ))}
            </SimpleBar>
          </TabPanel>
        ))}
      </Box>
    </div>
  );
}
