import React, { useEffect, useState } from 'react'
import './assets.scss'

function VipWithEach(props) {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const imageData = await props.image;
                setImageSrc(imageData);
            } catch (error) {
                console.error("Error fetching image:", error);
            }
        };

        fetchImage();


        return () => {
            setImageSrc(null);
        };
    }, [props.image]);
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7 col-xl-6 col-12 m-0">
                    <div className='deposit-history-main-eachsite'>
                        <div className="container-fluid mb-3">
                            <div className="row">
                                <div className="col-2">
                                    {imageSrc !== null ? <img src={imageSrc} alt="token logo" height={25} style={{ marginBottom: -60 }} /> : <></>}
                                </div>
                                <div className="col-12 text-center mt-2 success-text">
                                    <h5 style={{ direction: "ltr" }}> {props.amount}{" "}{props.symbol}</h5>
                                </div>
                                <div className="col-12 text-center mt-2" style={{ marginTop: -15, color: "#00b8f9" }}>
                                    <h6>  {props.status}</h6>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <h6>تاریخ و زمان</h6>
                                </div>
                                <div className="col-6 text-start">
                                    <h6>{props.time}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h6> شناسه تراکنش</h6>
                                </div>
                                <div className="col-12 text-center">
                                    <h6 className='txid-each-mobile'><span className="span-txid-deposit">{props.txid}</span></h6>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default VipWithEach
