import "./App.scss";
import { BrowserRouter } from "react-router-dom";
import Menubar from "./components/navbar/Navbar";
import React from "react";
import Footer from "./components/footer/Footer";
import { UserProvider } from "./contexts/providers/user";
import { RoutesMap } from "./route";
import { OrderProvider } from "./contexts/ClickedOrders";
import { SocketProvider } from "./contexts/providers/socket";

function App() {
  return (
    <UserProvider>
      <SocketProvider>
        <OrderProvider>
          <div className="main-app">
            <BrowserRouter>
              <nav className="navbar-app">
                <Menubar />
              </nav>
              <RoutesMap />
              <footer>
                <Footer />
              </footer>
            </BrowserRouter>
          </div>
        </OrderProvider>
      </SocketProvider>
    </UserProvider>

  );
}

export default App;
