import React, { useContext } from "react";
import OrderHistoryTitle from "./OrderHistoryTitle";
import EachOrderHistory from "./EachOrderHistory";
import SimpleBar from "simplebar-react";
import { UserContext } from "../../../contexts/providers/user";
import { SocketContext } from "../../../contexts/providers/socket";

function OrderHistory0() {
  const { stateIO } = React.useContext(SocketContext);

  return (
    <div className="orderhistory-mainA">
      <div className="row">
        <p className="me-4">آخرین معاملات</p>
        <div className="">
          <OrderHistoryTitle />
        </div>
        <div className="">
          <SimpleBar style={{ maxHeight: 185 }} autoHide={false}>
            {stateIO.histories.map(function (item, index) {
              return (
                <EachOrderHistory key={`order_history_${index}`} info={item} />
              );
            })}
          </SimpleBar>
        </div>
      </div>
    </div>
  );
}

export default OrderHistory0;
