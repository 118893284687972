import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import BedtimeIcon from "@mui/icons-material/Bedtime";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { ItemNames, getStorage, setStorage } from "./RSS";

const ThemeContext = React.createContext();
export default function SelectTheme() {
  const [selectedTheme, setSelectedTheme] = useState("");

  useEffect(() => {
    const storedTheme = getStorage(ItemNames.THEMES);
    if (storedTheme) {
      document.body.setAttribute("data-theme", storedTheme);
      setSelectedTheme(storedTheme);
    }
  }, []);

  useEffect(() => {
    const storedTheme = getStorage(ItemNames.THEMES);
    if (storedTheme !== 'dark-theme') {
      setSelectedTheme("light-theme")
    }
  }, [])




  const toggleTheme = () => {
    const newTheme =
      selectedTheme === "light-theme" ? "dark-theme" : "light-theme";
    document.body.setAttribute("data-theme", newTheme);
    setSelectedTheme(newTheme);
    setStorage(ItemNames.THEMES, newTheme);
    document.dispatchEvent(new CustomEvent("themeChanged", { detail: newTheme }));
  };



  return (
    <Box
      noValidate
      component="form"
      className="theme-selector-pop"
      sx={{
        flexDirection: "column",
        m: "auto",
        width: "fit-content",
      }}
    >
      <IconButton
        size="medium"
        color="info"
        aria-label="Toggle Theme"
        onClick={toggleTheme}
        sx={{ marginRight: "10px", marginTop: "5px" }}
      >
        {selectedTheme === "light-theme" ? (
          <BedtimeIcon className="theme-icon" />
        ) : (
          <WbSunnyIcon className="theme-icon" />
        )}
      </IconButton>
    </Box>
  );
}
