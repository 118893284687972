import "../home/home.scss";
import React from "react";
import Marketinhome from "./Marketinhome";
import HomeTop from "./HomeTop";
import FirstThreeMiddle from "./FirstThreeMiddle";
import PhoneApp from "./PhoneApp";
import HomeSocial from "./HomeSocial";
import HomeQuestion from "./HomeQuestion";
import { Button } from "react-bootstrap";
import ImageTopR from "../../assets/images/top-r.svg";
import ImageTopL from "../../assets/images/top-l.svg";
import ImageBotR from "../../assets/images/bot-r.svg";
import ImageBotL from "../../assets/images/bot-l.svg";
import { Link, NavLink } from "react-router-dom";
import MarketInHomeMobile from "./MarketinhomeMobile";
import HomeMobile from "./HomeMobile";

function Home() {

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);
  if (isMobileDevice === true) {
    return <>
      <HomeMobile />
    </>
  }

  return (
    <div className="home-page">
      <div className="home-container">
        <div className="home-body container-fluid">
          <HomeTop />
        </div>
        <div className="market-home-page container-fluid   justify-content-center">
          <div className="row market-home-table ">
            <div className="col marketinhome-visibility">
              <Marketinhome />
              <div className="text-center mt-3">
                <NavLink
                  as={Link}
                  to={`/market`}
                  className="nav-link"
                >
                  <Button className="market-table-btn" >ادامه</Button>
                </NavLink>

              </div>
            </div>
            <div className="col marketinhome-visibility-second">
              <MarketInHomeMobile />
              <div className="text-center mt-3">
                <NavLink
                  as={Link}
                  to={`/market`}
                  className="nav-link"
                >
                  <Button className="market-table-btn" >ادامه</Button>
                </NavLink>

              </div>
            </div>
          </div>
        </div>
        <FirstThreeMiddle />
        <PhoneApp />
        <div className="container-fluid home-6-steps-main justify-content-center">
          <div className="row image-section-main">
            <div className="col-10 col-md-5 image-4sections">
              <img src={ImageTopR} alt="" className="img-fluid" width={370} />
            </div>
            <div className="col-10 col-md-5 image-4sections">
              <img src={ImageTopL} alt="" className="img-fluid" width={370} />
            </div>
          </div>
          <div className="row image-section-main pb-5">
            <div className="col-10 col-md-5 image-4sections">
              <img src={ImageBotR} alt="" className="img-fluid" width={370} />
            </div>
            <div className="col-10 col-md-5 image-4sections">
              <img src={ImageBotL} alt="" className="img-fluid" width={370} />
            </div>
          </div>
        </div>
        <HomeSocial />
        <div className="container-fluid home-q">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col">
                <HomeQuestion />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
