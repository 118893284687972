import * as React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SimpleBar from "simplebar-react";
import PairTitle from "./PairTitleE";
import EachPair from "./EachPairE";
import { UserContext } from "../../../../contexts/providers/user";
import { Star } from "@mui/icons-material";
import { SocketContext } from "../../../../contexts/providers/socket";
import { Input } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function PairListTrade() {
  const [value, setValue] = useState(0);
  const { stateIO } = React.useContext(SocketContext);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const filteredPairs = [...stateIO.pairs].sort((a, b) => a.trading_pairs.localeCompare(b.trading_pairs));


  return (
    <div className="pairlist-mobile-main">
      <Box>
        <Box sx={{ borderBottom: 0, borderColor: "divider", width: "100%" }}>

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {stateIO.pairs.sort().map((item, index) => {
              return (
                <Tab
                  label={<Typography>تتر USDT</Typography>}
                  {...a11yProps(index)}
                />
              );
            })}
          </Tabs>
        </Box>
        <PairTitle />

        {filteredPairs.map((item, index) => (
          <TabPanel value={value} index={index} className="pair-list-mobile-list" key={item.label}>
            <SimpleBar style={{ maxHeight: 200 }} autoHide={false}>
              {item.pairs.map((pair) => (
                <EachPair pair={pair} key={pair.trading_pairs} />
              ))}
            </SimpleBar>
          </TabPanel>
        ))}
      </Box>
    </div>
  );
}
