import React from 'react'
import './withdraw.scss'
import { Alert, Button, Snackbar } from '@mui/material'
import { useRef } from 'react';
import { useState } from 'react';
import { FileCopy } from "@mui/icons-material";
import { createApiAddress } from '../../../constant/api.constant';
import { ItemNames, getStorage } from '../../../utils/RSS';

function WithdrawHistoryEach(props) {
    const copyToClipBoard = useRef([]);
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)
    const [severity, setSeverity] = useState('')
    const index = props.index;
    const errorCatch = (error) => { };
    const handleCloseSnackBar = () => {
        setShow(false)
    }

    const openErrorSnack = (msg, sev) => {
        setMessage(msg)
        setSeverity(sev)
        setShow(true)
    }

    const handleCopy = (index) => {
        const textToCopy = copyToClipBoard.current[index]?.innerText;
        if (textToCopy) {
            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(textToCopy);
                openErrorSnack('کپی شده است', 'success')
            } else {
                const tempTextArea = document.createElement("textarea");
                tempTextArea.value = textToCopy;
                document.body.appendChild(tempTextArea);
                tempTextArea.select();
                document.execCommand("copy");
                document.body.removeChild(tempTextArea);
                openErrorSnack('کپی شده است', 'success')
            }
        }
    };

    const [openSnackBar, setOpenSnackBar] = useState({
        show: false,
        severity: "warning",
        message: "",
    });


    const resendEmail = async (id) => {
        try {
            let data = {
                withdraw_id: id
            };
            let res = await fetch(createApiAddress("api.RESEND_WITHDRAW_EMAIL"), {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            });
            let resJson = await res.json();
            if (res.status === 200) {
                if (resJson.status === 1) {
                    setOpenSnackBar({
                        ...openSnackBar,
                        show: true,
                        severity: "success",
                        message: resJson.locale.fa,
                    });
                } else if (resJson.status === 0) {
                    setOpenSnackBar({
                        ...openSnackBar,
                        show: true,
                        severity: "warning",
                        message: resJson.locale.fa,
                    });
                }
            }
        } catch (err) { }
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7 col-xl-6 col-12 m-0">
                    <div className='deposit-history-main-eachsite'>
                        <div className="container-fluid mb-3">
                            <div className="row">
                                <div className="col-2">
                                    <img src={props.image} alt="token logo" height={25} style={{ marginBottom: -60 }} />
                                </div>
                                <div className="col-12 text-center mt-2 success-text">
                                    <h5> {props.amount}{" "}{props.symbol}</h5>
                                </div>
                                <div className="col-12 text-center mt-2" style={{ marginTop: -15, color: "#00b8f9" }}>
                                    <h6>  {props.status}</h6>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <h6>تاریخ و زمان</h6>
                                </div>
                                <div className="col-6 text-start">
                                    <h6>{props.time}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h6> شناسه تراکنش</h6>
                                </div>
                                <div className="col-12 text-center">
                                    <h6 className='txid-each-mobile'><span ref={(el) => (copyToClipBoard.current[index] = el)} className="span-txid-deposit">{props.txid}</span></h6>
                                </div>
                                <div className="col-12 text-center mb-2">
                                    <Button
                                        onClick={() => handleCopy(index)}
                                        endIcon={<FileCopy fontSize="10" />}
                                        className="button-copy m-1"
                                    >
                                        کپی
                                    </Button>
                                    {props.userStatus === 'pending' && <Button
                                        onClick={() => resendEmail(props.id)}
                                        className="button-copy m-1"
                                    >
                                        ارسال مجدد ایمیل تایید
                                    </Button>}
                                    {/* <Button
                            // onClick={() => handleCopy(index)}
                            className="button-copy m-1"
                        >
                            لغو
                        </Button> */}
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            open={show}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert severity={severity}>
                                {message}
                            </Alert>
                        </Snackbar>
                        <Snackbar
                            open={openSnackBar.show}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert severity={openSnackBar.severity}>
                                {openSnackBar.message}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WithdrawHistoryEach
