import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { SocketContext } from "../../../../contexts/providers/socket";

function LastPriceModule() {
  const { stateIO } = React.useContext(SocketContext);
  const colorInTrade = () => {
    if (stateIO?.histories[0]?.side === "sell") {
      return "textdanger";
    } else if (stateIO?.histories[0]?.side === "buy") {
      return "textsuccess";
    }
  };

  return (
    <div className="container-fluid last-price-orderlist">
      <div className="col-4  d-flex">
        <p className={`${colorInTrade()}`}>
          {stateIO?.histories[0]?.side === "buy" && (
            <KeyboardArrowUpIcon className="arrow-table-market" />
          )}
          {stateIO?.histories[0]?.side === "sell" && (
            <KeyboardArrowDownIcon className="arrow-table-market" />
          )}
        </p>
        <p className={`last-price-orderlist-center  ${colorInTrade()}`}>
          {stateIO?.histories[0]?.price}
        </p>
      </div>
    </div>
  );
}

export default LastPriceModule;
