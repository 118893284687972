import React, { useEffect, useState } from "react";
import "./termsofuse.scss";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

function TermsOfUse() {
  const [data, setData] = useState()

  useEffect(() => {
    fetch("https://s3.terixo.com/public/json/terms.json?nocache=" + Date.now())
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setData(data?.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, []);
  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="terms-main-mobile">
        <NavbarTopMobile />
        <div className="terms-module container">
          {
            (data?.map((item, index) => (
              item.title && item.content ? (
                <div key={index}>
                  <h5>{item.title}</h5>
                  <p className="mb-5">{item.content}</p>
                </div>
              ) : null
            )))}
        </div>
        <div className="container-fluid navbar-mobile-bot">
          <div className="row justify-content-center">
            <div className="col-12">
              <ResponsiveAppBar />
            </div>
          </div>
        </div>
      </div>
    </>
  }

  return (
    <div className="terms-main">
      <div className="terms-module container">
        {
          (data?.map((item, index) => (
            item.title && item.content ? (
              <div key={index}>
                <h5>{item.title}</h5>
                <p className="mb-5">{item.content}</p>
              </div>
            ) : null
          )))}
      </div>
    </div>
  );
}

export default TermsOfUse;
