import { useEffect } from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RotatingLines } from "react-loader-spinner";
import { getInstantHistory } from "../../../utils/Api";
import moment from "jalali-moment";
import './instant.scss'
import { toFixed } from "../../../utils/math";

function InstantHistory() {
  const [historyInstantData, setHistoryInstantData] = useState(null);

  const errorCatch = (error) => { };

  useEffect(() => {
    getInstantHistory(setHistoryInstantData, errorCatch);
  }, []);

  const colorForChange = (side) => {
    if (side === 'sell') {
      return "textdanger";
    } else if (side === 'buy') {
      return "textsuccess";
    }
  };

  const getDeposit = (row) => {
    if (row.pair?.to === 'USDT') {
      const formattedAmount = Math.abs(row.withdraw_amount.toFixed(2));
      const currency = row.side === 'sell' ? 'USDT' : 'IRT';
      return `${formattedAmount} ${currency}`;
    } else if (row.pair?.to === 'BUSD') {
      const formattedAmount = Math.abs(row.withdraw_amount.toFixed(2));
      const currency = row.side === 'sell' ? 'BUSD' : 'IRT';
      return `${formattedAmount} ${currency}`;
    }
    return '';
  };

  const getWithdraw = (row) => {
    if (row.pair?.to === 'USDT') {
      const formattedAmount = row.deposit_amount.toFixed(2);
      const currency = row.side === 'buy' ? 'USDT' : 'IRT';
      return `${formattedAmount} ${currency}`;
    } else if (row.pair?.to === 'BUSD') {
      const formattedAmount = row.deposit_amount.toFixed(2);
      const currency = row.side === 'buy' ? 'BUSD' : 'IRT';
      return `${formattedAmount} ${currency}`;
    }
    return ''; // Handle other cases or return an empty string as needed
  };

  if (!historyInstantData) {
    return (
      <div className="container-fluid rotating-loader-lines">
        <div className="row justify-content-center">
          <div className="col text-center">
            <RotatingLines
              strokeColor="grey"
              strokeWidth="5"
              animationDuration="0.75"
              width="35"
              visible={true}
            />
          </div>
        </div>
      </div>
    );
  }

  const userAgent = navigator.userAgent;
  const isMobileDevice = /Mobi|Android/i.test(userAgent);

  if (isMobileDevice === true) {
    return <>
      <div className="container-fluid">
        {historyInstantData.map((row) => (
          <div className="instant-his-main-mobile mb-2" key={row.currency}>
            <div className="row">
              <div className="col-6">
                <p>تاریخ و زمان</p>
                <p>نوع معامله</p>
              </div>
              <div className="col-6">
                <p>
                  {moment
                    .from(row.updated_at, "en")
                    .locale("fa")
                    .format("jYYYY/jMM/jDD")}{" "}
                  {moment(row.updated_at).format("HH:mm:ss")}</p>
                <p className={`table-row ${colorForChange(row.side)}`}>{row.side === 'buy' ? 'خرید' : 'فروش'}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>قیمت تتر</p>
                <p>مقدار پرداختی</p>
              </div>
              <div className="col-6">
                <p>{row.price}</p>
                <p>{getDeposit(row)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>مقدار دریافتی</p>
                <p>کارمزد</p>
              </div>
              <div className="col-6">
                <p>{getWithdraw(row)}</p>
                <p>{toFixed(row.fee_amount, 4)}</p>
              </div>
            </div>
          </div>))}
      </div>
    </>
  }
  return (
    // <div className="container instant-his-main">
    //   <TableContainer component={Paper} className="table-main">
    //     <Table sx={{ minWidth: 500, maxHeight: 400 }} aria-label="simple table dark">
    //       <TableHead className="table-head">
    //         <TableRow className="table-row">
    //           <TableCell className="table-row" align="right" width={80}>
    //             تاریخ و زمان
    //           </TableCell>
    //           <TableCell className="table-row" align="right" width={80}>
    //             نوع معامله
    //           </TableCell>
    //           <TableCell className="table-row" align="right" width={100}>
    //             قیمت تتر
    //           </TableCell>
    //           <TableCell className="table-row" align="right" width={120}>
    //             مقدار پرداختی
    //           </TableCell>
    //           <TableCell className="table-row" align="right" width={120}>
    //             مقدار دریافتی
    //           </TableCell>
    //           <TableCell className="table-row" align="right" width={70}>
    //             کارمزد
    //           </TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {historyInstantData.map((row) => (
    //           <TableRow
    //             className="table-row"
    //             key={row.currency}
    //             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //           >
    //             <TableCell className="table-row" align="right">
    //               تاریخ
    //               {moment
    //                 .from(row.updated_at, "en")
    //                 .locale("fa")
    //                 .format("jYYYY/jMM/jDD")}{" "}
    //               زمان{moment(row.updated_at).format("HH:mm:ss")}
    //             </TableCell>
    //             <TableCell
    //               className={`table-row ${colorForChange(row.side)}`}
    //               component="th"
    //               scope="row"
    //               align="right"
    //             >

    //               {row.side === 'buy' ? 'خرید' : 'فروش'}
    //             </TableCell>

    //             <TableCell className="table-row" align="right">
    //               {row.price}
    //             </TableCell>
    //             <TableCell className="table-row" align="right">
    //               {getDeposit(row)}
    //             </TableCell>
    //             <TableCell className="table-row" align="right">
    //               {getWithdraw(row)}
    //             </TableCell>

    //             <TableCell className="table-row" align="right">
    //               {toFixed(row.fee_amount, 4)}
    //             </TableCell>
    //           </TableRow>
    //         ))}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    // </div>
    <>
      <div className="container-fluid">
        {historyInstantData.map((row) => (
          <div className="instant-his-main-mobile mb-2" key={row.currency}>
            <div className="row">
              <div className="col-6">
                <p>تاریخ و زمان</p>
                <p>نوع معامله</p>
              </div>
              <div className="col-6">
                <p>
                  {moment
                    .from(row.updated_at, "en")
                    .locale("fa")
                    .format("jYYYY/jMM/jDD")}{" "}
                  {moment(row.updated_at).format("HH:mm:ss")}</p>
                <p className={`table-row ${colorForChange(row.side)}`}>{row.side === 'buy' ? 'خرید' : 'فروش'}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>قیمت تتر</p>
                <p>مقدار پرداختی</p>
              </div>
              <div className="col-6">
                <p>{row.price}</p>
                <p>{getDeposit(row)}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <p>مقدار دریافتی</p>
                <p>کارمزد</p>
              </div>
              <div className="col-6">
                <p>{getWithdraw(row)}</p>
                <p>{toFixed(row.fee_amount, 4)}</p>
              </div>
            </div>
          </div>))}
      </div>
    </>
  );
}

export default InstantHistory;
