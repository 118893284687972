import React from 'react'
import './deposit.scss'
import { Alert, Button, Snackbar } from '@mui/material'
import { useRef } from 'react';
import { useState } from 'react';
import { FileCopy } from "@mui/icons-material";
function DepositHistoryEachSite(props) {
    const copyToClipBoard = useRef([]);
    const [message, setMessage] = useState('')
    const [show, setShow] = useState(false)
    const [severity, setSeverity] = useState('')
    const index = props.index;
    const handleCloseSnackBar = () => {
        setShow(false)
    }

    const openErrorSnack = (msg, sev) => {
        setMessage(msg)
        setSeverity(sev)
        setShow(true)
    }
    const handleCopy = (index) => {

        const textToCopy = copyToClipBoard.current[index]?.innerText;
        if (textToCopy) {

            if (navigator.clipboard && navigator.clipboard.writeText) {
                navigator.clipboard.writeText(textToCopy);
                openErrorSnack('کپی شده است', 'success')
            } else {
                openErrorSnack('کپی شده است', 'success')
                const tempTextArea = document.createElement("textarea");
                tempTextArea.value = textToCopy;
                document.body.appendChild(tempTextArea);
                tempTextArea.select();
                document.execCommand("copy");
                document.body.removeChild(tempTextArea);

            }
        }
    };
    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                <div className="col-md-8 col-lg-7 col-xl-6 col-12 m-0">
                    <div className='deposit-history-main-eachsite'>
                        <div className="container-fluid mb-3">
                            <div className="row">
                                <div className="col-2">
                                    <img src={props.image} alt="token logo" height={25} style={{ marginBottom: -60 }} />
                                </div>
                                <div className="col-12 text-center mt-2 success-text">
                                    <h5> {props.amount}{" "}{props.symbol}</h5>
                                </div>
                                <div className="col-12 text-center mt-2" style={{ marginTop: -15, color: "#00b8f9" }}>
                                    <h6>  {props.status}</h6>
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-6">
                                    <h6>تاریخ و زمان</h6>
                                </div>
                                <div className="col-6 text-start">
                                    <h6>{props.time}</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 text-center">
                                    <h6> شناسه تراکنش</h6>
                                </div>
                                <div className="col-12 text-center">
                                    <h6 className='txid-each-mobile'><span ref={(el) => (copyToClipBoard.current[index] = el)} className="span-txid-deposit">{props.txid}</span></h6>
                                </div>
                                <div className="col-12 text-center mb-2">
                                    <Button
                                        onClick={() => handleCopy(index)}
                                        endIcon={<FileCopy fontSize="10" className='m-2' />}
                                        className="button-copy"
                                    >
                                        {" "}کپی{" "}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Snackbar
                            open={show}
                            autoHideDuration={6000}
                            onClose={handleCloseSnackBar}
                        >
                            <Alert severity={severity}>
                                {message}
                            </Alert>
                        </Snackbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DepositHistoryEachSite
