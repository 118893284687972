import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { SocketContext } from "../../../../contexts/providers/socket";

function LastPriceModule() {
  const { stateIO } = React.useContext(SocketContext);
  const colorInTrade = () => {
    if (stateIO?.histories[0]?.side === "sell") {
      return "textdanger";
    } else if (stateIO?.histories[0]?.side === "buy") {
      return "textsuccess";
    }
  };

  return (

    <div className="mobile-lastprice-orders">
      <p className={`${colorInTrade()}`}>
        {stateIO?.histories[0]?.side === "buy" && (
          <KeyboardArrowUpIcon className="arrow-table-market" />
        )}
        {stateIO?.histories[0]?.side === "sell" && (
          <KeyboardArrowDownIcon className="arrow-table-market" />
        )}
      </p>
      <p className={`last-price-orderlist-mobile  ${colorInTrade()}`}>
        {stateIO?.histories[0]?.price}
      </p>
    </div>

  );
}

export default LastPriceModule;
