import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./home.scss";

function FAccordion() {
  return (
    <div>
      <Accordion className="text-start">
        <Accordion.Item eventKey="0">
          <Accordion.Header className="text-end">
            چگونه ثبت نام نمایم
          </Accordion.Header>
          <Accordion.Body>
            شما با وارد کردن آدرس ایمیل خود یا شماره تلفن همراه که باید به اسم
            شما باشد و انتخاب یک پسورد می توانید در پلتفورم تریکسو ثبت نام نموده و
            صاحب کیف پول اختصاصی باشید.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>پروسه احراز هویت چگونه است</Accordion.Header>
          <Accordion.Body>
            طبق قانون کشورمان هر کاربر بایستی اطلاعات دقیق شخصی خود را برای
            فعالیت در پلتفورم به ثبت برساند که برای سطح دوم شامل ايميل، تلفن
            همراه، اسم، فامیل، کد ملی و برای سطح سوم اضافه کردن آدرس و تلفن ثابت
            نیز الزامی میباشد.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>چگونه تومان واریز کنم</Accordion.Header>
          <Accordion.Body>
            به قسمت افزودن کارت بانکی مراجعه نمایید و مشخصات کارت بانکی که
            بایستی حتما به اسم کاربر باشد را اعلام نموده سپس با آسانی مبلغ تومان
            مورد نظر خود را تا سقف مجاز به کیف پول خود در پلتفورم واریز می نمایید.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            ایا در یک روز می توانم از چند حساب تومان واریز نمایم
          </Accordion.Header>
          <Accordion.Body>
            شما می توانید از هر حساب به نام خودتان، روزانه تا سقف ۲۵ میلیون تومان واریز نمایید.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>چگونه رمز ارز خریداری نمایم</Accordion.Header>
          <Accordion.Body>
            شما ابتدا در کیف پول خود بایستی موجودی تومانی یا تتر داشته باشید سپس
            با مراجعه به قسمت معاملات حرفه ای یا درگاه معاملات تتر نسبت به خرید
            رمز ارز دلخواه خود اقدام نمایید و پس از انجام معامله رمز ارز خریداری
            شده را در کیف پول خود روئیت کنید.{" "}
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>در صورت بروز مشکل چکار کنم </Accordion.Header>
          <Accordion.Body>
            شما می توانید از طریق سیستم پشتیبانی و یا ارسال ایمیل به support@terixo.com با ما ارتباط برقرار کنید. همکاران ما به صورت 24 ساعته مشتاقانه آماده پاسخگویی به سوالات شما می باشند.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default FAccordion;
