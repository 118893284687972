import { createApiAddress } from "../constant/api.constant";
import { ItemNames, getStorage } from "./RSS";

export function getSummary(setFunction, setErrorFunction) {
  fetch(createApiAddress("trade.SUMMARY_WITH_CHANGE"))
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data)
    })
    .catch((error) => setErrorFunction(error));
}

export function getAnnouncement(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.GET_ANNOUNCEMENT"))
    .then((response) => response.json())
    .then((data) => setFunction(data))
    .catch((error) => setErrorFunction(error));
}

export function getMainWallet(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.MAIN_WALLET"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.wallets);
    })
    .catch((error) => setErrorFunction(error));
}

export function getTradeWallet(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.TRADE_WALLET"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => setFunction(data.data.wallets))
    .catch((error) => setErrorFunction(error));
}

export function getUserInfo(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.user_info"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.user);
    })
    .catch((error) => setErrorFunction(error));
}

export function getReferral(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.REFERRALS"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getReferralHistory(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.REFERRARL_HISTORY"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getNotification(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.NOTIFICATIONS"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}



export function getDepositCurrency(setFunction, setErrorFunction) {
  fetch(createApiAddress("transaction.CURRENCY_DEPOSIT_AVAILABLE"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getWithdrawInfo(setFunction, setErrorFunction) {
  fetch(createApiAddress("transaction.CURRENCY_WITHDRAW_AVAILABLE"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data);

    })
    .catch((error) => setErrorFunction(error));
}

export function getTransferWallet(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.TRANSFER_WALLET"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.wallets);
    })
    .catch((error) => setErrorFunction(error));
}

export function getDepositHistory(setFunction, setErrorFunction) {
  fetch(createApiAddress("transaction.DEPOSIT_HISTORY"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data);
    })
    .catch((error) => setErrorFunction(error));
}

export function getWithdrawHistory(setFunction, setErrorFunction) {
  fetch(createApiAddress("api.WITHDRAW_HISTORY"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.data.withdraws);
    })
    .catch((error) => setErrorFunction(error));
}

export function getInstantHistory(setFunction, setErrorFunction) {
  fetch(createApiAddress("trade.INSTANT_HISTORY"), {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setFunction(data.converts);
    })
    .catch((error) => setErrorFunction(error));
}
