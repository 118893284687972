import React from "react";
import "./contact.scss";

function ContactUs() {
  return (
    <div className="contact-main">
      <div className="contact-module container">
        <div className="text-contact">
          <h2>Get in touch with us:</h2>
          <p>e</p>
          <p>You can check our FAQ page to find your answer:</p>

          <p>Or you can raise a ticket:</p>
        </div>
        <div className="icons-main">
          <div>
            <h2 className="head">Find and follow us on:</h2>
          </div>
          <div className="icons">
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-telegram "></i>&nbsp; Telegram
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-facebook "></i>&nbsp; Facebook
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-youtube "></i>&nbsp; Youtube
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-twitter "></i>&nbsp; Twitter
            </a>
            <a
              href="#"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-instagram "></i>&nbsp; Instagram
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
