import React from "react";
import MiniChart from "../products/instantBuySell/MiniChart";
import "./mobileinstant.scss";
import { useParams } from "react-router-dom";

function MobileTradeMiniChart() {
  const { tradePair } = useParams();
  const cleanedPair = tradePair ? tradePair.split('_')[0] : null;
  // console.log(tradePair);

  if (tradePair === undefined) {
    return <></>
  }
  return (
    <div className="chart-main2">
      <div className="chart2">
        <MiniChart
          key={cleanedPair}
          symbol={cleanedPair}
          colorOne={"#00b8f9"}
          colorTwo={"#ffffff00"}
          colorThree={"#007df9"}
        />
      </div>
    </div>
  );
}

export default MobileTradeMiniChart;
