import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import StopLimit from "./StopLimit";
import { useParams } from "react-router-dom";
import { SocketContext } from "../../../../contexts/providers/socket";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`buysell-tabpanel-${index}`}
      aria-labelledby={`buysell-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `buysell-tab-${index}`,
    "aria-controls": `buysell-tabpanel-${index}`,
  };
}

export default function BuySellStopLimitTabStyleC() {
  const [value, setValue] = useState(0);
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  const { stateIO } = React.useContext(SocketContext);

  const getBalance = (symbol) => {
    return (stateIO?.user_balances[symbol] !== undefined) ? stateIO?.user_balances[symbol].trading : 0;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="buy-sell-module-mainA">
      <Box>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="buy-sell-module tabs"
          >
            <Tab
              label={<Typography fontSize={12}>خرید</Typography>}
              {...a11yProps(0)}
            />
            <Tab
              label={<Typography fontSize={12}>فروش</Typography>}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="row buy-side-moduleC m-0">
            <div className="col ">
              <StopLimit
                side="buy"
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="row buy-side-moduleC m-0">
            <div className="col ">
              <StopLimit
                side="sell"
                from={from}
                balance_from={getBalance(from)}
                balance_to={getBalance(to)}
                to={to}
              />
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
