import React, { useContext, useEffect, useState } from "react";
import './prizepage.scss'
import { Form, Modal, NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Alert, Button, Slide, Snackbar } from "@mui/material";
import { ItemNames, getStorage, isLoggedIn } from "../../utils/RSS";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import { createApiAddress } from "../../constant/api.constant";
import { UserContext } from "../../contexts/providers/user";
import NavbarTopMobile from "../../components/navbar/NavbarTopMobile";
import ResponsiveAppBar from "../home/NavbarMobile";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function PrizePage() {
    function addCommasToNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        return number
    }

    const [plansData, setPlansData] = useState([]);
    const [agreed, setAgreed] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [name, setName] = useState('')
    const [points, setPoints] = useState('')
    const [id, setId] = useState('')
    const [userInstagram, setUserInstagram] = useState(null);
    const [userTelegram, setUserTelegram] = useState(null);
    const [openSnack, setOpenSnack] = useState(false);
    const [disableSocial, setDisableSocial] = useState(false);

    const handleCloseSnack = () => {
        setOpenSnack(false)
    }

    const handleOpenSnack = () => {
        setOpenSnack(true)
    }

    const [openSnack2, setOpenSnack2] = useState(false);

    const handleCloseSnack2 = () => {
        setOpenSnack2(false)
    }

    const handleOpenSnack2 = () => {
        setOpenSnack2(true)
    }

    const [openSnack3, setOpenSnack3] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const handleCloseSnack3 = () => {
        setOpenSnack3(false)
    }

    const handleOpenSnack3 = () => {
        setOpenSnack3(true)
    }

    const handleInstagramChange = (event) => {
        setUserInstagram(event.target.value)
    }

    const handleTelegramChange = (event) => {
        setUserTelegram(event.target.value)
    }
    const handleOpenModal = (name, points, id) => {
        setShowModal(true);
        setName(name)
        setPoints(points)
        setId(id)
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setAgreed(false)
    };

    const handleOpenModal2 = (name, points) => {
        setShowModal2(true);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    const handleAgreementChange = () => {
        setAgreed(!agreed);
    };

    const handleConfirm = async () => {
        if (userInstagram !== ' ' && userInstagram !== null && userTelegram !== " " && userTelegram !== null) {
            let form = {
                "social_media_accounts": {
                    "telegram": userTelegram,
                    "instagram": userInstagram
                }
            }
            let res = await fetch(createApiAddress("api.CHECK_SOCIAL"), {
                method: "PATCH",
                body: JSON.stringify(form),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
                },
            });
            let result = await res.json()
            if (result.status === 1) {
                handleOpenSnack2()
                handleCloseModal2()
                setDisableSocial(true)
            } else if (result.status !== 1) {
                setErrorMsg(res.locale.fa)
                handleOpenSnack3()
            }
        } else {
            handleOpenSnack()
        }
    };

    const [reload, setReload] = useState(false)

    const attendInPool = async (points, id) => {
        if (points > remainPoints) {
            setErrorMsg('امتیاز شما کافی نیست.')
            handleOpenSnack3()
            return
        }
        // console.log(id)
        let res = await fetch(createApiAddress("api.ATTEND_PRIZE", id), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
        });

        let result = await res.json()

        if (result.status === 1) {
            handleOpenSnack2()
            handleCloseModal()
            // setReload(true)
            window.location.reload();
        } else if (result.status !== 1) {
            setErrorMsg(result.locale.fa)
            handleOpenSnack3()
        }
    };
    const { state } = useContext(UserContext)

    const [remainPoints, setRemainPoints] = useState(0);


    useEffect(() => {
        fetch(createApiAddress("api.user_points"), {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setRemainPoints(data.total_points - data.total_used_points)
            });
    }, [reload]);

    useEffect(() => {
        fetch(createApiAddress("api.PRIZES_DATA"), {
            method: "GET",
        })
            .then((response) => response.json())
            .then((data) => {
                if (state.isLoggedIn === true) {
                    const plans = data.data?.map((plan) => {
                        const match = state.user_profile?.prizes_list.find((userPlan) => userPlan._id === plan._id)

                        if (match) {
                            return {
                                ...plan,
                                attend: true
                            }
                        }
                        return {
                            ...plan,
                            attend: false
                        }
                    })
                    setPlansData(plans)
                } else {
                    setPlansData(data.data);
                }

            })
            .catch((error) => console.error('Error fetching data:', error));
    }, [reload]);



    const numberWithCommas = addCommasToNumber(remainPoints);

    const userAgent = navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);

    if (isMobileDevice === true) {
        return <>
            <div className="prize-main-mobile">
                <NavbarTopMobile />
                <div className="container-fluid prize-top">
                    <div className="row">
                        <div className="col-12 col-md-6 p-5">
                            <h4>جوایز ویژه تریکسو</h4>
                            <div className="col-11 p-4 explanation-text">
                                <h6>ما برای تشویق و پيشرفت کاربران خود در مراحل مختلف امتیازاتی قائل میشویم که منجر به کسب جوایز مالی ماهیانه گردد.</h6>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 right-image-prize">
                            <div className="container">
                                <div className="row justify-content-center prize-text-left">
                                    <div className="col mt-5">
                                        <div className="row">
                                            <div className="col justify-content-center d-flex align-items-center">
                                                <div className="user-prize-circle text-center justify-content-center d-flex align-items-center flex-column">
                                                    <p>امتیاز شما</p>
                                                    {isLoggedIn() ?
                                                        <>
                                                            <p>{numberWithCommas}</p>
                                                        </>
                                                        :
                                                        <NavLink
                                                            as={Link}
                                                            to={`/login`}
                                                            className="nav-link"
                                                        >
                                                            <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                                        </NavLink>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="move-prize">
                        <h5 className="m-5">طبق موارد زیر می توانید کسب امتیاز نمایید:</h5>
                        <div className="row justify-content-around">
                            <div className="col-11 col-md-5 col-xl-2 grey-box">
                                <h5 className="prize-h5">با ثبت نام</h5>
                                <h6 className="prize-h6"> ۵۰۰ امتیاز</h6>
                                {isLoggedIn() ?
                                    <p className="prize-h6">امتیاز ثبت نام را دریافت کرده اید</p>
                                    :
                                    <NavLink
                                        as={Link}
                                        to={`/login`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >ثبت نام نمایید</Button>
                                    </NavLink>
                                }
                            </div>
                            <div className="col-11 col-md-5 col-xl-2 grey-box">
                                <h5 className="prize-h5">معرفی هر نفر با احراز هویت</h5>
                                <h6 className="prize-h6">۳۰۰ امتیاز</h6>
                                {isLoggedIn() ?
                                    <NavLink
                                        as={Link}
                                        to={`/commission`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >به اشتراک گذاری لینک ارجاع</Button>
                                    </NavLink>
                                    :
                                    <NavLink
                                        as={Link}
                                        to={`/login`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                    </NavLink>
                                }
                            </div>

                            <div className="col-11 col-md-5 col-xl-2 grey-box">
                                <h5 className="prize-h5">معادل هر ۱۰۰ تتر معامله</h5>
                                <h6 className="prize-h6"> ۳۰۰ امتیاز</h6>
                                {isLoggedIn() ?
                                    <NavLink
                                        as={Link}
                                        to={`/trade/BTC_USDT`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >معاملات را شروع کنید</Button>
                                    </NavLink>
                                    :
                                    <NavLink
                                        as={Link}
                                        to={`/login`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                    </NavLink>
                                }
                            </div>
                            <div className="col-11 col-md-5 col-xl-2 grey-box">
                                <h5 className="prize-h5">فالو کردن تلگرام و اینستاگرام تریکسو</h5>
                                <h6 className="prize-h6"> ۵۰۰ امتیاز</h6>
                                {isLoggedIn() ?
                                    <Button className="login-prize-btn-y" onClick={handleOpenModal2} disabled={disableSocial}>{disableSocial ? 'در خواست شما ارسال شد' : 'فرآیند را شروع کنید'}</Button>
                                    :
                                    <NavLink
                                        as={Link}
                                        to={`/login`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                    </NavLink>
                                }
                            </div>
                        </div>
                        <h5 className="m-5 lh-lg bg-secondary p-1">چنانچه امتیاز شما به حد معينی برای شرکت در هر کدام از سطوح زیر رسیده باشد،  شما با کلیک دکمه شرکت میکنم می توانید در قرعه کشی شرکت نمایید و یکی از برندگان خوش شانس ما باشید. لطفا اطلاعات بیشتر و جزئیات را در صفحه اعلانات و شبکه های اجتماعی ما دنبال نمایید.</h5>
                        <div className="row justify-content-around ">
                            {plansData.map((item) =>
                                <div className="col-11 col-md-5 col-xl-3 text-center">
                                    <div className="row justify-content-center" key={item.attend}>
                                        <div className="col justify-content-center d-flex align-items-center">
                                            <div className="user-prize-circle-y text-center justify-content-center d-flex align-items-center flex-column mt-4">
                                                <h5>{item.name}</h5>
                                                <h5>{item.points_required}</h5>
                                                <h6>امتیاز</h6>
                                            </div>
                                        </div>
                                        <h6 className="mt-4">{item.description}</h6>
                                        <h6 className=" ">{item.reward}</h6>
                                        <h5>{item.attend}</h5>
                                        {isLoggedIn() ? <>
                                            {item.attend === false ?
                                                <Button className="join-prize-btn" onClick={() => handleOpenModal(item.name, item.points_required, item._id)}>شرکت میکنم</Button> : <h6 className="text-info">شما قبلا در این طرح ثبت نام کرده اید</h6>}
                                        </> : <></>}
                                    </div>
                                </div>)}
                        </div>
                        <div className="row justify-content-center p-4">
                            <div className="col-11 col-md-5 col-xl-2">
                                <div className="text-center mt-3">
                                    {!isLoggedIn() ?
                                        <NavLink
                                            as={Link}
                                            to={`/login`}
                                            className="nav-link"
                                        >
                                            <Button className="login-prize-btn" >وارد شوید و به جوایز ما بپیوندید</Button>
                                        </NavLink>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid navbar-mobile-bot">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <ResponsiveAppBar />
                        </div>
                    </div>
                </div>

                <Modal
                    show={showModal}
                    onHide={handleCloseModal}
                    className="modal-withdraw m-2"
                    TransitionComponent={Transition}
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>انتخاب طرح {name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>با کلیک بر روی "پذیرفتن" شما طرح {name} را انتخاب می کنید و امتیازات شما مصرف می شود و برنمی گردد و باید تا قرعه کشی صبر کنید.</p>
                        <p>امتیاز شما: {numberWithCommas}</p>
                        <p>مقدار امتیازی که مصرف خواهید کرد: {addCommasToNumber(points)}</p>
                        <Form.Check
                            type="checkbox"
                            label="من با شرایط و ضوابط قرعه کشی موافقم."
                            checked={agreed}
                            onChange={handleAgreementChange}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal}>
                            رد کردن
                        </Button>
                        <Button variant="primary" onClick={() => attendInPool(points, id)} disabled={!agreed}>
                            پذیرفتن
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showModal2} onHide={handleCloseModal2} className="modal-withdraw m-2" TransitionComponent={Transition} size="lg">
                    <Modal.Header>
                        <Modal.Title>
                            <div>
                                <p>مراحل دنبال کردن اینستا و تلگرام</p>
                                <h6 className="font-prize-modal">لطفا فقط نام کاربری یا آی دی خود را بدون @ و یا لینک وارد کنید.</h6>
                                <h6 className="font-prize-modal">برای مثال: terixoexchange</h6>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mt-3">
                                <Form.Label className="font-prize-modal">لطفا بعد از فالو کردن اینستاگرام ما آی دی اینستاگرام خود را وارد کنید</Form.Label>
                                <a href="https://www.instagram.com/terixoexc/" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                    <InstagramIcon /><span className="font-prize-modal">اینستاگرام ما</span>
                                </a>
                                <Form.Control type="text" placeholder="آی دی اینستاگرام" className="input-prize-social mt-2" onChange={handleInstagramChange} />
                            </Form.Group>

                            <Form.Group className="mt-3">
                                <Form.Label className="font-prize-modal">لطفا پس از دنبال کردن کانال و گروه تلگرام ما آیدی تلگرام خود را وارد کنید</Form.Label>
                                <a href="" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                    <TelegramIcon />
                                    <span className="font-prize-modal">گروه تلگرام ما</span>
                                </a>
                                <a href="https://t.me/terixoexc" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                    <TelegramIcon /><span className="font-prize-modal">کانال تلگرام ما</span>
                                </a>
                                <Form.Control type="text" placeholder="آیدی تلگرام" className="input-prize-social mt-2" onChange={handleTelegramChange} />
                            </Form.Group>
                        </Form>
                        <p className="font-prize-modal mt-4">امتیاز شما پس از پردازش فرآیند به حساب شما اضافه می شود، این کار کمتر از یک ساعت طول می کشد.</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModal2}>
                            لغو
                        </Button>
                        <Button variant="primary" onClick={handleConfirm}>
                            ثبت
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                        لطفاً تمامی فیلدهای الزامی را پر کنید
                    </Alert>
                </Snackbar>

                <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
                    <Alert onClose={handleCloseSnack2} severity="success" sx={{ width: '100%' }}>
                        در خواست شما ارسال شد
                    </Alert>
                </Snackbar>

                <Snackbar open={openSnack3} autoHideDuration={6000} onClose={handleCloseSnack3}>
                    <Alert onClose={handleCloseSnack3} severity="success" sx={{ width: '100%' }}>
                        {errorMsg}
                    </Alert>
                </Snackbar>

            </div>
        </>
    }

    return (
        <div className="prize-main">
            <div className="container-fluid prize-top">
                <div className="row">
                    <div className="col-12 col-md-6 p-5">
                        <h4>جوایز ویژه تریکسو</h4>
                        <div className="col-11 p-4 explanation-text">
                            <h6>ما برای تشویق و پيشرفت کاربران خود در مراحل مختلف امتیازاتی قائل میشویم که منجر به کسب جوایز مالی ماهیانه گردد.</h6>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 right-image-prize">
                        <div className="container">
                            <div className="row justify-content-center prize-text-left">
                                <div className="col mt-5">
                                    <div className="row">
                                        <div className="col justify-content-center d-flex align-items-center">
                                            <div className="user-prize-circle text-center justify-content-center d-flex align-items-center flex-column">
                                                <p>امتیاز شما</p>
                                                {isLoggedIn() ?
                                                    <>
                                                        <p>{numberWithCommas}</p>
                                                    </>
                                                    :
                                                    <NavLink
                                                        as={Link}
                                                        to={`/login`}
                                                        className="nav-link"
                                                    >
                                                        <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                                    </NavLink>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="move-prize">
                    <h5 className="m-5">طبق موارد زیر می توانید کسب امتیاز نمایید:</h5>
                    <div className="row justify-content-around">
                        <div className="col-11 col-md-5 col-xl-2 grey-box">
                            <h5 className="prize-h5">با ثبت نام</h5>
                            <h6 className="prize-h6"> ۵۰۰ امتیاز</h6>
                            {isLoggedIn() ?
                                <p className="prize-h6">امتیاز ثبت نام را دریافت کرده اید</p>
                                :
                                <NavLink
                                    as={Link}
                                    to={`/login`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >ثبت نام نمایید</Button>
                                </NavLink>
                            }
                        </div>
                        <div className="col-11 col-md-5 col-xl-2 grey-box">
                            <h5 className="prize-h5">معرفی هر نفر با احراز هویت</h5>
                            <h6 className="prize-h6">۳۰۰ امتیاز</h6>
                            {isLoggedIn() ?
                                <NavLink
                                    as={Link}
                                    to={`/commission`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >به اشتراک گذاری لینک ارجاع</Button>
                                </NavLink>
                                :
                                <NavLink
                                    as={Link}
                                    to={`/login`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                </NavLink>
                            }
                        </div>

                        <div className="col-11 col-md-5 col-xl-2 grey-box">
                            <h5 className="prize-h5">معادل هر ۱۰۰ تتر معامله</h5>
                            <h6 className="prize-h6"> ۳۰۰ امتیاز</h6>
                            {isLoggedIn() ?
                                <NavLink
                                    as={Link}
                                    to={`/trade/BTC_USDT`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >معاملات را شروع کنید</Button>
                                </NavLink>
                                :
                                <NavLink
                                    as={Link}
                                    to={`/login`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                </NavLink>
                            }
                        </div>
                        <div className="col-11 col-md-5 col-xl-2 grey-box">
                            <h5 className="prize-h5">فالو کردن تلگرام و اینستاگرام تریکسو</h5>
                            <h6 className="prize-h6"> ۵۰۰ امتیاز</h6>
                            {isLoggedIn() ?
                                <Button className="login-prize-btn-y" onClick={handleOpenModal2} disabled={disableSocial}>{disableSocial ? 'در خواست شما ارسال شد' : 'فرآیند را شروع کنید'}</Button>
                                :
                                <NavLink
                                    as={Link}
                                    to={`/login`}
                                    className="nav-link"
                                >
                                    <Button className="login-prize-btn-y" >ابتدا وارد شوید</Button>
                                </NavLink>
                            }
                        </div>
                    </div>
                    <h5 className="m-5 lh-lg bg-secondary p-1">چنانچه امتیاز شما به حد معينی برای شرکت در هر کدام از سطوح زیر رسیده باشد،  شما با کلیک دکمه شرکت میکنم می توانید در قرعه کشی شرکت نمایید و یکی از برندگان خوش شانس ما باشید. لطفا اطلاعات بیشتر و جزئیات را در صفحه اعلانات و شبکه های اجتماعی ما دنبال نمایید.</h5>
                    <div className="row justify-content-around ">
                        {plansData.map((item) =>
                            <div className="col-11 col-md-5 col-xl-3 text-center">
                                <div className="row justify-content-center" key={item.attend}>
                                    <div className="col justify-content-center d-flex align-items-center">
                                        <div className="user-prize-circle-y text-center justify-content-center d-flex align-items-center flex-column mt-4">
                                            <h5>{item.name}</h5>
                                            <h5>{item.points_required}</h5>
                                            <h6>امتیاز</h6>
                                        </div>
                                    </div>
                                    <h6 className="mt-4">{item.description}</h6>
                                    <h6 className=" ">{item.reward}</h6>
                                    <h5>{item.attend}</h5>
                                    {isLoggedIn() ? <>
                                        {item.attend === false ?
                                            <Button className="join-prize-btn" onClick={() => handleOpenModal(item.name, item.points_required, item._id)}>شرکت میکنم</Button> : <h6 className="text-info">شما قبلا در این طرح ثبت نام کرده اید</h6>}
                                    </> : <></>}
                                </div>
                            </div>)}
                    </div>
                    <div className="row justify-content-center p-4">
                        <div className="col-11 col-md-5 col-xl-2">
                            <div className="text-center mt-3">
                                {!isLoggedIn() ?
                                    <NavLink
                                        as={Link}
                                        to={`/login`}
                                        className="nav-link"
                                    >
                                        <Button className="login-prize-btn" >وارد شوید و به جوایز ما بپیوندید</Button>
                                    </NavLink>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                show={showModal}
                onHide={handleCloseModal}
                className="modal-withdraw m-2"
                TransitionComponent={Transition}
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>انتخاب طرح {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>با کلیک بر روی "پذیرفتن" شما طرح {name} را انتخاب می کنید و امتیازات شما مصرف می شود و برنمی گردد و باید تا قرعه کشی صبر کنید.</p>
                    <p>امتیاز شما: {numberWithCommas}</p>
                    <p>مقدار امتیازی که مصرف خواهید کرد: {addCommasToNumber(points)}</p>
                    <Form.Check
                        type="checkbox"
                        label="من با شرایط و ضوابط قرعه کشی موافقم."
                        checked={agreed}
                        onChange={handleAgreementChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        رد کردن
                    </Button>
                    <Button variant="primary" onClick={() => attendInPool(points, id)} disabled={!agreed}>
                        پذیرفتن
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModal2} onHide={handleCloseModal2} className="modal-withdraw m-2" TransitionComponent={Transition} size="lg">
                <Modal.Header>
                    <Modal.Title>
                        <div>
                            <p>مراحل دنبال کردن اینستا و تلگرام</p>
                            <h6 className="font-prize-modal">لطفا فقط نام کاربری یا آی دی خود را بدون @ و یا لینک وارد کنید.</h6>
                            <h6 className="font-prize-modal">برای مثال: terixoexchange</h6>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mt-3">
                            <Form.Label className="font-prize-modal">لطفا بعد از فالو کردن اینستاگرام ما آی دی اینستاگرام خود را وارد کنید</Form.Label>
                            <a href="https://www.instagram.com/terixoexc/" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                <InstagramIcon /><span className="font-prize-modal">اینستاگرام ما</span>
                            </a>
                            <Form.Control type="text" placeholder="آی دی اینستاگرام" className="input-prize-social mt-2" onChange={handleInstagramChange} />
                        </Form.Group>

                        <Form.Group className="mt-3">
                            <Form.Label className="font-prize-modal">لطفا پس از دنبال کردن کانال و گروه تلگرام ما آیدی تلگرام خود را وارد کنید</Form.Label>
                            <a href="" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                <TelegramIcon />
                                <span className="font-prize-modal">گروه تلگرام ما</span>
                            </a>
                            <a href="https://t.me/terixoexc" target="_blank" rel="noopener noreferrer" className="m-3 social-icon-prize">
                                <TelegramIcon /><span className="font-prize-modal">کانال تلگرام ما</span>
                            </a>
                            <Form.Control type="text" placeholder="آیدی تلگرام" className="input-prize-social mt-2" onChange={handleTelegramChange} />
                        </Form.Group>
                    </Form>
                    <p className="font-prize-modal mt-4">امتیاز شما پس از پردازش فرآیند به حساب شما اضافه می شود، این کار کمتر از یک ساعت طول می کشد.</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal2}>
                        لغو
                    </Button>
                    <Button variant="primary" onClick={handleConfirm}>
                        ثبت
                    </Button>
                </Modal.Footer>
            </Modal>

            <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="warning" sx={{ width: '100%' }}>
                    لطفاً تمامی فیلدهای الزامی را پر کنید
                </Alert>
            </Snackbar>

            <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleCloseSnack2}>
                <Alert onClose={handleCloseSnack2} severity="success" sx={{ width: '100%' }}>
                    در خواست شما ارسال شد
                </Alert>
            </Snackbar>

            <Snackbar open={openSnack3} autoHideDuration={6000} onClose={handleCloseSnack3}>
                <Alert onClose={handleCloseSnack3} severity="success" sx={{ width: '100%' }}>
                    {errorMsg}
                </Alert>
            </Snackbar>

        </div>
    );
}

export default PrizePage;