import { useContext, useEffect, useState } from "react";
import { UserContext } from "./contexts/providers/user";
import { Route, Routes, Navigate, Outlet, useNavigate } from "react-router-dom";
import Home from "./pages/home/Home";
import Market from "./pages/products/market/Market";
import Withdraw from "./pages/transaction/withdraw/Withdraw";
import Deposit from "./pages/transaction/deposit/Deposit";
import Assets from "./pages/assets/Assets";
import Profile from "./pages/profile/Profile";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Aboutus from "./pages/about/Aboutus";
import Announcement from "./pages/announcement/Announcement";
import TermsOfUse from "./pages/termsofuse/TermsOfUse";
import ContactUs from "./pages/contact/ContactUs";
import FeePage from "./pages/fee/FeePage";
import Trade from "./pages/products/trade/Trade";
import { ItemNames, getStorage, isLoggedIn } from "./utils/RSS";
import ModalGlobal from "./contexts/modal/Modal";
import InputDialogGlobal from "./contexts/dialog/dialog";
import SnackbarGlobal from "./contexts/snack/SnackGlobal";
import CommissionPage from "./pages/profile/CommissionPage";
import Transfer from "./pages/transaction/transfer/Transfer";
import InputDialogGlobal2 from "./contexts/dialog/DialogDouble";
import "./App.scss";
import WithdrawConfirmation from "./pages/transaction/withdraw/WithdrawConfirmation";
import ResetPassword from "./pages/login/ResetPassword";
import InstantBuySell from "./pages/products/instantBuySell/InstantBuySell";
import Staking from "./pages/products/staking/Staking";
import Career from "./pages/career/Career";
import Kyc from "./pages/profile/Kyc";
import Learning from "./pages/learning/Learning";
import MobileInstantChart from "./pages/mobile-chart/Mobile-Instant-Chart";
import Mobile_trade_chart from "./pages/mobile-chart/Mobile_trade_chart";
import { createApiAddress } from "./constant/api.constant";
import PrizePage from "./pages/prizepage/Prizepage";
import Loader from "./utils/Loader";
import P404 from "./404";
import Support from "./pages/support/Support";
import ConversationPage from "./pages/support/SupportIndividual";
import RedirectPage from "./utils/Redirect";
import RedirectLogout from "./utils/RedirectLogout";
import TradeMainChart from "./pages/products/mobile-main-chart/mobile-chart";
import DepositMoneyConfirmation from "./pages/transaction/deposit/DepositMoneyConfirmation";
import Maintenance from "./utils/Maintenance";
import HomeMobile from "./pages/home/HomeMobile";
import PSecurityMobile from "./pages/profile/PSecurityMobile";
import HomeQuestion from "./pages/home/HomeQuestion";
import CardManager from "./pages/profile/CardManager";
// import KycNew from "./pages/profile/KycNew";
// import KycLvl2 from "./pages/profile/KycLvl2";
// import KycLvl3 from "./pages/profile/KycLvl3";
import TransactionHistoryMobile from "./pages/profile/TransactionHistoryMobile";
import VipWallet from "./pages/assets/VipWallet";
import VipHistory from "./pages/assets/VipHistory";

export function RoutesMap(props) {
  const { state, dispatch } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (state.isLoggedIn === true) {
      fetch(
        createApiAddress("api.user_info"), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getStorage(ItemNames.TOKEN)}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === 1) {
            dispatch({ type: "set:profile", profile: data.user });
          } else {
            dispatch({ type: "Logout" });
          }
        })
        .catch((error) => dispatch({ type: "Logout" }));
    }
  }, [state.isLoggedIn, dispatch]);

  useEffect(() => {
    const getCurrencyImages = async () => {
      const response = await fetch(
        createApiAddress("api.currency_images")
      );
      const images = await response.json();
      if (response.status === 503) {
        navigate('/maintenance')
        return;
      } else {
        dispatch({ type: "load:currency:image", images: images });
      }
    };

    getCurrencyImages();
  }, [dispatch]);

  useEffect(() => {
    const getCurrencyInfo = async () => {
      const response = await fetch(
        createApiAddress("api.CURRENCIES_INFO")
      );
      const data = await response.json();
      if (response.status === 503) {
        navigate('/maintenance')
        return;
      } else {
        dispatch({ type: "load:currency:details", currencies: data.data });
      }
    };

    getCurrencyInfo();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dispatch]);

  useEffect(() => {
    if (state.currency.details["USDT"] !== undefined) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [state.currency.details]);

  const NeedAuthentication = ({ checkAuthFunc, redirectPath = "/login" }) => {
    if (!checkAuthFunc()) {
      return <Navigate to={redirectPath} replace />;
    }
    return <Outlet />;
  };
  return (
    <>
      {state.isOpenModal && (
        <ModalGlobal
          content={state.modalContent}
          closeModal={() => dispatch({ type: "toggle_modal", content: "" })}
        />
      )}
      {state.isOpenDialog && (
        <InputDialogGlobal
          closeDialog={() => dispatch({ type: "open_dialog", content: "" })}
        />
      )}
      {state.isOpenDialog2 && (
        <InputDialogGlobal2
          closeDialog2={() => dispatch({ type: "open_dialog2", content: "" })}
        />
      )}
      {state.isOpenSnack && (
        <SnackbarGlobal
          closeSnack={() => dispatch({ type: "open_snack", snackMessage: "" })}
        />
      )}
      <>
        {isLoading === true ? (
          <div className="loading container-fluid">
            <div className="row justify-content-center">
              <div className="col loader">
                <Loader />
              </div>
            </div>
          </div>
        ) : (
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="mhome" element={<HomeMobile />} />
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="about" element={<Aboutus />} />
              <Route path="announcement" element={<Announcement />} />
              <Route path="termsofuse" element={<TermsOfUse />} />
              <Route path="contact" element={<ContactUs />} />
              <Route path="fee" element={<FeePage />} />
              <Route path="learning" element={<Learning />} />
              <Route path="career" element={<Career />} />
              <Route path="faqm" element={<HomeQuestion />} />
              <Route path="prizes" element={<PrizePage />} />
              <Route path="logoutuser" element={<RedirectLogout />} />
              <Route path="maintenance" element={<Maintenance />} />
              <Route path="cardmanager" element={<CardManager />} />
              <Route path="mobilechart/:tradePair" element={<TradeMainChart />} />
              <Route path="*" element={<P404 />} />
              <Route
                path="trade-chart-mobile/:token"
                element={<Mobile_trade_chart />}
              />
              <Route
                path="instant-chart/:token"
                element={<MobileInstantChart />}
              />
              <Route path="trade/:tradePair" element={<Trade />} />
              <Route path="reset_password" element={<ResetPassword />} />
              <Route
                path="withdrawconfirmation"
                element={<WithdrawConfirmation />}
              />
              <Route
                path="dm_verify"
                element={<DepositMoneyConfirmation />}
              />
              <Route path="market" element={<Market />} />
              {/* Auth Pages Below Here */}
              <Route
                element={<NeedAuthentication checkAuthFunc={isLoggedIn} />}
              >
                <Route path="instantbuysell" element={<InstantBuySell />} />
                <Route path="withdraw" element={<Withdraw />} />
                <Route path="deposit" element={<Deposit />} />
                <Route path="assets" element={<Assets />} />
                <Route path="profile" element={<Profile />} />
                <Route path="commission" element={<CommissionPage />} />
                <Route path="transfer" element={<Transfer />} />
                <Route path="staking" element={<Staking />} />
                <Route path="kyc" element={<Kyc />} />
                {/* <Route path="kycnew" element={<KycNew />} />
                <Route path="kyclvl2" element={<KycLvl2 />} />
                <Route path="kyclvl3" element={<KycLvl3 />} /> */}
                <Route path="securitym" element={<PSecurityMobile />} />
                <Route path="support" element={<Support />} />
                <Route path="conversation" element={<ConversationPage />} />
                <Route path="checkuser" element={<RedirectPage />} />
                <Route path="transactionhistory" element={<TransactionHistoryMobile />} />
                <Route path="vipwallet" element={<VipWallet />} />
                <Route path="viphistory" element={<VipHistory />} />
              </Route>
            </Route>
          </Routes>
        )}
      </>
    </>
  );
}
