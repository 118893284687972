import React from "react";
import { useParams } from "react-router-dom";
import { getCurrencyDecimals, toFixed } from "../../../../utils/math";
import { useOrderContext } from "../../../../contexts/ClickedOrders";
import { ItemNames, getStorage } from "../../../../utils/RSS";

function UserOrder({ order, side, percentage }) {
  const { tradePair } = useParams();
  const [from, to] = tradePair.split("_");
  const { setClickedOrderData } = useOrderContext();
  const storedTheme = getStorage(ItemNames.THEMES);

  const handleOrderClick = () => {
    const clickedPrice = order.price;
    setClickedOrderData({ price: clickedPrice });
  };

  const per = parseInt(percentage / 7) * 9

  const backgroundColorStyle = () => {
    if (storedTheme === 'dark-theme') {
      const style = {
        backgroundImage: `linear-gradient(
        to left, 
        ${side === "buys" ? '#1AACAC30' : '#f93e5c30'} ${per}%,
        transparent ${per}%
        )`,
      }
      return style
    } else {
      const style = {
        backgroundImage: `linear-gradient(
          to left, 
          ${side === "buys" ? '#1AACAC30' : '#f93e5c30'} ${per}%,
          transparent ${per}%
          )`,
      }
      return style
    }
  };

  return (
    <div className="row" style={backgroundColorStyle()}>
      <div className="col d-flex justify-content-between"
        onClick={handleOrderClick}>
        <div
          className="order-total-in-mobile"
        >
          {toFixed(order.price * order.amount, getCurrencyDecimals(from, "total"))}
        </div>
        <div className="order-amount-in-mobile">
          {toFixed(order.amount, getCurrencyDecimals(from, "amount"))}
        </div>
        <div
          className={
            "order-price-in-mobile " +
            (side === "sell" ? "textdanger" : "textsuccess")
          }
        >
          {toFixed(order.price, getCurrencyDecimals(from, "price"))}
        </div>
      </div>
    </div>
  );
}

export default UserOrder;
